import { useAuth } from '../hooks/auth'

const ERROR = 'Error'

export const useFetch = <TData, TVariables>(
  query: string
  // eslint-disable-next-line no-unused-vars
): ((variables?: TVariables) => Promise<TData>) => {
  const { accessToken } = useAuth()
  return async (variables?: TVariables) => {
    // environment variable
    const res = await fetch(process.env.NEXT_PUBLIC_GRAPHQL_API_ENDPOINT, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...(accessToken
          ? { Authorization: `Bearer ${accessToken}` }
          : undefined),
      },
      body: JSON.stringify({
        query,
        variables,
      }),
    })

    const json = await res.json()

    if (json.errors) {
      const { message } = json.errors[0] || ERROR
      throw new Error(message)
    }

    return json.data
  }
}
