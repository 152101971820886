import { Dispatch, SetStateAction, useState } from 'react'
import { Dropdown } from '../../blocks/Dropdown'
import { useAuth } from '../../../hooks/auth'
import { Button } from '../../bits/Button'
import useLanguagePreference from '../../../hooks/useLanguagePreference'
import useTranslation from 'next-translate/useTranslation'
import Link from 'next/link'
import { VARIANT } from '@goodsidehealth/component-library'
import { Translate } from 'next-translate'
import { HeaderLogo } from '../../blocks/HeaderLogo'

export const AUTH_PAGE = 'auth'
export const REGISTER_PAGE = 'register'
export const RECONSENT_PAGE = 'reconsent'
export const WELCOME_PAGE = 'welcome'

export type HeaderProps = {
  page?: string
}

type EndSessionButtonProps = {
  setCollapsed?: Dispatch<SetStateAction<boolean>>
  t: Translate
  logout: () => void
}

const EndSessionButton = ({
  logout,
  t,
  setCollapsed,
}: EndSessionButtonProps) => (
  <Button
    variant={VARIANT.LIGHT}
    className="p-0"
    onClick={() => {
      setCollapsed && setCollapsed(true)
      logout()
    }}
  >
    {t('End Session')}
  </Button>
)

export const Header = ({ page = AUTH_PAGE }: HeaderProps) => {
  const { logout, isAuthenticated } = useAuth()
  const { t } = useTranslation('common')

  const [languagePreference, setLanguagePreference] = useLanguagePreference()
  const [isCollapsed, setCollapsed] = useState(true)

  return (
    <header className="flex px-6 py-3 relative items-center justify-between bg-white">
      {!isCollapsed ? (
        <div className="absolute inset-x-0 bg-white border top-14 p-4 text-center text-white z-10">
          <EndSessionButton logout={logout} t={t} setCollapsed={setCollapsed} />
        </div>
      ) : null}

      {page === WELCOME_PAGE ? (
        <HeaderLogo type="logo-heading" />
      ) : (
        <Link href="/family" passHref>
          <a id="home-nav-logo">
            <HeaderLogo type="logo-duck" />
          </a>
        </Link>
      )}

      <div className="flex md:gap-6 gap-2 items-center">
        {isAuthenticated ? (
          <>
            <Button
              id="user-hamburger-button"
              className="bg-transparent block sm:hidden p-0"
              variant={VARIANT.LIGHT}
              onClick={() => setCollapsed(!isCollapsed)}
            >
              <img src="/Menu.png" className="w-7" alt="Menu" />
            </Button>
            <div className="hidden sm:block">
              <EndSessionButton logout={logout} t={t} />
            </div>
          </>
        ) : null}

        <Dropdown
          id="language-dropdown"
          selectedOption={languagePreference === 'en' ? 'English' : 'Español'}
          changeHandler={(e) => {
            const locale = e.target.value === 'English' ? 'en' : 'es'
            setLanguagePreference(locale, { days: 365 })
          }}
          options={['English', 'Español']}
          className={'w-28 rounded border-pondGray-darkest'}
        />
      </div>
    </header>
  )
}
