import classNames from 'classnames'
import { ChangeEvent } from 'react'
import { Label } from '../../bits/Label'

export type InsurancePlanItem = {
  insurance_id: string
  insurance_name: string
  carrier_code: string
}

export type InsuranceItem = {
  carrier_code: string
  carrier_name: string
  ins_plans: InsurancePlanItem[]
}

export type DropdownItem = {
  displayValue: string
  value: string
}

type OptionType = readonly string[] | string[] | DropdownItem[]

export type DropdownProps = {
  label?: string
  id?: 'language-dropdown' | string
  options: OptionType
  selectedOption?: string | DropdownItem
  // eslint-disable-next-line
  changeHandler: (e: ChangeEvent<HTMLSelectElement>) => void
  name?: string
  rawErrors?: string[]
  className?: string
  disabled?: boolean
  required?: boolean
  placeholder?: string
}

export const Dropdown = ({
  label,
  id,
  options,
  changeHandler,
  name,
  rawErrors,
  selectedOption,
  className,
  disabled,
  required,
  placeholder,
}: DropdownProps) => {
  let optionDisplayValues: string[]
  let optionValues: string[]
  if ((options as unknown[]).every((val) => typeof val == 'string')) {
    optionDisplayValues = options as string[]
    optionValues = options as string[]
  } else {
    optionDisplayValues = (options as DropdownItem[]).map((i) => i.displayValue)
    optionValues = (options as DropdownItem[]).map((i) => i.value)
  }

  const preppedOptions = optionDisplayValues.map((display, i) => [
    display,
    optionValues[i],
  ])

  return (
    <div>
      {label && (
        <Label id={id} required={required}>
          {label}
        </Label>
      )}
      <select
        id={id}
        name={name}
        value={
          (selectedOption as DropdownItem)?.value ?? (selectedOption as string)
        }
        className={classNames(
          'h-10 bg-white bg-arrowDown border px-3 pr-9 appearance-none bg-no-repeat bg-20 bg-center-right-15 text-sm font-bold border-pondGray-darkest',
          { 'border-danger': rawErrors?.length },
          className
        )}
        onChange={changeHandler}
        disabled={disabled}
      >
        {placeholder && <option value="">{placeholder}</option>}
        {preppedOptions.map(([option, val], i) => (
          <option key={i} value={val}>
            {option}
          </option>
        ))}
      </select>
      {rawErrors?.map((error) => (
        <p className="mt-2 text-danger" key={error}>
          {error}
        </p>
      ))}
    </div>
  )
}
export default Dropdown
