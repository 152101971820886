import { useQuery, useMutation, UseQueryOptions, UseMutationOptions } from 'react-query';
import { useFetch } from '../../fetch';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  _int4: any;
  bigint: any;
  bpchar: any;
  date: any;
  json: any;
  jsonb: any;
  numeric: any;
  smallint: any;
  timestamptz: any;
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Boolean']>;
  _gt?: InputMaybe<Scalars['Boolean']>;
  _gte?: InputMaybe<Scalars['Boolean']>;
  _in?: InputMaybe<Array<Scalars['Boolean']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Boolean']>;
  _lte?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Scalars['Boolean']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']>>;
};

/** Boolean expression to compare columns of type "Float". All fields are combined with logical 'AND'. */
export type Float_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Float']>;
  _gt?: InputMaybe<Scalars['Float']>;
  _gte?: InputMaybe<Scalars['Float']>;
  _in?: InputMaybe<Array<Scalars['Float']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Float']>;
  _lte?: InputMaybe<Scalars['Float']>;
  _neq?: InputMaybe<Scalars['Float']>;
  _nin?: InputMaybe<Array<Scalars['Float']>>;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Int']>;
  _gt?: InputMaybe<Scalars['Int']>;
  _gte?: InputMaybe<Scalars['Int']>;
  _in?: InputMaybe<Array<Scalars['Int']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Int']>;
  _lte?: InputMaybe<Scalars['Int']>;
  _neq?: InputMaybe<Scalars['Int']>;
  _nin?: InputMaybe<Array<Scalars['Int']>>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['String']>;
  _gt?: InputMaybe<Scalars['String']>;
  _gte?: InputMaybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']>;
  _in?: InputMaybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']>;
  _lt?: InputMaybe<Scalars['String']>;
  _lte?: InputMaybe<Scalars['String']>;
  _neq?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']>;
  _nin?: InputMaybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']>;
};

export type Video = {
  __typename?: 'Video';
  id: Scalars['Int'];
  videoUrl: Scalars['String'];
};

/** Boolean expression to compare columns of type "_int4". All fields are combined with logical 'AND'. */
export type _Int4_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['_int4']>;
  _gt?: InputMaybe<Scalars['_int4']>;
  _gte?: InputMaybe<Scalars['_int4']>;
  _in?: InputMaybe<Array<Scalars['_int4']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['_int4']>;
  _lte?: InputMaybe<Scalars['_int4']>;
  _neq?: InputMaybe<Scalars['_int4']>;
  _nin?: InputMaybe<Array<Scalars['_int4']>>;
};

/**
 * Many-to-many table to associate additional Provider entities to Patients
 *
 *
 * columns and relationships of "additional_care_providers"
 *
 */
export type Additional_Care_Providers = {
  __typename?: 'additional_care_providers';
  /** An object relationship */
  patient: Portal_Patients;
  patient_id: Scalars['Int'];
  /** An object relationship */
  provider: Providers;
  provider_id: Scalars['Int'];
};

/** aggregated selection of "additional_care_providers" */
export type Additional_Care_Providers_Aggregate = {
  __typename?: 'additional_care_providers_aggregate';
  aggregate?: Maybe<Additional_Care_Providers_Aggregate_Fields>;
  nodes: Array<Additional_Care_Providers>;
};

/** aggregate fields of "additional_care_providers" */
export type Additional_Care_Providers_Aggregate_Fields = {
  __typename?: 'additional_care_providers_aggregate_fields';
  avg?: Maybe<Additional_Care_Providers_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Additional_Care_Providers_Max_Fields>;
  min?: Maybe<Additional_Care_Providers_Min_Fields>;
  stddev?: Maybe<Additional_Care_Providers_Stddev_Fields>;
  stddev_pop?: Maybe<Additional_Care_Providers_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Additional_Care_Providers_Stddev_Samp_Fields>;
  sum?: Maybe<Additional_Care_Providers_Sum_Fields>;
  var_pop?: Maybe<Additional_Care_Providers_Var_Pop_Fields>;
  var_samp?: Maybe<Additional_Care_Providers_Var_Samp_Fields>;
  variance?: Maybe<Additional_Care_Providers_Variance_Fields>;
};


/** aggregate fields of "additional_care_providers" */
export type Additional_Care_Providers_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Additional_Care_Providers_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "additional_care_providers" */
export type Additional_Care_Providers_Aggregate_Order_By = {
  avg?: InputMaybe<Additional_Care_Providers_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Additional_Care_Providers_Max_Order_By>;
  min?: InputMaybe<Additional_Care_Providers_Min_Order_By>;
  stddev?: InputMaybe<Additional_Care_Providers_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Additional_Care_Providers_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Additional_Care_Providers_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Additional_Care_Providers_Sum_Order_By>;
  var_pop?: InputMaybe<Additional_Care_Providers_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Additional_Care_Providers_Var_Samp_Order_By>;
  variance?: InputMaybe<Additional_Care_Providers_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "additional_care_providers" */
export type Additional_Care_Providers_Arr_Rel_Insert_Input = {
  data: Array<Additional_Care_Providers_Insert_Input>;
  /** on conflict condition */
  on_conflict?: InputMaybe<Additional_Care_Providers_On_Conflict>;
};

/** aggregate avg on columns */
export type Additional_Care_Providers_Avg_Fields = {
  __typename?: 'additional_care_providers_avg_fields';
  patient_id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "additional_care_providers" */
export type Additional_Care_Providers_Avg_Order_By = {
  patient_id?: InputMaybe<Order_By>;
  provider_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "additional_care_providers". All fields are combined with a logical 'AND'. */
export type Additional_Care_Providers_Bool_Exp = {
  _and?: InputMaybe<Array<Additional_Care_Providers_Bool_Exp>>;
  _not?: InputMaybe<Additional_Care_Providers_Bool_Exp>;
  _or?: InputMaybe<Array<Additional_Care_Providers_Bool_Exp>>;
  patient?: InputMaybe<Portal_Patients_Bool_Exp>;
  patient_id?: InputMaybe<Int_Comparison_Exp>;
  provider?: InputMaybe<Providers_Bool_Exp>;
  provider_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "additional_care_providers" */
export enum Additional_Care_Providers_Constraint {
  /** unique or primary key constraint */
  ProviderToPatientPkey = 'provider_to_patient_pkey'
}

/** input type for incrementing numeric columns in table "additional_care_providers" */
export type Additional_Care_Providers_Inc_Input = {
  patient_id?: InputMaybe<Scalars['Int']>;
  provider_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "additional_care_providers" */
export type Additional_Care_Providers_Insert_Input = {
  patient?: InputMaybe<Portal_Patients_Obj_Rel_Insert_Input>;
  patient_id?: InputMaybe<Scalars['Int']>;
  provider?: InputMaybe<Providers_Obj_Rel_Insert_Input>;
  provider_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Additional_Care_Providers_Max_Fields = {
  __typename?: 'additional_care_providers_max_fields';
  patient_id?: Maybe<Scalars['Int']>;
  provider_id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "additional_care_providers" */
export type Additional_Care_Providers_Max_Order_By = {
  patient_id?: InputMaybe<Order_By>;
  provider_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Additional_Care_Providers_Min_Fields = {
  __typename?: 'additional_care_providers_min_fields';
  patient_id?: Maybe<Scalars['Int']>;
  provider_id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "additional_care_providers" */
export type Additional_Care_Providers_Min_Order_By = {
  patient_id?: InputMaybe<Order_By>;
  provider_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "additional_care_providers" */
export type Additional_Care_Providers_Mutation_Response = {
  __typename?: 'additional_care_providers_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Additional_Care_Providers>;
};

/** on conflict condition type for table "additional_care_providers" */
export type Additional_Care_Providers_On_Conflict = {
  constraint: Additional_Care_Providers_Constraint;
  update_columns?: Array<Additional_Care_Providers_Update_Column>;
  where?: InputMaybe<Additional_Care_Providers_Bool_Exp>;
};

/** Ordering options when selecting data from "additional_care_providers". */
export type Additional_Care_Providers_Order_By = {
  patient?: InputMaybe<Portal_Patients_Order_By>;
  patient_id?: InputMaybe<Order_By>;
  provider?: InputMaybe<Providers_Order_By>;
  provider_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: additional_care_providers */
export type Additional_Care_Providers_Pk_Columns_Input = {
  patient_id: Scalars['Int'];
  provider_id: Scalars['Int'];
};

/** select columns of table "additional_care_providers" */
export enum Additional_Care_Providers_Select_Column {
  /** column name */
  PatientId = 'patient_id',
  /** column name */
  ProviderId = 'provider_id'
}

/** input type for updating data in table "additional_care_providers" */
export type Additional_Care_Providers_Set_Input = {
  patient_id?: InputMaybe<Scalars['Int']>;
  provider_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Additional_Care_Providers_Stddev_Fields = {
  __typename?: 'additional_care_providers_stddev_fields';
  patient_id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "additional_care_providers" */
export type Additional_Care_Providers_Stddev_Order_By = {
  patient_id?: InputMaybe<Order_By>;
  provider_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Additional_Care_Providers_Stddev_Pop_Fields = {
  __typename?: 'additional_care_providers_stddev_pop_fields';
  patient_id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "additional_care_providers" */
export type Additional_Care_Providers_Stddev_Pop_Order_By = {
  patient_id?: InputMaybe<Order_By>;
  provider_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Additional_Care_Providers_Stddev_Samp_Fields = {
  __typename?: 'additional_care_providers_stddev_samp_fields';
  patient_id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "additional_care_providers" */
export type Additional_Care_Providers_Stddev_Samp_Order_By = {
  patient_id?: InputMaybe<Order_By>;
  provider_id?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Additional_Care_Providers_Sum_Fields = {
  __typename?: 'additional_care_providers_sum_fields';
  patient_id?: Maybe<Scalars['Int']>;
  provider_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "additional_care_providers" */
export type Additional_Care_Providers_Sum_Order_By = {
  patient_id?: InputMaybe<Order_By>;
  provider_id?: InputMaybe<Order_By>;
};

/** update columns of table "additional_care_providers" */
export enum Additional_Care_Providers_Update_Column {
  /** column name */
  PatientId = 'patient_id',
  /** column name */
  ProviderId = 'provider_id'
}

/** aggregate var_pop on columns */
export type Additional_Care_Providers_Var_Pop_Fields = {
  __typename?: 'additional_care_providers_var_pop_fields';
  patient_id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "additional_care_providers" */
export type Additional_Care_Providers_Var_Pop_Order_By = {
  patient_id?: InputMaybe<Order_By>;
  provider_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Additional_Care_Providers_Var_Samp_Fields = {
  __typename?: 'additional_care_providers_var_samp_fields';
  patient_id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "additional_care_providers" */
export type Additional_Care_Providers_Var_Samp_Order_By = {
  patient_id?: InputMaybe<Order_By>;
  provider_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Additional_Care_Providers_Variance_Fields = {
  __typename?: 'additional_care_providers_variance_fields';
  patient_id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "additional_care_providers" */
export type Additional_Care_Providers_Variance_Order_By = {
  patient_id?: InputMaybe<Order_By>;
  provider_id?: InputMaybe<Order_By>;
};

/**
 * User-scoped patient allergy data
 *
 *
 * columns and relationships of "allergies"
 *
 */
export type Allergies = {
  __typename?: 'allergies';
  environmental: Scalars['Boolean'];
  environmental_allergies_text?: Maybe<Scalars['String']>;
  food: Scalars['Boolean'];
  food_allergies_text?: Maybe<Scalars['String']>;
  is_deleted: Scalars['Boolean'];
  medication: Scalars['Boolean'];
  medication_allergies_text?: Maybe<Scalars['String']>;
  /** An object relationship */
  patient: Portal_Patients;
  patient_id: Scalars['Int'];
};

/** aggregated selection of "allergies" */
export type Allergies_Aggregate = {
  __typename?: 'allergies_aggregate';
  aggregate?: Maybe<Allergies_Aggregate_Fields>;
  nodes: Array<Allergies>;
};

/** aggregate fields of "allergies" */
export type Allergies_Aggregate_Fields = {
  __typename?: 'allergies_aggregate_fields';
  avg?: Maybe<Allergies_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Allergies_Max_Fields>;
  min?: Maybe<Allergies_Min_Fields>;
  stddev?: Maybe<Allergies_Stddev_Fields>;
  stddev_pop?: Maybe<Allergies_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Allergies_Stddev_Samp_Fields>;
  sum?: Maybe<Allergies_Sum_Fields>;
  var_pop?: Maybe<Allergies_Var_Pop_Fields>;
  var_samp?: Maybe<Allergies_Var_Samp_Fields>;
  variance?: Maybe<Allergies_Variance_Fields>;
};


/** aggregate fields of "allergies" */
export type Allergies_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Allergies_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Allergies_Avg_Fields = {
  __typename?: 'allergies_avg_fields';
  patient_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "allergies". All fields are combined with a logical 'AND'. */
export type Allergies_Bool_Exp = {
  _and?: InputMaybe<Array<Allergies_Bool_Exp>>;
  _not?: InputMaybe<Allergies_Bool_Exp>;
  _or?: InputMaybe<Array<Allergies_Bool_Exp>>;
  environmental?: InputMaybe<Boolean_Comparison_Exp>;
  environmental_allergies_text?: InputMaybe<String_Comparison_Exp>;
  food?: InputMaybe<Boolean_Comparison_Exp>;
  food_allergies_text?: InputMaybe<String_Comparison_Exp>;
  is_deleted?: InputMaybe<Boolean_Comparison_Exp>;
  medication?: InputMaybe<Boolean_Comparison_Exp>;
  medication_allergies_text?: InputMaybe<String_Comparison_Exp>;
  patient?: InputMaybe<Portal_Patients_Bool_Exp>;
  patient_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "allergies" */
export enum Allergies_Constraint {
  /** unique or primary key constraint */
  AllergiesPkey = 'allergies_pkey'
}

/** input type for incrementing numeric columns in table "allergies" */
export type Allergies_Inc_Input = {
  patient_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "allergies" */
export type Allergies_Insert_Input = {
  environmental?: InputMaybe<Scalars['Boolean']>;
  environmental_allergies_text?: InputMaybe<Scalars['String']>;
  food?: InputMaybe<Scalars['Boolean']>;
  food_allergies_text?: InputMaybe<Scalars['String']>;
  is_deleted?: InputMaybe<Scalars['Boolean']>;
  medication?: InputMaybe<Scalars['Boolean']>;
  medication_allergies_text?: InputMaybe<Scalars['String']>;
  patient?: InputMaybe<Portal_Patients_Obj_Rel_Insert_Input>;
  patient_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Allergies_Max_Fields = {
  __typename?: 'allergies_max_fields';
  environmental_allergies_text?: Maybe<Scalars['String']>;
  food_allergies_text?: Maybe<Scalars['String']>;
  medication_allergies_text?: Maybe<Scalars['String']>;
  patient_id?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Allergies_Min_Fields = {
  __typename?: 'allergies_min_fields';
  environmental_allergies_text?: Maybe<Scalars['String']>;
  food_allergies_text?: Maybe<Scalars['String']>;
  medication_allergies_text?: Maybe<Scalars['String']>;
  patient_id?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "allergies" */
export type Allergies_Mutation_Response = {
  __typename?: 'allergies_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Allergies>;
};

/** input type for inserting object relation for remote table "allergies" */
export type Allergies_Obj_Rel_Insert_Input = {
  data: Allergies_Insert_Input;
  /** on conflict condition */
  on_conflict?: InputMaybe<Allergies_On_Conflict>;
};

/** on conflict condition type for table "allergies" */
export type Allergies_On_Conflict = {
  constraint: Allergies_Constraint;
  update_columns?: Array<Allergies_Update_Column>;
  where?: InputMaybe<Allergies_Bool_Exp>;
};

/** Ordering options when selecting data from "allergies". */
export type Allergies_Order_By = {
  environmental?: InputMaybe<Order_By>;
  environmental_allergies_text?: InputMaybe<Order_By>;
  food?: InputMaybe<Order_By>;
  food_allergies_text?: InputMaybe<Order_By>;
  is_deleted?: InputMaybe<Order_By>;
  medication?: InputMaybe<Order_By>;
  medication_allergies_text?: InputMaybe<Order_By>;
  patient?: InputMaybe<Portal_Patients_Order_By>;
  patient_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: allergies */
export type Allergies_Pk_Columns_Input = {
  patient_id: Scalars['Int'];
};

/** select columns of table "allergies" */
export enum Allergies_Select_Column {
  /** column name */
  Environmental = 'environmental',
  /** column name */
  EnvironmentalAllergiesText = 'environmental_allergies_text',
  /** column name */
  Food = 'food',
  /** column name */
  FoodAllergiesText = 'food_allergies_text',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  Medication = 'medication',
  /** column name */
  MedicationAllergiesText = 'medication_allergies_text',
  /** column name */
  PatientId = 'patient_id'
}

/** input type for updating data in table "allergies" */
export type Allergies_Set_Input = {
  environmental?: InputMaybe<Scalars['Boolean']>;
  environmental_allergies_text?: InputMaybe<Scalars['String']>;
  food?: InputMaybe<Scalars['Boolean']>;
  food_allergies_text?: InputMaybe<Scalars['String']>;
  is_deleted?: InputMaybe<Scalars['Boolean']>;
  medication?: InputMaybe<Scalars['Boolean']>;
  medication_allergies_text?: InputMaybe<Scalars['String']>;
  patient_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Allergies_Stddev_Fields = {
  __typename?: 'allergies_stddev_fields';
  patient_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Allergies_Stddev_Pop_Fields = {
  __typename?: 'allergies_stddev_pop_fields';
  patient_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Allergies_Stddev_Samp_Fields = {
  __typename?: 'allergies_stddev_samp_fields';
  patient_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Allergies_Sum_Fields = {
  __typename?: 'allergies_sum_fields';
  patient_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "allergies" */
export enum Allergies_Update_Column {
  /** column name */
  Environmental = 'environmental',
  /** column name */
  EnvironmentalAllergiesText = 'environmental_allergies_text',
  /** column name */
  Food = 'food',
  /** column name */
  FoodAllergiesText = 'food_allergies_text',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  Medication = 'medication',
  /** column name */
  MedicationAllergiesText = 'medication_allergies_text',
  /** column name */
  PatientId = 'patient_id'
}

/** aggregate var_pop on columns */
export type Allergies_Var_Pop_Fields = {
  __typename?: 'allergies_var_pop_fields';
  patient_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Allergies_Var_Samp_Fields = {
  __typename?: 'allergies_var_samp_fields';
  patient_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Allergies_Variance_Fields = {
  __typename?: 'allergies_variance_fields';
  patient_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export type Bigint_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['bigint']>;
  _gt?: InputMaybe<Scalars['bigint']>;
  _gte?: InputMaybe<Scalars['bigint']>;
  _in?: InputMaybe<Array<Scalars['bigint']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['bigint']>;
  _lte?: InputMaybe<Scalars['bigint']>;
  _neq?: InputMaybe<Scalars['bigint']>;
  _nin?: InputMaybe<Array<Scalars['bigint']>>;
};

/** Boolean expression to compare columns of type "bpchar". All fields are combined with logical 'AND'. */
export type Bpchar_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['bpchar']>;
  _gt?: InputMaybe<Scalars['bpchar']>;
  _gte?: InputMaybe<Scalars['bpchar']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['bpchar']>;
  _in?: InputMaybe<Array<Scalars['bpchar']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['bpchar']>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['bpchar']>;
  _lt?: InputMaybe<Scalars['bpchar']>;
  _lte?: InputMaybe<Scalars['bpchar']>;
  _neq?: InputMaybe<Scalars['bpchar']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['bpchar']>;
  _nin?: InputMaybe<Array<Scalars['bpchar']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['bpchar']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['bpchar']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['bpchar']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['bpchar']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['bpchar']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['bpchar']>;
};

/** columns and relationships of "clearance_notes" */
export type Clearance_Notes = {
  __typename?: 'clearance_notes';
  clearance_status: Scalars['String'];
  created_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['Int'];
  is_deleted?: Maybe<Scalars['Boolean']>;
  not_cleared_details?: Maybe<Scalars['String']>;
  not_cleared_reason?: Maybe<Scalars['String']>;
  pre_clearance_evaluation?: Maybe<Scalars['String']>;
  provider_credentials: Scalars['bpchar'];
  provider_first_name: Scalars['String'];
  provider_id: Scalars['Int'];
  provider_last_name: Scalars['String'];
  provider_recommendation?: Maybe<Scalars['String']>;
  provider_signature: Scalars['String'];
  provider_signature_date_time: Scalars['String'];
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  visit: Visits;
  visit_id: Scalars['Int'];
};

/** aggregated selection of "clearance_notes" */
export type Clearance_Notes_Aggregate = {
  __typename?: 'clearance_notes_aggregate';
  aggregate?: Maybe<Clearance_Notes_Aggregate_Fields>;
  nodes: Array<Clearance_Notes>;
};

/** aggregate fields of "clearance_notes" */
export type Clearance_Notes_Aggregate_Fields = {
  __typename?: 'clearance_notes_aggregate_fields';
  avg?: Maybe<Clearance_Notes_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Clearance_Notes_Max_Fields>;
  min?: Maybe<Clearance_Notes_Min_Fields>;
  stddev?: Maybe<Clearance_Notes_Stddev_Fields>;
  stddev_pop?: Maybe<Clearance_Notes_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Clearance_Notes_Stddev_Samp_Fields>;
  sum?: Maybe<Clearance_Notes_Sum_Fields>;
  var_pop?: Maybe<Clearance_Notes_Var_Pop_Fields>;
  var_samp?: Maybe<Clearance_Notes_Var_Samp_Fields>;
  variance?: Maybe<Clearance_Notes_Variance_Fields>;
};


/** aggregate fields of "clearance_notes" */
export type Clearance_Notes_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Clearance_Notes_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Clearance_Notes_Avg_Fields = {
  __typename?: 'clearance_notes_avg_fields';
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
  visit_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "clearance_notes". All fields are combined with a logical 'AND'. */
export type Clearance_Notes_Bool_Exp = {
  _and?: InputMaybe<Array<Clearance_Notes_Bool_Exp>>;
  _not?: InputMaybe<Clearance_Notes_Bool_Exp>;
  _or?: InputMaybe<Array<Clearance_Notes_Bool_Exp>>;
  clearance_status?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  is_deleted?: InputMaybe<Boolean_Comparison_Exp>;
  not_cleared_details?: InputMaybe<String_Comparison_Exp>;
  not_cleared_reason?: InputMaybe<String_Comparison_Exp>;
  pre_clearance_evaluation?: InputMaybe<String_Comparison_Exp>;
  provider_credentials?: InputMaybe<Bpchar_Comparison_Exp>;
  provider_first_name?: InputMaybe<String_Comparison_Exp>;
  provider_id?: InputMaybe<Int_Comparison_Exp>;
  provider_last_name?: InputMaybe<String_Comparison_Exp>;
  provider_recommendation?: InputMaybe<String_Comparison_Exp>;
  provider_signature?: InputMaybe<String_Comparison_Exp>;
  provider_signature_date_time?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  visit?: InputMaybe<Visits_Bool_Exp>;
  visit_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "clearance_notes" */
export enum Clearance_Notes_Constraint {
  /** unique or primary key constraint */
  ClearanceNotesPkey = 'clearance_notes_pkey',
  /** unique or primary key constraint */
  ClearanceNotesVisitIdKey = 'clearance_notes_visit_id_key'
}

/** input type for incrementing numeric columns in table "clearance_notes" */
export type Clearance_Notes_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  provider_id?: InputMaybe<Scalars['Int']>;
  visit_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "clearance_notes" */
export type Clearance_Notes_Insert_Input = {
  clearance_status?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  is_deleted?: InputMaybe<Scalars['Boolean']>;
  not_cleared_details?: InputMaybe<Scalars['String']>;
  not_cleared_reason?: InputMaybe<Scalars['String']>;
  pre_clearance_evaluation?: InputMaybe<Scalars['String']>;
  provider_credentials?: InputMaybe<Scalars['bpchar']>;
  provider_first_name?: InputMaybe<Scalars['String']>;
  provider_id?: InputMaybe<Scalars['Int']>;
  provider_last_name?: InputMaybe<Scalars['String']>;
  provider_recommendation?: InputMaybe<Scalars['String']>;
  provider_signature?: InputMaybe<Scalars['String']>;
  provider_signature_date_time?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  visit?: InputMaybe<Visits_Obj_Rel_Insert_Input>;
  visit_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Clearance_Notes_Max_Fields = {
  __typename?: 'clearance_notes_max_fields';
  clearance_status?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  not_cleared_details?: Maybe<Scalars['String']>;
  not_cleared_reason?: Maybe<Scalars['String']>;
  pre_clearance_evaluation?: Maybe<Scalars['String']>;
  provider_credentials?: Maybe<Scalars['bpchar']>;
  provider_first_name?: Maybe<Scalars['String']>;
  provider_id?: Maybe<Scalars['Int']>;
  provider_last_name?: Maybe<Scalars['String']>;
  provider_recommendation?: Maybe<Scalars['String']>;
  provider_signature?: Maybe<Scalars['String']>;
  provider_signature_date_time?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  visit_id?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Clearance_Notes_Min_Fields = {
  __typename?: 'clearance_notes_min_fields';
  clearance_status?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  not_cleared_details?: Maybe<Scalars['String']>;
  not_cleared_reason?: Maybe<Scalars['String']>;
  pre_clearance_evaluation?: Maybe<Scalars['String']>;
  provider_credentials?: Maybe<Scalars['bpchar']>;
  provider_first_name?: Maybe<Scalars['String']>;
  provider_id?: Maybe<Scalars['Int']>;
  provider_last_name?: Maybe<Scalars['String']>;
  provider_recommendation?: Maybe<Scalars['String']>;
  provider_signature?: Maybe<Scalars['String']>;
  provider_signature_date_time?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  visit_id?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "clearance_notes" */
export type Clearance_Notes_Mutation_Response = {
  __typename?: 'clearance_notes_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Clearance_Notes>;
};

/** input type for inserting object relation for remote table "clearance_notes" */
export type Clearance_Notes_Obj_Rel_Insert_Input = {
  data: Clearance_Notes_Insert_Input;
  /** on conflict condition */
  on_conflict?: InputMaybe<Clearance_Notes_On_Conflict>;
};

/** on conflict condition type for table "clearance_notes" */
export type Clearance_Notes_On_Conflict = {
  constraint: Clearance_Notes_Constraint;
  update_columns?: Array<Clearance_Notes_Update_Column>;
  where?: InputMaybe<Clearance_Notes_Bool_Exp>;
};

/** Ordering options when selecting data from "clearance_notes". */
export type Clearance_Notes_Order_By = {
  clearance_status?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_deleted?: InputMaybe<Order_By>;
  not_cleared_details?: InputMaybe<Order_By>;
  not_cleared_reason?: InputMaybe<Order_By>;
  pre_clearance_evaluation?: InputMaybe<Order_By>;
  provider_credentials?: InputMaybe<Order_By>;
  provider_first_name?: InputMaybe<Order_By>;
  provider_id?: InputMaybe<Order_By>;
  provider_last_name?: InputMaybe<Order_By>;
  provider_recommendation?: InputMaybe<Order_By>;
  provider_signature?: InputMaybe<Order_By>;
  provider_signature_date_time?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  visit?: InputMaybe<Visits_Order_By>;
  visit_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: clearance_notes */
export type Clearance_Notes_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "clearance_notes" */
export enum Clearance_Notes_Select_Column {
  /** column name */
  ClearanceStatus = 'clearance_status',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  NotClearedDetails = 'not_cleared_details',
  /** column name */
  NotClearedReason = 'not_cleared_reason',
  /** column name */
  PreClearanceEvaluation = 'pre_clearance_evaluation',
  /** column name */
  ProviderCredentials = 'provider_credentials',
  /** column name */
  ProviderFirstName = 'provider_first_name',
  /** column name */
  ProviderId = 'provider_id',
  /** column name */
  ProviderLastName = 'provider_last_name',
  /** column name */
  ProviderRecommendation = 'provider_recommendation',
  /** column name */
  ProviderSignature = 'provider_signature',
  /** column name */
  ProviderSignatureDateTime = 'provider_signature_date_time',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VisitId = 'visit_id'
}

/** input type for updating data in table "clearance_notes" */
export type Clearance_Notes_Set_Input = {
  clearance_status?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  is_deleted?: InputMaybe<Scalars['Boolean']>;
  not_cleared_details?: InputMaybe<Scalars['String']>;
  not_cleared_reason?: InputMaybe<Scalars['String']>;
  pre_clearance_evaluation?: InputMaybe<Scalars['String']>;
  provider_credentials?: InputMaybe<Scalars['bpchar']>;
  provider_first_name?: InputMaybe<Scalars['String']>;
  provider_id?: InputMaybe<Scalars['Int']>;
  provider_last_name?: InputMaybe<Scalars['String']>;
  provider_recommendation?: InputMaybe<Scalars['String']>;
  provider_signature?: InputMaybe<Scalars['String']>;
  provider_signature_date_time?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  visit_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Clearance_Notes_Stddev_Fields = {
  __typename?: 'clearance_notes_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
  visit_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Clearance_Notes_Stddev_Pop_Fields = {
  __typename?: 'clearance_notes_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
  visit_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Clearance_Notes_Stddev_Samp_Fields = {
  __typename?: 'clearance_notes_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
  visit_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Clearance_Notes_Sum_Fields = {
  __typename?: 'clearance_notes_sum_fields';
  id?: Maybe<Scalars['Int']>;
  provider_id?: Maybe<Scalars['Int']>;
  visit_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "clearance_notes" */
export enum Clearance_Notes_Update_Column {
  /** column name */
  ClearanceStatus = 'clearance_status',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  NotClearedDetails = 'not_cleared_details',
  /** column name */
  NotClearedReason = 'not_cleared_reason',
  /** column name */
  PreClearanceEvaluation = 'pre_clearance_evaluation',
  /** column name */
  ProviderCredentials = 'provider_credentials',
  /** column name */
  ProviderFirstName = 'provider_first_name',
  /** column name */
  ProviderId = 'provider_id',
  /** column name */
  ProviderLastName = 'provider_last_name',
  /** column name */
  ProviderRecommendation = 'provider_recommendation',
  /** column name */
  ProviderSignature = 'provider_signature',
  /** column name */
  ProviderSignatureDateTime = 'provider_signature_date_time',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VisitId = 'visit_id'
}

/** aggregate var_pop on columns */
export type Clearance_Notes_Var_Pop_Fields = {
  __typename?: 'clearance_notes_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
  visit_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Clearance_Notes_Var_Samp_Fields = {
  __typename?: 'clearance_notes_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
  visit_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Clearance_Notes_Variance_Fields = {
  __typename?: 'clearance_notes_variance_fields';
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
  visit_id?: Maybe<Scalars['Float']>;
};

/**
 * User-scope based Person Contacts to be assigned to Patients
 *
 *
 * columns and relationships of "contacts"
 *
 */
export type Contacts = {
  __typename?: 'contacts';
  birth_sex: Scalars['String'];
  can_access_billing_information: Scalars['Boolean'];
  can_access_medical_information: Scalars['Boolean'];
  city?: Maybe<Scalars['String']>;
  /** An array relationship */
  contact_patients: Array<Contacts_To_Patients>;
  /** An aggregate relationship */
  contact_patients_aggregate: Contacts_To_Patients_Aggregate;
  date_of_birth: Scalars['String'];
  email_address?: Maybe<Scalars['String']>;
  first_name: Scalars['String'];
  id: Scalars['Int'];
  is_billing?: Maybe<Scalars['Boolean']>;
  is_deleted: Scalars['Boolean'];
  is_emergency?: Maybe<Scalars['Boolean']>;
  is_policy_holder?: Maybe<Scalars['Boolean']>;
  is_primary?: Maybe<Scalars['Boolean']>;
  last_name: Scalars['String'];
  marital_status?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  /** An array relationship */
  primary_guardian_patients: Array<Portal_Patients>;
  /** An aggregate relationship */
  primary_guardian_patients_aggregate: Portal_Patients_Aggregate;
  state?: Maybe<Scalars['String']>;
  street_address_1?: Maybe<Scalars['String']>;
  street_address_2?: Maybe<Scalars['String']>;
  user_id: Scalars['String'];
  zip_code?: Maybe<Scalars['String']>;
};


/**
 * User-scope based Person Contacts to be assigned to Patients
 *
 *
 * columns and relationships of "contacts"
 *
 */
export type ContactsContact_PatientsArgs = {
  distinct_on?: InputMaybe<Array<Contacts_To_Patients_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contacts_To_Patients_Order_By>>;
  where?: InputMaybe<Contacts_To_Patients_Bool_Exp>;
};


/**
 * User-scope based Person Contacts to be assigned to Patients
 *
 *
 * columns and relationships of "contacts"
 *
 */
export type ContactsContact_Patients_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contacts_To_Patients_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contacts_To_Patients_Order_By>>;
  where?: InputMaybe<Contacts_To_Patients_Bool_Exp>;
};


/**
 * User-scope based Person Contacts to be assigned to Patients
 *
 *
 * columns and relationships of "contacts"
 *
 */
export type ContactsPrimary_Guardian_PatientsArgs = {
  distinct_on?: InputMaybe<Array<Portal_Patients_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Portal_Patients_Order_By>>;
  where?: InputMaybe<Portal_Patients_Bool_Exp>;
};


/**
 * User-scope based Person Contacts to be assigned to Patients
 *
 *
 * columns and relationships of "contacts"
 *
 */
export type ContactsPrimary_Guardian_Patients_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Portal_Patients_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Portal_Patients_Order_By>>;
  where?: InputMaybe<Portal_Patients_Bool_Exp>;
};

/** aggregated selection of "contacts" */
export type Contacts_Aggregate = {
  __typename?: 'contacts_aggregate';
  aggregate?: Maybe<Contacts_Aggregate_Fields>;
  nodes: Array<Contacts>;
};

/** aggregate fields of "contacts" */
export type Contacts_Aggregate_Fields = {
  __typename?: 'contacts_aggregate_fields';
  avg?: Maybe<Contacts_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Contacts_Max_Fields>;
  min?: Maybe<Contacts_Min_Fields>;
  stddev?: Maybe<Contacts_Stddev_Fields>;
  stddev_pop?: Maybe<Contacts_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Contacts_Stddev_Samp_Fields>;
  sum?: Maybe<Contacts_Sum_Fields>;
  var_pop?: Maybe<Contacts_Var_Pop_Fields>;
  var_samp?: Maybe<Contacts_Var_Samp_Fields>;
  variance?: Maybe<Contacts_Variance_Fields>;
};


/** aggregate fields of "contacts" */
export type Contacts_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Contacts_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Contacts_Avg_Fields = {
  __typename?: 'contacts_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "contacts". All fields are combined with a logical 'AND'. */
export type Contacts_Bool_Exp = {
  _and?: InputMaybe<Array<Contacts_Bool_Exp>>;
  _not?: InputMaybe<Contacts_Bool_Exp>;
  _or?: InputMaybe<Array<Contacts_Bool_Exp>>;
  birth_sex?: InputMaybe<String_Comparison_Exp>;
  can_access_billing_information?: InputMaybe<Boolean_Comparison_Exp>;
  can_access_medical_information?: InputMaybe<Boolean_Comparison_Exp>;
  city?: InputMaybe<String_Comparison_Exp>;
  contact_patients?: InputMaybe<Contacts_To_Patients_Bool_Exp>;
  date_of_birth?: InputMaybe<String_Comparison_Exp>;
  email_address?: InputMaybe<String_Comparison_Exp>;
  first_name?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  is_billing?: InputMaybe<Boolean_Comparison_Exp>;
  is_deleted?: InputMaybe<Boolean_Comparison_Exp>;
  is_emergency?: InputMaybe<Boolean_Comparison_Exp>;
  is_policy_holder?: InputMaybe<Boolean_Comparison_Exp>;
  is_primary?: InputMaybe<Boolean_Comparison_Exp>;
  last_name?: InputMaybe<String_Comparison_Exp>;
  marital_status?: InputMaybe<String_Comparison_Exp>;
  phone_number?: InputMaybe<String_Comparison_Exp>;
  primary_guardian_patients?: InputMaybe<Portal_Patients_Bool_Exp>;
  state?: InputMaybe<String_Comparison_Exp>;
  street_address_1?: InputMaybe<String_Comparison_Exp>;
  street_address_2?: InputMaybe<String_Comparison_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
  zip_code?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "contacts" */
export enum Contacts_Constraint {
  /** unique or primary key constraint */
  ContactPkey = 'contact_pkey'
}

/** input type for incrementing numeric columns in table "contacts" */
export type Contacts_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "contacts" */
export type Contacts_Insert_Input = {
  birth_sex?: InputMaybe<Scalars['String']>;
  can_access_billing_information?: InputMaybe<Scalars['Boolean']>;
  can_access_medical_information?: InputMaybe<Scalars['Boolean']>;
  city?: InputMaybe<Scalars['String']>;
  contact_patients?: InputMaybe<Contacts_To_Patients_Arr_Rel_Insert_Input>;
  date_of_birth?: InputMaybe<Scalars['String']>;
  email_address?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  is_billing?: InputMaybe<Scalars['Boolean']>;
  is_deleted?: InputMaybe<Scalars['Boolean']>;
  is_emergency?: InputMaybe<Scalars['Boolean']>;
  is_policy_holder?: InputMaybe<Scalars['Boolean']>;
  is_primary?: InputMaybe<Scalars['Boolean']>;
  last_name?: InputMaybe<Scalars['String']>;
  marital_status?: InputMaybe<Scalars['String']>;
  phone_number?: InputMaybe<Scalars['String']>;
  primary_guardian_patients?: InputMaybe<Portal_Patients_Arr_Rel_Insert_Input>;
  state?: InputMaybe<Scalars['String']>;
  street_address_1?: InputMaybe<Scalars['String']>;
  street_address_2?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['String']>;
  zip_code?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Contacts_Max_Fields = {
  __typename?: 'contacts_max_fields';
  birth_sex?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  date_of_birth?: Maybe<Scalars['String']>;
  email_address?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  last_name?: Maybe<Scalars['String']>;
  marital_status?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  street_address_1?: Maybe<Scalars['String']>;
  street_address_2?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
  zip_code?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Contacts_Min_Fields = {
  __typename?: 'contacts_min_fields';
  birth_sex?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  date_of_birth?: Maybe<Scalars['String']>;
  email_address?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  last_name?: Maybe<Scalars['String']>;
  marital_status?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  street_address_1?: Maybe<Scalars['String']>;
  street_address_2?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
  zip_code?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "contacts" */
export type Contacts_Mutation_Response = {
  __typename?: 'contacts_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Contacts>;
};

/** input type for inserting object relation for remote table "contacts" */
export type Contacts_Obj_Rel_Insert_Input = {
  data: Contacts_Insert_Input;
  /** on conflict condition */
  on_conflict?: InputMaybe<Contacts_On_Conflict>;
};

/** on conflict condition type for table "contacts" */
export type Contacts_On_Conflict = {
  constraint: Contacts_Constraint;
  update_columns?: Array<Contacts_Update_Column>;
  where?: InputMaybe<Contacts_Bool_Exp>;
};

/** Ordering options when selecting data from "contacts". */
export type Contacts_Order_By = {
  birth_sex?: InputMaybe<Order_By>;
  can_access_billing_information?: InputMaybe<Order_By>;
  can_access_medical_information?: InputMaybe<Order_By>;
  city?: InputMaybe<Order_By>;
  contact_patients_aggregate?: InputMaybe<Contacts_To_Patients_Aggregate_Order_By>;
  date_of_birth?: InputMaybe<Order_By>;
  email_address?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_billing?: InputMaybe<Order_By>;
  is_deleted?: InputMaybe<Order_By>;
  is_emergency?: InputMaybe<Order_By>;
  is_policy_holder?: InputMaybe<Order_By>;
  is_primary?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  marital_status?: InputMaybe<Order_By>;
  phone_number?: InputMaybe<Order_By>;
  primary_guardian_patients_aggregate?: InputMaybe<Portal_Patients_Aggregate_Order_By>;
  state?: InputMaybe<Order_By>;
  street_address_1?: InputMaybe<Order_By>;
  street_address_2?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  zip_code?: InputMaybe<Order_By>;
};

/** primary key columns input for table: contacts */
export type Contacts_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "contacts" */
export enum Contacts_Select_Column {
  /** column name */
  BirthSex = 'birth_sex',
  /** column name */
  CanAccessBillingInformation = 'can_access_billing_information',
  /** column name */
  CanAccessMedicalInformation = 'can_access_medical_information',
  /** column name */
  City = 'city',
  /** column name */
  DateOfBirth = 'date_of_birth',
  /** column name */
  EmailAddress = 'email_address',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  IsBilling = 'is_billing',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  IsEmergency = 'is_emergency',
  /** column name */
  IsPolicyHolder = 'is_policy_holder',
  /** column name */
  IsPrimary = 'is_primary',
  /** column name */
  LastName = 'last_name',
  /** column name */
  MaritalStatus = 'marital_status',
  /** column name */
  PhoneNumber = 'phone_number',
  /** column name */
  State = 'state',
  /** column name */
  StreetAddress_1 = 'street_address_1',
  /** column name */
  StreetAddress_2 = 'street_address_2',
  /** column name */
  UserId = 'user_id',
  /** column name */
  ZipCode = 'zip_code'
}

/** input type for updating data in table "contacts" */
export type Contacts_Set_Input = {
  birth_sex?: InputMaybe<Scalars['String']>;
  can_access_billing_information?: InputMaybe<Scalars['Boolean']>;
  can_access_medical_information?: InputMaybe<Scalars['Boolean']>;
  city?: InputMaybe<Scalars['String']>;
  date_of_birth?: InputMaybe<Scalars['String']>;
  email_address?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  is_billing?: InputMaybe<Scalars['Boolean']>;
  is_deleted?: InputMaybe<Scalars['Boolean']>;
  is_emergency?: InputMaybe<Scalars['Boolean']>;
  is_policy_holder?: InputMaybe<Scalars['Boolean']>;
  is_primary?: InputMaybe<Scalars['Boolean']>;
  last_name?: InputMaybe<Scalars['String']>;
  marital_status?: InputMaybe<Scalars['String']>;
  phone_number?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  street_address_1?: InputMaybe<Scalars['String']>;
  street_address_2?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['String']>;
  zip_code?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Contacts_Stddev_Fields = {
  __typename?: 'contacts_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Contacts_Stddev_Pop_Fields = {
  __typename?: 'contacts_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Contacts_Stddev_Samp_Fields = {
  __typename?: 'contacts_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Contacts_Sum_Fields = {
  __typename?: 'contacts_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/**
 * Relational many-to-many table for Contact to Patient
 *
 *
 * columns and relationships of "contacts_to_patients"
 *
 */
export type Contacts_To_Patients = {
  __typename?: 'contacts_to_patients';
  /** An object relationship */
  contact: Contacts;
  contact_id: Scalars['Int'];
  contact_type_code: Scalars['String'];
  id: Scalars['Int'];
  is_deleted: Scalars['Boolean'];
  /** An object relationship */
  patient: Portal_Patients;
  patient_id: Scalars['Int'];
};

/** aggregated selection of "contacts_to_patients" */
export type Contacts_To_Patients_Aggregate = {
  __typename?: 'contacts_to_patients_aggregate';
  aggregate?: Maybe<Contacts_To_Patients_Aggregate_Fields>;
  nodes: Array<Contacts_To_Patients>;
};

/** aggregate fields of "contacts_to_patients" */
export type Contacts_To_Patients_Aggregate_Fields = {
  __typename?: 'contacts_to_patients_aggregate_fields';
  avg?: Maybe<Contacts_To_Patients_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Contacts_To_Patients_Max_Fields>;
  min?: Maybe<Contacts_To_Patients_Min_Fields>;
  stddev?: Maybe<Contacts_To_Patients_Stddev_Fields>;
  stddev_pop?: Maybe<Contacts_To_Patients_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Contacts_To_Patients_Stddev_Samp_Fields>;
  sum?: Maybe<Contacts_To_Patients_Sum_Fields>;
  var_pop?: Maybe<Contacts_To_Patients_Var_Pop_Fields>;
  var_samp?: Maybe<Contacts_To_Patients_Var_Samp_Fields>;
  variance?: Maybe<Contacts_To_Patients_Variance_Fields>;
};


/** aggregate fields of "contacts_to_patients" */
export type Contacts_To_Patients_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Contacts_To_Patients_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "contacts_to_patients" */
export type Contacts_To_Patients_Aggregate_Order_By = {
  avg?: InputMaybe<Contacts_To_Patients_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Contacts_To_Patients_Max_Order_By>;
  min?: InputMaybe<Contacts_To_Patients_Min_Order_By>;
  stddev?: InputMaybe<Contacts_To_Patients_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Contacts_To_Patients_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Contacts_To_Patients_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Contacts_To_Patients_Sum_Order_By>;
  var_pop?: InputMaybe<Contacts_To_Patients_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Contacts_To_Patients_Var_Samp_Order_By>;
  variance?: InputMaybe<Contacts_To_Patients_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "contacts_to_patients" */
export type Contacts_To_Patients_Arr_Rel_Insert_Input = {
  data: Array<Contacts_To_Patients_Insert_Input>;
  /** on conflict condition */
  on_conflict?: InputMaybe<Contacts_To_Patients_On_Conflict>;
};

/** aggregate avg on columns */
export type Contacts_To_Patients_Avg_Fields = {
  __typename?: 'contacts_to_patients_avg_fields';
  contact_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patient_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "contacts_to_patients" */
export type Contacts_To_Patients_Avg_Order_By = {
  contact_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patient_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "contacts_to_patients". All fields are combined with a logical 'AND'. */
export type Contacts_To_Patients_Bool_Exp = {
  _and?: InputMaybe<Array<Contacts_To_Patients_Bool_Exp>>;
  _not?: InputMaybe<Contacts_To_Patients_Bool_Exp>;
  _or?: InputMaybe<Array<Contacts_To_Patients_Bool_Exp>>;
  contact?: InputMaybe<Contacts_Bool_Exp>;
  contact_id?: InputMaybe<Int_Comparison_Exp>;
  contact_type_code?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  is_deleted?: InputMaybe<Boolean_Comparison_Exp>;
  patient?: InputMaybe<Portal_Patients_Bool_Exp>;
  patient_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "contacts_to_patients" */
export enum Contacts_To_Patients_Constraint {
  /** unique or primary key constraint */
  ContactToPatientPkey = 'contact_to_patient_pkey',
  /** unique or primary key constraint */
  ContactsToPatientsContactIdPatientIdKey = 'contacts_to_patients_contact_id_patient_id_key'
}

/** input type for incrementing numeric columns in table "contacts_to_patients" */
export type Contacts_To_Patients_Inc_Input = {
  contact_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  patient_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "contacts_to_patients" */
export type Contacts_To_Patients_Insert_Input = {
  contact?: InputMaybe<Contacts_Obj_Rel_Insert_Input>;
  contact_id?: InputMaybe<Scalars['Int']>;
  contact_type_code?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  is_deleted?: InputMaybe<Scalars['Boolean']>;
  patient?: InputMaybe<Portal_Patients_Obj_Rel_Insert_Input>;
  patient_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Contacts_To_Patients_Max_Fields = {
  __typename?: 'contacts_to_patients_max_fields';
  contact_id?: Maybe<Scalars['Int']>;
  contact_type_code?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  patient_id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "contacts_to_patients" */
export type Contacts_To_Patients_Max_Order_By = {
  contact_id?: InputMaybe<Order_By>;
  contact_type_code?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patient_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Contacts_To_Patients_Min_Fields = {
  __typename?: 'contacts_to_patients_min_fields';
  contact_id?: Maybe<Scalars['Int']>;
  contact_type_code?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  patient_id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "contacts_to_patients" */
export type Contacts_To_Patients_Min_Order_By = {
  contact_id?: InputMaybe<Order_By>;
  contact_type_code?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patient_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "contacts_to_patients" */
export type Contacts_To_Patients_Mutation_Response = {
  __typename?: 'contacts_to_patients_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Contacts_To_Patients>;
};

/** on conflict condition type for table "contacts_to_patients" */
export type Contacts_To_Patients_On_Conflict = {
  constraint: Contacts_To_Patients_Constraint;
  update_columns?: Array<Contacts_To_Patients_Update_Column>;
  where?: InputMaybe<Contacts_To_Patients_Bool_Exp>;
};

/** Ordering options when selecting data from "contacts_to_patients". */
export type Contacts_To_Patients_Order_By = {
  contact?: InputMaybe<Contacts_Order_By>;
  contact_id?: InputMaybe<Order_By>;
  contact_type_code?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_deleted?: InputMaybe<Order_By>;
  patient?: InputMaybe<Portal_Patients_Order_By>;
  patient_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: contacts_to_patients */
export type Contacts_To_Patients_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "contacts_to_patients" */
export enum Contacts_To_Patients_Select_Column {
  /** column name */
  ContactId = 'contact_id',
  /** column name */
  ContactTypeCode = 'contact_type_code',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  PatientId = 'patient_id'
}

/** input type for updating data in table "contacts_to_patients" */
export type Contacts_To_Patients_Set_Input = {
  contact_id?: InputMaybe<Scalars['Int']>;
  contact_type_code?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  is_deleted?: InputMaybe<Scalars['Boolean']>;
  patient_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Contacts_To_Patients_Stddev_Fields = {
  __typename?: 'contacts_to_patients_stddev_fields';
  contact_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patient_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "contacts_to_patients" */
export type Contacts_To_Patients_Stddev_Order_By = {
  contact_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patient_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Contacts_To_Patients_Stddev_Pop_Fields = {
  __typename?: 'contacts_to_patients_stddev_pop_fields';
  contact_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patient_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "contacts_to_patients" */
export type Contacts_To_Patients_Stddev_Pop_Order_By = {
  contact_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patient_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Contacts_To_Patients_Stddev_Samp_Fields = {
  __typename?: 'contacts_to_patients_stddev_samp_fields';
  contact_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patient_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "contacts_to_patients" */
export type Contacts_To_Patients_Stddev_Samp_Order_By = {
  contact_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patient_id?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Contacts_To_Patients_Sum_Fields = {
  __typename?: 'contacts_to_patients_sum_fields';
  contact_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  patient_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "contacts_to_patients" */
export type Contacts_To_Patients_Sum_Order_By = {
  contact_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patient_id?: InputMaybe<Order_By>;
};

/** update columns of table "contacts_to_patients" */
export enum Contacts_To_Patients_Update_Column {
  /** column name */
  ContactId = 'contact_id',
  /** column name */
  ContactTypeCode = 'contact_type_code',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  PatientId = 'patient_id'
}

/** aggregate var_pop on columns */
export type Contacts_To_Patients_Var_Pop_Fields = {
  __typename?: 'contacts_to_patients_var_pop_fields';
  contact_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patient_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "contacts_to_patients" */
export type Contacts_To_Patients_Var_Pop_Order_By = {
  contact_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patient_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Contacts_To_Patients_Var_Samp_Fields = {
  __typename?: 'contacts_to_patients_var_samp_fields';
  contact_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patient_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "contacts_to_patients" */
export type Contacts_To_Patients_Var_Samp_Order_By = {
  contact_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patient_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Contacts_To_Patients_Variance_Fields = {
  __typename?: 'contacts_to_patients_variance_fields';
  contact_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patient_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "contacts_to_patients" */
export type Contacts_To_Patients_Variance_Order_By = {
  contact_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patient_id?: InputMaybe<Order_By>;
};

/** update columns of table "contacts" */
export enum Contacts_Update_Column {
  /** column name */
  BirthSex = 'birth_sex',
  /** column name */
  CanAccessBillingInformation = 'can_access_billing_information',
  /** column name */
  CanAccessMedicalInformation = 'can_access_medical_information',
  /** column name */
  City = 'city',
  /** column name */
  DateOfBirth = 'date_of_birth',
  /** column name */
  EmailAddress = 'email_address',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  IsBilling = 'is_billing',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  IsEmergency = 'is_emergency',
  /** column name */
  IsPolicyHolder = 'is_policy_holder',
  /** column name */
  IsPrimary = 'is_primary',
  /** column name */
  LastName = 'last_name',
  /** column name */
  MaritalStatus = 'marital_status',
  /** column name */
  PhoneNumber = 'phone_number',
  /** column name */
  State = 'state',
  /** column name */
  StreetAddress_1 = 'street_address_1',
  /** column name */
  StreetAddress_2 = 'street_address_2',
  /** column name */
  UserId = 'user_id',
  /** column name */
  ZipCode = 'zip_code'
}

/** aggregate var_pop on columns */
export type Contacts_Var_Pop_Fields = {
  __typename?: 'contacts_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Contacts_Var_Samp_Fields = {
  __typename?: 'contacts_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Contacts_Variance_Fields = {
  __typename?: 'contacts_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
export type Date_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['date']>;
  _gt?: InputMaybe<Scalars['date']>;
  _gte?: InputMaybe<Scalars['date']>;
  _in?: InputMaybe<Array<Scalars['date']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['date']>;
  _lte?: InputMaybe<Scalars['date']>;
  _neq?: InputMaybe<Scalars['date']>;
  _nin?: InputMaybe<Array<Scalars['date']>>;
};

/** columns and relationships of "discharges" */
export type Discharges = {
  __typename?: 'discharges';
  antibiotics_prescribed?: Maybe<Scalars['Boolean']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  facilitator?: Maybe<Users>;
  facilitator_id?: Maybe<Scalars['Int']>;
  facilitator_signature?: Maybe<Scalars['String']>;
  facilitator_signature_date_time?: Maybe<Scalars['timestamptz']>;
  facilitator_visit_summary?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  is_deleted?: Maybe<Scalars['Boolean']>;
  may_return?: Maybe<Scalars['Boolean']>;
  may_return_when_fever_free?: Maybe<Scalars['Boolean']>;
  medication_information?: Maybe<Scalars['String']>;
  other_medication_prescribed?: Maybe<Scalars['Boolean']>;
  other_note?: Maybe<Scalars['String']>;
  pcp_note?: Maybe<Scalars['String']>;
  pharmacy_address?: Maybe<Scalars['String']>;
  pharmacy_address2?: Maybe<Scalars['String']>;
  pharmacy_city?: Maybe<Scalars['String']>;
  pharmacy_latitude?: Maybe<Scalars['numeric']>;
  pharmacy_longitude?: Maybe<Scalars['numeric']>;
  pharmacy_name?: Maybe<Scalars['String']>;
  pharmacy_state?: Maybe<Scalars['String']>;
  pharmacy_zipcode?: Maybe<Scalars['String']>;
  /** An object relationship */
  provider?: Maybe<Users>;
  provider_id?: Maybe<Scalars['Int']>;
  provider_signature?: Maybe<Scalars['String']>;
  provider_signature_date_time?: Maybe<Scalars['timestamptz']>;
  provider_visit_summary?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  visit: Visits;
  visit_id: Scalars['Int'];
};

/** aggregated selection of "discharges" */
export type Discharges_Aggregate = {
  __typename?: 'discharges_aggregate';
  aggregate?: Maybe<Discharges_Aggregate_Fields>;
  nodes: Array<Discharges>;
};

/** aggregate fields of "discharges" */
export type Discharges_Aggregate_Fields = {
  __typename?: 'discharges_aggregate_fields';
  avg?: Maybe<Discharges_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Discharges_Max_Fields>;
  min?: Maybe<Discharges_Min_Fields>;
  stddev?: Maybe<Discharges_Stddev_Fields>;
  stddev_pop?: Maybe<Discharges_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Discharges_Stddev_Samp_Fields>;
  sum?: Maybe<Discharges_Sum_Fields>;
  var_pop?: Maybe<Discharges_Var_Pop_Fields>;
  var_samp?: Maybe<Discharges_Var_Samp_Fields>;
  variance?: Maybe<Discharges_Variance_Fields>;
};


/** aggregate fields of "discharges" */
export type Discharges_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Discharges_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Discharges_Avg_Fields = {
  __typename?: 'discharges_avg_fields';
  facilitator_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  pharmacy_latitude?: Maybe<Scalars['Float']>;
  pharmacy_longitude?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
  visit_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "discharges". All fields are combined with a logical 'AND'. */
export type Discharges_Bool_Exp = {
  _and?: InputMaybe<Array<Discharges_Bool_Exp>>;
  _not?: InputMaybe<Discharges_Bool_Exp>;
  _or?: InputMaybe<Array<Discharges_Bool_Exp>>;
  antibiotics_prescribed?: InputMaybe<Boolean_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  facilitator?: InputMaybe<Users_Bool_Exp>;
  facilitator_id?: InputMaybe<Int_Comparison_Exp>;
  facilitator_signature?: InputMaybe<String_Comparison_Exp>;
  facilitator_signature_date_time?: InputMaybe<Timestamptz_Comparison_Exp>;
  facilitator_visit_summary?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  is_deleted?: InputMaybe<Boolean_Comparison_Exp>;
  may_return?: InputMaybe<Boolean_Comparison_Exp>;
  may_return_when_fever_free?: InputMaybe<Boolean_Comparison_Exp>;
  medication_information?: InputMaybe<String_Comparison_Exp>;
  other_medication_prescribed?: InputMaybe<Boolean_Comparison_Exp>;
  other_note?: InputMaybe<String_Comparison_Exp>;
  pcp_note?: InputMaybe<String_Comparison_Exp>;
  pharmacy_address?: InputMaybe<String_Comparison_Exp>;
  pharmacy_address2?: InputMaybe<String_Comparison_Exp>;
  pharmacy_city?: InputMaybe<String_Comparison_Exp>;
  pharmacy_latitude?: InputMaybe<Numeric_Comparison_Exp>;
  pharmacy_longitude?: InputMaybe<Numeric_Comparison_Exp>;
  pharmacy_name?: InputMaybe<String_Comparison_Exp>;
  pharmacy_state?: InputMaybe<String_Comparison_Exp>;
  pharmacy_zipcode?: InputMaybe<String_Comparison_Exp>;
  provider?: InputMaybe<Users_Bool_Exp>;
  provider_id?: InputMaybe<Int_Comparison_Exp>;
  provider_signature?: InputMaybe<String_Comparison_Exp>;
  provider_signature_date_time?: InputMaybe<Timestamptz_Comparison_Exp>;
  provider_visit_summary?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  visit?: InputMaybe<Visits_Bool_Exp>;
  visit_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "discharges" */
export enum Discharges_Constraint {
  /** unique or primary key constraint */
  DischargesPkey = 'discharges_pkey',
  /** unique or primary key constraint */
  DischargesVisitidUnique = 'discharges_visitid_unique'
}

/** input type for incrementing numeric columns in table "discharges" */
export type Discharges_Inc_Input = {
  facilitator_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  pharmacy_latitude?: InputMaybe<Scalars['numeric']>;
  pharmacy_longitude?: InputMaybe<Scalars['numeric']>;
  provider_id?: InputMaybe<Scalars['Int']>;
  visit_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "discharges" */
export type Discharges_Insert_Input = {
  antibiotics_prescribed?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  facilitator?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  facilitator_id?: InputMaybe<Scalars['Int']>;
  facilitator_signature?: InputMaybe<Scalars['String']>;
  facilitator_signature_date_time?: InputMaybe<Scalars['timestamptz']>;
  facilitator_visit_summary?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  is_deleted?: InputMaybe<Scalars['Boolean']>;
  may_return?: InputMaybe<Scalars['Boolean']>;
  may_return_when_fever_free?: InputMaybe<Scalars['Boolean']>;
  medication_information?: InputMaybe<Scalars['String']>;
  other_medication_prescribed?: InputMaybe<Scalars['Boolean']>;
  other_note?: InputMaybe<Scalars['String']>;
  pcp_note?: InputMaybe<Scalars['String']>;
  pharmacy_address?: InputMaybe<Scalars['String']>;
  pharmacy_address2?: InputMaybe<Scalars['String']>;
  pharmacy_city?: InputMaybe<Scalars['String']>;
  pharmacy_latitude?: InputMaybe<Scalars['numeric']>;
  pharmacy_longitude?: InputMaybe<Scalars['numeric']>;
  pharmacy_name?: InputMaybe<Scalars['String']>;
  pharmacy_state?: InputMaybe<Scalars['String']>;
  pharmacy_zipcode?: InputMaybe<Scalars['String']>;
  provider?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  provider_id?: InputMaybe<Scalars['Int']>;
  provider_signature?: InputMaybe<Scalars['String']>;
  provider_signature_date_time?: InputMaybe<Scalars['timestamptz']>;
  provider_visit_summary?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  visit?: InputMaybe<Visits_Obj_Rel_Insert_Input>;
  visit_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Discharges_Max_Fields = {
  __typename?: 'discharges_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  facilitator_id?: Maybe<Scalars['Int']>;
  facilitator_signature?: Maybe<Scalars['String']>;
  facilitator_signature_date_time?: Maybe<Scalars['timestamptz']>;
  facilitator_visit_summary?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  medication_information?: Maybe<Scalars['String']>;
  other_note?: Maybe<Scalars['String']>;
  pcp_note?: Maybe<Scalars['String']>;
  pharmacy_address?: Maybe<Scalars['String']>;
  pharmacy_address2?: Maybe<Scalars['String']>;
  pharmacy_city?: Maybe<Scalars['String']>;
  pharmacy_latitude?: Maybe<Scalars['numeric']>;
  pharmacy_longitude?: Maybe<Scalars['numeric']>;
  pharmacy_name?: Maybe<Scalars['String']>;
  pharmacy_state?: Maybe<Scalars['String']>;
  pharmacy_zipcode?: Maybe<Scalars['String']>;
  provider_id?: Maybe<Scalars['Int']>;
  provider_signature?: Maybe<Scalars['String']>;
  provider_signature_date_time?: Maybe<Scalars['timestamptz']>;
  provider_visit_summary?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  visit_id?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Discharges_Min_Fields = {
  __typename?: 'discharges_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  facilitator_id?: Maybe<Scalars['Int']>;
  facilitator_signature?: Maybe<Scalars['String']>;
  facilitator_signature_date_time?: Maybe<Scalars['timestamptz']>;
  facilitator_visit_summary?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  medication_information?: Maybe<Scalars['String']>;
  other_note?: Maybe<Scalars['String']>;
  pcp_note?: Maybe<Scalars['String']>;
  pharmacy_address?: Maybe<Scalars['String']>;
  pharmacy_address2?: Maybe<Scalars['String']>;
  pharmacy_city?: Maybe<Scalars['String']>;
  pharmacy_latitude?: Maybe<Scalars['numeric']>;
  pharmacy_longitude?: Maybe<Scalars['numeric']>;
  pharmacy_name?: Maybe<Scalars['String']>;
  pharmacy_state?: Maybe<Scalars['String']>;
  pharmacy_zipcode?: Maybe<Scalars['String']>;
  provider_id?: Maybe<Scalars['Int']>;
  provider_signature?: Maybe<Scalars['String']>;
  provider_signature_date_time?: Maybe<Scalars['timestamptz']>;
  provider_visit_summary?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  visit_id?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "discharges" */
export type Discharges_Mutation_Response = {
  __typename?: 'discharges_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Discharges>;
};

/** input type for inserting object relation for remote table "discharges" */
export type Discharges_Obj_Rel_Insert_Input = {
  data: Discharges_Insert_Input;
  /** on conflict condition */
  on_conflict?: InputMaybe<Discharges_On_Conflict>;
};

/** on conflict condition type for table "discharges" */
export type Discharges_On_Conflict = {
  constraint: Discharges_Constraint;
  update_columns?: Array<Discharges_Update_Column>;
  where?: InputMaybe<Discharges_Bool_Exp>;
};

/** Ordering options when selecting data from "discharges". */
export type Discharges_Order_By = {
  antibiotics_prescribed?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  facilitator?: InputMaybe<Users_Order_By>;
  facilitator_id?: InputMaybe<Order_By>;
  facilitator_signature?: InputMaybe<Order_By>;
  facilitator_signature_date_time?: InputMaybe<Order_By>;
  facilitator_visit_summary?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_deleted?: InputMaybe<Order_By>;
  may_return?: InputMaybe<Order_By>;
  may_return_when_fever_free?: InputMaybe<Order_By>;
  medication_information?: InputMaybe<Order_By>;
  other_medication_prescribed?: InputMaybe<Order_By>;
  other_note?: InputMaybe<Order_By>;
  pcp_note?: InputMaybe<Order_By>;
  pharmacy_address?: InputMaybe<Order_By>;
  pharmacy_address2?: InputMaybe<Order_By>;
  pharmacy_city?: InputMaybe<Order_By>;
  pharmacy_latitude?: InputMaybe<Order_By>;
  pharmacy_longitude?: InputMaybe<Order_By>;
  pharmacy_name?: InputMaybe<Order_By>;
  pharmacy_state?: InputMaybe<Order_By>;
  pharmacy_zipcode?: InputMaybe<Order_By>;
  provider?: InputMaybe<Users_Order_By>;
  provider_id?: InputMaybe<Order_By>;
  provider_signature?: InputMaybe<Order_By>;
  provider_signature_date_time?: InputMaybe<Order_By>;
  provider_visit_summary?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  visit?: InputMaybe<Visits_Order_By>;
  visit_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: discharges */
export type Discharges_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "discharges" */
export enum Discharges_Select_Column {
  /** column name */
  AntibioticsPrescribed = 'antibiotics_prescribed',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FacilitatorId = 'facilitator_id',
  /** column name */
  FacilitatorSignature = 'facilitator_signature',
  /** column name */
  FacilitatorSignatureDateTime = 'facilitator_signature_date_time',
  /** column name */
  FacilitatorVisitSummary = 'facilitator_visit_summary',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  MayReturn = 'may_return',
  /** column name */
  MayReturnWhenFeverFree = 'may_return_when_fever_free',
  /** column name */
  MedicationInformation = 'medication_information',
  /** column name */
  OtherMedicationPrescribed = 'other_medication_prescribed',
  /** column name */
  OtherNote = 'other_note',
  /** column name */
  PcpNote = 'pcp_note',
  /** column name */
  PharmacyAddress = 'pharmacy_address',
  /** column name */
  PharmacyAddress2 = 'pharmacy_address2',
  /** column name */
  PharmacyCity = 'pharmacy_city',
  /** column name */
  PharmacyLatitude = 'pharmacy_latitude',
  /** column name */
  PharmacyLongitude = 'pharmacy_longitude',
  /** column name */
  PharmacyName = 'pharmacy_name',
  /** column name */
  PharmacyState = 'pharmacy_state',
  /** column name */
  PharmacyZipcode = 'pharmacy_zipcode',
  /** column name */
  ProviderId = 'provider_id',
  /** column name */
  ProviderSignature = 'provider_signature',
  /** column name */
  ProviderSignatureDateTime = 'provider_signature_date_time',
  /** column name */
  ProviderVisitSummary = 'provider_visit_summary',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VisitId = 'visit_id'
}

/** input type for updating data in table "discharges" */
export type Discharges_Set_Input = {
  antibiotics_prescribed?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  facilitator_id?: InputMaybe<Scalars['Int']>;
  facilitator_signature?: InputMaybe<Scalars['String']>;
  facilitator_signature_date_time?: InputMaybe<Scalars['timestamptz']>;
  facilitator_visit_summary?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  is_deleted?: InputMaybe<Scalars['Boolean']>;
  may_return?: InputMaybe<Scalars['Boolean']>;
  may_return_when_fever_free?: InputMaybe<Scalars['Boolean']>;
  medication_information?: InputMaybe<Scalars['String']>;
  other_medication_prescribed?: InputMaybe<Scalars['Boolean']>;
  other_note?: InputMaybe<Scalars['String']>;
  pcp_note?: InputMaybe<Scalars['String']>;
  pharmacy_address?: InputMaybe<Scalars['String']>;
  pharmacy_address2?: InputMaybe<Scalars['String']>;
  pharmacy_city?: InputMaybe<Scalars['String']>;
  pharmacy_latitude?: InputMaybe<Scalars['numeric']>;
  pharmacy_longitude?: InputMaybe<Scalars['numeric']>;
  pharmacy_name?: InputMaybe<Scalars['String']>;
  pharmacy_state?: InputMaybe<Scalars['String']>;
  pharmacy_zipcode?: InputMaybe<Scalars['String']>;
  provider_id?: InputMaybe<Scalars['Int']>;
  provider_signature?: InputMaybe<Scalars['String']>;
  provider_signature_date_time?: InputMaybe<Scalars['timestamptz']>;
  provider_visit_summary?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  visit_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Discharges_Stddev_Fields = {
  __typename?: 'discharges_stddev_fields';
  facilitator_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  pharmacy_latitude?: Maybe<Scalars['Float']>;
  pharmacy_longitude?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
  visit_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Discharges_Stddev_Pop_Fields = {
  __typename?: 'discharges_stddev_pop_fields';
  facilitator_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  pharmacy_latitude?: Maybe<Scalars['Float']>;
  pharmacy_longitude?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
  visit_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Discharges_Stddev_Samp_Fields = {
  __typename?: 'discharges_stddev_samp_fields';
  facilitator_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  pharmacy_latitude?: Maybe<Scalars['Float']>;
  pharmacy_longitude?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
  visit_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Discharges_Sum_Fields = {
  __typename?: 'discharges_sum_fields';
  facilitator_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  pharmacy_latitude?: Maybe<Scalars['numeric']>;
  pharmacy_longitude?: Maybe<Scalars['numeric']>;
  provider_id?: Maybe<Scalars['Int']>;
  visit_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "discharges" */
export enum Discharges_Update_Column {
  /** column name */
  AntibioticsPrescribed = 'antibiotics_prescribed',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FacilitatorId = 'facilitator_id',
  /** column name */
  FacilitatorSignature = 'facilitator_signature',
  /** column name */
  FacilitatorSignatureDateTime = 'facilitator_signature_date_time',
  /** column name */
  FacilitatorVisitSummary = 'facilitator_visit_summary',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  MayReturn = 'may_return',
  /** column name */
  MayReturnWhenFeverFree = 'may_return_when_fever_free',
  /** column name */
  MedicationInformation = 'medication_information',
  /** column name */
  OtherMedicationPrescribed = 'other_medication_prescribed',
  /** column name */
  OtherNote = 'other_note',
  /** column name */
  PcpNote = 'pcp_note',
  /** column name */
  PharmacyAddress = 'pharmacy_address',
  /** column name */
  PharmacyAddress2 = 'pharmacy_address2',
  /** column name */
  PharmacyCity = 'pharmacy_city',
  /** column name */
  PharmacyLatitude = 'pharmacy_latitude',
  /** column name */
  PharmacyLongitude = 'pharmacy_longitude',
  /** column name */
  PharmacyName = 'pharmacy_name',
  /** column name */
  PharmacyState = 'pharmacy_state',
  /** column name */
  PharmacyZipcode = 'pharmacy_zipcode',
  /** column name */
  ProviderId = 'provider_id',
  /** column name */
  ProviderSignature = 'provider_signature',
  /** column name */
  ProviderSignatureDateTime = 'provider_signature_date_time',
  /** column name */
  ProviderVisitSummary = 'provider_visit_summary',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VisitId = 'visit_id'
}

/** aggregate var_pop on columns */
export type Discharges_Var_Pop_Fields = {
  __typename?: 'discharges_var_pop_fields';
  facilitator_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  pharmacy_latitude?: Maybe<Scalars['Float']>;
  pharmacy_longitude?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
  visit_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Discharges_Var_Samp_Fields = {
  __typename?: 'discharges_var_samp_fields';
  facilitator_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  pharmacy_latitude?: Maybe<Scalars['Float']>;
  pharmacy_longitude?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
  visit_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Discharges_Variance_Fields = {
  __typename?: 'discharges_variance_fields';
  facilitator_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  pharmacy_latitude?: Maybe<Scalars['Float']>;
  pharmacy_longitude?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
  visit_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "enrollments" */
export type Enrollments = {
  __typename?: 'enrollments';
  id: Scalars['Int'];
  is_deleted?: Maybe<Scalars['Boolean']>;
  location_id?: Maybe<Scalars['Int']>;
  organization_id?: Maybe<Scalars['Int']>;
  program_id?: Maybe<Scalars['Int']>;
  role_id: Scalars['String'];
};

/** aggregated selection of "enrollments" */
export type Enrollments_Aggregate = {
  __typename?: 'enrollments_aggregate';
  aggregate?: Maybe<Enrollments_Aggregate_Fields>;
  nodes: Array<Enrollments>;
};

/** aggregate fields of "enrollments" */
export type Enrollments_Aggregate_Fields = {
  __typename?: 'enrollments_aggregate_fields';
  avg?: Maybe<Enrollments_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Enrollments_Max_Fields>;
  min?: Maybe<Enrollments_Min_Fields>;
  stddev?: Maybe<Enrollments_Stddev_Fields>;
  stddev_pop?: Maybe<Enrollments_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Enrollments_Stddev_Samp_Fields>;
  sum?: Maybe<Enrollments_Sum_Fields>;
  var_pop?: Maybe<Enrollments_Var_Pop_Fields>;
  var_samp?: Maybe<Enrollments_Var_Samp_Fields>;
  variance?: Maybe<Enrollments_Variance_Fields>;
};


/** aggregate fields of "enrollments" */
export type Enrollments_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Enrollments_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Enrollments_Avg_Fields = {
  __typename?: 'enrollments_avg_fields';
  id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
  organization_id?: Maybe<Scalars['Float']>;
  program_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "enrollments". All fields are combined with a logical 'AND'. */
export type Enrollments_Bool_Exp = {
  _and?: InputMaybe<Array<Enrollments_Bool_Exp>>;
  _not?: InputMaybe<Enrollments_Bool_Exp>;
  _or?: InputMaybe<Array<Enrollments_Bool_Exp>>;
  id?: InputMaybe<Int_Comparison_Exp>;
  is_deleted?: InputMaybe<Boolean_Comparison_Exp>;
  location_id?: InputMaybe<Int_Comparison_Exp>;
  organization_id?: InputMaybe<Int_Comparison_Exp>;
  program_id?: InputMaybe<Int_Comparison_Exp>;
  role_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "enrollments" */
export enum Enrollments_Constraint {
  /** unique or primary key constraint */
  EnrollmentsPkey = 'enrollments_pkey'
}

/** input type for incrementing numeric columns in table "enrollments" */
export type Enrollments_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  location_id?: InputMaybe<Scalars['Int']>;
  organization_id?: InputMaybe<Scalars['Int']>;
  program_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "enrollments" */
export type Enrollments_Insert_Input = {
  id?: InputMaybe<Scalars['Int']>;
  is_deleted?: InputMaybe<Scalars['Boolean']>;
  location_id?: InputMaybe<Scalars['Int']>;
  organization_id?: InputMaybe<Scalars['Int']>;
  program_id?: InputMaybe<Scalars['Int']>;
  role_id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enrollments_Max_Fields = {
  __typename?: 'enrollments_max_fields';
  id?: Maybe<Scalars['Int']>;
  location_id?: Maybe<Scalars['Int']>;
  organization_id?: Maybe<Scalars['Int']>;
  program_id?: Maybe<Scalars['Int']>;
  role_id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enrollments_Min_Fields = {
  __typename?: 'enrollments_min_fields';
  id?: Maybe<Scalars['Int']>;
  location_id?: Maybe<Scalars['Int']>;
  organization_id?: Maybe<Scalars['Int']>;
  program_id?: Maybe<Scalars['Int']>;
  role_id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "enrollments" */
export type Enrollments_Mutation_Response = {
  __typename?: 'enrollments_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enrollments>;
};

/** on conflict condition type for table "enrollments" */
export type Enrollments_On_Conflict = {
  constraint: Enrollments_Constraint;
  update_columns?: Array<Enrollments_Update_Column>;
  where?: InputMaybe<Enrollments_Bool_Exp>;
};

/** Ordering options when selecting data from "enrollments". */
export type Enrollments_Order_By = {
  id?: InputMaybe<Order_By>;
  is_deleted?: InputMaybe<Order_By>;
  location_id?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  program_id?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: enrollments */
export type Enrollments_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "enrollments" */
export enum Enrollments_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  ProgramId = 'program_id',
  /** column name */
  RoleId = 'role_id'
}

/** input type for updating data in table "enrollments" */
export type Enrollments_Set_Input = {
  id?: InputMaybe<Scalars['Int']>;
  is_deleted?: InputMaybe<Scalars['Boolean']>;
  location_id?: InputMaybe<Scalars['Int']>;
  organization_id?: InputMaybe<Scalars['Int']>;
  program_id?: InputMaybe<Scalars['Int']>;
  role_id?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Enrollments_Stddev_Fields = {
  __typename?: 'enrollments_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
  organization_id?: Maybe<Scalars['Float']>;
  program_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Enrollments_Stddev_Pop_Fields = {
  __typename?: 'enrollments_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
  organization_id?: Maybe<Scalars['Float']>;
  program_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Enrollments_Stddev_Samp_Fields = {
  __typename?: 'enrollments_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
  organization_id?: Maybe<Scalars['Float']>;
  program_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Enrollments_Sum_Fields = {
  __typename?: 'enrollments_sum_fields';
  id?: Maybe<Scalars['Int']>;
  location_id?: Maybe<Scalars['Int']>;
  organization_id?: Maybe<Scalars['Int']>;
  program_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "enrollments" */
export enum Enrollments_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  ProgramId = 'program_id',
  /** column name */
  RoleId = 'role_id'
}

/** aggregate var_pop on columns */
export type Enrollments_Var_Pop_Fields = {
  __typename?: 'enrollments_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
  organization_id?: Maybe<Scalars['Float']>;
  program_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Enrollments_Var_Samp_Fields = {
  __typename?: 'enrollments_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
  organization_id?: Maybe<Scalars['Float']>;
  program_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Enrollments_Variance_Fields = {
  __typename?: 'enrollments_variance_fields';
  id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
  organization_id?: Maybe<Scalars['Float']>;
  program_id?: Maybe<Scalars['Float']>;
};

/**
 * Global table to handle event types
 *
 *
 * columns and relationships of "event_types"
 *
 */
export type Event_Types = {
  __typename?: 'event_types';
  code: Scalars['String'];
  /** An array relationship */
  events: Array<Events>;
  /** An aggregate relationship */
  events_aggregate: Events_Aggregate;
  title: Scalars['String'];
};


/**
 * Global table to handle event types
 *
 *
 * columns and relationships of "event_types"
 *
 */
export type Event_TypesEventsArgs = {
  distinct_on?: InputMaybe<Array<Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Events_Order_By>>;
  where?: InputMaybe<Events_Bool_Exp>;
};


/**
 * Global table to handle event types
 *
 *
 * columns and relationships of "event_types"
 *
 */
export type Event_TypesEvents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Events_Order_By>>;
  where?: InputMaybe<Events_Bool_Exp>;
};

/** aggregated selection of "event_types" */
export type Event_Types_Aggregate = {
  __typename?: 'event_types_aggregate';
  aggregate?: Maybe<Event_Types_Aggregate_Fields>;
  nodes: Array<Event_Types>;
};

/** aggregate fields of "event_types" */
export type Event_Types_Aggregate_Fields = {
  __typename?: 'event_types_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Event_Types_Max_Fields>;
  min?: Maybe<Event_Types_Min_Fields>;
};


/** aggregate fields of "event_types" */
export type Event_Types_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Event_Types_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "event_types". All fields are combined with a logical 'AND'. */
export type Event_Types_Bool_Exp = {
  _and?: InputMaybe<Array<Event_Types_Bool_Exp>>;
  _not?: InputMaybe<Event_Types_Bool_Exp>;
  _or?: InputMaybe<Array<Event_Types_Bool_Exp>>;
  code?: InputMaybe<String_Comparison_Exp>;
  events?: InputMaybe<Events_Bool_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "event_types" */
export enum Event_Types_Constraint {
  /** unique or primary key constraint */
  EventTypesPkey = 'event_types_pkey'
}

/** input type for inserting data into table "event_types" */
export type Event_Types_Insert_Input = {
  code?: InputMaybe<Scalars['String']>;
  events?: InputMaybe<Events_Arr_Rel_Insert_Input>;
  title?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Event_Types_Max_Fields = {
  __typename?: 'event_types_max_fields';
  code?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Event_Types_Min_Fields = {
  __typename?: 'event_types_min_fields';
  code?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "event_types" */
export type Event_Types_Mutation_Response = {
  __typename?: 'event_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Event_Types>;
};

/** input type for inserting object relation for remote table "event_types" */
export type Event_Types_Obj_Rel_Insert_Input = {
  data: Event_Types_Insert_Input;
  /** on conflict condition */
  on_conflict?: InputMaybe<Event_Types_On_Conflict>;
};

/** on conflict condition type for table "event_types" */
export type Event_Types_On_Conflict = {
  constraint: Event_Types_Constraint;
  update_columns?: Array<Event_Types_Update_Column>;
  where?: InputMaybe<Event_Types_Bool_Exp>;
};

/** Ordering options when selecting data from "event_types". */
export type Event_Types_Order_By = {
  code?: InputMaybe<Order_By>;
  events_aggregate?: InputMaybe<Events_Aggregate_Order_By>;
  title?: InputMaybe<Order_By>;
};

/** primary key columns input for table: event_types */
export type Event_Types_Pk_Columns_Input = {
  code: Scalars['String'];
};

/** select columns of table "event_types" */
export enum Event_Types_Select_Column {
  /** column name */
  Code = 'code',
  /** column name */
  Title = 'title'
}

/** input type for updating data in table "event_types" */
export type Event_Types_Set_Input = {
  code?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

/** update columns of table "event_types" */
export enum Event_Types_Update_Column {
  /** column name */
  Code = 'code',
  /** column name */
  Title = 'title'
}

/**
 * Handles Events based on Organizations and Event Types
 *
 *
 * columns and relationships of "events"
 *
 */
export type Events = {
  __typename?: 'events';
  end_date?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  event_type: Event_Types;
  event_type_code: Scalars['String'];
  id: Scalars['Int'];
  is_active: Scalars['Boolean'];
  organization?: Maybe<Organizations_Organizations>;
  organization_id: Scalars['Int'];
  start_date?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "events" */
export type Events_Aggregate = {
  __typename?: 'events_aggregate';
  aggregate?: Maybe<Events_Aggregate_Fields>;
  nodes: Array<Events>;
};

/** aggregate fields of "events" */
export type Events_Aggregate_Fields = {
  __typename?: 'events_aggregate_fields';
  avg?: Maybe<Events_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Events_Max_Fields>;
  min?: Maybe<Events_Min_Fields>;
  stddev?: Maybe<Events_Stddev_Fields>;
  stddev_pop?: Maybe<Events_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Events_Stddev_Samp_Fields>;
  sum?: Maybe<Events_Sum_Fields>;
  var_pop?: Maybe<Events_Var_Pop_Fields>;
  var_samp?: Maybe<Events_Var_Samp_Fields>;
  variance?: Maybe<Events_Variance_Fields>;
};


/** aggregate fields of "events" */
export type Events_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Events_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "events" */
export type Events_Aggregate_Order_By = {
  avg?: InputMaybe<Events_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Events_Max_Order_By>;
  min?: InputMaybe<Events_Min_Order_By>;
  stddev?: InputMaybe<Events_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Events_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Events_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Events_Sum_Order_By>;
  var_pop?: InputMaybe<Events_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Events_Var_Samp_Order_By>;
  variance?: InputMaybe<Events_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "events" */
export type Events_Arr_Rel_Insert_Input = {
  data: Array<Events_Insert_Input>;
  /** on conflict condition */
  on_conflict?: InputMaybe<Events_On_Conflict>;
};

/** aggregate avg on columns */
export type Events_Avg_Fields = {
  __typename?: 'events_avg_fields';
  id?: Maybe<Scalars['Float']>;
  organization_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "events" */
export type Events_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "events". All fields are combined with a logical 'AND'. */
export type Events_Bool_Exp = {
  _and?: InputMaybe<Array<Events_Bool_Exp>>;
  _not?: InputMaybe<Events_Bool_Exp>;
  _or?: InputMaybe<Array<Events_Bool_Exp>>;
  end_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  event_type?: InputMaybe<Event_Types_Bool_Exp>;
  event_type_code?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  is_active?: InputMaybe<Boolean_Comparison_Exp>;
  organization_id?: InputMaybe<Int_Comparison_Exp>;
  start_date?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "events" */
export enum Events_Constraint {
  /** unique or primary key constraint */
  EventsPkey = 'events_pkey'
}

/** input type for incrementing numeric columns in table "events" */
export type Events_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  organization_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "events" */
export type Events_Insert_Input = {
  end_date?: InputMaybe<Scalars['timestamptz']>;
  event_type?: InputMaybe<Event_Types_Obj_Rel_Insert_Input>;
  event_type_code?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  organization_id?: InputMaybe<Scalars['Int']>;
  start_date?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Events_Max_Fields = {
  __typename?: 'events_max_fields';
  end_date?: Maybe<Scalars['timestamptz']>;
  event_type_code?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  organization_id?: Maybe<Scalars['Int']>;
  start_date?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "events" */
export type Events_Max_Order_By = {
  end_date?: InputMaybe<Order_By>;
  event_type_code?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  start_date?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Events_Min_Fields = {
  __typename?: 'events_min_fields';
  end_date?: Maybe<Scalars['timestamptz']>;
  event_type_code?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  organization_id?: Maybe<Scalars['Int']>;
  start_date?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "events" */
export type Events_Min_Order_By = {
  end_date?: InputMaybe<Order_By>;
  event_type_code?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  start_date?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "events" */
export type Events_Mutation_Response = {
  __typename?: 'events_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Events>;
};

/** on conflict condition type for table "events" */
export type Events_On_Conflict = {
  constraint: Events_Constraint;
  update_columns?: Array<Events_Update_Column>;
  where?: InputMaybe<Events_Bool_Exp>;
};

/** Ordering options when selecting data from "events". */
export type Events_Order_By = {
  end_date?: InputMaybe<Order_By>;
  event_type?: InputMaybe<Event_Types_Order_By>;
  event_type_code?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_active?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  start_date?: InputMaybe<Order_By>;
};

/** primary key columns input for table: events */
export type Events_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "events" */
export enum Events_Select_Column {
  /** column name */
  EndDate = 'end_date',
  /** column name */
  EventTypeCode = 'event_type_code',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  StartDate = 'start_date'
}

/** input type for updating data in table "events" */
export type Events_Set_Input = {
  end_date?: InputMaybe<Scalars['timestamptz']>;
  event_type_code?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  organization_id?: InputMaybe<Scalars['Int']>;
  start_date?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Events_Stddev_Fields = {
  __typename?: 'events_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  organization_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "events" */
export type Events_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Events_Stddev_Pop_Fields = {
  __typename?: 'events_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  organization_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "events" */
export type Events_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Events_Stddev_Samp_Fields = {
  __typename?: 'events_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  organization_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "events" */
export type Events_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Events_Sum_Fields = {
  __typename?: 'events_sum_fields';
  id?: Maybe<Scalars['Int']>;
  organization_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "events" */
export type Events_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
};

/** update columns of table "events" */
export enum Events_Update_Column {
  /** column name */
  EndDate = 'end_date',
  /** column name */
  EventTypeCode = 'event_type_code',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  StartDate = 'start_date'
}

/** aggregate var_pop on columns */
export type Events_Var_Pop_Fields = {
  __typename?: 'events_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  organization_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "events" */
export type Events_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Events_Var_Samp_Fields = {
  __typename?: 'events_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  organization_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "events" */
export type Events_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Events_Variance_Fields = {
  __typename?: 'events_variance_fields';
  id?: Maybe<Scalars['Float']>;
  organization_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "events" */
export type Events_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
};

export type Form_Images = {
  __typename?: 'form_images';
  patient_id: Scalars['String'];
  type: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  url: Scalars['String'];
};

export type Form_Images_Insert = {
  __typename?: 'form_images_insert';
  url: Scalars['String'];
};

/** columns and relationships of "form_localizations" */
export type Form_Localizations = {
  __typename?: 'form_localizations';
  dictionary: Scalars['jsonb'];
  locale: Scalars['String'];
  type: Scalars['String'];
};


/** columns and relationships of "form_localizations" */
export type Form_LocalizationsDictionaryArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "form_localizations" */
export type Form_Localizations_Aggregate = {
  __typename?: 'form_localizations_aggregate';
  aggregate?: Maybe<Form_Localizations_Aggregate_Fields>;
  nodes: Array<Form_Localizations>;
};

/** aggregate fields of "form_localizations" */
export type Form_Localizations_Aggregate_Fields = {
  __typename?: 'form_localizations_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Form_Localizations_Max_Fields>;
  min?: Maybe<Form_Localizations_Min_Fields>;
};


/** aggregate fields of "form_localizations" */
export type Form_Localizations_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Form_Localizations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "form_localizations" */
export type Form_Localizations_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Form_Localizations_Max_Order_By>;
  min?: InputMaybe<Form_Localizations_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Form_Localizations_Append_Input = {
  dictionary?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "form_localizations" */
export type Form_Localizations_Arr_Rel_Insert_Input = {
  data: Array<Form_Localizations_Insert_Input>;
  /** on conflict condition */
  on_conflict?: InputMaybe<Form_Localizations_On_Conflict>;
};

/** Boolean expression to filter rows from the table "form_localizations". All fields are combined with a logical 'AND'. */
export type Form_Localizations_Bool_Exp = {
  _and?: InputMaybe<Array<Form_Localizations_Bool_Exp>>;
  _not?: InputMaybe<Form_Localizations_Bool_Exp>;
  _or?: InputMaybe<Array<Form_Localizations_Bool_Exp>>;
  dictionary?: InputMaybe<Jsonb_Comparison_Exp>;
  locale?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "form_localizations" */
export enum Form_Localizations_Constraint {
  /** unique or primary key constraint */
  FormLocalizationsPkey = 'form_localizations_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Form_Localizations_Delete_At_Path_Input = {
  dictionary?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Form_Localizations_Delete_Elem_Input = {
  dictionary?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Form_Localizations_Delete_Key_Input = {
  dictionary?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "form_localizations" */
export type Form_Localizations_Insert_Input = {
  dictionary?: InputMaybe<Scalars['jsonb']>;
  locale?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Form_Localizations_Max_Fields = {
  __typename?: 'form_localizations_max_fields';
  locale?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "form_localizations" */
export type Form_Localizations_Max_Order_By = {
  locale?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Form_Localizations_Min_Fields = {
  __typename?: 'form_localizations_min_fields';
  locale?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "form_localizations" */
export type Form_Localizations_Min_Order_By = {
  locale?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "form_localizations" */
export type Form_Localizations_Mutation_Response = {
  __typename?: 'form_localizations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Form_Localizations>;
};

/** on conflict condition type for table "form_localizations" */
export type Form_Localizations_On_Conflict = {
  constraint: Form_Localizations_Constraint;
  update_columns?: Array<Form_Localizations_Update_Column>;
  where?: InputMaybe<Form_Localizations_Bool_Exp>;
};

/** Ordering options when selecting data from "form_localizations". */
export type Form_Localizations_Order_By = {
  dictionary?: InputMaybe<Order_By>;
  locale?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** primary key columns input for table: form_localizations */
export type Form_Localizations_Pk_Columns_Input = {
  locale: Scalars['String'];
  type: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Form_Localizations_Prepend_Input = {
  dictionary?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "form_localizations" */
export enum Form_Localizations_Select_Column {
  /** column name */
  Dictionary = 'dictionary',
  /** column name */
  Locale = 'locale',
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "form_localizations" */
export type Form_Localizations_Set_Input = {
  dictionary?: InputMaybe<Scalars['jsonb']>;
  locale?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

/** update columns of table "form_localizations" */
export enum Form_Localizations_Update_Column {
  /** column name */
  Dictionary = 'dictionary',
  /** column name */
  Locale = 'locale',
  /** column name */
  Type = 'type'
}

/** columns and relationships of "form_types" */
export type Form_Types = {
  __typename?: 'form_types';
  /** fetch data from the table: "form_localizations" */
  form_localizations: Array<Form_Localizations>;
  /** fetch aggregated fields from the table: "form_localizations" */
  form_localizations_aggregate: Form_Localizations_Aggregate;
  id: Scalars['String'];
  on_registration: Scalars['Boolean'];
  schema: Scalars['jsonb'];
  ui_schema?: Maybe<Scalars['jsonb']>;
};


/** columns and relationships of "form_types" */
export type Form_TypesForm_LocalizationsArgs = {
  distinct_on?: InputMaybe<Array<Form_Localizations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Form_Localizations_Order_By>>;
  where?: InputMaybe<Form_Localizations_Bool_Exp>;
};


/** columns and relationships of "form_types" */
export type Form_TypesForm_Localizations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Form_Localizations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Form_Localizations_Order_By>>;
  where?: InputMaybe<Form_Localizations_Bool_Exp>;
};


/** columns and relationships of "form_types" */
export type Form_TypesSchemaArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "form_types" */
export type Form_TypesUi_SchemaArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "form_types" */
export type Form_Types_Aggregate = {
  __typename?: 'form_types_aggregate';
  aggregate?: Maybe<Form_Types_Aggregate_Fields>;
  nodes: Array<Form_Types>;
};

/** aggregate fields of "form_types" */
export type Form_Types_Aggregate_Fields = {
  __typename?: 'form_types_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Form_Types_Max_Fields>;
  min?: Maybe<Form_Types_Min_Fields>;
};


/** aggregate fields of "form_types" */
export type Form_Types_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Form_Types_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Form_Types_Append_Input = {
  schema?: InputMaybe<Scalars['jsonb']>;
  ui_schema?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "form_types". All fields are combined with a logical 'AND'. */
export type Form_Types_Bool_Exp = {
  _and?: InputMaybe<Array<Form_Types_Bool_Exp>>;
  _not?: InputMaybe<Form_Types_Bool_Exp>;
  _or?: InputMaybe<Array<Form_Types_Bool_Exp>>;
  form_localizations?: InputMaybe<Form_Localizations_Bool_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  on_registration?: InputMaybe<Boolean_Comparison_Exp>;
  schema?: InputMaybe<Jsonb_Comparison_Exp>;
  ui_schema?: InputMaybe<Jsonb_Comparison_Exp>;
};

/** unique or primary key constraints on table "form_types" */
export enum Form_Types_Constraint {
  /** unique or primary key constraint */
  FormTypesPkey = 'form_types_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Form_Types_Delete_At_Path_Input = {
  schema?: InputMaybe<Array<Scalars['String']>>;
  ui_schema?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Form_Types_Delete_Elem_Input = {
  schema?: InputMaybe<Scalars['Int']>;
  ui_schema?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Form_Types_Delete_Key_Input = {
  schema?: InputMaybe<Scalars['String']>;
  ui_schema?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "form_types" */
export type Form_Types_Insert_Input = {
  form_localizations?: InputMaybe<Form_Localizations_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['String']>;
  on_registration?: InputMaybe<Scalars['Boolean']>;
  schema?: InputMaybe<Scalars['jsonb']>;
  ui_schema?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate max on columns */
export type Form_Types_Max_Fields = {
  __typename?: 'form_types_max_fields';
  id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Form_Types_Min_Fields = {
  __typename?: 'form_types_min_fields';
  id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "form_types" */
export type Form_Types_Mutation_Response = {
  __typename?: 'form_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Form_Types>;
};

/** input type for inserting object relation for remote table "form_types" */
export type Form_Types_Obj_Rel_Insert_Input = {
  data: Form_Types_Insert_Input;
  /** on conflict condition */
  on_conflict?: InputMaybe<Form_Types_On_Conflict>;
};

/** on conflict condition type for table "form_types" */
export type Form_Types_On_Conflict = {
  constraint: Form_Types_Constraint;
  update_columns?: Array<Form_Types_Update_Column>;
  where?: InputMaybe<Form_Types_Bool_Exp>;
};

/** Ordering options when selecting data from "form_types". */
export type Form_Types_Order_By = {
  form_localizations_aggregate?: InputMaybe<Form_Localizations_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  on_registration?: InputMaybe<Order_By>;
  schema?: InputMaybe<Order_By>;
  ui_schema?: InputMaybe<Order_By>;
};

/** primary key columns input for table: form_types */
export type Form_Types_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Form_Types_Prepend_Input = {
  schema?: InputMaybe<Scalars['jsonb']>;
  ui_schema?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "form_types" */
export enum Form_Types_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  OnRegistration = 'on_registration',
  /** column name */
  Schema = 'schema',
  /** column name */
  UiSchema = 'ui_schema'
}

/** input type for updating data in table "form_types" */
export type Form_Types_Set_Input = {
  id?: InputMaybe<Scalars['String']>;
  on_registration?: InputMaybe<Scalars['Boolean']>;
  schema?: InputMaybe<Scalars['jsonb']>;
  ui_schema?: InputMaybe<Scalars['jsonb']>;
};

/** update columns of table "form_types" */
export enum Form_Types_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  OnRegistration = 'on_registration',
  /** column name */
  Schema = 'schema',
  /** column name */
  UiSchema = 'ui_schema'
}

/** columns and relationships of "forms" */
export type Forms = {
  __typename?: 'forms';
  created_at: Scalars['timestamptz'];
  data: Scalars['jsonb'];
  /** An object relationship */
  form_type: Form_Types;
  patient_id: Scalars['String'];
  type: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  user_id: Scalars['String'];
};


/** columns and relationships of "forms" */
export type FormsDataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "forms" */
export type Forms_Aggregate = {
  __typename?: 'forms_aggregate';
  aggregate?: Maybe<Forms_Aggregate_Fields>;
  nodes: Array<Forms>;
};

/** aggregate fields of "forms" */
export type Forms_Aggregate_Fields = {
  __typename?: 'forms_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Forms_Max_Fields>;
  min?: Maybe<Forms_Min_Fields>;
};


/** aggregate fields of "forms" */
export type Forms_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Forms_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Forms_Append_Input = {
  data?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "forms". All fields are combined with a logical 'AND'. */
export type Forms_Bool_Exp = {
  _and?: InputMaybe<Array<Forms_Bool_Exp>>;
  _not?: InputMaybe<Forms_Bool_Exp>;
  _or?: InputMaybe<Array<Forms_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  data?: InputMaybe<Jsonb_Comparison_Exp>;
  form_type?: InputMaybe<Form_Types_Bool_Exp>;
  patient_id?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "forms" */
export enum Forms_Constraint {
  /** unique or primary key constraint */
  FormsPkey = 'forms_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Forms_Delete_At_Path_Input = {
  data?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Forms_Delete_Elem_Input = {
  data?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Forms_Delete_Key_Input = {
  data?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "forms" */
export type Forms_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  data?: InputMaybe<Scalars['jsonb']>;
  form_type?: InputMaybe<Form_Types_Obj_Rel_Insert_Input>;
  patient_id?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Forms_Max_Fields = {
  __typename?: 'forms_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  patient_id?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Forms_Min_Fields = {
  __typename?: 'forms_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  patient_id?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "forms" */
export type Forms_Mutation_Response = {
  __typename?: 'forms_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Forms>;
};

/** on conflict condition type for table "forms" */
export type Forms_On_Conflict = {
  constraint: Forms_Constraint;
  update_columns?: Array<Forms_Update_Column>;
  where?: InputMaybe<Forms_Bool_Exp>;
};

/** Ordering options when selecting data from "forms". */
export type Forms_Order_By = {
  created_at?: InputMaybe<Order_By>;
  data?: InputMaybe<Order_By>;
  form_type?: InputMaybe<Form_Types_Order_By>;
  patient_id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: forms */
export type Forms_Pk_Columns_Input = {
  patient_id: Scalars['String'];
  type: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Forms_Prepend_Input = {
  data?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "forms" */
export enum Forms_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Data = 'data',
  /** column name */
  PatientId = 'patient_id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "forms" */
export type Forms_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  data?: InputMaybe<Scalars['jsonb']>;
  patient_id?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['String']>;
};

/** update columns of table "forms" */
export enum Forms_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Data = 'data',
  /** column name */
  PatientId = 'patient_id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** columns and relationships of "insurance_vendors" */
export type Insurance_Vendors = {
  __typename?: 'insurance_vendors';
  external_id: Scalars['String'];
  id: Scalars['Int'];
  is_active: Scalars['Boolean'];
  title: Scalars['String'];
};

/** aggregated selection of "insurance_vendors" */
export type Insurance_Vendors_Aggregate = {
  __typename?: 'insurance_vendors_aggregate';
  aggregate?: Maybe<Insurance_Vendors_Aggregate_Fields>;
  nodes: Array<Insurance_Vendors>;
};

/** aggregate fields of "insurance_vendors" */
export type Insurance_Vendors_Aggregate_Fields = {
  __typename?: 'insurance_vendors_aggregate_fields';
  avg?: Maybe<Insurance_Vendors_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Insurance_Vendors_Max_Fields>;
  min?: Maybe<Insurance_Vendors_Min_Fields>;
  stddev?: Maybe<Insurance_Vendors_Stddev_Fields>;
  stddev_pop?: Maybe<Insurance_Vendors_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Insurance_Vendors_Stddev_Samp_Fields>;
  sum?: Maybe<Insurance_Vendors_Sum_Fields>;
  var_pop?: Maybe<Insurance_Vendors_Var_Pop_Fields>;
  var_samp?: Maybe<Insurance_Vendors_Var_Samp_Fields>;
  variance?: Maybe<Insurance_Vendors_Variance_Fields>;
};


/** aggregate fields of "insurance_vendors" */
export type Insurance_Vendors_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Insurance_Vendors_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Insurance_Vendors_Avg_Fields = {
  __typename?: 'insurance_vendors_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "insurance_vendors". All fields are combined with a logical 'AND'. */
export type Insurance_Vendors_Bool_Exp = {
  _and?: InputMaybe<Array<Insurance_Vendors_Bool_Exp>>;
  _not?: InputMaybe<Insurance_Vendors_Bool_Exp>;
  _or?: InputMaybe<Array<Insurance_Vendors_Bool_Exp>>;
  external_id?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  is_active?: InputMaybe<Boolean_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "insurance_vendors" */
export enum Insurance_Vendors_Constraint {
  /** unique or primary key constraint */
  InsuranceVendorsPkey = 'insurance_vendors_pkey'
}

/** input type for incrementing numeric columns in table "insurance_vendors" */
export type Insurance_Vendors_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "insurance_vendors" */
export type Insurance_Vendors_Insert_Input = {
  external_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  title?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Insurance_Vendors_Max_Fields = {
  __typename?: 'insurance_vendors_max_fields';
  external_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Insurance_Vendors_Min_Fields = {
  __typename?: 'insurance_vendors_min_fields';
  external_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "insurance_vendors" */
export type Insurance_Vendors_Mutation_Response = {
  __typename?: 'insurance_vendors_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Insurance_Vendors>;
};

/** input type for inserting object relation for remote table "insurance_vendors" */
export type Insurance_Vendors_Obj_Rel_Insert_Input = {
  data: Insurance_Vendors_Insert_Input;
  /** on conflict condition */
  on_conflict?: InputMaybe<Insurance_Vendors_On_Conflict>;
};

/** on conflict condition type for table "insurance_vendors" */
export type Insurance_Vendors_On_Conflict = {
  constraint: Insurance_Vendors_Constraint;
  update_columns?: Array<Insurance_Vendors_Update_Column>;
  where?: InputMaybe<Insurance_Vendors_Bool_Exp>;
};

/** Ordering options when selecting data from "insurance_vendors". */
export type Insurance_Vendors_Order_By = {
  external_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_active?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
};

/** primary key columns input for table: insurance_vendors */
export type Insurance_Vendors_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "insurance_vendors" */
export enum Insurance_Vendors_Select_Column {
  /** column name */
  ExternalId = 'external_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  Title = 'title'
}

/** input type for updating data in table "insurance_vendors" */
export type Insurance_Vendors_Set_Input = {
  external_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  title?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Insurance_Vendors_Stddev_Fields = {
  __typename?: 'insurance_vendors_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Insurance_Vendors_Stddev_Pop_Fields = {
  __typename?: 'insurance_vendors_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Insurance_Vendors_Stddev_Samp_Fields = {
  __typename?: 'insurance_vendors_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Insurance_Vendors_Sum_Fields = {
  __typename?: 'insurance_vendors_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "insurance_vendors" */
export enum Insurance_Vendors_Update_Column {
  /** column name */
  ExternalId = 'external_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  Title = 'title'
}

/** aggregate var_pop on columns */
export type Insurance_Vendors_Var_Pop_Fields = {
  __typename?: 'insurance_vendors_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Insurance_Vendors_Var_Samp_Fields = {
  __typename?: 'insurance_vendors_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Insurance_Vendors_Variance_Fields = {
  __typename?: 'insurance_vendors_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "insurances" */
export type Insurances = {
  __typename?: 'insurances';
  /** An object relationship */
  contact?: Maybe<Contacts>;
  contact_id?: Maybe<Scalars['Int']>;
  group_policy_id: Scalars['String'];
  id: Scalars['Int'];
  /** An object relationship */
  insurance_vendor: Insurance_Vendors;
  insurance_vendor_id: Scalars['Int'];
  personal_policy_id: Scalars['String'];
  user_id: Scalars['String'];
};

/** aggregated selection of "insurances" */
export type Insurances_Aggregate = {
  __typename?: 'insurances_aggregate';
  aggregate?: Maybe<Insurances_Aggregate_Fields>;
  nodes: Array<Insurances>;
};

/** aggregate fields of "insurances" */
export type Insurances_Aggregate_Fields = {
  __typename?: 'insurances_aggregate_fields';
  avg?: Maybe<Insurances_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Insurances_Max_Fields>;
  min?: Maybe<Insurances_Min_Fields>;
  stddev?: Maybe<Insurances_Stddev_Fields>;
  stddev_pop?: Maybe<Insurances_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Insurances_Stddev_Samp_Fields>;
  sum?: Maybe<Insurances_Sum_Fields>;
  var_pop?: Maybe<Insurances_Var_Pop_Fields>;
  var_samp?: Maybe<Insurances_Var_Samp_Fields>;
  variance?: Maybe<Insurances_Variance_Fields>;
};


/** aggregate fields of "insurances" */
export type Insurances_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Insurances_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Insurances_Avg_Fields = {
  __typename?: 'insurances_avg_fields';
  contact_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  insurance_vendor_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "insurances". All fields are combined with a logical 'AND'. */
export type Insurances_Bool_Exp = {
  _and?: InputMaybe<Array<Insurances_Bool_Exp>>;
  _not?: InputMaybe<Insurances_Bool_Exp>;
  _or?: InputMaybe<Array<Insurances_Bool_Exp>>;
  contact?: InputMaybe<Contacts_Bool_Exp>;
  contact_id?: InputMaybe<Int_Comparison_Exp>;
  group_policy_id?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  insurance_vendor?: InputMaybe<Insurance_Vendors_Bool_Exp>;
  insurance_vendor_id?: InputMaybe<Int_Comparison_Exp>;
  personal_policy_id?: InputMaybe<String_Comparison_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "insurances" */
export enum Insurances_Constraint {
  /** unique or primary key constraint */
  InsurancesPkey = 'insurances_pkey'
}

/** input type for incrementing numeric columns in table "insurances" */
export type Insurances_Inc_Input = {
  contact_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  insurance_vendor_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "insurances" */
export type Insurances_Insert_Input = {
  contact?: InputMaybe<Contacts_Obj_Rel_Insert_Input>;
  contact_id?: InputMaybe<Scalars['Int']>;
  group_policy_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  insurance_vendor?: InputMaybe<Insurance_Vendors_Obj_Rel_Insert_Input>;
  insurance_vendor_id?: InputMaybe<Scalars['Int']>;
  personal_policy_id?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Insurances_Max_Fields = {
  __typename?: 'insurances_max_fields';
  contact_id?: Maybe<Scalars['Int']>;
  group_policy_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  insurance_vendor_id?: Maybe<Scalars['Int']>;
  personal_policy_id?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Insurances_Min_Fields = {
  __typename?: 'insurances_min_fields';
  contact_id?: Maybe<Scalars['Int']>;
  group_policy_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  insurance_vendor_id?: Maybe<Scalars['Int']>;
  personal_policy_id?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "insurances" */
export type Insurances_Mutation_Response = {
  __typename?: 'insurances_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Insurances>;
};

/** input type for inserting object relation for remote table "insurances" */
export type Insurances_Obj_Rel_Insert_Input = {
  data: Insurances_Insert_Input;
  /** on conflict condition */
  on_conflict?: InputMaybe<Insurances_On_Conflict>;
};

/** on conflict condition type for table "insurances" */
export type Insurances_On_Conflict = {
  constraint: Insurances_Constraint;
  update_columns?: Array<Insurances_Update_Column>;
  where?: InputMaybe<Insurances_Bool_Exp>;
};

/** Ordering options when selecting data from "insurances". */
export type Insurances_Order_By = {
  contact?: InputMaybe<Contacts_Order_By>;
  contact_id?: InputMaybe<Order_By>;
  group_policy_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  insurance_vendor?: InputMaybe<Insurance_Vendors_Order_By>;
  insurance_vendor_id?: InputMaybe<Order_By>;
  personal_policy_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: insurances */
export type Insurances_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "insurances" */
export enum Insurances_Select_Column {
  /** column name */
  ContactId = 'contact_id',
  /** column name */
  GroupPolicyId = 'group_policy_id',
  /** column name */
  Id = 'id',
  /** column name */
  InsuranceVendorId = 'insurance_vendor_id',
  /** column name */
  PersonalPolicyId = 'personal_policy_id',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "insurances" */
export type Insurances_Set_Input = {
  contact_id?: InputMaybe<Scalars['Int']>;
  group_policy_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  insurance_vendor_id?: InputMaybe<Scalars['Int']>;
  personal_policy_id?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Insurances_Stddev_Fields = {
  __typename?: 'insurances_stddev_fields';
  contact_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  insurance_vendor_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Insurances_Stddev_Pop_Fields = {
  __typename?: 'insurances_stddev_pop_fields';
  contact_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  insurance_vendor_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Insurances_Stddev_Samp_Fields = {
  __typename?: 'insurances_stddev_samp_fields';
  contact_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  insurance_vendor_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Insurances_Sum_Fields = {
  __typename?: 'insurances_sum_fields';
  contact_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  insurance_vendor_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "insurances" */
export enum Insurances_Update_Column {
  /** column name */
  ContactId = 'contact_id',
  /** column name */
  GroupPolicyId = 'group_policy_id',
  /** column name */
  Id = 'id',
  /** column name */
  InsuranceVendorId = 'insurance_vendor_id',
  /** column name */
  PersonalPolicyId = 'personal_policy_id',
  /** column name */
  UserId = 'user_id'
}

/** aggregate var_pop on columns */
export type Insurances_Var_Pop_Fields = {
  __typename?: 'insurances_var_pop_fields';
  contact_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  insurance_vendor_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Insurances_Var_Samp_Fields = {
  __typename?: 'insurances_var_samp_fields';
  contact_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  insurance_vendor_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Insurances_Variance_Fields = {
  __typename?: 'insurances_variance_fields';
  contact_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  insurance_vendor_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to compare columns of type "json". All fields are combined with logical 'AND'. */
export type Json_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['json']>;
  _gt?: InputMaybe<Scalars['json']>;
  _gte?: InputMaybe<Scalars['json']>;
  _in?: InputMaybe<Array<Scalars['json']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['json']>;
  _lte?: InputMaybe<Scalars['json']>;
  _neq?: InputMaybe<Scalars['json']>;
  _nin?: InputMaybe<Array<Scalars['json']>>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  /** is the column contained in the given json value */
  _contained_in?: InputMaybe<Scalars['jsonb']>;
  /** does the column contain the given json value at the top level */
  _contains?: InputMaybe<Scalars['jsonb']>;
  _eq?: InputMaybe<Scalars['jsonb']>;
  _gt?: InputMaybe<Scalars['jsonb']>;
  _gte?: InputMaybe<Scalars['jsonb']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: InputMaybe<Scalars['String']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: InputMaybe<Array<Scalars['String']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: InputMaybe<Array<Scalars['String']>>;
  _in?: InputMaybe<Array<Scalars['jsonb']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['jsonb']>;
  _lte?: InputMaybe<Scalars['jsonb']>;
  _neq?: InputMaybe<Scalars['jsonb']>;
  _nin?: InputMaybe<Array<Scalars['jsonb']>>;
};

/** columns and relationships of "medication_orders" */
export type Medication_Orders = {
  __typename?: 'medication_orders';
  administration_notes?: Maybe<Scalars['String']>;
  category: Scalars['String'];
  completed_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  dosage: Scalars['String'];
  expiration_date?: Maybe<Scalars['date']>;
  facilitator_id?: Maybe<Scalars['Int']>;
  follow_up_instructions?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  is_deleted?: Maybe<Scalars['Boolean']>;
  is_standing?: Maybe<Scalars['Boolean']>;
  manufacturer?: Maybe<Scalars['String']>;
  medication: Scalars['String'];
  medication_lot_number?: Maybe<Scalars['String']>;
  provider_id: Scalars['Int'];
  provider_orders?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  unread?: Maybe<Scalars['Boolean']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  visit_id: Scalars['Int'];
};

/** aggregated selection of "medication_orders" */
export type Medication_Orders_Aggregate = {
  __typename?: 'medication_orders_aggregate';
  aggregate?: Maybe<Medication_Orders_Aggregate_Fields>;
  nodes: Array<Medication_Orders>;
};

/** aggregate fields of "medication_orders" */
export type Medication_Orders_Aggregate_Fields = {
  __typename?: 'medication_orders_aggregate_fields';
  avg?: Maybe<Medication_Orders_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Medication_Orders_Max_Fields>;
  min?: Maybe<Medication_Orders_Min_Fields>;
  stddev?: Maybe<Medication_Orders_Stddev_Fields>;
  stddev_pop?: Maybe<Medication_Orders_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Medication_Orders_Stddev_Samp_Fields>;
  sum?: Maybe<Medication_Orders_Sum_Fields>;
  var_pop?: Maybe<Medication_Orders_Var_Pop_Fields>;
  var_samp?: Maybe<Medication_Orders_Var_Samp_Fields>;
  variance?: Maybe<Medication_Orders_Variance_Fields>;
};


/** aggregate fields of "medication_orders" */
export type Medication_Orders_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Medication_Orders_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "medication_orders" */
export type Medication_Orders_Aggregate_Order_By = {
  avg?: InputMaybe<Medication_Orders_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Medication_Orders_Max_Order_By>;
  min?: InputMaybe<Medication_Orders_Min_Order_By>;
  stddev?: InputMaybe<Medication_Orders_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Medication_Orders_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Medication_Orders_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Medication_Orders_Sum_Order_By>;
  var_pop?: InputMaybe<Medication_Orders_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Medication_Orders_Var_Samp_Order_By>;
  variance?: InputMaybe<Medication_Orders_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "medication_orders" */
export type Medication_Orders_Arr_Rel_Insert_Input = {
  data: Array<Medication_Orders_Insert_Input>;
  /** on conflict condition */
  on_conflict?: InputMaybe<Medication_Orders_On_Conflict>;
};

/** aggregate avg on columns */
export type Medication_Orders_Avg_Fields = {
  __typename?: 'medication_orders_avg_fields';
  facilitator_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
  visit_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "medication_orders" */
export type Medication_Orders_Avg_Order_By = {
  facilitator_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  provider_id?: InputMaybe<Order_By>;
  visit_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "medication_orders". All fields are combined with a logical 'AND'. */
export type Medication_Orders_Bool_Exp = {
  _and?: InputMaybe<Array<Medication_Orders_Bool_Exp>>;
  _not?: InputMaybe<Medication_Orders_Bool_Exp>;
  _or?: InputMaybe<Array<Medication_Orders_Bool_Exp>>;
  administration_notes?: InputMaybe<String_Comparison_Exp>;
  category?: InputMaybe<String_Comparison_Exp>;
  completed_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  dosage?: InputMaybe<String_Comparison_Exp>;
  expiration_date?: InputMaybe<Date_Comparison_Exp>;
  facilitator_id?: InputMaybe<Int_Comparison_Exp>;
  follow_up_instructions?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  is_deleted?: InputMaybe<Boolean_Comparison_Exp>;
  is_standing?: InputMaybe<Boolean_Comparison_Exp>;
  manufacturer?: InputMaybe<String_Comparison_Exp>;
  medication?: InputMaybe<String_Comparison_Exp>;
  medication_lot_number?: InputMaybe<String_Comparison_Exp>;
  provider_id?: InputMaybe<Int_Comparison_Exp>;
  provider_orders?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  unread?: InputMaybe<Boolean_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  visit_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "medication_orders" */
export enum Medication_Orders_Constraint {
  /** unique or primary key constraint */
  MedicationOrdersPkey = 'medication_orders_pkey'
}

/** input type for incrementing numeric columns in table "medication_orders" */
export type Medication_Orders_Inc_Input = {
  facilitator_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  provider_id?: InputMaybe<Scalars['Int']>;
  visit_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "medication_orders" */
export type Medication_Orders_Insert_Input = {
  administration_notes?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<Scalars['String']>;
  completed_at?: InputMaybe<Scalars['timestamptz']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  dosage?: InputMaybe<Scalars['String']>;
  expiration_date?: InputMaybe<Scalars['date']>;
  facilitator_id?: InputMaybe<Scalars['Int']>;
  follow_up_instructions?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  is_deleted?: InputMaybe<Scalars['Boolean']>;
  is_standing?: InputMaybe<Scalars['Boolean']>;
  manufacturer?: InputMaybe<Scalars['String']>;
  medication?: InputMaybe<Scalars['String']>;
  medication_lot_number?: InputMaybe<Scalars['String']>;
  provider_id?: InputMaybe<Scalars['Int']>;
  provider_orders?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  unread?: InputMaybe<Scalars['Boolean']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  visit_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Medication_Orders_Max_Fields = {
  __typename?: 'medication_orders_max_fields';
  administration_notes?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  completed_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  dosage?: Maybe<Scalars['String']>;
  expiration_date?: Maybe<Scalars['date']>;
  facilitator_id?: Maybe<Scalars['Int']>;
  follow_up_instructions?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  manufacturer?: Maybe<Scalars['String']>;
  medication?: Maybe<Scalars['String']>;
  medication_lot_number?: Maybe<Scalars['String']>;
  provider_id?: Maybe<Scalars['Int']>;
  provider_orders?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  visit_id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "medication_orders" */
export type Medication_Orders_Max_Order_By = {
  administration_notes?: InputMaybe<Order_By>;
  category?: InputMaybe<Order_By>;
  completed_at?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  dosage?: InputMaybe<Order_By>;
  expiration_date?: InputMaybe<Order_By>;
  facilitator_id?: InputMaybe<Order_By>;
  follow_up_instructions?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  manufacturer?: InputMaybe<Order_By>;
  medication?: InputMaybe<Order_By>;
  medication_lot_number?: InputMaybe<Order_By>;
  provider_id?: InputMaybe<Order_By>;
  provider_orders?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  visit_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Medication_Orders_Min_Fields = {
  __typename?: 'medication_orders_min_fields';
  administration_notes?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  completed_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  dosage?: Maybe<Scalars['String']>;
  expiration_date?: Maybe<Scalars['date']>;
  facilitator_id?: Maybe<Scalars['Int']>;
  follow_up_instructions?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  manufacturer?: Maybe<Scalars['String']>;
  medication?: Maybe<Scalars['String']>;
  medication_lot_number?: Maybe<Scalars['String']>;
  provider_id?: Maybe<Scalars['Int']>;
  provider_orders?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  visit_id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "medication_orders" */
export type Medication_Orders_Min_Order_By = {
  administration_notes?: InputMaybe<Order_By>;
  category?: InputMaybe<Order_By>;
  completed_at?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  dosage?: InputMaybe<Order_By>;
  expiration_date?: InputMaybe<Order_By>;
  facilitator_id?: InputMaybe<Order_By>;
  follow_up_instructions?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  manufacturer?: InputMaybe<Order_By>;
  medication?: InputMaybe<Order_By>;
  medication_lot_number?: InputMaybe<Order_By>;
  provider_id?: InputMaybe<Order_By>;
  provider_orders?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  visit_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "medication_orders" */
export type Medication_Orders_Mutation_Response = {
  __typename?: 'medication_orders_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Medication_Orders>;
};

/** on conflict condition type for table "medication_orders" */
export type Medication_Orders_On_Conflict = {
  constraint: Medication_Orders_Constraint;
  update_columns?: Array<Medication_Orders_Update_Column>;
  where?: InputMaybe<Medication_Orders_Bool_Exp>;
};

/** Ordering options when selecting data from "medication_orders". */
export type Medication_Orders_Order_By = {
  administration_notes?: InputMaybe<Order_By>;
  category?: InputMaybe<Order_By>;
  completed_at?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  dosage?: InputMaybe<Order_By>;
  expiration_date?: InputMaybe<Order_By>;
  facilitator_id?: InputMaybe<Order_By>;
  follow_up_instructions?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_deleted?: InputMaybe<Order_By>;
  is_standing?: InputMaybe<Order_By>;
  manufacturer?: InputMaybe<Order_By>;
  medication?: InputMaybe<Order_By>;
  medication_lot_number?: InputMaybe<Order_By>;
  provider_id?: InputMaybe<Order_By>;
  provider_orders?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  unread?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  visit_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: medication_orders */
export type Medication_Orders_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "medication_orders" */
export enum Medication_Orders_Select_Column {
  /** column name */
  AdministrationNotes = 'administration_notes',
  /** column name */
  Category = 'category',
  /** column name */
  CompletedAt = 'completed_at',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Dosage = 'dosage',
  /** column name */
  ExpirationDate = 'expiration_date',
  /** column name */
  FacilitatorId = 'facilitator_id',
  /** column name */
  FollowUpInstructions = 'follow_up_instructions',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  IsStanding = 'is_standing',
  /** column name */
  Manufacturer = 'manufacturer',
  /** column name */
  Medication = 'medication',
  /** column name */
  MedicationLotNumber = 'medication_lot_number',
  /** column name */
  ProviderId = 'provider_id',
  /** column name */
  ProviderOrders = 'provider_orders',
  /** column name */
  Status = 'status',
  /** column name */
  Unread = 'unread',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VisitId = 'visit_id'
}

/** input type for updating data in table "medication_orders" */
export type Medication_Orders_Set_Input = {
  administration_notes?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<Scalars['String']>;
  completed_at?: InputMaybe<Scalars['timestamptz']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  dosage?: InputMaybe<Scalars['String']>;
  expiration_date?: InputMaybe<Scalars['date']>;
  facilitator_id?: InputMaybe<Scalars['Int']>;
  follow_up_instructions?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  is_deleted?: InputMaybe<Scalars['Boolean']>;
  is_standing?: InputMaybe<Scalars['Boolean']>;
  manufacturer?: InputMaybe<Scalars['String']>;
  medication?: InputMaybe<Scalars['String']>;
  medication_lot_number?: InputMaybe<Scalars['String']>;
  provider_id?: InputMaybe<Scalars['Int']>;
  provider_orders?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  unread?: InputMaybe<Scalars['Boolean']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  visit_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Medication_Orders_Stddev_Fields = {
  __typename?: 'medication_orders_stddev_fields';
  facilitator_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
  visit_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "medication_orders" */
export type Medication_Orders_Stddev_Order_By = {
  facilitator_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  provider_id?: InputMaybe<Order_By>;
  visit_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Medication_Orders_Stddev_Pop_Fields = {
  __typename?: 'medication_orders_stddev_pop_fields';
  facilitator_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
  visit_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "medication_orders" */
export type Medication_Orders_Stddev_Pop_Order_By = {
  facilitator_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  provider_id?: InputMaybe<Order_By>;
  visit_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Medication_Orders_Stddev_Samp_Fields = {
  __typename?: 'medication_orders_stddev_samp_fields';
  facilitator_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
  visit_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "medication_orders" */
export type Medication_Orders_Stddev_Samp_Order_By = {
  facilitator_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  provider_id?: InputMaybe<Order_By>;
  visit_id?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Medication_Orders_Sum_Fields = {
  __typename?: 'medication_orders_sum_fields';
  facilitator_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  provider_id?: Maybe<Scalars['Int']>;
  visit_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "medication_orders" */
export type Medication_Orders_Sum_Order_By = {
  facilitator_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  provider_id?: InputMaybe<Order_By>;
  visit_id?: InputMaybe<Order_By>;
};

/** update columns of table "medication_orders" */
export enum Medication_Orders_Update_Column {
  /** column name */
  AdministrationNotes = 'administration_notes',
  /** column name */
  Category = 'category',
  /** column name */
  CompletedAt = 'completed_at',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Dosage = 'dosage',
  /** column name */
  ExpirationDate = 'expiration_date',
  /** column name */
  FacilitatorId = 'facilitator_id',
  /** column name */
  FollowUpInstructions = 'follow_up_instructions',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  IsStanding = 'is_standing',
  /** column name */
  Manufacturer = 'manufacturer',
  /** column name */
  Medication = 'medication',
  /** column name */
  MedicationLotNumber = 'medication_lot_number',
  /** column name */
  ProviderId = 'provider_id',
  /** column name */
  ProviderOrders = 'provider_orders',
  /** column name */
  Status = 'status',
  /** column name */
  Unread = 'unread',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VisitId = 'visit_id'
}

/** aggregate var_pop on columns */
export type Medication_Orders_Var_Pop_Fields = {
  __typename?: 'medication_orders_var_pop_fields';
  facilitator_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
  visit_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "medication_orders" */
export type Medication_Orders_Var_Pop_Order_By = {
  facilitator_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  provider_id?: InputMaybe<Order_By>;
  visit_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Medication_Orders_Var_Samp_Fields = {
  __typename?: 'medication_orders_var_samp_fields';
  facilitator_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
  visit_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "medication_orders" */
export type Medication_Orders_Var_Samp_Order_By = {
  facilitator_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  provider_id?: InputMaybe<Order_By>;
  visit_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Medication_Orders_Variance_Fields = {
  __typename?: 'medication_orders_variance_fields';
  facilitator_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
  visit_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "medication_orders" */
export type Medication_Orders_Variance_Order_By = {
  facilitator_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  provider_id?: InputMaybe<Order_By>;
  visit_id?: InputMaybe<Order_By>;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  /** delete data from the table: "additional_care_providers" */
  delete_additional_care_providers?: Maybe<Additional_Care_Providers_Mutation_Response>;
  /** delete single row from the table: "additional_care_providers" */
  delete_additional_care_providers_by_pk?: Maybe<Additional_Care_Providers>;
  /** delete data from the table: "allergies" */
  delete_allergies?: Maybe<Allergies_Mutation_Response>;
  /** delete single row from the table: "allergies" */
  delete_allergies_by_pk?: Maybe<Allergies>;
  /** delete data from the table: "clearance_notes" */
  delete_clearance_notes?: Maybe<Clearance_Notes_Mutation_Response>;
  /** delete single row from the table: "clearance_notes" */
  delete_clearance_notes_by_pk?: Maybe<Clearance_Notes>;
  /** delete data from the table: "contacts" */
  delete_contacts?: Maybe<Contacts_Mutation_Response>;
  /** delete single row from the table: "contacts" */
  delete_contacts_by_pk?: Maybe<Contacts>;
  /** delete data from the table: "contacts_to_patients" */
  delete_contacts_to_patients?: Maybe<Contacts_To_Patients_Mutation_Response>;
  /** delete single row from the table: "contacts_to_patients" */
  delete_contacts_to_patients_by_pk?: Maybe<Contacts_To_Patients>;
  /** delete data from the table: "discharges" */
  delete_discharges?: Maybe<Discharges_Mutation_Response>;
  /** delete single row from the table: "discharges" */
  delete_discharges_by_pk?: Maybe<Discharges>;
  /** delete data from the table: "enrollments" */
  delete_enrollments?: Maybe<Enrollments_Mutation_Response>;
  /** delete single row from the table: "enrollments" */
  delete_enrollments_by_pk?: Maybe<Enrollments>;
  /** delete data from the table: "event_types" */
  delete_event_types?: Maybe<Event_Types_Mutation_Response>;
  /** delete single row from the table: "event_types" */
  delete_event_types_by_pk?: Maybe<Event_Types>;
  /** delete data from the table: "events" */
  delete_events?: Maybe<Events_Mutation_Response>;
  /** delete single row from the table: "events" */
  delete_events_by_pk?: Maybe<Events>;
  /** delete data from the table: "form_localizations" */
  delete_form_localizations?: Maybe<Form_Localizations_Mutation_Response>;
  /** delete single row from the table: "form_localizations" */
  delete_form_localizations_by_pk?: Maybe<Form_Localizations>;
  /** delete data from the table: "form_types" */
  delete_form_types?: Maybe<Form_Types_Mutation_Response>;
  /** delete single row from the table: "form_types" */
  delete_form_types_by_pk?: Maybe<Form_Types>;
  /** delete data from the table: "forms" */
  delete_forms?: Maybe<Forms_Mutation_Response>;
  /** delete single row from the table: "forms" */
  delete_forms_by_pk?: Maybe<Forms>;
  /** delete data from the table: "insurance_vendors" */
  delete_insurance_vendors?: Maybe<Insurance_Vendors_Mutation_Response>;
  /** delete single row from the table: "insurance_vendors" */
  delete_insurance_vendors_by_pk?: Maybe<Insurance_Vendors>;
  /** delete data from the table: "insurances" */
  delete_insurances?: Maybe<Insurances_Mutation_Response>;
  /** delete single row from the table: "insurances" */
  delete_insurances_by_pk?: Maybe<Insurances>;
  /** delete data from the table: "medication_orders" */
  delete_medication_orders?: Maybe<Medication_Orders_Mutation_Response>;
  /** delete single row from the table: "medication_orders" */
  delete_medication_orders_by_pk?: Maybe<Medication_Orders>;
  /** delete data from the table: "order_dshs_covid_test" */
  delete_order_dshs_covid_test?: Maybe<Order_Dshs_Covid_Test_Mutation_Response>;
  /** delete single row from the table: "order_dshs_covid_test" */
  delete_order_dshs_covid_test_by_pk?: Maybe<Order_Dshs_Covid_Test>;
  /** delete data from the table: "order_height_and_weight" */
  delete_order_height_and_weight?: Maybe<Order_Height_And_Weight_Mutation_Response>;
  /** delete single row from the table: "order_height_and_weight" */
  delete_order_height_and_weight_by_pk?: Maybe<Order_Height_And_Weight>;
  /** delete data from the table: "order_medical_assessment" */
  delete_order_medical_assessment?: Maybe<Order_Medical_Assessment_Mutation_Response>;
  /** delete single row from the table: "order_medical_assessment" */
  delete_order_medical_assessment_by_pk?: Maybe<Order_Medical_Assessment>;
  /** delete data from the table: "order_musculoskeletal" */
  delete_order_musculoskeletal?: Maybe<Order_Musculoskeletal_Mutation_Response>;
  /** delete single row from the table: "order_musculoskeletal" */
  delete_order_musculoskeletal_by_pk?: Maybe<Order_Musculoskeletal>;
  /** delete data from the table: "order_type" */
  delete_order_type?: Maybe<Order_Type_Mutation_Response>;
  /** delete single row from the table: "order_type" */
  delete_order_type_by_pk?: Maybe<Order_Type>;
  /** delete data from the table: "order_vision" */
  delete_order_vision?: Maybe<Order_Vision_Mutation_Response>;
  /** delete single row from the table: "order_vision" */
  delete_order_vision_by_pk?: Maybe<Order_Vision>;
  /** delete data from the table: "orders" */
  delete_orders?: Maybe<Orders_Mutation_Response>;
  /** delete single row from the table: "orders" */
  delete_orders_by_pk?: Maybe<Orders>;
  /** delete data from the table: "organizations.district_days_off" */
  delete_organizations_district_days_off?: Maybe<Organizations_District_Days_Off_Mutation_Response>;
  /** delete single row from the table: "organizations.district_days_off" */
  delete_organizations_district_days_off_by_pk?: Maybe<Organizations_District_Days_Off>;
  /** delete data from the table: "organizations.features" */
  delete_organizations_features?: Maybe<Organizations_Features_Mutation_Response>;
  /** delete single row from the table: "organizations.features" */
  delete_organizations_features_by_pk?: Maybe<Organizations_Features>;
  /** delete data from the table: "organizations.locations" */
  delete_organizations_locations?: Maybe<Organizations_Locations_Mutation_Response>;
  /** delete single row from the table: "organizations.locations" */
  delete_organizations_locations_by_pk?: Maybe<Organizations_Locations>;
  /** delete data from the table: "organizations.organizations" */
  delete_organizations_organizations?: Maybe<Organizations_Organizations_Mutation_Response>;
  /** delete single row from the table: "organizations.organizations" */
  delete_organizations_organizations_by_pk?: Maybe<Organizations_Organizations>;
  /** delete data from the table: "organizations.organizations_features" */
  delete_organizations_organizations_features?: Maybe<Organizations_Organizations_Features_Mutation_Response>;
  /** delete single row from the table: "organizations.organizations_features" */
  delete_organizations_organizations_features_by_pk?: Maybe<Organizations_Organizations_Features>;
  /** delete data from the table: "organizations.regions" */
  delete_organizations_regions?: Maybe<Organizations_Regions_Mutation_Response>;
  /** delete single row from the table: "organizations.regions" */
  delete_organizations_regions_by_pk?: Maybe<Organizations_Regions>;
  /** delete data from the table: "organizations.organization_x_schoolyear" */
  delete_organizations_school_years?: Maybe<Organizations_School_Years_Mutation_Response>;
  /** delete single row from the table: "organizations.organization_x_schoolyear" */
  delete_organizations_school_years_by_pk?: Maybe<Organizations_School_Years>;
  /** delete data from the table: "organizations.location_x_testing_location" */
  delete_organizations_testing_locations?: Maybe<Organizations_Testing_Locations_Mutation_Response>;
  /** delete single row from the table: "organizations.location_x_testing_location" */
  delete_organizations_testing_locations_by_pk?: Maybe<Organizations_Testing_Locations>;
  /** delete data from the table: "mat_tmp_fast_demographics" */
  delete_patient_demographics?: Maybe<Patient_Demographics_Mutation_Response>;
  /** delete data from the table: "mat_fast_search_json" */
  delete_patients?: Maybe<Patients_Mutation_Response>;
  /** delete data from the table: "pharmacies" */
  delete_pharmacies?: Maybe<Pharmacies_Mutation_Response>;
  /** delete single row from the table: "pharmacies" */
  delete_pharmacies_by_pk?: Maybe<Pharmacies>;
  /** delete data from the table: "place_of_service" */
  delete_place_of_service?: Maybe<Place_Of_Service_Mutation_Response>;
  /** delete single row from the table: "place_of_service" */
  delete_place_of_service_by_pk?: Maybe<Place_Of_Service>;
  /** delete data from the table: "pond_form_localizations" */
  delete_pond_form_localizations?: Maybe<Pond_Form_Localizations_Mutation_Response>;
  /** delete single row from the table: "pond_form_localizations" */
  delete_pond_form_localizations_by_pk?: Maybe<Pond_Form_Localizations>;
  /** delete data from the table: "pond_form_types" */
  delete_pond_form_types?: Maybe<Pond_Form_Types_Mutation_Response>;
  /** delete single row from the table: "pond_form_types" */
  delete_pond_form_types_by_pk?: Maybe<Pond_Form_Types>;
  /** delete data from the table: "pond_forms" */
  delete_pond_forms?: Maybe<Pond_Forms_Mutation_Response>;
  /** delete single row from the table: "pond_forms" */
  delete_pond_forms_by_pk?: Maybe<Pond_Forms>;
  /** delete data from the table: "patients" */
  delete_portal_patients?: Maybe<Portal_Patients_Mutation_Response>;
  /** delete single row from the table: "patients" */
  delete_portal_patients_by_pk?: Maybe<Portal_Patients>;
  /** delete data from the table: "programs" */
  delete_programs?: Maybe<Programs_Mutation_Response>;
  /** delete single row from the table: "programs" */
  delete_programs_by_pk?: Maybe<Programs>;
  /** delete data from the table: "providers" */
  delete_providers?: Maybe<Providers_Mutation_Response>;
  /** delete single row from the table: "providers" */
  delete_providers_by_pk?: Maybe<Providers>;
  /** delete data from the table: "roles" */
  delete_roles?: Maybe<Roles_Mutation_Response>;
  /** delete single row from the table: "roles" */
  delete_roles_by_pk?: Maybe<Roles>;
  /** delete data from the table: "session_logs" */
  delete_session_logs?: Maybe<Session_Logs_Mutation_Response>;
  /** delete single row from the table: "session_logs" */
  delete_session_logs_by_pk?: Maybe<Session_Logs>;
  /** delete data from the table: "session_order_config" */
  delete_session_order_config?: Maybe<Session_Order_Config_Mutation_Response>;
  /** delete single row from the table: "session_order_config" */
  delete_session_order_config_by_pk?: Maybe<Session_Order_Config>;
  /** delete data from the table: "session_station_config" */
  delete_session_station_config?: Maybe<Session_Station_Config_Mutation_Response>;
  /** delete single row from the table: "session_station_config" */
  delete_session_station_config_by_pk?: Maybe<Session_Station_Config>;
  /** delete data from the table: "session_type" */
  delete_session_type?: Maybe<Session_Type_Mutation_Response>;
  /** delete single row from the table: "session_type" */
  delete_session_type_by_pk?: Maybe<Session_Type>;
  /** delete data from the table: "sessions" */
  delete_sessions?: Maybe<Sessions_Mutation_Response>;
  /** delete single row from the table: "sessions" */
  delete_sessions_by_pk?: Maybe<Sessions>;
  /** delete data from the table: "station_orders" */
  delete_station_orders?: Maybe<Station_Orders_Mutation_Response>;
  /** delete single row from the table: "station_orders" */
  delete_station_orders_by_pk?: Maybe<Station_Orders>;
  /** delete data from the table: "station_type" */
  delete_station_type?: Maybe<Station_Type_Mutation_Response>;
  /** delete single row from the table: "station_type" */
  delete_station_type_by_pk?: Maybe<Station_Type>;
  /** delete data from the table: "station_visits" */
  delete_station_visits?: Maybe<Station_Visits_Mutation_Response>;
  /** delete single row from the table: "station_visits" */
  delete_station_visits_by_pk?: Maybe<Station_Visits>;
  /** delete data from the table: "stations" */
  delete_stations?: Maybe<Stations_Mutation_Response>;
  /** delete single row from the table: "stations" */
  delete_stations_by_pk?: Maybe<Stations>;
  /** delete data from the table: "test_orders" */
  delete_test_orders?: Maybe<Test_Orders_Mutation_Response>;
  /** delete single row from the table: "test_orders" */
  delete_test_orders_by_pk?: Maybe<Test_Orders>;
  /** delete data from the table: "users" */
  delete_users?: Maybe<Users_Mutation_Response>;
  /** delete single row from the table: "users" */
  delete_users_by_pk?: Maybe<Users>;
  /** delete data from the table: "video_calls" */
  delete_video_calls?: Maybe<Video_Calls_Mutation_Response>;
  /** delete single row from the table: "video_calls" */
  delete_video_calls_by_pk?: Maybe<Video_Calls>;
  /** delete data from the table: "visits" */
  delete_visits?: Maybe<Visits_Mutation_Response>;
  /** delete single row from the table: "visits" */
  delete_visits_by_pk?: Maybe<Visits>;
  /** get_sendbird_token */
  get_sendbird_token: Sendbird_Response;
  /** insert data into the table: "additional_care_providers" */
  insert_additional_care_providers?: Maybe<Additional_Care_Providers_Mutation_Response>;
  /** insert a single row into the table: "additional_care_providers" */
  insert_additional_care_providers_one?: Maybe<Additional_Care_Providers>;
  /** insert data into the table: "allergies" */
  insert_allergies?: Maybe<Allergies_Mutation_Response>;
  /** insert a single row into the table: "allergies" */
  insert_allergies_one?: Maybe<Allergies>;
  /** insert data into the table: "clearance_notes" */
  insert_clearance_notes?: Maybe<Clearance_Notes_Mutation_Response>;
  /** insert a single row into the table: "clearance_notes" */
  insert_clearance_notes_one?: Maybe<Clearance_Notes>;
  /** insert data into the table: "contacts" */
  insert_contacts?: Maybe<Contacts_Mutation_Response>;
  /** insert a single row into the table: "contacts" */
  insert_contacts_one?: Maybe<Contacts>;
  /** insert data into the table: "contacts_to_patients" */
  insert_contacts_to_patients?: Maybe<Contacts_To_Patients_Mutation_Response>;
  /** insert a single row into the table: "contacts_to_patients" */
  insert_contacts_to_patients_one?: Maybe<Contacts_To_Patients>;
  /** insert data into the table: "discharges" */
  insert_discharges?: Maybe<Discharges_Mutation_Response>;
  /** insert a single row into the table: "discharges" */
  insert_discharges_one?: Maybe<Discharges>;
  /** insert data into the table: "enrollments" */
  insert_enrollments?: Maybe<Enrollments_Mutation_Response>;
  /** insert a single row into the table: "enrollments" */
  insert_enrollments_one?: Maybe<Enrollments>;
  /** insert data into the table: "event_types" */
  insert_event_types?: Maybe<Event_Types_Mutation_Response>;
  /** insert a single row into the table: "event_types" */
  insert_event_types_one?: Maybe<Event_Types>;
  /** insert data into the table: "events" */
  insert_events?: Maybe<Events_Mutation_Response>;
  /** insert a single row into the table: "events" */
  insert_events_one?: Maybe<Events>;
  insert_form_images_one: Form_Images_Insert;
  /** insert data into the table: "form_localizations" */
  insert_form_localizations?: Maybe<Form_Localizations_Mutation_Response>;
  /** insert a single row into the table: "form_localizations" */
  insert_form_localizations_one?: Maybe<Form_Localizations>;
  /** insert data into the table: "form_types" */
  insert_form_types?: Maybe<Form_Types_Mutation_Response>;
  /** insert a single row into the table: "form_types" */
  insert_form_types_one?: Maybe<Form_Types>;
  /** insert data into the table: "forms" */
  insert_forms?: Maybe<Forms_Mutation_Response>;
  /** insert a single row into the table: "forms" */
  insert_forms_one?: Maybe<Forms>;
  /** insert data into the table: "insurance_vendors" */
  insert_insurance_vendors?: Maybe<Insurance_Vendors_Mutation_Response>;
  /** insert a single row into the table: "insurance_vendors" */
  insert_insurance_vendors_one?: Maybe<Insurance_Vendors>;
  /** insert data into the table: "insurances" */
  insert_insurances?: Maybe<Insurances_Mutation_Response>;
  /** insert a single row into the table: "insurances" */
  insert_insurances_one?: Maybe<Insurances>;
  /** insert data into the table: "medication_orders" */
  insert_medication_orders?: Maybe<Medication_Orders_Mutation_Response>;
  /** insert a single row into the table: "medication_orders" */
  insert_medication_orders_one?: Maybe<Medication_Orders>;
  /** insert data into the table: "order_dshs_covid_test" */
  insert_order_dshs_covid_test?: Maybe<Order_Dshs_Covid_Test_Mutation_Response>;
  /** insert a single row into the table: "order_dshs_covid_test" */
  insert_order_dshs_covid_test_one?: Maybe<Order_Dshs_Covid_Test>;
  /** insert data into the table: "order_height_and_weight" */
  insert_order_height_and_weight?: Maybe<Order_Height_And_Weight_Mutation_Response>;
  /** insert a single row into the table: "order_height_and_weight" */
  insert_order_height_and_weight_one?: Maybe<Order_Height_And_Weight>;
  /** insert data into the table: "order_medical_assessment" */
  insert_order_medical_assessment?: Maybe<Order_Medical_Assessment_Mutation_Response>;
  /** insert a single row into the table: "order_medical_assessment" */
  insert_order_medical_assessment_one?: Maybe<Order_Medical_Assessment>;
  /** insert data into the table: "order_musculoskeletal" */
  insert_order_musculoskeletal?: Maybe<Order_Musculoskeletal_Mutation_Response>;
  /** insert a single row into the table: "order_musculoskeletal" */
  insert_order_musculoskeletal_one?: Maybe<Order_Musculoskeletal>;
  /** insert data into the table: "order_type" */
  insert_order_type?: Maybe<Order_Type_Mutation_Response>;
  /** insert a single row into the table: "order_type" */
  insert_order_type_one?: Maybe<Order_Type>;
  /** insert data into the table: "order_vision" */
  insert_order_vision?: Maybe<Order_Vision_Mutation_Response>;
  /** insert a single row into the table: "order_vision" */
  insert_order_vision_one?: Maybe<Order_Vision>;
  /** insert data into the table: "orders" */
  insert_orders?: Maybe<Orders_Mutation_Response>;
  /** insert a single row into the table: "orders" */
  insert_orders_one?: Maybe<Orders>;
  /** insert data into the table: "organizations.district_days_off" */
  insert_organizations_district_days_off?: Maybe<Organizations_District_Days_Off_Mutation_Response>;
  /** insert a single row into the table: "organizations.district_days_off" */
  insert_organizations_district_days_off_one?: Maybe<Organizations_District_Days_Off>;
  /** insert data into the table: "organizations.features" */
  insert_organizations_features?: Maybe<Organizations_Features_Mutation_Response>;
  /** insert a single row into the table: "organizations.features" */
  insert_organizations_features_one?: Maybe<Organizations_Features>;
  /** insert data into the table: "organizations.locations" */
  insert_organizations_locations?: Maybe<Organizations_Locations_Mutation_Response>;
  /** insert a single row into the table: "organizations.locations" */
  insert_organizations_locations_one?: Maybe<Organizations_Locations>;
  /** insert data into the table: "organizations.organizations" */
  insert_organizations_organizations?: Maybe<Organizations_Organizations_Mutation_Response>;
  /** insert data into the table: "organizations.organizations_features" */
  insert_organizations_organizations_features?: Maybe<Organizations_Organizations_Features_Mutation_Response>;
  /** insert a single row into the table: "organizations.organizations_features" */
  insert_organizations_organizations_features_one?: Maybe<Organizations_Organizations_Features>;
  /** insert a single row into the table: "organizations.organizations" */
  insert_organizations_organizations_one?: Maybe<Organizations_Organizations>;
  /** insert data into the table: "organizations.regions" */
  insert_organizations_regions?: Maybe<Organizations_Regions_Mutation_Response>;
  /** insert a single row into the table: "organizations.regions" */
  insert_organizations_regions_one?: Maybe<Organizations_Regions>;
  /** insert data into the table: "organizations.organization_x_schoolyear" */
  insert_organizations_school_years?: Maybe<Organizations_School_Years_Mutation_Response>;
  /** insert a single row into the table: "organizations.organization_x_schoolyear" */
  insert_organizations_school_years_one?: Maybe<Organizations_School_Years>;
  /** insert data into the table: "organizations.location_x_testing_location" */
  insert_organizations_testing_locations?: Maybe<Organizations_Testing_Locations_Mutation_Response>;
  /** insert a single row into the table: "organizations.location_x_testing_location" */
  insert_organizations_testing_locations_one?: Maybe<Organizations_Testing_Locations>;
  /** insert data into the table: "mat_tmp_fast_demographics" */
  insert_patient_demographics?: Maybe<Patient_Demographics_Mutation_Response>;
  /** insert a single row into the table: "mat_tmp_fast_demographics" */
  insert_patient_demographics_one?: Maybe<Patient_Demographics>;
  /** insert data into the table: "mat_fast_search_json" */
  insert_patients?: Maybe<Patients_Mutation_Response>;
  /** insert a single row into the table: "mat_fast_search_json" */
  insert_patients_one?: Maybe<Patients>;
  /** insert data into the table: "pharmacies" */
  insert_pharmacies?: Maybe<Pharmacies_Mutation_Response>;
  /** insert a single row into the table: "pharmacies" */
  insert_pharmacies_one?: Maybe<Pharmacies>;
  /** insert data into the table: "place_of_service" */
  insert_place_of_service?: Maybe<Place_Of_Service_Mutation_Response>;
  /** insert a single row into the table: "place_of_service" */
  insert_place_of_service_one?: Maybe<Place_Of_Service>;
  /** insert data into the table: "pond_form_localizations" */
  insert_pond_form_localizations?: Maybe<Pond_Form_Localizations_Mutation_Response>;
  /** insert a single row into the table: "pond_form_localizations" */
  insert_pond_form_localizations_one?: Maybe<Pond_Form_Localizations>;
  /** insert data into the table: "pond_form_types" */
  insert_pond_form_types?: Maybe<Pond_Form_Types_Mutation_Response>;
  /** insert a single row into the table: "pond_form_types" */
  insert_pond_form_types_one?: Maybe<Pond_Form_Types>;
  /** insert data into the table: "pond_forms" */
  insert_pond_forms?: Maybe<Pond_Forms_Mutation_Response>;
  /** insert a single row into the table: "pond_forms" */
  insert_pond_forms_one?: Maybe<Pond_Forms>;
  /** insert data into the table: "patients" */
  insert_portal_patients?: Maybe<Portal_Patients_Mutation_Response>;
  /** insert a single row into the table: "patients" */
  insert_portal_patients_one?: Maybe<Portal_Patients>;
  /** insert data into the table: "programs" */
  insert_programs?: Maybe<Programs_Mutation_Response>;
  /** insert a single row into the table: "programs" */
  insert_programs_one?: Maybe<Programs>;
  /** insert data into the table: "providers" */
  insert_providers?: Maybe<Providers_Mutation_Response>;
  /** insert a single row into the table: "providers" */
  insert_providers_one?: Maybe<Providers>;
  /** insert data into the table: "roles" */
  insert_roles?: Maybe<Roles_Mutation_Response>;
  /** insert a single row into the table: "roles" */
  insert_roles_one?: Maybe<Roles>;
  /** insert data into the table: "session_logs" */
  insert_session_logs?: Maybe<Session_Logs_Mutation_Response>;
  /** insert a single row into the table: "session_logs" */
  insert_session_logs_one?: Maybe<Session_Logs>;
  /** insert data into the table: "session_order_config" */
  insert_session_order_config?: Maybe<Session_Order_Config_Mutation_Response>;
  /** insert a single row into the table: "session_order_config" */
  insert_session_order_config_one?: Maybe<Session_Order_Config>;
  /** insert data into the table: "session_station_config" */
  insert_session_station_config?: Maybe<Session_Station_Config_Mutation_Response>;
  /** insert a single row into the table: "session_station_config" */
  insert_session_station_config_one?: Maybe<Session_Station_Config>;
  /** insert data into the table: "session_type" */
  insert_session_type?: Maybe<Session_Type_Mutation_Response>;
  /** insert a single row into the table: "session_type" */
  insert_session_type_one?: Maybe<Session_Type>;
  /** insert data into the table: "sessions" */
  insert_sessions?: Maybe<Sessions_Mutation_Response>;
  /** insert a single row into the table: "sessions" */
  insert_sessions_one?: Maybe<Sessions>;
  /** insert data into the table: "station_orders" */
  insert_station_orders?: Maybe<Station_Orders_Mutation_Response>;
  /** insert a single row into the table: "station_orders" */
  insert_station_orders_one?: Maybe<Station_Orders>;
  /** insert data into the table: "station_type" */
  insert_station_type?: Maybe<Station_Type_Mutation_Response>;
  /** insert a single row into the table: "station_type" */
  insert_station_type_one?: Maybe<Station_Type>;
  /** insert data into the table: "station_visits" */
  insert_station_visits?: Maybe<Station_Visits_Mutation_Response>;
  /** insert a single row into the table: "station_visits" */
  insert_station_visits_one?: Maybe<Station_Visits>;
  /** insert data into the table: "stations" */
  insert_stations?: Maybe<Stations_Mutation_Response>;
  /** insert a single row into the table: "stations" */
  insert_stations_one?: Maybe<Stations>;
  /** insert data into the table: "test_orders" */
  insert_test_orders?: Maybe<Test_Orders_Mutation_Response>;
  /** insert a single row into the table: "test_orders" */
  insert_test_orders_one?: Maybe<Test_Orders>;
  /** insert data into the table: "users" */
  insert_users?: Maybe<Users_Mutation_Response>;
  /** insert a single row into the table: "users" */
  insert_users_one?: Maybe<Users>;
  /** insert data into the table: "video_calls" */
  insert_video_calls?: Maybe<Video_Calls_Mutation_Response>;
  /** insert a single row into the table: "video_calls" */
  insert_video_calls_one?: Maybe<Video_Calls>;
  /** insert data into the table: "visits" */
  insert_visits?: Maybe<Visits_Mutation_Response>;
  /** insert a single row into the table: "visits" */
  insert_visits_one?: Maybe<Visits>;
  submit_patient_registration?: Maybe<Submit_Patient_Registration_Response>;
  /** update data of the table: "additional_care_providers" */
  update_additional_care_providers?: Maybe<Additional_Care_Providers_Mutation_Response>;
  /** update single row of the table: "additional_care_providers" */
  update_additional_care_providers_by_pk?: Maybe<Additional_Care_Providers>;
  /** update data of the table: "allergies" */
  update_allergies?: Maybe<Allergies_Mutation_Response>;
  /** update single row of the table: "allergies" */
  update_allergies_by_pk?: Maybe<Allergies>;
  /** update data of the table: "clearance_notes" */
  update_clearance_notes?: Maybe<Clearance_Notes_Mutation_Response>;
  /** update single row of the table: "clearance_notes" */
  update_clearance_notes_by_pk?: Maybe<Clearance_Notes>;
  /** update data of the table: "contacts" */
  update_contacts?: Maybe<Contacts_Mutation_Response>;
  /** update single row of the table: "contacts" */
  update_contacts_by_pk?: Maybe<Contacts>;
  /** update data of the table: "contacts_to_patients" */
  update_contacts_to_patients?: Maybe<Contacts_To_Patients_Mutation_Response>;
  /** update single row of the table: "contacts_to_patients" */
  update_contacts_to_patients_by_pk?: Maybe<Contacts_To_Patients>;
  /** update data of the table: "discharges" */
  update_discharges?: Maybe<Discharges_Mutation_Response>;
  /** update single row of the table: "discharges" */
  update_discharges_by_pk?: Maybe<Discharges>;
  /** update data of the table: "enrollments" */
  update_enrollments?: Maybe<Enrollments_Mutation_Response>;
  /** update single row of the table: "enrollments" */
  update_enrollments_by_pk?: Maybe<Enrollments>;
  /** update data of the table: "event_types" */
  update_event_types?: Maybe<Event_Types_Mutation_Response>;
  /** update single row of the table: "event_types" */
  update_event_types_by_pk?: Maybe<Event_Types>;
  /** update data of the table: "events" */
  update_events?: Maybe<Events_Mutation_Response>;
  /** update single row of the table: "events" */
  update_events_by_pk?: Maybe<Events>;
  /** update data of the table: "form_localizations" */
  update_form_localizations?: Maybe<Form_Localizations_Mutation_Response>;
  /** update single row of the table: "form_localizations" */
  update_form_localizations_by_pk?: Maybe<Form_Localizations>;
  /** update data of the table: "form_types" */
  update_form_types?: Maybe<Form_Types_Mutation_Response>;
  /** update single row of the table: "form_types" */
  update_form_types_by_pk?: Maybe<Form_Types>;
  /** update data of the table: "forms" */
  update_forms?: Maybe<Forms_Mutation_Response>;
  /** update single row of the table: "forms" */
  update_forms_by_pk?: Maybe<Forms>;
  /** update data of the table: "insurance_vendors" */
  update_insurance_vendors?: Maybe<Insurance_Vendors_Mutation_Response>;
  /** update single row of the table: "insurance_vendors" */
  update_insurance_vendors_by_pk?: Maybe<Insurance_Vendors>;
  /** update data of the table: "insurances" */
  update_insurances?: Maybe<Insurances_Mutation_Response>;
  /** update single row of the table: "insurances" */
  update_insurances_by_pk?: Maybe<Insurances>;
  /** update data of the table: "medication_orders" */
  update_medication_orders?: Maybe<Medication_Orders_Mutation_Response>;
  /** update single row of the table: "medication_orders" */
  update_medication_orders_by_pk?: Maybe<Medication_Orders>;
  /** update data of the table: "order_dshs_covid_test" */
  update_order_dshs_covid_test?: Maybe<Order_Dshs_Covid_Test_Mutation_Response>;
  /** update single row of the table: "order_dshs_covid_test" */
  update_order_dshs_covid_test_by_pk?: Maybe<Order_Dshs_Covid_Test>;
  /** update data of the table: "order_height_and_weight" */
  update_order_height_and_weight?: Maybe<Order_Height_And_Weight_Mutation_Response>;
  /** update single row of the table: "order_height_and_weight" */
  update_order_height_and_weight_by_pk?: Maybe<Order_Height_And_Weight>;
  /** update data of the table: "order_medical_assessment" */
  update_order_medical_assessment?: Maybe<Order_Medical_Assessment_Mutation_Response>;
  /** update single row of the table: "order_medical_assessment" */
  update_order_medical_assessment_by_pk?: Maybe<Order_Medical_Assessment>;
  /** update data of the table: "order_musculoskeletal" */
  update_order_musculoskeletal?: Maybe<Order_Musculoskeletal_Mutation_Response>;
  /** update single row of the table: "order_musculoskeletal" */
  update_order_musculoskeletal_by_pk?: Maybe<Order_Musculoskeletal>;
  /** update data of the table: "order_type" */
  update_order_type?: Maybe<Order_Type_Mutation_Response>;
  /** update single row of the table: "order_type" */
  update_order_type_by_pk?: Maybe<Order_Type>;
  /** update data of the table: "order_vision" */
  update_order_vision?: Maybe<Order_Vision_Mutation_Response>;
  /** update single row of the table: "order_vision" */
  update_order_vision_by_pk?: Maybe<Order_Vision>;
  /** update data of the table: "orders" */
  update_orders?: Maybe<Orders_Mutation_Response>;
  /** update single row of the table: "orders" */
  update_orders_by_pk?: Maybe<Orders>;
  /** update data of the table: "organizations.district_days_off" */
  update_organizations_district_days_off?: Maybe<Organizations_District_Days_Off_Mutation_Response>;
  /** update single row of the table: "organizations.district_days_off" */
  update_organizations_district_days_off_by_pk?: Maybe<Organizations_District_Days_Off>;
  /** update data of the table: "organizations.features" */
  update_organizations_features?: Maybe<Organizations_Features_Mutation_Response>;
  /** update single row of the table: "organizations.features" */
  update_organizations_features_by_pk?: Maybe<Organizations_Features>;
  /** update data of the table: "organizations.locations" */
  update_organizations_locations?: Maybe<Organizations_Locations_Mutation_Response>;
  /** update single row of the table: "organizations.locations" */
  update_organizations_locations_by_pk?: Maybe<Organizations_Locations>;
  /** update data of the table: "organizations.organizations" */
  update_organizations_organizations?: Maybe<Organizations_Organizations_Mutation_Response>;
  /** update single row of the table: "organizations.organizations" */
  update_organizations_organizations_by_pk?: Maybe<Organizations_Organizations>;
  /** update data of the table: "organizations.organizations_features" */
  update_organizations_organizations_features?: Maybe<Organizations_Organizations_Features_Mutation_Response>;
  /** update single row of the table: "organizations.organizations_features" */
  update_organizations_organizations_features_by_pk?: Maybe<Organizations_Organizations_Features>;
  /** update data of the table: "organizations.regions" */
  update_organizations_regions?: Maybe<Organizations_Regions_Mutation_Response>;
  /** update single row of the table: "organizations.regions" */
  update_organizations_regions_by_pk?: Maybe<Organizations_Regions>;
  /** update data of the table: "organizations.organization_x_schoolyear" */
  update_organizations_school_years?: Maybe<Organizations_School_Years_Mutation_Response>;
  /** update single row of the table: "organizations.organization_x_schoolyear" */
  update_organizations_school_years_by_pk?: Maybe<Organizations_School_Years>;
  /** update data of the table: "organizations.location_x_testing_location" */
  update_organizations_testing_locations?: Maybe<Organizations_Testing_Locations_Mutation_Response>;
  /** update single row of the table: "organizations.location_x_testing_location" */
  update_organizations_testing_locations_by_pk?: Maybe<Organizations_Testing_Locations>;
  /** update data of the table: "mat_tmp_fast_demographics" */
  update_patient_demographics?: Maybe<Patient_Demographics_Mutation_Response>;
  /** update data of the table: "mat_fast_search_json" */
  update_patients?: Maybe<Patients_Mutation_Response>;
  /** update data of the table: "pharmacies" */
  update_pharmacies?: Maybe<Pharmacies_Mutation_Response>;
  /** update single row of the table: "pharmacies" */
  update_pharmacies_by_pk?: Maybe<Pharmacies>;
  /** update data of the table: "place_of_service" */
  update_place_of_service?: Maybe<Place_Of_Service_Mutation_Response>;
  /** update single row of the table: "place_of_service" */
  update_place_of_service_by_pk?: Maybe<Place_Of_Service>;
  /** update data of the table: "pond_form_localizations" */
  update_pond_form_localizations?: Maybe<Pond_Form_Localizations_Mutation_Response>;
  /** update single row of the table: "pond_form_localizations" */
  update_pond_form_localizations_by_pk?: Maybe<Pond_Form_Localizations>;
  /** update data of the table: "pond_form_types" */
  update_pond_form_types?: Maybe<Pond_Form_Types_Mutation_Response>;
  /** update single row of the table: "pond_form_types" */
  update_pond_form_types_by_pk?: Maybe<Pond_Form_Types>;
  /** update data of the table: "pond_forms" */
  update_pond_forms?: Maybe<Pond_Forms_Mutation_Response>;
  /** update single row of the table: "pond_forms" */
  update_pond_forms_by_pk?: Maybe<Pond_Forms>;
  /** update data of the table: "patients" */
  update_portal_patients?: Maybe<Portal_Patients_Mutation_Response>;
  /** update single row of the table: "patients" */
  update_portal_patients_by_pk?: Maybe<Portal_Patients>;
  /** update data of the table: "programs" */
  update_programs?: Maybe<Programs_Mutation_Response>;
  /** update single row of the table: "programs" */
  update_programs_by_pk?: Maybe<Programs>;
  /** update data of the table: "providers" */
  update_providers?: Maybe<Providers_Mutation_Response>;
  /** update single row of the table: "providers" */
  update_providers_by_pk?: Maybe<Providers>;
  /** update data of the table: "roles" */
  update_roles?: Maybe<Roles_Mutation_Response>;
  /** update single row of the table: "roles" */
  update_roles_by_pk?: Maybe<Roles>;
  /** update data of the table: "session_logs" */
  update_session_logs?: Maybe<Session_Logs_Mutation_Response>;
  /** update single row of the table: "session_logs" */
  update_session_logs_by_pk?: Maybe<Session_Logs>;
  /** update data of the table: "session_order_config" */
  update_session_order_config?: Maybe<Session_Order_Config_Mutation_Response>;
  /** update single row of the table: "session_order_config" */
  update_session_order_config_by_pk?: Maybe<Session_Order_Config>;
  /** update data of the table: "session_station_config" */
  update_session_station_config?: Maybe<Session_Station_Config_Mutation_Response>;
  /** update single row of the table: "session_station_config" */
  update_session_station_config_by_pk?: Maybe<Session_Station_Config>;
  /** update data of the table: "session_type" */
  update_session_type?: Maybe<Session_Type_Mutation_Response>;
  /** update single row of the table: "session_type" */
  update_session_type_by_pk?: Maybe<Session_Type>;
  /** update data of the table: "sessions" */
  update_sessions?: Maybe<Sessions_Mutation_Response>;
  /** update single row of the table: "sessions" */
  update_sessions_by_pk?: Maybe<Sessions>;
  /** update data of the table: "station_orders" */
  update_station_orders?: Maybe<Station_Orders_Mutation_Response>;
  /** update single row of the table: "station_orders" */
  update_station_orders_by_pk?: Maybe<Station_Orders>;
  /** update data of the table: "station_type" */
  update_station_type?: Maybe<Station_Type_Mutation_Response>;
  /** update single row of the table: "station_type" */
  update_station_type_by_pk?: Maybe<Station_Type>;
  /** update data of the table: "station_visits" */
  update_station_visits?: Maybe<Station_Visits_Mutation_Response>;
  /** update single row of the table: "station_visits" */
  update_station_visits_by_pk?: Maybe<Station_Visits>;
  /** update data of the table: "stations" */
  update_stations?: Maybe<Stations_Mutation_Response>;
  /** update single row of the table: "stations" */
  update_stations_by_pk?: Maybe<Stations>;
  /** update data of the table: "test_orders" */
  update_test_orders?: Maybe<Test_Orders_Mutation_Response>;
  /** update single row of the table: "test_orders" */
  update_test_orders_by_pk?: Maybe<Test_Orders>;
  /** update data of the table: "users" */
  update_users?: Maybe<Users_Mutation_Response>;
  /** update single row of the table: "users" */
  update_users_by_pk?: Maybe<Users>;
  /** update data of the table: "video_calls" */
  update_video_calls?: Maybe<Video_Calls_Mutation_Response>;
  /** update single row of the table: "video_calls" */
  update_video_calls_by_pk?: Maybe<Video_Calls>;
  /** update data of the table: "visits" */
  update_visits?: Maybe<Visits_Mutation_Response>;
  /** update single row of the table: "visits" */
  update_visits_by_pk?: Maybe<Visits>;
};


/** mutation root */
export type Mutation_RootDelete_Additional_Care_ProvidersArgs = {
  where: Additional_Care_Providers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Additional_Care_Providers_By_PkArgs = {
  patient_id: Scalars['Int'];
  provider_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_AllergiesArgs = {
  where: Allergies_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Allergies_By_PkArgs = {
  patient_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Clearance_NotesArgs = {
  where: Clearance_Notes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Clearance_Notes_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_ContactsArgs = {
  where: Contacts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Contacts_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Contacts_To_PatientsArgs = {
  where: Contacts_To_Patients_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Contacts_To_Patients_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_DischargesArgs = {
  where: Discharges_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Discharges_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_EnrollmentsArgs = {
  where: Enrollments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enrollments_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Event_TypesArgs = {
  where: Event_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Event_Types_By_PkArgs = {
  code: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_EventsArgs = {
  where: Events_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Events_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Form_LocalizationsArgs = {
  where: Form_Localizations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Form_Localizations_By_PkArgs = {
  locale: Scalars['String'];
  type: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Form_TypesArgs = {
  where: Form_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Form_Types_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_FormsArgs = {
  where: Forms_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Forms_By_PkArgs = {
  patient_id: Scalars['String'];
  type: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Insurance_VendorsArgs = {
  where: Insurance_Vendors_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Insurance_Vendors_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_InsurancesArgs = {
  where: Insurances_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Insurances_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Medication_OrdersArgs = {
  where: Medication_Orders_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Medication_Orders_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Order_Dshs_Covid_TestArgs = {
  where: Order_Dshs_Covid_Test_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Order_Dshs_Covid_Test_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Order_Height_And_WeightArgs = {
  where: Order_Height_And_Weight_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Order_Height_And_Weight_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Order_Medical_AssessmentArgs = {
  where: Order_Medical_Assessment_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Order_Medical_Assessment_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Order_MusculoskeletalArgs = {
  where: Order_Musculoskeletal_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Order_Musculoskeletal_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Order_TypeArgs = {
  where: Order_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Order_Type_By_PkArgs = {
  code: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Order_VisionArgs = {
  where: Order_Vision_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Order_Vision_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_OrdersArgs = {
  where: Orders_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Orders_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Organizations_District_Days_OffArgs = {
  where: Organizations_District_Days_Off_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Organizations_District_Days_Off_By_PkArgs = {
  days_off_id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Organizations_FeaturesArgs = {
  where: Organizations_Features_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Organizations_Features_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Organizations_LocationsArgs = {
  where: Organizations_Locations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Organizations_Locations_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Organizations_OrganizationsArgs = {
  where: Organizations_Organizations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Organizations_Organizations_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Organizations_Organizations_FeaturesArgs = {
  where: Organizations_Organizations_Features_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Organizations_Organizations_Features_By_PkArgs = {
  feature_id: Scalars['String'];
  organization_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Organizations_RegionsArgs = {
  where: Organizations_Regions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Organizations_Regions_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Organizations_School_YearsArgs = {
  where: Organizations_School_Years_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Organizations_School_Years_By_PkArgs = {
  organization_x_schoolyear_id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Organizations_Testing_LocationsArgs = {
  where: Organizations_Testing_Locations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Organizations_Testing_Locations_By_PkArgs = {
  location_x_testing_location_id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Patient_DemographicsArgs = {
  where: Patient_Demographics_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_PatientsArgs = {
  where: Patients_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_PharmaciesArgs = {
  where: Pharmacies_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Pharmacies_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Place_Of_ServiceArgs = {
  where: Place_Of_Service_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Place_Of_Service_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Pond_Form_LocalizationsArgs = {
  where: Pond_Form_Localizations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Pond_Form_Localizations_By_PkArgs = {
  form_type_code: Scalars['String'];
  locale: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Pond_Form_TypesArgs = {
  where: Pond_Form_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Pond_Form_Types_By_PkArgs = {
  code: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Pond_FormsArgs = {
  where: Pond_Forms_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Pond_Forms_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Portal_PatientsArgs = {
  where: Portal_Patients_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Portal_Patients_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_ProgramsArgs = {
  where: Programs_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Programs_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_ProvidersArgs = {
  where: Providers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Providers_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_RolesArgs = {
  where: Roles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Roles_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Session_LogsArgs = {
  where: Session_Logs_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Session_Logs_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Session_Order_ConfigArgs = {
  where: Session_Order_Config_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Session_Order_Config_By_PkArgs = {
  order_code: Scalars['String'];
  session_code: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Session_Station_ConfigArgs = {
  where: Session_Station_Config_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Session_Station_Config_By_PkArgs = {
  session_code: Scalars['String'];
  station_code: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Session_TypeArgs = {
  where: Session_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Session_Type_By_PkArgs = {
  code: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_SessionsArgs = {
  where: Sessions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Sessions_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Station_OrdersArgs = {
  where: Station_Orders_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Station_Orders_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Station_TypeArgs = {
  where: Station_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Station_Type_By_PkArgs = {
  code: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Station_VisitsArgs = {
  where: Station_Visits_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Station_Visits_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_StationsArgs = {
  where: Stations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Stations_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Test_OrdersArgs = {
  where: Test_Orders_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Test_Orders_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_UsersArgs = {
  where: Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Users_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Video_CallsArgs = {
  where: Video_Calls_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Video_Calls_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_VisitsArgs = {
  where: Visits_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Visits_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootInsert_Additional_Care_ProvidersArgs = {
  objects: Array<Additional_Care_Providers_Insert_Input>;
  on_conflict?: InputMaybe<Additional_Care_Providers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Additional_Care_Providers_OneArgs = {
  object: Additional_Care_Providers_Insert_Input;
  on_conflict?: InputMaybe<Additional_Care_Providers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_AllergiesArgs = {
  objects: Array<Allergies_Insert_Input>;
  on_conflict?: InputMaybe<Allergies_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Allergies_OneArgs = {
  object: Allergies_Insert_Input;
  on_conflict?: InputMaybe<Allergies_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Clearance_NotesArgs = {
  objects: Array<Clearance_Notes_Insert_Input>;
  on_conflict?: InputMaybe<Clearance_Notes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Clearance_Notes_OneArgs = {
  object: Clearance_Notes_Insert_Input;
  on_conflict?: InputMaybe<Clearance_Notes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ContactsArgs = {
  objects: Array<Contacts_Insert_Input>;
  on_conflict?: InputMaybe<Contacts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Contacts_OneArgs = {
  object: Contacts_Insert_Input;
  on_conflict?: InputMaybe<Contacts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Contacts_To_PatientsArgs = {
  objects: Array<Contacts_To_Patients_Insert_Input>;
  on_conflict?: InputMaybe<Contacts_To_Patients_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Contacts_To_Patients_OneArgs = {
  object: Contacts_To_Patients_Insert_Input;
  on_conflict?: InputMaybe<Contacts_To_Patients_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_DischargesArgs = {
  objects: Array<Discharges_Insert_Input>;
  on_conflict?: InputMaybe<Discharges_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Discharges_OneArgs = {
  object: Discharges_Insert_Input;
  on_conflict?: InputMaybe<Discharges_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_EnrollmentsArgs = {
  objects: Array<Enrollments_Insert_Input>;
  on_conflict?: InputMaybe<Enrollments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enrollments_OneArgs = {
  object: Enrollments_Insert_Input;
  on_conflict?: InputMaybe<Enrollments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Event_TypesArgs = {
  objects: Array<Event_Types_Insert_Input>;
  on_conflict?: InputMaybe<Event_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Event_Types_OneArgs = {
  object: Event_Types_Insert_Input;
  on_conflict?: InputMaybe<Event_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_EventsArgs = {
  objects: Array<Events_Insert_Input>;
  on_conflict?: InputMaybe<Events_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Events_OneArgs = {
  object: Events_Insert_Input;
  on_conflict?: InputMaybe<Events_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Form_Images_OneArgs = {
  form_id: Scalars['String'];
  patient_id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootInsert_Form_LocalizationsArgs = {
  objects: Array<Form_Localizations_Insert_Input>;
  on_conflict?: InputMaybe<Form_Localizations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Form_Localizations_OneArgs = {
  object: Form_Localizations_Insert_Input;
  on_conflict?: InputMaybe<Form_Localizations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Form_TypesArgs = {
  objects: Array<Form_Types_Insert_Input>;
  on_conflict?: InputMaybe<Form_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Form_Types_OneArgs = {
  object: Form_Types_Insert_Input;
  on_conflict?: InputMaybe<Form_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_FormsArgs = {
  objects: Array<Forms_Insert_Input>;
  on_conflict?: InputMaybe<Forms_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Forms_OneArgs = {
  object: Forms_Insert_Input;
  on_conflict?: InputMaybe<Forms_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Insurance_VendorsArgs = {
  objects: Array<Insurance_Vendors_Insert_Input>;
  on_conflict?: InputMaybe<Insurance_Vendors_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Insurance_Vendors_OneArgs = {
  object: Insurance_Vendors_Insert_Input;
  on_conflict?: InputMaybe<Insurance_Vendors_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_InsurancesArgs = {
  objects: Array<Insurances_Insert_Input>;
  on_conflict?: InputMaybe<Insurances_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Insurances_OneArgs = {
  object: Insurances_Insert_Input;
  on_conflict?: InputMaybe<Insurances_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Medication_OrdersArgs = {
  objects: Array<Medication_Orders_Insert_Input>;
  on_conflict?: InputMaybe<Medication_Orders_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Medication_Orders_OneArgs = {
  object: Medication_Orders_Insert_Input;
  on_conflict?: InputMaybe<Medication_Orders_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Order_Dshs_Covid_TestArgs = {
  objects: Array<Order_Dshs_Covid_Test_Insert_Input>;
  on_conflict?: InputMaybe<Order_Dshs_Covid_Test_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Order_Dshs_Covid_Test_OneArgs = {
  object: Order_Dshs_Covid_Test_Insert_Input;
  on_conflict?: InputMaybe<Order_Dshs_Covid_Test_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Order_Height_And_WeightArgs = {
  objects: Array<Order_Height_And_Weight_Insert_Input>;
  on_conflict?: InputMaybe<Order_Height_And_Weight_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Order_Height_And_Weight_OneArgs = {
  object: Order_Height_And_Weight_Insert_Input;
  on_conflict?: InputMaybe<Order_Height_And_Weight_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Order_Medical_AssessmentArgs = {
  objects: Array<Order_Medical_Assessment_Insert_Input>;
  on_conflict?: InputMaybe<Order_Medical_Assessment_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Order_Medical_Assessment_OneArgs = {
  object: Order_Medical_Assessment_Insert_Input;
  on_conflict?: InputMaybe<Order_Medical_Assessment_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Order_MusculoskeletalArgs = {
  objects: Array<Order_Musculoskeletal_Insert_Input>;
  on_conflict?: InputMaybe<Order_Musculoskeletal_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Order_Musculoskeletal_OneArgs = {
  object: Order_Musculoskeletal_Insert_Input;
  on_conflict?: InputMaybe<Order_Musculoskeletal_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Order_TypeArgs = {
  objects: Array<Order_Type_Insert_Input>;
  on_conflict?: InputMaybe<Order_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Order_Type_OneArgs = {
  object: Order_Type_Insert_Input;
  on_conflict?: InputMaybe<Order_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Order_VisionArgs = {
  objects: Array<Order_Vision_Insert_Input>;
  on_conflict?: InputMaybe<Order_Vision_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Order_Vision_OneArgs = {
  object: Order_Vision_Insert_Input;
  on_conflict?: InputMaybe<Order_Vision_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_OrdersArgs = {
  objects: Array<Orders_Insert_Input>;
  on_conflict?: InputMaybe<Orders_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Orders_OneArgs = {
  object: Orders_Insert_Input;
  on_conflict?: InputMaybe<Orders_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Organizations_District_Days_OffArgs = {
  objects: Array<Organizations_District_Days_Off_Insert_Input>;
  on_conflict?: InputMaybe<Organizations_District_Days_Off_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Organizations_District_Days_Off_OneArgs = {
  object: Organizations_District_Days_Off_Insert_Input;
  on_conflict?: InputMaybe<Organizations_District_Days_Off_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Organizations_FeaturesArgs = {
  objects: Array<Organizations_Features_Insert_Input>;
  on_conflict?: InputMaybe<Organizations_Features_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Organizations_Features_OneArgs = {
  object: Organizations_Features_Insert_Input;
  on_conflict?: InputMaybe<Organizations_Features_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Organizations_LocationsArgs = {
  objects: Array<Organizations_Locations_Insert_Input>;
  on_conflict?: InputMaybe<Organizations_Locations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Organizations_Locations_OneArgs = {
  object: Organizations_Locations_Insert_Input;
  on_conflict?: InputMaybe<Organizations_Locations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Organizations_OrganizationsArgs = {
  objects: Array<Organizations_Organizations_Insert_Input>;
  on_conflict?: InputMaybe<Organizations_Organizations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Organizations_Organizations_FeaturesArgs = {
  objects: Array<Organizations_Organizations_Features_Insert_Input>;
  on_conflict?: InputMaybe<Organizations_Organizations_Features_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Organizations_Organizations_Features_OneArgs = {
  object: Organizations_Organizations_Features_Insert_Input;
  on_conflict?: InputMaybe<Organizations_Organizations_Features_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Organizations_Organizations_OneArgs = {
  object: Organizations_Organizations_Insert_Input;
  on_conflict?: InputMaybe<Organizations_Organizations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Organizations_RegionsArgs = {
  objects: Array<Organizations_Regions_Insert_Input>;
  on_conflict?: InputMaybe<Organizations_Regions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Organizations_Regions_OneArgs = {
  object: Organizations_Regions_Insert_Input;
  on_conflict?: InputMaybe<Organizations_Regions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Organizations_School_YearsArgs = {
  objects: Array<Organizations_School_Years_Insert_Input>;
  on_conflict?: InputMaybe<Organizations_School_Years_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Organizations_School_Years_OneArgs = {
  object: Organizations_School_Years_Insert_Input;
  on_conflict?: InputMaybe<Organizations_School_Years_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Organizations_Testing_LocationsArgs = {
  objects: Array<Organizations_Testing_Locations_Insert_Input>;
  on_conflict?: InputMaybe<Organizations_Testing_Locations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Organizations_Testing_Locations_OneArgs = {
  object: Organizations_Testing_Locations_Insert_Input;
  on_conflict?: InputMaybe<Organizations_Testing_Locations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Patient_DemographicsArgs = {
  objects: Array<Patient_Demographics_Insert_Input>;
  on_conflict?: InputMaybe<Patient_Demographics_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Patient_Demographics_OneArgs = {
  object: Patient_Demographics_Insert_Input;
  on_conflict?: InputMaybe<Patient_Demographics_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PatientsArgs = {
  objects: Array<Patients_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Patients_OneArgs = {
  object: Patients_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_PharmaciesArgs = {
  objects: Array<Pharmacies_Insert_Input>;
  on_conflict?: InputMaybe<Pharmacies_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Pharmacies_OneArgs = {
  object: Pharmacies_Insert_Input;
  on_conflict?: InputMaybe<Pharmacies_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Place_Of_ServiceArgs = {
  objects: Array<Place_Of_Service_Insert_Input>;
  on_conflict?: InputMaybe<Place_Of_Service_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Place_Of_Service_OneArgs = {
  object: Place_Of_Service_Insert_Input;
  on_conflict?: InputMaybe<Place_Of_Service_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Pond_Form_LocalizationsArgs = {
  objects: Array<Pond_Form_Localizations_Insert_Input>;
  on_conflict?: InputMaybe<Pond_Form_Localizations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Pond_Form_Localizations_OneArgs = {
  object: Pond_Form_Localizations_Insert_Input;
  on_conflict?: InputMaybe<Pond_Form_Localizations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Pond_Form_TypesArgs = {
  objects: Array<Pond_Form_Types_Insert_Input>;
  on_conflict?: InputMaybe<Pond_Form_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Pond_Form_Types_OneArgs = {
  object: Pond_Form_Types_Insert_Input;
  on_conflict?: InputMaybe<Pond_Form_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Pond_FormsArgs = {
  objects: Array<Pond_Forms_Insert_Input>;
  on_conflict?: InputMaybe<Pond_Forms_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Pond_Forms_OneArgs = {
  object: Pond_Forms_Insert_Input;
  on_conflict?: InputMaybe<Pond_Forms_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Portal_PatientsArgs = {
  objects: Array<Portal_Patients_Insert_Input>;
  on_conflict?: InputMaybe<Portal_Patients_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Portal_Patients_OneArgs = {
  object: Portal_Patients_Insert_Input;
  on_conflict?: InputMaybe<Portal_Patients_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ProgramsArgs = {
  objects: Array<Programs_Insert_Input>;
  on_conflict?: InputMaybe<Programs_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Programs_OneArgs = {
  object: Programs_Insert_Input;
  on_conflict?: InputMaybe<Programs_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ProvidersArgs = {
  objects: Array<Providers_Insert_Input>;
  on_conflict?: InputMaybe<Providers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Providers_OneArgs = {
  object: Providers_Insert_Input;
  on_conflict?: InputMaybe<Providers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_RolesArgs = {
  objects: Array<Roles_Insert_Input>;
  on_conflict?: InputMaybe<Roles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Roles_OneArgs = {
  object: Roles_Insert_Input;
  on_conflict?: InputMaybe<Roles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Session_LogsArgs = {
  objects: Array<Session_Logs_Insert_Input>;
  on_conflict?: InputMaybe<Session_Logs_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Session_Logs_OneArgs = {
  object: Session_Logs_Insert_Input;
  on_conflict?: InputMaybe<Session_Logs_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Session_Order_ConfigArgs = {
  objects: Array<Session_Order_Config_Insert_Input>;
  on_conflict?: InputMaybe<Session_Order_Config_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Session_Order_Config_OneArgs = {
  object: Session_Order_Config_Insert_Input;
  on_conflict?: InputMaybe<Session_Order_Config_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Session_Station_ConfigArgs = {
  objects: Array<Session_Station_Config_Insert_Input>;
  on_conflict?: InputMaybe<Session_Station_Config_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Session_Station_Config_OneArgs = {
  object: Session_Station_Config_Insert_Input;
  on_conflict?: InputMaybe<Session_Station_Config_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Session_TypeArgs = {
  objects: Array<Session_Type_Insert_Input>;
  on_conflict?: InputMaybe<Session_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Session_Type_OneArgs = {
  object: Session_Type_Insert_Input;
  on_conflict?: InputMaybe<Session_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_SessionsArgs = {
  objects: Array<Sessions_Insert_Input>;
  on_conflict?: InputMaybe<Sessions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Sessions_OneArgs = {
  object: Sessions_Insert_Input;
  on_conflict?: InputMaybe<Sessions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Station_OrdersArgs = {
  objects: Array<Station_Orders_Insert_Input>;
  on_conflict?: InputMaybe<Station_Orders_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Station_Orders_OneArgs = {
  object: Station_Orders_Insert_Input;
  on_conflict?: InputMaybe<Station_Orders_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Station_TypeArgs = {
  objects: Array<Station_Type_Insert_Input>;
  on_conflict?: InputMaybe<Station_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Station_Type_OneArgs = {
  object: Station_Type_Insert_Input;
  on_conflict?: InputMaybe<Station_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Station_VisitsArgs = {
  objects: Array<Station_Visits_Insert_Input>;
  on_conflict?: InputMaybe<Station_Visits_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Station_Visits_OneArgs = {
  object: Station_Visits_Insert_Input;
  on_conflict?: InputMaybe<Station_Visits_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_StationsArgs = {
  objects: Array<Stations_Insert_Input>;
  on_conflict?: InputMaybe<Stations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Stations_OneArgs = {
  object: Stations_Insert_Input;
  on_conflict?: InputMaybe<Stations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Test_OrdersArgs = {
  objects: Array<Test_Orders_Insert_Input>;
  on_conflict?: InputMaybe<Test_Orders_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Test_Orders_OneArgs = {
  object: Test_Orders_Insert_Input;
  on_conflict?: InputMaybe<Test_Orders_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UsersArgs = {
  objects: Array<Users_Insert_Input>;
  on_conflict?: InputMaybe<Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Users_OneArgs = {
  object: Users_Insert_Input;
  on_conflict?: InputMaybe<Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Video_CallsArgs = {
  objects: Array<Video_Calls_Insert_Input>;
  on_conflict?: InputMaybe<Video_Calls_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Video_Calls_OneArgs = {
  object: Video_Calls_Insert_Input;
  on_conflict?: InputMaybe<Video_Calls_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_VisitsArgs = {
  objects: Array<Visits_Insert_Input>;
  on_conflict?: InputMaybe<Visits_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Visits_OneArgs = {
  object: Visits_Insert_Input;
  on_conflict?: InputMaybe<Visits_On_Conflict>;
};


/** mutation root */
export type Mutation_RootSubmit_Patient_RegistrationArgs = {
  portal_patient_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootUpdate_Additional_Care_ProvidersArgs = {
  _inc?: InputMaybe<Additional_Care_Providers_Inc_Input>;
  _set?: InputMaybe<Additional_Care_Providers_Set_Input>;
  where: Additional_Care_Providers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Additional_Care_Providers_By_PkArgs = {
  _inc?: InputMaybe<Additional_Care_Providers_Inc_Input>;
  _set?: InputMaybe<Additional_Care_Providers_Set_Input>;
  pk_columns: Additional_Care_Providers_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_AllergiesArgs = {
  _inc?: InputMaybe<Allergies_Inc_Input>;
  _set?: InputMaybe<Allergies_Set_Input>;
  where: Allergies_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Allergies_By_PkArgs = {
  _inc?: InputMaybe<Allergies_Inc_Input>;
  _set?: InputMaybe<Allergies_Set_Input>;
  pk_columns: Allergies_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Clearance_NotesArgs = {
  _inc?: InputMaybe<Clearance_Notes_Inc_Input>;
  _set?: InputMaybe<Clearance_Notes_Set_Input>;
  where: Clearance_Notes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Clearance_Notes_By_PkArgs = {
  _inc?: InputMaybe<Clearance_Notes_Inc_Input>;
  _set?: InputMaybe<Clearance_Notes_Set_Input>;
  pk_columns: Clearance_Notes_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ContactsArgs = {
  _inc?: InputMaybe<Contacts_Inc_Input>;
  _set?: InputMaybe<Contacts_Set_Input>;
  where: Contacts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Contacts_By_PkArgs = {
  _inc?: InputMaybe<Contacts_Inc_Input>;
  _set?: InputMaybe<Contacts_Set_Input>;
  pk_columns: Contacts_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Contacts_To_PatientsArgs = {
  _inc?: InputMaybe<Contacts_To_Patients_Inc_Input>;
  _set?: InputMaybe<Contacts_To_Patients_Set_Input>;
  where: Contacts_To_Patients_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Contacts_To_Patients_By_PkArgs = {
  _inc?: InputMaybe<Contacts_To_Patients_Inc_Input>;
  _set?: InputMaybe<Contacts_To_Patients_Set_Input>;
  pk_columns: Contacts_To_Patients_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_DischargesArgs = {
  _inc?: InputMaybe<Discharges_Inc_Input>;
  _set?: InputMaybe<Discharges_Set_Input>;
  where: Discharges_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Discharges_By_PkArgs = {
  _inc?: InputMaybe<Discharges_Inc_Input>;
  _set?: InputMaybe<Discharges_Set_Input>;
  pk_columns: Discharges_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_EnrollmentsArgs = {
  _inc?: InputMaybe<Enrollments_Inc_Input>;
  _set?: InputMaybe<Enrollments_Set_Input>;
  where: Enrollments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enrollments_By_PkArgs = {
  _inc?: InputMaybe<Enrollments_Inc_Input>;
  _set?: InputMaybe<Enrollments_Set_Input>;
  pk_columns: Enrollments_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Event_TypesArgs = {
  _set?: InputMaybe<Event_Types_Set_Input>;
  where: Event_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Event_Types_By_PkArgs = {
  _set?: InputMaybe<Event_Types_Set_Input>;
  pk_columns: Event_Types_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_EventsArgs = {
  _inc?: InputMaybe<Events_Inc_Input>;
  _set?: InputMaybe<Events_Set_Input>;
  where: Events_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Events_By_PkArgs = {
  _inc?: InputMaybe<Events_Inc_Input>;
  _set?: InputMaybe<Events_Set_Input>;
  pk_columns: Events_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Form_LocalizationsArgs = {
  _append?: InputMaybe<Form_Localizations_Append_Input>;
  _delete_at_path?: InputMaybe<Form_Localizations_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Form_Localizations_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Form_Localizations_Delete_Key_Input>;
  _prepend?: InputMaybe<Form_Localizations_Prepend_Input>;
  _set?: InputMaybe<Form_Localizations_Set_Input>;
  where: Form_Localizations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Form_Localizations_By_PkArgs = {
  _append?: InputMaybe<Form_Localizations_Append_Input>;
  _delete_at_path?: InputMaybe<Form_Localizations_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Form_Localizations_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Form_Localizations_Delete_Key_Input>;
  _prepend?: InputMaybe<Form_Localizations_Prepend_Input>;
  _set?: InputMaybe<Form_Localizations_Set_Input>;
  pk_columns: Form_Localizations_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Form_TypesArgs = {
  _append?: InputMaybe<Form_Types_Append_Input>;
  _delete_at_path?: InputMaybe<Form_Types_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Form_Types_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Form_Types_Delete_Key_Input>;
  _prepend?: InputMaybe<Form_Types_Prepend_Input>;
  _set?: InputMaybe<Form_Types_Set_Input>;
  where: Form_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Form_Types_By_PkArgs = {
  _append?: InputMaybe<Form_Types_Append_Input>;
  _delete_at_path?: InputMaybe<Form_Types_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Form_Types_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Form_Types_Delete_Key_Input>;
  _prepend?: InputMaybe<Form_Types_Prepend_Input>;
  _set?: InputMaybe<Form_Types_Set_Input>;
  pk_columns: Form_Types_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_FormsArgs = {
  _append?: InputMaybe<Forms_Append_Input>;
  _delete_at_path?: InputMaybe<Forms_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Forms_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Forms_Delete_Key_Input>;
  _prepend?: InputMaybe<Forms_Prepend_Input>;
  _set?: InputMaybe<Forms_Set_Input>;
  where: Forms_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Forms_By_PkArgs = {
  _append?: InputMaybe<Forms_Append_Input>;
  _delete_at_path?: InputMaybe<Forms_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Forms_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Forms_Delete_Key_Input>;
  _prepend?: InputMaybe<Forms_Prepend_Input>;
  _set?: InputMaybe<Forms_Set_Input>;
  pk_columns: Forms_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Insurance_VendorsArgs = {
  _inc?: InputMaybe<Insurance_Vendors_Inc_Input>;
  _set?: InputMaybe<Insurance_Vendors_Set_Input>;
  where: Insurance_Vendors_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Insurance_Vendors_By_PkArgs = {
  _inc?: InputMaybe<Insurance_Vendors_Inc_Input>;
  _set?: InputMaybe<Insurance_Vendors_Set_Input>;
  pk_columns: Insurance_Vendors_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_InsurancesArgs = {
  _inc?: InputMaybe<Insurances_Inc_Input>;
  _set?: InputMaybe<Insurances_Set_Input>;
  where: Insurances_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Insurances_By_PkArgs = {
  _inc?: InputMaybe<Insurances_Inc_Input>;
  _set?: InputMaybe<Insurances_Set_Input>;
  pk_columns: Insurances_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Medication_OrdersArgs = {
  _inc?: InputMaybe<Medication_Orders_Inc_Input>;
  _set?: InputMaybe<Medication_Orders_Set_Input>;
  where: Medication_Orders_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Medication_Orders_By_PkArgs = {
  _inc?: InputMaybe<Medication_Orders_Inc_Input>;
  _set?: InputMaybe<Medication_Orders_Set_Input>;
  pk_columns: Medication_Orders_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Order_Dshs_Covid_TestArgs = {
  _inc?: InputMaybe<Order_Dshs_Covid_Test_Inc_Input>;
  _set?: InputMaybe<Order_Dshs_Covid_Test_Set_Input>;
  where: Order_Dshs_Covid_Test_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Order_Dshs_Covid_Test_By_PkArgs = {
  _inc?: InputMaybe<Order_Dshs_Covid_Test_Inc_Input>;
  _set?: InputMaybe<Order_Dshs_Covid_Test_Set_Input>;
  pk_columns: Order_Dshs_Covid_Test_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Order_Height_And_WeightArgs = {
  _inc?: InputMaybe<Order_Height_And_Weight_Inc_Input>;
  _set?: InputMaybe<Order_Height_And_Weight_Set_Input>;
  where: Order_Height_And_Weight_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Order_Height_And_Weight_By_PkArgs = {
  _inc?: InputMaybe<Order_Height_And_Weight_Inc_Input>;
  _set?: InputMaybe<Order_Height_And_Weight_Set_Input>;
  pk_columns: Order_Height_And_Weight_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Order_Medical_AssessmentArgs = {
  _inc?: InputMaybe<Order_Medical_Assessment_Inc_Input>;
  _set?: InputMaybe<Order_Medical_Assessment_Set_Input>;
  where: Order_Medical_Assessment_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Order_Medical_Assessment_By_PkArgs = {
  _inc?: InputMaybe<Order_Medical_Assessment_Inc_Input>;
  _set?: InputMaybe<Order_Medical_Assessment_Set_Input>;
  pk_columns: Order_Medical_Assessment_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Order_MusculoskeletalArgs = {
  _inc?: InputMaybe<Order_Musculoskeletal_Inc_Input>;
  _set?: InputMaybe<Order_Musculoskeletal_Set_Input>;
  where: Order_Musculoskeletal_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Order_Musculoskeletal_By_PkArgs = {
  _inc?: InputMaybe<Order_Musculoskeletal_Inc_Input>;
  _set?: InputMaybe<Order_Musculoskeletal_Set_Input>;
  pk_columns: Order_Musculoskeletal_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Order_TypeArgs = {
  _inc?: InputMaybe<Order_Type_Inc_Input>;
  _set?: InputMaybe<Order_Type_Set_Input>;
  where: Order_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Order_Type_By_PkArgs = {
  _inc?: InputMaybe<Order_Type_Inc_Input>;
  _set?: InputMaybe<Order_Type_Set_Input>;
  pk_columns: Order_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Order_VisionArgs = {
  _inc?: InputMaybe<Order_Vision_Inc_Input>;
  _set?: InputMaybe<Order_Vision_Set_Input>;
  where: Order_Vision_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Order_Vision_By_PkArgs = {
  _inc?: InputMaybe<Order_Vision_Inc_Input>;
  _set?: InputMaybe<Order_Vision_Set_Input>;
  pk_columns: Order_Vision_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_OrdersArgs = {
  _inc?: InputMaybe<Orders_Inc_Input>;
  _set?: InputMaybe<Orders_Set_Input>;
  where: Orders_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Orders_By_PkArgs = {
  _inc?: InputMaybe<Orders_Inc_Input>;
  _set?: InputMaybe<Orders_Set_Input>;
  pk_columns: Orders_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Organizations_District_Days_OffArgs = {
  _inc?: InputMaybe<Organizations_District_Days_Off_Inc_Input>;
  _set?: InputMaybe<Organizations_District_Days_Off_Set_Input>;
  where: Organizations_District_Days_Off_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Organizations_District_Days_Off_By_PkArgs = {
  _inc?: InputMaybe<Organizations_District_Days_Off_Inc_Input>;
  _set?: InputMaybe<Organizations_District_Days_Off_Set_Input>;
  pk_columns: Organizations_District_Days_Off_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Organizations_FeaturesArgs = {
  _append?: InputMaybe<Organizations_Features_Append_Input>;
  _delete_at_path?: InputMaybe<Organizations_Features_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Organizations_Features_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Organizations_Features_Delete_Key_Input>;
  _prepend?: InputMaybe<Organizations_Features_Prepend_Input>;
  _set?: InputMaybe<Organizations_Features_Set_Input>;
  where: Organizations_Features_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Organizations_Features_By_PkArgs = {
  _append?: InputMaybe<Organizations_Features_Append_Input>;
  _delete_at_path?: InputMaybe<Organizations_Features_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Organizations_Features_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Organizations_Features_Delete_Key_Input>;
  _prepend?: InputMaybe<Organizations_Features_Prepend_Input>;
  _set?: InputMaybe<Organizations_Features_Set_Input>;
  pk_columns: Organizations_Features_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Organizations_LocationsArgs = {
  _inc?: InputMaybe<Organizations_Locations_Inc_Input>;
  _set?: InputMaybe<Organizations_Locations_Set_Input>;
  where: Organizations_Locations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Organizations_Locations_By_PkArgs = {
  _inc?: InputMaybe<Organizations_Locations_Inc_Input>;
  _set?: InputMaybe<Organizations_Locations_Set_Input>;
  pk_columns: Organizations_Locations_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Organizations_OrganizationsArgs = {
  _inc?: InputMaybe<Organizations_Organizations_Inc_Input>;
  _set?: InputMaybe<Organizations_Organizations_Set_Input>;
  where: Organizations_Organizations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Organizations_Organizations_By_PkArgs = {
  _inc?: InputMaybe<Organizations_Organizations_Inc_Input>;
  _set?: InputMaybe<Organizations_Organizations_Set_Input>;
  pk_columns: Organizations_Organizations_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Organizations_Organizations_FeaturesArgs = {
  _append?: InputMaybe<Organizations_Organizations_Features_Append_Input>;
  _delete_at_path?: InputMaybe<Organizations_Organizations_Features_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Organizations_Organizations_Features_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Organizations_Organizations_Features_Delete_Key_Input>;
  _inc?: InputMaybe<Organizations_Organizations_Features_Inc_Input>;
  _prepend?: InputMaybe<Organizations_Organizations_Features_Prepend_Input>;
  _set?: InputMaybe<Organizations_Organizations_Features_Set_Input>;
  where: Organizations_Organizations_Features_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Organizations_Organizations_Features_By_PkArgs = {
  _append?: InputMaybe<Organizations_Organizations_Features_Append_Input>;
  _delete_at_path?: InputMaybe<Organizations_Organizations_Features_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Organizations_Organizations_Features_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Organizations_Organizations_Features_Delete_Key_Input>;
  _inc?: InputMaybe<Organizations_Organizations_Features_Inc_Input>;
  _prepend?: InputMaybe<Organizations_Organizations_Features_Prepend_Input>;
  _set?: InputMaybe<Organizations_Organizations_Features_Set_Input>;
  pk_columns: Organizations_Organizations_Features_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Organizations_RegionsArgs = {
  _inc?: InputMaybe<Organizations_Regions_Inc_Input>;
  _set?: InputMaybe<Organizations_Regions_Set_Input>;
  where: Organizations_Regions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Organizations_Regions_By_PkArgs = {
  _inc?: InputMaybe<Organizations_Regions_Inc_Input>;
  _set?: InputMaybe<Organizations_Regions_Set_Input>;
  pk_columns: Organizations_Regions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Organizations_School_YearsArgs = {
  _inc?: InputMaybe<Organizations_School_Years_Inc_Input>;
  _set?: InputMaybe<Organizations_School_Years_Set_Input>;
  where: Organizations_School_Years_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Organizations_School_Years_By_PkArgs = {
  _inc?: InputMaybe<Organizations_School_Years_Inc_Input>;
  _set?: InputMaybe<Organizations_School_Years_Set_Input>;
  pk_columns: Organizations_School_Years_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Organizations_Testing_LocationsArgs = {
  _inc?: InputMaybe<Organizations_Testing_Locations_Inc_Input>;
  _set?: InputMaybe<Organizations_Testing_Locations_Set_Input>;
  where: Organizations_Testing_Locations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Organizations_Testing_Locations_By_PkArgs = {
  _inc?: InputMaybe<Organizations_Testing_Locations_Inc_Input>;
  _set?: InputMaybe<Organizations_Testing_Locations_Set_Input>;
  pk_columns: Organizations_Testing_Locations_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Patient_DemographicsArgs = {
  _inc?: InputMaybe<Patient_Demographics_Inc_Input>;
  _set?: InputMaybe<Patient_Demographics_Set_Input>;
  where: Patient_Demographics_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_PatientsArgs = {
  _append?: InputMaybe<Patients_Append_Input>;
  _delete_at_path?: InputMaybe<Patients_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Patients_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Patients_Delete_Key_Input>;
  _inc?: InputMaybe<Patients_Inc_Input>;
  _prepend?: InputMaybe<Patients_Prepend_Input>;
  _set?: InputMaybe<Patients_Set_Input>;
  where: Patients_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_PharmaciesArgs = {
  _inc?: InputMaybe<Pharmacies_Inc_Input>;
  _set?: InputMaybe<Pharmacies_Set_Input>;
  where: Pharmacies_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Pharmacies_By_PkArgs = {
  _inc?: InputMaybe<Pharmacies_Inc_Input>;
  _set?: InputMaybe<Pharmacies_Set_Input>;
  pk_columns: Pharmacies_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Place_Of_ServiceArgs = {
  _inc?: InputMaybe<Place_Of_Service_Inc_Input>;
  _set?: InputMaybe<Place_Of_Service_Set_Input>;
  where: Place_Of_Service_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Place_Of_Service_By_PkArgs = {
  _inc?: InputMaybe<Place_Of_Service_Inc_Input>;
  _set?: InputMaybe<Place_Of_Service_Set_Input>;
  pk_columns: Place_Of_Service_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Pond_Form_LocalizationsArgs = {
  _append?: InputMaybe<Pond_Form_Localizations_Append_Input>;
  _delete_at_path?: InputMaybe<Pond_Form_Localizations_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Pond_Form_Localizations_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Pond_Form_Localizations_Delete_Key_Input>;
  _prepend?: InputMaybe<Pond_Form_Localizations_Prepend_Input>;
  _set?: InputMaybe<Pond_Form_Localizations_Set_Input>;
  where: Pond_Form_Localizations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Pond_Form_Localizations_By_PkArgs = {
  _append?: InputMaybe<Pond_Form_Localizations_Append_Input>;
  _delete_at_path?: InputMaybe<Pond_Form_Localizations_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Pond_Form_Localizations_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Pond_Form_Localizations_Delete_Key_Input>;
  _prepend?: InputMaybe<Pond_Form_Localizations_Prepend_Input>;
  _set?: InputMaybe<Pond_Form_Localizations_Set_Input>;
  pk_columns: Pond_Form_Localizations_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Pond_Form_TypesArgs = {
  _append?: InputMaybe<Pond_Form_Types_Append_Input>;
  _delete_at_path?: InputMaybe<Pond_Form_Types_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Pond_Form_Types_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Pond_Form_Types_Delete_Key_Input>;
  _prepend?: InputMaybe<Pond_Form_Types_Prepend_Input>;
  _set?: InputMaybe<Pond_Form_Types_Set_Input>;
  where: Pond_Form_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Pond_Form_Types_By_PkArgs = {
  _append?: InputMaybe<Pond_Form_Types_Append_Input>;
  _delete_at_path?: InputMaybe<Pond_Form_Types_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Pond_Form_Types_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Pond_Form_Types_Delete_Key_Input>;
  _prepend?: InputMaybe<Pond_Form_Types_Prepend_Input>;
  _set?: InputMaybe<Pond_Form_Types_Set_Input>;
  pk_columns: Pond_Form_Types_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Pond_FormsArgs = {
  _append?: InputMaybe<Pond_Forms_Append_Input>;
  _delete_at_path?: InputMaybe<Pond_Forms_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Pond_Forms_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Pond_Forms_Delete_Key_Input>;
  _inc?: InputMaybe<Pond_Forms_Inc_Input>;
  _prepend?: InputMaybe<Pond_Forms_Prepend_Input>;
  _set?: InputMaybe<Pond_Forms_Set_Input>;
  where: Pond_Forms_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Pond_Forms_By_PkArgs = {
  _append?: InputMaybe<Pond_Forms_Append_Input>;
  _delete_at_path?: InputMaybe<Pond_Forms_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Pond_Forms_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Pond_Forms_Delete_Key_Input>;
  _inc?: InputMaybe<Pond_Forms_Inc_Input>;
  _prepend?: InputMaybe<Pond_Forms_Prepend_Input>;
  _set?: InputMaybe<Pond_Forms_Set_Input>;
  pk_columns: Pond_Forms_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Portal_PatientsArgs = {
  _inc?: InputMaybe<Portal_Patients_Inc_Input>;
  _set?: InputMaybe<Portal_Patients_Set_Input>;
  where: Portal_Patients_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Portal_Patients_By_PkArgs = {
  _inc?: InputMaybe<Portal_Patients_Inc_Input>;
  _set?: InputMaybe<Portal_Patients_Set_Input>;
  pk_columns: Portal_Patients_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ProgramsArgs = {
  _inc?: InputMaybe<Programs_Inc_Input>;
  _set?: InputMaybe<Programs_Set_Input>;
  where: Programs_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Programs_By_PkArgs = {
  _inc?: InputMaybe<Programs_Inc_Input>;
  _set?: InputMaybe<Programs_Set_Input>;
  pk_columns: Programs_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ProvidersArgs = {
  _inc?: InputMaybe<Providers_Inc_Input>;
  _set?: InputMaybe<Providers_Set_Input>;
  where: Providers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Providers_By_PkArgs = {
  _inc?: InputMaybe<Providers_Inc_Input>;
  _set?: InputMaybe<Providers_Set_Input>;
  pk_columns: Providers_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_RolesArgs = {
  _set?: InputMaybe<Roles_Set_Input>;
  where: Roles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Roles_By_PkArgs = {
  _set?: InputMaybe<Roles_Set_Input>;
  pk_columns: Roles_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Session_LogsArgs = {
  _append?: InputMaybe<Session_Logs_Append_Input>;
  _delete_at_path?: InputMaybe<Session_Logs_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Session_Logs_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Session_Logs_Delete_Key_Input>;
  _inc?: InputMaybe<Session_Logs_Inc_Input>;
  _prepend?: InputMaybe<Session_Logs_Prepend_Input>;
  _set?: InputMaybe<Session_Logs_Set_Input>;
  where: Session_Logs_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Session_Logs_By_PkArgs = {
  _append?: InputMaybe<Session_Logs_Append_Input>;
  _delete_at_path?: InputMaybe<Session_Logs_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Session_Logs_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Session_Logs_Delete_Key_Input>;
  _inc?: InputMaybe<Session_Logs_Inc_Input>;
  _prepend?: InputMaybe<Session_Logs_Prepend_Input>;
  _set?: InputMaybe<Session_Logs_Set_Input>;
  pk_columns: Session_Logs_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Session_Order_ConfigArgs = {
  _set?: InputMaybe<Session_Order_Config_Set_Input>;
  where: Session_Order_Config_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Session_Order_Config_By_PkArgs = {
  _set?: InputMaybe<Session_Order_Config_Set_Input>;
  pk_columns: Session_Order_Config_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Session_Station_ConfigArgs = {
  _inc?: InputMaybe<Session_Station_Config_Inc_Input>;
  _set?: InputMaybe<Session_Station_Config_Set_Input>;
  where: Session_Station_Config_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Session_Station_Config_By_PkArgs = {
  _inc?: InputMaybe<Session_Station_Config_Inc_Input>;
  _set?: InputMaybe<Session_Station_Config_Set_Input>;
  pk_columns: Session_Station_Config_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Session_TypeArgs = {
  _inc?: InputMaybe<Session_Type_Inc_Input>;
  _set?: InputMaybe<Session_Type_Set_Input>;
  where: Session_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Session_Type_By_PkArgs = {
  _inc?: InputMaybe<Session_Type_Inc_Input>;
  _set?: InputMaybe<Session_Type_Set_Input>;
  pk_columns: Session_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_SessionsArgs = {
  _inc?: InputMaybe<Sessions_Inc_Input>;
  _set?: InputMaybe<Sessions_Set_Input>;
  where: Sessions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Sessions_By_PkArgs = {
  _inc?: InputMaybe<Sessions_Inc_Input>;
  _set?: InputMaybe<Sessions_Set_Input>;
  pk_columns: Sessions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Station_OrdersArgs = {
  _inc?: InputMaybe<Station_Orders_Inc_Input>;
  _set?: InputMaybe<Station_Orders_Set_Input>;
  where: Station_Orders_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Station_Orders_By_PkArgs = {
  _inc?: InputMaybe<Station_Orders_Inc_Input>;
  _set?: InputMaybe<Station_Orders_Set_Input>;
  pk_columns: Station_Orders_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Station_TypeArgs = {
  _set?: InputMaybe<Station_Type_Set_Input>;
  where: Station_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Station_Type_By_PkArgs = {
  _set?: InputMaybe<Station_Type_Set_Input>;
  pk_columns: Station_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Station_VisitsArgs = {
  _inc?: InputMaybe<Station_Visits_Inc_Input>;
  _set?: InputMaybe<Station_Visits_Set_Input>;
  where: Station_Visits_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Station_Visits_By_PkArgs = {
  _inc?: InputMaybe<Station_Visits_Inc_Input>;
  _set?: InputMaybe<Station_Visits_Set_Input>;
  pk_columns: Station_Visits_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_StationsArgs = {
  _inc?: InputMaybe<Stations_Inc_Input>;
  _set?: InputMaybe<Stations_Set_Input>;
  where: Stations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Stations_By_PkArgs = {
  _inc?: InputMaybe<Stations_Inc_Input>;
  _set?: InputMaybe<Stations_Set_Input>;
  pk_columns: Stations_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Test_OrdersArgs = {
  _inc?: InputMaybe<Test_Orders_Inc_Input>;
  _set?: InputMaybe<Test_Orders_Set_Input>;
  where: Test_Orders_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Test_Orders_By_PkArgs = {
  _inc?: InputMaybe<Test_Orders_Inc_Input>;
  _set?: InputMaybe<Test_Orders_Set_Input>;
  pk_columns: Test_Orders_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_UsersArgs = {
  _inc?: InputMaybe<Users_Inc_Input>;
  _set?: InputMaybe<Users_Set_Input>;
  where: Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Users_By_PkArgs = {
  _inc?: InputMaybe<Users_Inc_Input>;
  _set?: InputMaybe<Users_Set_Input>;
  pk_columns: Users_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Video_CallsArgs = {
  _append?: InputMaybe<Video_Calls_Append_Input>;
  _delete_at_path?: InputMaybe<Video_Calls_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Video_Calls_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Video_Calls_Delete_Key_Input>;
  _inc?: InputMaybe<Video_Calls_Inc_Input>;
  _prepend?: InputMaybe<Video_Calls_Prepend_Input>;
  _set?: InputMaybe<Video_Calls_Set_Input>;
  where: Video_Calls_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Video_Calls_By_PkArgs = {
  _append?: InputMaybe<Video_Calls_Append_Input>;
  _delete_at_path?: InputMaybe<Video_Calls_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Video_Calls_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Video_Calls_Delete_Key_Input>;
  _inc?: InputMaybe<Video_Calls_Inc_Input>;
  _prepend?: InputMaybe<Video_Calls_Prepend_Input>;
  _set?: InputMaybe<Video_Calls_Set_Input>;
  pk_columns: Video_Calls_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_VisitsArgs = {
  _append?: InputMaybe<Visits_Append_Input>;
  _delete_at_path?: InputMaybe<Visits_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Visits_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Visits_Delete_Key_Input>;
  _inc?: InputMaybe<Visits_Inc_Input>;
  _prepend?: InputMaybe<Visits_Prepend_Input>;
  _set?: InputMaybe<Visits_Set_Input>;
  where: Visits_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Visits_By_PkArgs = {
  _append?: InputMaybe<Visits_Append_Input>;
  _delete_at_path?: InputMaybe<Visits_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Visits_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Visits_Delete_Key_Input>;
  _inc?: InputMaybe<Visits_Inc_Input>;
  _prepend?: InputMaybe<Visits_Prepend_Input>;
  _set?: InputMaybe<Visits_Set_Input>;
  pk_columns: Visits_Pk_Columns_Input;
};

/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type Numeric_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['numeric']>;
  _gt?: InputMaybe<Scalars['numeric']>;
  _gte?: InputMaybe<Scalars['numeric']>;
  _in?: InputMaybe<Array<Scalars['numeric']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['numeric']>;
  _lte?: InputMaybe<Scalars['numeric']>;
  _neq?: InputMaybe<Scalars['numeric']>;
  _nin?: InputMaybe<Array<Scalars['numeric']>>;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

/** columns and relationships of "order_dshs_covid_test" */
export type Order_Dshs_Covid_Test = {
  __typename?: 'order_dshs_covid_test';
  administration_notes?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  has_sent_dshs_email: Scalars['Boolean'];
  id: Scalars['Int'];
  is_deleted?: Maybe<Scalars['Boolean']>;
  /** An object relationship */
  order: Orders;
  order_id: Scalars['Int'];
  test_name?: Maybe<Scalars['String']>;
  test_outcome?: Maybe<Scalars['String']>;
  test_type?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "order_dshs_covid_test" */
export type Order_Dshs_Covid_Test_Aggregate = {
  __typename?: 'order_dshs_covid_test_aggregate';
  aggregate?: Maybe<Order_Dshs_Covid_Test_Aggregate_Fields>;
  nodes: Array<Order_Dshs_Covid_Test>;
};

/** aggregate fields of "order_dshs_covid_test" */
export type Order_Dshs_Covid_Test_Aggregate_Fields = {
  __typename?: 'order_dshs_covid_test_aggregate_fields';
  avg?: Maybe<Order_Dshs_Covid_Test_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Order_Dshs_Covid_Test_Max_Fields>;
  min?: Maybe<Order_Dshs_Covid_Test_Min_Fields>;
  stddev?: Maybe<Order_Dshs_Covid_Test_Stddev_Fields>;
  stddev_pop?: Maybe<Order_Dshs_Covid_Test_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Order_Dshs_Covid_Test_Stddev_Samp_Fields>;
  sum?: Maybe<Order_Dshs_Covid_Test_Sum_Fields>;
  var_pop?: Maybe<Order_Dshs_Covid_Test_Var_Pop_Fields>;
  var_samp?: Maybe<Order_Dshs_Covid_Test_Var_Samp_Fields>;
  variance?: Maybe<Order_Dshs_Covid_Test_Variance_Fields>;
};


/** aggregate fields of "order_dshs_covid_test" */
export type Order_Dshs_Covid_Test_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Order_Dshs_Covid_Test_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Order_Dshs_Covid_Test_Avg_Fields = {
  __typename?: 'order_dshs_covid_test_avg_fields';
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "order_dshs_covid_test". All fields are combined with a logical 'AND'. */
export type Order_Dshs_Covid_Test_Bool_Exp = {
  _and?: InputMaybe<Array<Order_Dshs_Covid_Test_Bool_Exp>>;
  _not?: InputMaybe<Order_Dshs_Covid_Test_Bool_Exp>;
  _or?: InputMaybe<Array<Order_Dshs_Covid_Test_Bool_Exp>>;
  administration_notes?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  has_sent_dshs_email?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  is_deleted?: InputMaybe<Boolean_Comparison_Exp>;
  order?: InputMaybe<Orders_Bool_Exp>;
  order_id?: InputMaybe<Int_Comparison_Exp>;
  test_name?: InputMaybe<String_Comparison_Exp>;
  test_outcome?: InputMaybe<String_Comparison_Exp>;
  test_type?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "order_dshs_covid_test" */
export enum Order_Dshs_Covid_Test_Constraint {
  /** unique or primary key constraint */
  OrderDshsCovidOrderIdKey = 'order_dshs_covid_order_id_key',
  /** unique or primary key constraint */
  OrderDshsCovidPkey = 'order_dshs_covid_pkey'
}

/** input type for incrementing numeric columns in table "order_dshs_covid_test" */
export type Order_Dshs_Covid_Test_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  order_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "order_dshs_covid_test" */
export type Order_Dshs_Covid_Test_Insert_Input = {
  administration_notes?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  has_sent_dshs_email?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  is_deleted?: InputMaybe<Scalars['Boolean']>;
  order?: InputMaybe<Orders_Obj_Rel_Insert_Input>;
  order_id?: InputMaybe<Scalars['Int']>;
  test_name?: InputMaybe<Scalars['String']>;
  test_outcome?: InputMaybe<Scalars['String']>;
  test_type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Order_Dshs_Covid_Test_Max_Fields = {
  __typename?: 'order_dshs_covid_test_max_fields';
  administration_notes?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  order_id?: Maybe<Scalars['Int']>;
  test_name?: Maybe<Scalars['String']>;
  test_outcome?: Maybe<Scalars['String']>;
  test_type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Order_Dshs_Covid_Test_Min_Fields = {
  __typename?: 'order_dshs_covid_test_min_fields';
  administration_notes?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  order_id?: Maybe<Scalars['Int']>;
  test_name?: Maybe<Scalars['String']>;
  test_outcome?: Maybe<Scalars['String']>;
  test_type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "order_dshs_covid_test" */
export type Order_Dshs_Covid_Test_Mutation_Response = {
  __typename?: 'order_dshs_covid_test_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Order_Dshs_Covid_Test>;
};

/** input type for inserting object relation for remote table "order_dshs_covid_test" */
export type Order_Dshs_Covid_Test_Obj_Rel_Insert_Input = {
  data: Order_Dshs_Covid_Test_Insert_Input;
  /** on conflict condition */
  on_conflict?: InputMaybe<Order_Dshs_Covid_Test_On_Conflict>;
};

/** on conflict condition type for table "order_dshs_covid_test" */
export type Order_Dshs_Covid_Test_On_Conflict = {
  constraint: Order_Dshs_Covid_Test_Constraint;
  update_columns?: Array<Order_Dshs_Covid_Test_Update_Column>;
  where?: InputMaybe<Order_Dshs_Covid_Test_Bool_Exp>;
};

/** Ordering options when selecting data from "order_dshs_covid_test". */
export type Order_Dshs_Covid_Test_Order_By = {
  administration_notes?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  has_sent_dshs_email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_deleted?: InputMaybe<Order_By>;
  order?: InputMaybe<Orders_Order_By>;
  order_id?: InputMaybe<Order_By>;
  test_name?: InputMaybe<Order_By>;
  test_outcome?: InputMaybe<Order_By>;
  test_type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: order_dshs_covid_test */
export type Order_Dshs_Covid_Test_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "order_dshs_covid_test" */
export enum Order_Dshs_Covid_Test_Select_Column {
  /** column name */
  AdministrationNotes = 'administration_notes',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  HasSentDshsEmail = 'has_sent_dshs_email',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  TestName = 'test_name',
  /** column name */
  TestOutcome = 'test_outcome',
  /** column name */
  TestType = 'test_type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "order_dshs_covid_test" */
export type Order_Dshs_Covid_Test_Set_Input = {
  administration_notes?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  has_sent_dshs_email?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  is_deleted?: InputMaybe<Scalars['Boolean']>;
  order_id?: InputMaybe<Scalars['Int']>;
  test_name?: InputMaybe<Scalars['String']>;
  test_outcome?: InputMaybe<Scalars['String']>;
  test_type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Order_Dshs_Covid_Test_Stddev_Fields = {
  __typename?: 'order_dshs_covid_test_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Order_Dshs_Covid_Test_Stddev_Pop_Fields = {
  __typename?: 'order_dshs_covid_test_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Order_Dshs_Covid_Test_Stddev_Samp_Fields = {
  __typename?: 'order_dshs_covid_test_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Order_Dshs_Covid_Test_Sum_Fields = {
  __typename?: 'order_dshs_covid_test_sum_fields';
  id?: Maybe<Scalars['Int']>;
  order_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "order_dshs_covid_test" */
export enum Order_Dshs_Covid_Test_Update_Column {
  /** column name */
  AdministrationNotes = 'administration_notes',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  HasSentDshsEmail = 'has_sent_dshs_email',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  TestName = 'test_name',
  /** column name */
  TestOutcome = 'test_outcome',
  /** column name */
  TestType = 'test_type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Order_Dshs_Covid_Test_Var_Pop_Fields = {
  __typename?: 'order_dshs_covid_test_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Order_Dshs_Covid_Test_Var_Samp_Fields = {
  __typename?: 'order_dshs_covid_test_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Order_Dshs_Covid_Test_Variance_Fields = {
  __typename?: 'order_dshs_covid_test_variance_fields';
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
};

/**
 * order category
 *
 *
 * columns and relationships of "order_height_and_weight"
 *
 */
export type Order_Height_And_Weight = {
  __typename?: 'order_height_and_weight';
  birth_sex?: Maybe<Scalars['String']>;
  bmi?: Maybe<Scalars['Float']>;
  bmi_percentile?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  date_of_birth?: Maybe<Scalars['date']>;
  height_ft?: Maybe<Scalars['Int']>;
  height_in?: Maybe<Scalars['Float']>;
  id: Scalars['Int'];
  is_deleted?: Maybe<Scalars['Boolean']>;
  /** An object relationship */
  order: Orders;
  order_id: Scalars['Int'];
  pressure_diastolic?: Maybe<Scalars['Int']>;
  pressure_systolic?: Maybe<Scalars['Int']>;
  pulse_bpm?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  weight_lbs?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "order_height_and_weight" */
export type Order_Height_And_Weight_Aggregate = {
  __typename?: 'order_height_and_weight_aggregate';
  aggregate?: Maybe<Order_Height_And_Weight_Aggregate_Fields>;
  nodes: Array<Order_Height_And_Weight>;
};

/** aggregate fields of "order_height_and_weight" */
export type Order_Height_And_Weight_Aggregate_Fields = {
  __typename?: 'order_height_and_weight_aggregate_fields';
  avg?: Maybe<Order_Height_And_Weight_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Order_Height_And_Weight_Max_Fields>;
  min?: Maybe<Order_Height_And_Weight_Min_Fields>;
  stddev?: Maybe<Order_Height_And_Weight_Stddev_Fields>;
  stddev_pop?: Maybe<Order_Height_And_Weight_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Order_Height_And_Weight_Stddev_Samp_Fields>;
  sum?: Maybe<Order_Height_And_Weight_Sum_Fields>;
  var_pop?: Maybe<Order_Height_And_Weight_Var_Pop_Fields>;
  var_samp?: Maybe<Order_Height_And_Weight_Var_Samp_Fields>;
  variance?: Maybe<Order_Height_And_Weight_Variance_Fields>;
};


/** aggregate fields of "order_height_and_weight" */
export type Order_Height_And_Weight_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Order_Height_And_Weight_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Order_Height_And_Weight_Avg_Fields = {
  __typename?: 'order_height_and_weight_avg_fields';
  bmi?: Maybe<Scalars['Float']>;
  bmi_percentile?: Maybe<Scalars['Float']>;
  height_ft?: Maybe<Scalars['Float']>;
  height_in?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  pressure_diastolic?: Maybe<Scalars['Float']>;
  pressure_systolic?: Maybe<Scalars['Float']>;
  pulse_bpm?: Maybe<Scalars['Float']>;
  weight_lbs?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "order_height_and_weight". All fields are combined with a logical 'AND'. */
export type Order_Height_And_Weight_Bool_Exp = {
  _and?: InputMaybe<Array<Order_Height_And_Weight_Bool_Exp>>;
  _not?: InputMaybe<Order_Height_And_Weight_Bool_Exp>;
  _or?: InputMaybe<Array<Order_Height_And_Weight_Bool_Exp>>;
  birth_sex?: InputMaybe<String_Comparison_Exp>;
  bmi?: InputMaybe<Float_Comparison_Exp>;
  bmi_percentile?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  date_of_birth?: InputMaybe<Date_Comparison_Exp>;
  height_ft?: InputMaybe<Int_Comparison_Exp>;
  height_in?: InputMaybe<Float_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  is_deleted?: InputMaybe<Boolean_Comparison_Exp>;
  order?: InputMaybe<Orders_Bool_Exp>;
  order_id?: InputMaybe<Int_Comparison_Exp>;
  pressure_diastolic?: InputMaybe<Int_Comparison_Exp>;
  pressure_systolic?: InputMaybe<Int_Comparison_Exp>;
  pulse_bpm?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  weight_lbs?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "order_height_and_weight" */
export enum Order_Height_And_Weight_Constraint {
  /** unique or primary key constraint */
  OrderVitalSignsOrderIdKey = 'order_vital_signs_order_id_key',
  /** unique or primary key constraint */
  OrderVitalSignsPkey = 'order_vital_signs_pkey'
}

/** input type for incrementing numeric columns in table "order_height_and_weight" */
export type Order_Height_And_Weight_Inc_Input = {
  bmi?: InputMaybe<Scalars['Float']>;
  bmi_percentile?: InputMaybe<Scalars['Int']>;
  height_ft?: InputMaybe<Scalars['Int']>;
  height_in?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['Int']>;
  order_id?: InputMaybe<Scalars['Int']>;
  pressure_diastolic?: InputMaybe<Scalars['Int']>;
  pressure_systolic?: InputMaybe<Scalars['Int']>;
  pulse_bpm?: InputMaybe<Scalars['Int']>;
  weight_lbs?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "order_height_and_weight" */
export type Order_Height_And_Weight_Insert_Input = {
  birth_sex?: InputMaybe<Scalars['String']>;
  bmi?: InputMaybe<Scalars['Float']>;
  bmi_percentile?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  date_of_birth?: InputMaybe<Scalars['date']>;
  height_ft?: InputMaybe<Scalars['Int']>;
  height_in?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['Int']>;
  is_deleted?: InputMaybe<Scalars['Boolean']>;
  order?: InputMaybe<Orders_Obj_Rel_Insert_Input>;
  order_id?: InputMaybe<Scalars['Int']>;
  pressure_diastolic?: InputMaybe<Scalars['Int']>;
  pressure_systolic?: InputMaybe<Scalars['Int']>;
  pulse_bpm?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  weight_lbs?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Order_Height_And_Weight_Max_Fields = {
  __typename?: 'order_height_and_weight_max_fields';
  birth_sex?: Maybe<Scalars['String']>;
  bmi?: Maybe<Scalars['Float']>;
  bmi_percentile?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  date_of_birth?: Maybe<Scalars['date']>;
  height_ft?: Maybe<Scalars['Int']>;
  height_in?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Int']>;
  order_id?: Maybe<Scalars['Int']>;
  pressure_diastolic?: Maybe<Scalars['Int']>;
  pressure_systolic?: Maybe<Scalars['Int']>;
  pulse_bpm?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  weight_lbs?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Order_Height_And_Weight_Min_Fields = {
  __typename?: 'order_height_and_weight_min_fields';
  birth_sex?: Maybe<Scalars['String']>;
  bmi?: Maybe<Scalars['Float']>;
  bmi_percentile?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  date_of_birth?: Maybe<Scalars['date']>;
  height_ft?: Maybe<Scalars['Int']>;
  height_in?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Int']>;
  order_id?: Maybe<Scalars['Int']>;
  pressure_diastolic?: Maybe<Scalars['Int']>;
  pressure_systolic?: Maybe<Scalars['Int']>;
  pulse_bpm?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  weight_lbs?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "order_height_and_weight" */
export type Order_Height_And_Weight_Mutation_Response = {
  __typename?: 'order_height_and_weight_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Order_Height_And_Weight>;
};

/** input type for inserting object relation for remote table "order_height_and_weight" */
export type Order_Height_And_Weight_Obj_Rel_Insert_Input = {
  data: Order_Height_And_Weight_Insert_Input;
  /** on conflict condition */
  on_conflict?: InputMaybe<Order_Height_And_Weight_On_Conflict>;
};

/** on conflict condition type for table "order_height_and_weight" */
export type Order_Height_And_Weight_On_Conflict = {
  constraint: Order_Height_And_Weight_Constraint;
  update_columns?: Array<Order_Height_And_Weight_Update_Column>;
  where?: InputMaybe<Order_Height_And_Weight_Bool_Exp>;
};

/** Ordering options when selecting data from "order_height_and_weight". */
export type Order_Height_And_Weight_Order_By = {
  birth_sex?: InputMaybe<Order_By>;
  bmi?: InputMaybe<Order_By>;
  bmi_percentile?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  date_of_birth?: InputMaybe<Order_By>;
  height_ft?: InputMaybe<Order_By>;
  height_in?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_deleted?: InputMaybe<Order_By>;
  order?: InputMaybe<Orders_Order_By>;
  order_id?: InputMaybe<Order_By>;
  pressure_diastolic?: InputMaybe<Order_By>;
  pressure_systolic?: InputMaybe<Order_By>;
  pulse_bpm?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  weight_lbs?: InputMaybe<Order_By>;
};

/** primary key columns input for table: order_height_and_weight */
export type Order_Height_And_Weight_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "order_height_and_weight" */
export enum Order_Height_And_Weight_Select_Column {
  /** column name */
  BirthSex = 'birth_sex',
  /** column name */
  Bmi = 'bmi',
  /** column name */
  BmiPercentile = 'bmi_percentile',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DateOfBirth = 'date_of_birth',
  /** column name */
  HeightFt = 'height_ft',
  /** column name */
  HeightIn = 'height_in',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  PressureDiastolic = 'pressure_diastolic',
  /** column name */
  PressureSystolic = 'pressure_systolic',
  /** column name */
  PulseBpm = 'pulse_bpm',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WeightLbs = 'weight_lbs'
}

/** input type for updating data in table "order_height_and_weight" */
export type Order_Height_And_Weight_Set_Input = {
  birth_sex?: InputMaybe<Scalars['String']>;
  bmi?: InputMaybe<Scalars['Float']>;
  bmi_percentile?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  date_of_birth?: InputMaybe<Scalars['date']>;
  height_ft?: InputMaybe<Scalars['Int']>;
  height_in?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['Int']>;
  is_deleted?: InputMaybe<Scalars['Boolean']>;
  order_id?: InputMaybe<Scalars['Int']>;
  pressure_diastolic?: InputMaybe<Scalars['Int']>;
  pressure_systolic?: InputMaybe<Scalars['Int']>;
  pulse_bpm?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  weight_lbs?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Order_Height_And_Weight_Stddev_Fields = {
  __typename?: 'order_height_and_weight_stddev_fields';
  bmi?: Maybe<Scalars['Float']>;
  bmi_percentile?: Maybe<Scalars['Float']>;
  height_ft?: Maybe<Scalars['Float']>;
  height_in?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  pressure_diastolic?: Maybe<Scalars['Float']>;
  pressure_systolic?: Maybe<Scalars['Float']>;
  pulse_bpm?: Maybe<Scalars['Float']>;
  weight_lbs?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Order_Height_And_Weight_Stddev_Pop_Fields = {
  __typename?: 'order_height_and_weight_stddev_pop_fields';
  bmi?: Maybe<Scalars['Float']>;
  bmi_percentile?: Maybe<Scalars['Float']>;
  height_ft?: Maybe<Scalars['Float']>;
  height_in?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  pressure_diastolic?: Maybe<Scalars['Float']>;
  pressure_systolic?: Maybe<Scalars['Float']>;
  pulse_bpm?: Maybe<Scalars['Float']>;
  weight_lbs?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Order_Height_And_Weight_Stddev_Samp_Fields = {
  __typename?: 'order_height_and_weight_stddev_samp_fields';
  bmi?: Maybe<Scalars['Float']>;
  bmi_percentile?: Maybe<Scalars['Float']>;
  height_ft?: Maybe<Scalars['Float']>;
  height_in?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  pressure_diastolic?: Maybe<Scalars['Float']>;
  pressure_systolic?: Maybe<Scalars['Float']>;
  pulse_bpm?: Maybe<Scalars['Float']>;
  weight_lbs?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Order_Height_And_Weight_Sum_Fields = {
  __typename?: 'order_height_and_weight_sum_fields';
  bmi?: Maybe<Scalars['Float']>;
  bmi_percentile?: Maybe<Scalars['Int']>;
  height_ft?: Maybe<Scalars['Int']>;
  height_in?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Int']>;
  order_id?: Maybe<Scalars['Int']>;
  pressure_diastolic?: Maybe<Scalars['Int']>;
  pressure_systolic?: Maybe<Scalars['Int']>;
  pulse_bpm?: Maybe<Scalars['Int']>;
  weight_lbs?: Maybe<Scalars['Int']>;
};

/** update columns of table "order_height_and_weight" */
export enum Order_Height_And_Weight_Update_Column {
  /** column name */
  BirthSex = 'birth_sex',
  /** column name */
  Bmi = 'bmi',
  /** column name */
  BmiPercentile = 'bmi_percentile',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DateOfBirth = 'date_of_birth',
  /** column name */
  HeightFt = 'height_ft',
  /** column name */
  HeightIn = 'height_in',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  PressureDiastolic = 'pressure_diastolic',
  /** column name */
  PressureSystolic = 'pressure_systolic',
  /** column name */
  PulseBpm = 'pulse_bpm',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WeightLbs = 'weight_lbs'
}

/** aggregate var_pop on columns */
export type Order_Height_And_Weight_Var_Pop_Fields = {
  __typename?: 'order_height_and_weight_var_pop_fields';
  bmi?: Maybe<Scalars['Float']>;
  bmi_percentile?: Maybe<Scalars['Float']>;
  height_ft?: Maybe<Scalars['Float']>;
  height_in?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  pressure_diastolic?: Maybe<Scalars['Float']>;
  pressure_systolic?: Maybe<Scalars['Float']>;
  pulse_bpm?: Maybe<Scalars['Float']>;
  weight_lbs?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Order_Height_And_Weight_Var_Samp_Fields = {
  __typename?: 'order_height_and_weight_var_samp_fields';
  bmi?: Maybe<Scalars['Float']>;
  bmi_percentile?: Maybe<Scalars['Float']>;
  height_ft?: Maybe<Scalars['Float']>;
  height_in?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  pressure_diastolic?: Maybe<Scalars['Float']>;
  pressure_systolic?: Maybe<Scalars['Float']>;
  pulse_bpm?: Maybe<Scalars['Float']>;
  weight_lbs?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Order_Height_And_Weight_Variance_Fields = {
  __typename?: 'order_height_and_weight_variance_fields';
  bmi?: Maybe<Scalars['Float']>;
  bmi_percentile?: Maybe<Scalars['Float']>;
  height_ft?: Maybe<Scalars['Float']>;
  height_in?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  pressure_diastolic?: Maybe<Scalars['Float']>;
  pressure_systolic?: Maybe<Scalars['Float']>;
  pulse_bpm?: Maybe<Scalars['Float']>;
  weight_lbs?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "order_medical_assessment" */
export type Order_Medical_Assessment = {
  __typename?: 'order_medical_assessment';
  abdomen?: Maybe<Scalars['String']>;
  abdomen_notes?: Maybe<Scalars['String']>;
  appearance?: Maybe<Scalars['String']>;
  appearance_notes?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  eyes_ears_nose_throat?: Maybe<Scalars['String']>;
  eyes_ears_nose_throat_notes?: Maybe<Scalars['String']>;
  heart_auscultation_standing_position?: Maybe<Scalars['String']>;
  heart_auscultation_standing_position_notes?: Maybe<Scalars['String']>;
  heart_auscultation_supine_position?: Maybe<Scalars['String']>;
  heart_auscultation_supine_position_notes?: Maybe<Scalars['String']>;
  heart_lower_pulses?: Maybe<Scalars['String']>;
  heart_lower_pulses_notes?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  is_deleted?: Maybe<Scalars['Boolean']>;
  is_pupils_equal?: Maybe<Scalars['Boolean']>;
  lungs?: Maybe<Scalars['String']>;
  lungs_notes?: Maybe<Scalars['String']>;
  lymph_nodes?: Maybe<Scalars['String']>;
  lymph_nodes_notes?: Maybe<Scalars['String']>;
  marfans_stigmata?: Maybe<Scalars['String']>;
  marfans_stigmata_notes?: Maybe<Scalars['String']>;
  /** An object relationship */
  order: Orders;
  order_id: Scalars['Int'];
  pulses?: Maybe<Scalars['String']>;
  pulses_notes?: Maybe<Scalars['String']>;
  signature?: Maybe<Scalars['String']>;
  skin?: Maybe<Scalars['String']>;
  skin_notes?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "order_medical_assessment" */
export type Order_Medical_Assessment_Aggregate = {
  __typename?: 'order_medical_assessment_aggregate';
  aggregate?: Maybe<Order_Medical_Assessment_Aggregate_Fields>;
  nodes: Array<Order_Medical_Assessment>;
};

/** aggregate fields of "order_medical_assessment" */
export type Order_Medical_Assessment_Aggregate_Fields = {
  __typename?: 'order_medical_assessment_aggregate_fields';
  avg?: Maybe<Order_Medical_Assessment_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Order_Medical_Assessment_Max_Fields>;
  min?: Maybe<Order_Medical_Assessment_Min_Fields>;
  stddev?: Maybe<Order_Medical_Assessment_Stddev_Fields>;
  stddev_pop?: Maybe<Order_Medical_Assessment_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Order_Medical_Assessment_Stddev_Samp_Fields>;
  sum?: Maybe<Order_Medical_Assessment_Sum_Fields>;
  var_pop?: Maybe<Order_Medical_Assessment_Var_Pop_Fields>;
  var_samp?: Maybe<Order_Medical_Assessment_Var_Samp_Fields>;
  variance?: Maybe<Order_Medical_Assessment_Variance_Fields>;
};


/** aggregate fields of "order_medical_assessment" */
export type Order_Medical_Assessment_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Order_Medical_Assessment_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Order_Medical_Assessment_Avg_Fields = {
  __typename?: 'order_medical_assessment_avg_fields';
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "order_medical_assessment". All fields are combined with a logical 'AND'. */
export type Order_Medical_Assessment_Bool_Exp = {
  _and?: InputMaybe<Array<Order_Medical_Assessment_Bool_Exp>>;
  _not?: InputMaybe<Order_Medical_Assessment_Bool_Exp>;
  _or?: InputMaybe<Array<Order_Medical_Assessment_Bool_Exp>>;
  abdomen?: InputMaybe<String_Comparison_Exp>;
  abdomen_notes?: InputMaybe<String_Comparison_Exp>;
  appearance?: InputMaybe<String_Comparison_Exp>;
  appearance_notes?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  eyes_ears_nose_throat?: InputMaybe<String_Comparison_Exp>;
  eyes_ears_nose_throat_notes?: InputMaybe<String_Comparison_Exp>;
  heart_auscultation_standing_position?: InputMaybe<String_Comparison_Exp>;
  heart_auscultation_standing_position_notes?: InputMaybe<String_Comparison_Exp>;
  heart_auscultation_supine_position?: InputMaybe<String_Comparison_Exp>;
  heart_auscultation_supine_position_notes?: InputMaybe<String_Comparison_Exp>;
  heart_lower_pulses?: InputMaybe<String_Comparison_Exp>;
  heart_lower_pulses_notes?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  is_deleted?: InputMaybe<Boolean_Comparison_Exp>;
  is_pupils_equal?: InputMaybe<Boolean_Comparison_Exp>;
  lungs?: InputMaybe<String_Comparison_Exp>;
  lungs_notes?: InputMaybe<String_Comparison_Exp>;
  lymph_nodes?: InputMaybe<String_Comparison_Exp>;
  lymph_nodes_notes?: InputMaybe<String_Comparison_Exp>;
  marfans_stigmata?: InputMaybe<String_Comparison_Exp>;
  marfans_stigmata_notes?: InputMaybe<String_Comparison_Exp>;
  order?: InputMaybe<Orders_Bool_Exp>;
  order_id?: InputMaybe<Int_Comparison_Exp>;
  pulses?: InputMaybe<String_Comparison_Exp>;
  pulses_notes?: InputMaybe<String_Comparison_Exp>;
  signature?: InputMaybe<String_Comparison_Exp>;
  skin?: InputMaybe<String_Comparison_Exp>;
  skin_notes?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "order_medical_assessment" */
export enum Order_Medical_Assessment_Constraint {
  /** unique or primary key constraint */
  OrderMedicalAssessmentOrderIdKey = 'order_medical_assessment_order_id_key',
  /** unique or primary key constraint */
  OrderMedicalAssessmentPkey = 'order_medical_assessment_pkey'
}

/** input type for incrementing numeric columns in table "order_medical_assessment" */
export type Order_Medical_Assessment_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  order_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "order_medical_assessment" */
export type Order_Medical_Assessment_Insert_Input = {
  abdomen?: InputMaybe<Scalars['String']>;
  abdomen_notes?: InputMaybe<Scalars['String']>;
  appearance?: InputMaybe<Scalars['String']>;
  appearance_notes?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  eyes_ears_nose_throat?: InputMaybe<Scalars['String']>;
  eyes_ears_nose_throat_notes?: InputMaybe<Scalars['String']>;
  heart_auscultation_standing_position?: InputMaybe<Scalars['String']>;
  heart_auscultation_standing_position_notes?: InputMaybe<Scalars['String']>;
  heart_auscultation_supine_position?: InputMaybe<Scalars['String']>;
  heart_auscultation_supine_position_notes?: InputMaybe<Scalars['String']>;
  heart_lower_pulses?: InputMaybe<Scalars['String']>;
  heart_lower_pulses_notes?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  is_deleted?: InputMaybe<Scalars['Boolean']>;
  is_pupils_equal?: InputMaybe<Scalars['Boolean']>;
  lungs?: InputMaybe<Scalars['String']>;
  lungs_notes?: InputMaybe<Scalars['String']>;
  lymph_nodes?: InputMaybe<Scalars['String']>;
  lymph_nodes_notes?: InputMaybe<Scalars['String']>;
  marfans_stigmata?: InputMaybe<Scalars['String']>;
  marfans_stigmata_notes?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Orders_Obj_Rel_Insert_Input>;
  order_id?: InputMaybe<Scalars['Int']>;
  pulses?: InputMaybe<Scalars['String']>;
  pulses_notes?: InputMaybe<Scalars['String']>;
  signature?: InputMaybe<Scalars['String']>;
  skin?: InputMaybe<Scalars['String']>;
  skin_notes?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Order_Medical_Assessment_Max_Fields = {
  __typename?: 'order_medical_assessment_max_fields';
  abdomen?: Maybe<Scalars['String']>;
  abdomen_notes?: Maybe<Scalars['String']>;
  appearance?: Maybe<Scalars['String']>;
  appearance_notes?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  eyes_ears_nose_throat?: Maybe<Scalars['String']>;
  eyes_ears_nose_throat_notes?: Maybe<Scalars['String']>;
  heart_auscultation_standing_position?: Maybe<Scalars['String']>;
  heart_auscultation_standing_position_notes?: Maybe<Scalars['String']>;
  heart_auscultation_supine_position?: Maybe<Scalars['String']>;
  heart_auscultation_supine_position_notes?: Maybe<Scalars['String']>;
  heart_lower_pulses?: Maybe<Scalars['String']>;
  heart_lower_pulses_notes?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  lungs?: Maybe<Scalars['String']>;
  lungs_notes?: Maybe<Scalars['String']>;
  lymph_nodes?: Maybe<Scalars['String']>;
  lymph_nodes_notes?: Maybe<Scalars['String']>;
  marfans_stigmata?: Maybe<Scalars['String']>;
  marfans_stigmata_notes?: Maybe<Scalars['String']>;
  order_id?: Maybe<Scalars['Int']>;
  pulses?: Maybe<Scalars['String']>;
  pulses_notes?: Maybe<Scalars['String']>;
  signature?: Maybe<Scalars['String']>;
  skin?: Maybe<Scalars['String']>;
  skin_notes?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Order_Medical_Assessment_Min_Fields = {
  __typename?: 'order_medical_assessment_min_fields';
  abdomen?: Maybe<Scalars['String']>;
  abdomen_notes?: Maybe<Scalars['String']>;
  appearance?: Maybe<Scalars['String']>;
  appearance_notes?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  eyes_ears_nose_throat?: Maybe<Scalars['String']>;
  eyes_ears_nose_throat_notes?: Maybe<Scalars['String']>;
  heart_auscultation_standing_position?: Maybe<Scalars['String']>;
  heart_auscultation_standing_position_notes?: Maybe<Scalars['String']>;
  heart_auscultation_supine_position?: Maybe<Scalars['String']>;
  heart_auscultation_supine_position_notes?: Maybe<Scalars['String']>;
  heart_lower_pulses?: Maybe<Scalars['String']>;
  heart_lower_pulses_notes?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  lungs?: Maybe<Scalars['String']>;
  lungs_notes?: Maybe<Scalars['String']>;
  lymph_nodes?: Maybe<Scalars['String']>;
  lymph_nodes_notes?: Maybe<Scalars['String']>;
  marfans_stigmata?: Maybe<Scalars['String']>;
  marfans_stigmata_notes?: Maybe<Scalars['String']>;
  order_id?: Maybe<Scalars['Int']>;
  pulses?: Maybe<Scalars['String']>;
  pulses_notes?: Maybe<Scalars['String']>;
  signature?: Maybe<Scalars['String']>;
  skin?: Maybe<Scalars['String']>;
  skin_notes?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "order_medical_assessment" */
export type Order_Medical_Assessment_Mutation_Response = {
  __typename?: 'order_medical_assessment_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Order_Medical_Assessment>;
};

/** input type for inserting object relation for remote table "order_medical_assessment" */
export type Order_Medical_Assessment_Obj_Rel_Insert_Input = {
  data: Order_Medical_Assessment_Insert_Input;
  /** on conflict condition */
  on_conflict?: InputMaybe<Order_Medical_Assessment_On_Conflict>;
};

/** on conflict condition type for table "order_medical_assessment" */
export type Order_Medical_Assessment_On_Conflict = {
  constraint: Order_Medical_Assessment_Constraint;
  update_columns?: Array<Order_Medical_Assessment_Update_Column>;
  where?: InputMaybe<Order_Medical_Assessment_Bool_Exp>;
};

/** Ordering options when selecting data from "order_medical_assessment". */
export type Order_Medical_Assessment_Order_By = {
  abdomen?: InputMaybe<Order_By>;
  abdomen_notes?: InputMaybe<Order_By>;
  appearance?: InputMaybe<Order_By>;
  appearance_notes?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  eyes_ears_nose_throat?: InputMaybe<Order_By>;
  eyes_ears_nose_throat_notes?: InputMaybe<Order_By>;
  heart_auscultation_standing_position?: InputMaybe<Order_By>;
  heart_auscultation_standing_position_notes?: InputMaybe<Order_By>;
  heart_auscultation_supine_position?: InputMaybe<Order_By>;
  heart_auscultation_supine_position_notes?: InputMaybe<Order_By>;
  heart_lower_pulses?: InputMaybe<Order_By>;
  heart_lower_pulses_notes?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_deleted?: InputMaybe<Order_By>;
  is_pupils_equal?: InputMaybe<Order_By>;
  lungs?: InputMaybe<Order_By>;
  lungs_notes?: InputMaybe<Order_By>;
  lymph_nodes?: InputMaybe<Order_By>;
  lymph_nodes_notes?: InputMaybe<Order_By>;
  marfans_stigmata?: InputMaybe<Order_By>;
  marfans_stigmata_notes?: InputMaybe<Order_By>;
  order?: InputMaybe<Orders_Order_By>;
  order_id?: InputMaybe<Order_By>;
  pulses?: InputMaybe<Order_By>;
  pulses_notes?: InputMaybe<Order_By>;
  signature?: InputMaybe<Order_By>;
  skin?: InputMaybe<Order_By>;
  skin_notes?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: order_medical_assessment */
export type Order_Medical_Assessment_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "order_medical_assessment" */
export enum Order_Medical_Assessment_Select_Column {
  /** column name */
  Abdomen = 'abdomen',
  /** column name */
  AbdomenNotes = 'abdomen_notes',
  /** column name */
  Appearance = 'appearance',
  /** column name */
  AppearanceNotes = 'appearance_notes',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EyesEarsNoseThroat = 'eyes_ears_nose_throat',
  /** column name */
  EyesEarsNoseThroatNotes = 'eyes_ears_nose_throat_notes',
  /** column name */
  HeartAuscultationStandingPosition = 'heart_auscultation_standing_position',
  /** column name */
  HeartAuscultationStandingPositionNotes = 'heart_auscultation_standing_position_notes',
  /** column name */
  HeartAuscultationSupinePosition = 'heart_auscultation_supine_position',
  /** column name */
  HeartAuscultationSupinePositionNotes = 'heart_auscultation_supine_position_notes',
  /** column name */
  HeartLowerPulses = 'heart_lower_pulses',
  /** column name */
  HeartLowerPulsesNotes = 'heart_lower_pulses_notes',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  IsPupilsEqual = 'is_pupils_equal',
  /** column name */
  Lungs = 'lungs',
  /** column name */
  LungsNotes = 'lungs_notes',
  /** column name */
  LymphNodes = 'lymph_nodes',
  /** column name */
  LymphNodesNotes = 'lymph_nodes_notes',
  /** column name */
  MarfansStigmata = 'marfans_stigmata',
  /** column name */
  MarfansStigmataNotes = 'marfans_stigmata_notes',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  Pulses = 'pulses',
  /** column name */
  PulsesNotes = 'pulses_notes',
  /** column name */
  Signature = 'signature',
  /** column name */
  Skin = 'skin',
  /** column name */
  SkinNotes = 'skin_notes',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "order_medical_assessment" */
export type Order_Medical_Assessment_Set_Input = {
  abdomen?: InputMaybe<Scalars['String']>;
  abdomen_notes?: InputMaybe<Scalars['String']>;
  appearance?: InputMaybe<Scalars['String']>;
  appearance_notes?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  eyes_ears_nose_throat?: InputMaybe<Scalars['String']>;
  eyes_ears_nose_throat_notes?: InputMaybe<Scalars['String']>;
  heart_auscultation_standing_position?: InputMaybe<Scalars['String']>;
  heart_auscultation_standing_position_notes?: InputMaybe<Scalars['String']>;
  heart_auscultation_supine_position?: InputMaybe<Scalars['String']>;
  heart_auscultation_supine_position_notes?: InputMaybe<Scalars['String']>;
  heart_lower_pulses?: InputMaybe<Scalars['String']>;
  heart_lower_pulses_notes?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  is_deleted?: InputMaybe<Scalars['Boolean']>;
  is_pupils_equal?: InputMaybe<Scalars['Boolean']>;
  lungs?: InputMaybe<Scalars['String']>;
  lungs_notes?: InputMaybe<Scalars['String']>;
  lymph_nodes?: InputMaybe<Scalars['String']>;
  lymph_nodes_notes?: InputMaybe<Scalars['String']>;
  marfans_stigmata?: InputMaybe<Scalars['String']>;
  marfans_stigmata_notes?: InputMaybe<Scalars['String']>;
  order_id?: InputMaybe<Scalars['Int']>;
  pulses?: InputMaybe<Scalars['String']>;
  pulses_notes?: InputMaybe<Scalars['String']>;
  signature?: InputMaybe<Scalars['String']>;
  skin?: InputMaybe<Scalars['String']>;
  skin_notes?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Order_Medical_Assessment_Stddev_Fields = {
  __typename?: 'order_medical_assessment_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Order_Medical_Assessment_Stddev_Pop_Fields = {
  __typename?: 'order_medical_assessment_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Order_Medical_Assessment_Stddev_Samp_Fields = {
  __typename?: 'order_medical_assessment_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Order_Medical_Assessment_Sum_Fields = {
  __typename?: 'order_medical_assessment_sum_fields';
  id?: Maybe<Scalars['Int']>;
  order_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "order_medical_assessment" */
export enum Order_Medical_Assessment_Update_Column {
  /** column name */
  Abdomen = 'abdomen',
  /** column name */
  AbdomenNotes = 'abdomen_notes',
  /** column name */
  Appearance = 'appearance',
  /** column name */
  AppearanceNotes = 'appearance_notes',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EyesEarsNoseThroat = 'eyes_ears_nose_throat',
  /** column name */
  EyesEarsNoseThroatNotes = 'eyes_ears_nose_throat_notes',
  /** column name */
  HeartAuscultationStandingPosition = 'heart_auscultation_standing_position',
  /** column name */
  HeartAuscultationStandingPositionNotes = 'heart_auscultation_standing_position_notes',
  /** column name */
  HeartAuscultationSupinePosition = 'heart_auscultation_supine_position',
  /** column name */
  HeartAuscultationSupinePositionNotes = 'heart_auscultation_supine_position_notes',
  /** column name */
  HeartLowerPulses = 'heart_lower_pulses',
  /** column name */
  HeartLowerPulsesNotes = 'heart_lower_pulses_notes',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  IsPupilsEqual = 'is_pupils_equal',
  /** column name */
  Lungs = 'lungs',
  /** column name */
  LungsNotes = 'lungs_notes',
  /** column name */
  LymphNodes = 'lymph_nodes',
  /** column name */
  LymphNodesNotes = 'lymph_nodes_notes',
  /** column name */
  MarfansStigmata = 'marfans_stigmata',
  /** column name */
  MarfansStigmataNotes = 'marfans_stigmata_notes',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  Pulses = 'pulses',
  /** column name */
  PulsesNotes = 'pulses_notes',
  /** column name */
  Signature = 'signature',
  /** column name */
  Skin = 'skin',
  /** column name */
  SkinNotes = 'skin_notes',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Order_Medical_Assessment_Var_Pop_Fields = {
  __typename?: 'order_medical_assessment_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Order_Medical_Assessment_Var_Samp_Fields = {
  __typename?: 'order_medical_assessment_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Order_Medical_Assessment_Variance_Fields = {
  __typename?: 'order_medical_assessment_variance_fields';
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "order_musculoskeletal" */
export type Order_Musculoskeletal = {
  __typename?: 'order_musculoskeletal';
  back?: Maybe<Scalars['String']>;
  back_notes?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  elbow_or_forearm?: Maybe<Scalars['String']>;
  elbow_or_forearm_notes?: Maybe<Scalars['String']>;
  foot?: Maybe<Scalars['String']>;
  foot_notes?: Maybe<Scalars['String']>;
  hip_or_thigh?: Maybe<Scalars['String']>;
  hip_or_thigh_notes?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  is_deleted?: Maybe<Scalars['Boolean']>;
  knee?: Maybe<Scalars['String']>;
  knee_notes?: Maybe<Scalars['String']>;
  leg_or_ankle?: Maybe<Scalars['String']>;
  leg_or_ankle_notes?: Maybe<Scalars['String']>;
  neck?: Maybe<Scalars['String']>;
  neck_notes?: Maybe<Scalars['String']>;
  /** An object relationship */
  order: Orders;
  order_id: Scalars['Int'];
  shoulder_or_arm?: Maybe<Scalars['String']>;
  shoulder_or_arm_notes?: Maybe<Scalars['String']>;
  signature?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  wrist_or_hand?: Maybe<Scalars['String']>;
  wrist_or_hand_notes?: Maybe<Scalars['String']>;
};

/** aggregated selection of "order_musculoskeletal" */
export type Order_Musculoskeletal_Aggregate = {
  __typename?: 'order_musculoskeletal_aggregate';
  aggregate?: Maybe<Order_Musculoskeletal_Aggregate_Fields>;
  nodes: Array<Order_Musculoskeletal>;
};

/** aggregate fields of "order_musculoskeletal" */
export type Order_Musculoskeletal_Aggregate_Fields = {
  __typename?: 'order_musculoskeletal_aggregate_fields';
  avg?: Maybe<Order_Musculoskeletal_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Order_Musculoskeletal_Max_Fields>;
  min?: Maybe<Order_Musculoskeletal_Min_Fields>;
  stddev?: Maybe<Order_Musculoskeletal_Stddev_Fields>;
  stddev_pop?: Maybe<Order_Musculoskeletal_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Order_Musculoskeletal_Stddev_Samp_Fields>;
  sum?: Maybe<Order_Musculoskeletal_Sum_Fields>;
  var_pop?: Maybe<Order_Musculoskeletal_Var_Pop_Fields>;
  var_samp?: Maybe<Order_Musculoskeletal_Var_Samp_Fields>;
  variance?: Maybe<Order_Musculoskeletal_Variance_Fields>;
};


/** aggregate fields of "order_musculoskeletal" */
export type Order_Musculoskeletal_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Order_Musculoskeletal_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Order_Musculoskeletal_Avg_Fields = {
  __typename?: 'order_musculoskeletal_avg_fields';
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "order_musculoskeletal". All fields are combined with a logical 'AND'. */
export type Order_Musculoskeletal_Bool_Exp = {
  _and?: InputMaybe<Array<Order_Musculoskeletal_Bool_Exp>>;
  _not?: InputMaybe<Order_Musculoskeletal_Bool_Exp>;
  _or?: InputMaybe<Array<Order_Musculoskeletal_Bool_Exp>>;
  back?: InputMaybe<String_Comparison_Exp>;
  back_notes?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  elbow_or_forearm?: InputMaybe<String_Comparison_Exp>;
  elbow_or_forearm_notes?: InputMaybe<String_Comparison_Exp>;
  foot?: InputMaybe<String_Comparison_Exp>;
  foot_notes?: InputMaybe<String_Comparison_Exp>;
  hip_or_thigh?: InputMaybe<String_Comparison_Exp>;
  hip_or_thigh_notes?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  is_deleted?: InputMaybe<Boolean_Comparison_Exp>;
  knee?: InputMaybe<String_Comparison_Exp>;
  knee_notes?: InputMaybe<String_Comparison_Exp>;
  leg_or_ankle?: InputMaybe<String_Comparison_Exp>;
  leg_or_ankle_notes?: InputMaybe<String_Comparison_Exp>;
  neck?: InputMaybe<String_Comparison_Exp>;
  neck_notes?: InputMaybe<String_Comparison_Exp>;
  order?: InputMaybe<Orders_Bool_Exp>;
  order_id?: InputMaybe<Int_Comparison_Exp>;
  shoulder_or_arm?: InputMaybe<String_Comparison_Exp>;
  shoulder_or_arm_notes?: InputMaybe<String_Comparison_Exp>;
  signature?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  wrist_or_hand?: InputMaybe<String_Comparison_Exp>;
  wrist_or_hand_notes?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "order_musculoskeletal" */
export enum Order_Musculoskeletal_Constraint {
  /** unique or primary key constraint */
  OrderMusculoskeletalOrderIdKey = 'order_musculoskeletal_order_id_key',
  /** unique or primary key constraint */
  OrderMusculoskeletalPkey = 'order_musculoskeletal_pkey'
}

/** input type for incrementing numeric columns in table "order_musculoskeletal" */
export type Order_Musculoskeletal_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  order_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "order_musculoskeletal" */
export type Order_Musculoskeletal_Insert_Input = {
  back?: InputMaybe<Scalars['String']>;
  back_notes?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  elbow_or_forearm?: InputMaybe<Scalars['String']>;
  elbow_or_forearm_notes?: InputMaybe<Scalars['String']>;
  foot?: InputMaybe<Scalars['String']>;
  foot_notes?: InputMaybe<Scalars['String']>;
  hip_or_thigh?: InputMaybe<Scalars['String']>;
  hip_or_thigh_notes?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  is_deleted?: InputMaybe<Scalars['Boolean']>;
  knee?: InputMaybe<Scalars['String']>;
  knee_notes?: InputMaybe<Scalars['String']>;
  leg_or_ankle?: InputMaybe<Scalars['String']>;
  leg_or_ankle_notes?: InputMaybe<Scalars['String']>;
  neck?: InputMaybe<Scalars['String']>;
  neck_notes?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Orders_Obj_Rel_Insert_Input>;
  order_id?: InputMaybe<Scalars['Int']>;
  shoulder_or_arm?: InputMaybe<Scalars['String']>;
  shoulder_or_arm_notes?: InputMaybe<Scalars['String']>;
  signature?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  wrist_or_hand?: InputMaybe<Scalars['String']>;
  wrist_or_hand_notes?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Order_Musculoskeletal_Max_Fields = {
  __typename?: 'order_musculoskeletal_max_fields';
  back?: Maybe<Scalars['String']>;
  back_notes?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  elbow_or_forearm?: Maybe<Scalars['String']>;
  elbow_or_forearm_notes?: Maybe<Scalars['String']>;
  foot?: Maybe<Scalars['String']>;
  foot_notes?: Maybe<Scalars['String']>;
  hip_or_thigh?: Maybe<Scalars['String']>;
  hip_or_thigh_notes?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  knee?: Maybe<Scalars['String']>;
  knee_notes?: Maybe<Scalars['String']>;
  leg_or_ankle?: Maybe<Scalars['String']>;
  leg_or_ankle_notes?: Maybe<Scalars['String']>;
  neck?: Maybe<Scalars['String']>;
  neck_notes?: Maybe<Scalars['String']>;
  order_id?: Maybe<Scalars['Int']>;
  shoulder_or_arm?: Maybe<Scalars['String']>;
  shoulder_or_arm_notes?: Maybe<Scalars['String']>;
  signature?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  wrist_or_hand?: Maybe<Scalars['String']>;
  wrist_or_hand_notes?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Order_Musculoskeletal_Min_Fields = {
  __typename?: 'order_musculoskeletal_min_fields';
  back?: Maybe<Scalars['String']>;
  back_notes?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  elbow_or_forearm?: Maybe<Scalars['String']>;
  elbow_or_forearm_notes?: Maybe<Scalars['String']>;
  foot?: Maybe<Scalars['String']>;
  foot_notes?: Maybe<Scalars['String']>;
  hip_or_thigh?: Maybe<Scalars['String']>;
  hip_or_thigh_notes?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  knee?: Maybe<Scalars['String']>;
  knee_notes?: Maybe<Scalars['String']>;
  leg_or_ankle?: Maybe<Scalars['String']>;
  leg_or_ankle_notes?: Maybe<Scalars['String']>;
  neck?: Maybe<Scalars['String']>;
  neck_notes?: Maybe<Scalars['String']>;
  order_id?: Maybe<Scalars['Int']>;
  shoulder_or_arm?: Maybe<Scalars['String']>;
  shoulder_or_arm_notes?: Maybe<Scalars['String']>;
  signature?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  wrist_or_hand?: Maybe<Scalars['String']>;
  wrist_or_hand_notes?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "order_musculoskeletal" */
export type Order_Musculoskeletal_Mutation_Response = {
  __typename?: 'order_musculoskeletal_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Order_Musculoskeletal>;
};

/** input type for inserting object relation for remote table "order_musculoskeletal" */
export type Order_Musculoskeletal_Obj_Rel_Insert_Input = {
  data: Order_Musculoskeletal_Insert_Input;
  /** on conflict condition */
  on_conflict?: InputMaybe<Order_Musculoskeletal_On_Conflict>;
};

/** on conflict condition type for table "order_musculoskeletal" */
export type Order_Musculoskeletal_On_Conflict = {
  constraint: Order_Musculoskeletal_Constraint;
  update_columns?: Array<Order_Musculoskeletal_Update_Column>;
  where?: InputMaybe<Order_Musculoskeletal_Bool_Exp>;
};

/** Ordering options when selecting data from "order_musculoskeletal". */
export type Order_Musculoskeletal_Order_By = {
  back?: InputMaybe<Order_By>;
  back_notes?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  elbow_or_forearm?: InputMaybe<Order_By>;
  elbow_or_forearm_notes?: InputMaybe<Order_By>;
  foot?: InputMaybe<Order_By>;
  foot_notes?: InputMaybe<Order_By>;
  hip_or_thigh?: InputMaybe<Order_By>;
  hip_or_thigh_notes?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_deleted?: InputMaybe<Order_By>;
  knee?: InputMaybe<Order_By>;
  knee_notes?: InputMaybe<Order_By>;
  leg_or_ankle?: InputMaybe<Order_By>;
  leg_or_ankle_notes?: InputMaybe<Order_By>;
  neck?: InputMaybe<Order_By>;
  neck_notes?: InputMaybe<Order_By>;
  order?: InputMaybe<Orders_Order_By>;
  order_id?: InputMaybe<Order_By>;
  shoulder_or_arm?: InputMaybe<Order_By>;
  shoulder_or_arm_notes?: InputMaybe<Order_By>;
  signature?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  wrist_or_hand?: InputMaybe<Order_By>;
  wrist_or_hand_notes?: InputMaybe<Order_By>;
};

/** primary key columns input for table: order_musculoskeletal */
export type Order_Musculoskeletal_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "order_musculoskeletal" */
export enum Order_Musculoskeletal_Select_Column {
  /** column name */
  Back = 'back',
  /** column name */
  BackNotes = 'back_notes',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ElbowOrForearm = 'elbow_or_forearm',
  /** column name */
  ElbowOrForearmNotes = 'elbow_or_forearm_notes',
  /** column name */
  Foot = 'foot',
  /** column name */
  FootNotes = 'foot_notes',
  /** column name */
  HipOrThigh = 'hip_or_thigh',
  /** column name */
  HipOrThighNotes = 'hip_or_thigh_notes',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  Knee = 'knee',
  /** column name */
  KneeNotes = 'knee_notes',
  /** column name */
  LegOrAnkle = 'leg_or_ankle',
  /** column name */
  LegOrAnkleNotes = 'leg_or_ankle_notes',
  /** column name */
  Neck = 'neck',
  /** column name */
  NeckNotes = 'neck_notes',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  ShoulderOrArm = 'shoulder_or_arm',
  /** column name */
  ShoulderOrArmNotes = 'shoulder_or_arm_notes',
  /** column name */
  Signature = 'signature',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WristOrHand = 'wrist_or_hand',
  /** column name */
  WristOrHandNotes = 'wrist_or_hand_notes'
}

/** input type for updating data in table "order_musculoskeletal" */
export type Order_Musculoskeletal_Set_Input = {
  back?: InputMaybe<Scalars['String']>;
  back_notes?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  elbow_or_forearm?: InputMaybe<Scalars['String']>;
  elbow_or_forearm_notes?: InputMaybe<Scalars['String']>;
  foot?: InputMaybe<Scalars['String']>;
  foot_notes?: InputMaybe<Scalars['String']>;
  hip_or_thigh?: InputMaybe<Scalars['String']>;
  hip_or_thigh_notes?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  is_deleted?: InputMaybe<Scalars['Boolean']>;
  knee?: InputMaybe<Scalars['String']>;
  knee_notes?: InputMaybe<Scalars['String']>;
  leg_or_ankle?: InputMaybe<Scalars['String']>;
  leg_or_ankle_notes?: InputMaybe<Scalars['String']>;
  neck?: InputMaybe<Scalars['String']>;
  neck_notes?: InputMaybe<Scalars['String']>;
  order_id?: InputMaybe<Scalars['Int']>;
  shoulder_or_arm?: InputMaybe<Scalars['String']>;
  shoulder_or_arm_notes?: InputMaybe<Scalars['String']>;
  signature?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  wrist_or_hand?: InputMaybe<Scalars['String']>;
  wrist_or_hand_notes?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Order_Musculoskeletal_Stddev_Fields = {
  __typename?: 'order_musculoskeletal_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Order_Musculoskeletal_Stddev_Pop_Fields = {
  __typename?: 'order_musculoskeletal_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Order_Musculoskeletal_Stddev_Samp_Fields = {
  __typename?: 'order_musculoskeletal_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Order_Musculoskeletal_Sum_Fields = {
  __typename?: 'order_musculoskeletal_sum_fields';
  id?: Maybe<Scalars['Int']>;
  order_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "order_musculoskeletal" */
export enum Order_Musculoskeletal_Update_Column {
  /** column name */
  Back = 'back',
  /** column name */
  BackNotes = 'back_notes',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ElbowOrForearm = 'elbow_or_forearm',
  /** column name */
  ElbowOrForearmNotes = 'elbow_or_forearm_notes',
  /** column name */
  Foot = 'foot',
  /** column name */
  FootNotes = 'foot_notes',
  /** column name */
  HipOrThigh = 'hip_or_thigh',
  /** column name */
  HipOrThighNotes = 'hip_or_thigh_notes',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  Knee = 'knee',
  /** column name */
  KneeNotes = 'knee_notes',
  /** column name */
  LegOrAnkle = 'leg_or_ankle',
  /** column name */
  LegOrAnkleNotes = 'leg_or_ankle_notes',
  /** column name */
  Neck = 'neck',
  /** column name */
  NeckNotes = 'neck_notes',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  ShoulderOrArm = 'shoulder_or_arm',
  /** column name */
  ShoulderOrArmNotes = 'shoulder_or_arm_notes',
  /** column name */
  Signature = 'signature',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WristOrHand = 'wrist_or_hand',
  /** column name */
  WristOrHandNotes = 'wrist_or_hand_notes'
}

/** aggregate var_pop on columns */
export type Order_Musculoskeletal_Var_Pop_Fields = {
  __typename?: 'order_musculoskeletal_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Order_Musculoskeletal_Var_Samp_Fields = {
  __typename?: 'order_musculoskeletal_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Order_Musculoskeletal_Variance_Fields = {
  __typename?: 'order_musculoskeletal_variance_fields';
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "order_type" */
export type Order_Type = {
  __typename?: 'order_type';
  category?: Maybe<Scalars['String']>;
  code: Scalars['String'];
  form_type?: Maybe<Form_Types>;
  form_type_code?: Maybe<Scalars['String']>;
  form_type_id?: Maybe<Scalars['String']>;
  is_deleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  /** An object relationship */
  pond_form_type?: Maybe<Pond_Form_Types>;
  position: Scalars['Int'];
  /** An array relationship */
  session_order_configs: Array<Session_Order_Config>;
  /** An aggregate relationship */
  session_order_configs_aggregate: Session_Order_Config_Aggregate;
};


/** columns and relationships of "order_type" */
export type Order_TypeSession_Order_ConfigsArgs = {
  distinct_on?: InputMaybe<Array<Session_Order_Config_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Session_Order_Config_Order_By>>;
  where?: InputMaybe<Session_Order_Config_Bool_Exp>;
};


/** columns and relationships of "order_type" */
export type Order_TypeSession_Order_Configs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Session_Order_Config_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Session_Order_Config_Order_By>>;
  where?: InputMaybe<Session_Order_Config_Bool_Exp>;
};

/** aggregated selection of "order_type" */
export type Order_Type_Aggregate = {
  __typename?: 'order_type_aggregate';
  aggregate?: Maybe<Order_Type_Aggregate_Fields>;
  nodes: Array<Order_Type>;
};

/** aggregate fields of "order_type" */
export type Order_Type_Aggregate_Fields = {
  __typename?: 'order_type_aggregate_fields';
  avg?: Maybe<Order_Type_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Order_Type_Max_Fields>;
  min?: Maybe<Order_Type_Min_Fields>;
  stddev?: Maybe<Order_Type_Stddev_Fields>;
  stddev_pop?: Maybe<Order_Type_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Order_Type_Stddev_Samp_Fields>;
  sum?: Maybe<Order_Type_Sum_Fields>;
  var_pop?: Maybe<Order_Type_Var_Pop_Fields>;
  var_samp?: Maybe<Order_Type_Var_Samp_Fields>;
  variance?: Maybe<Order_Type_Variance_Fields>;
};


/** aggregate fields of "order_type" */
export type Order_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Order_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "order_type" */
export type Order_Type_Aggregate_Order_By = {
  avg?: InputMaybe<Order_Type_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Order_Type_Max_Order_By>;
  min?: InputMaybe<Order_Type_Min_Order_By>;
  stddev?: InputMaybe<Order_Type_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Order_Type_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Order_Type_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Order_Type_Sum_Order_By>;
  var_pop?: InputMaybe<Order_Type_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Order_Type_Var_Samp_Order_By>;
  variance?: InputMaybe<Order_Type_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "order_type" */
export type Order_Type_Arr_Rel_Insert_Input = {
  data: Array<Order_Type_Insert_Input>;
  /** on conflict condition */
  on_conflict?: InputMaybe<Order_Type_On_Conflict>;
};

/** aggregate avg on columns */
export type Order_Type_Avg_Fields = {
  __typename?: 'order_type_avg_fields';
  position?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "order_type" */
export type Order_Type_Avg_Order_By = {
  position?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "order_type". All fields are combined with a logical 'AND'. */
export type Order_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Order_Type_Bool_Exp>>;
  _not?: InputMaybe<Order_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Order_Type_Bool_Exp>>;
  category?: InputMaybe<String_Comparison_Exp>;
  code?: InputMaybe<String_Comparison_Exp>;
  form_type_code?: InputMaybe<String_Comparison_Exp>;
  form_type_id?: InputMaybe<String_Comparison_Exp>;
  is_deleted?: InputMaybe<Boolean_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  pond_form_type?: InputMaybe<Pond_Form_Types_Bool_Exp>;
  position?: InputMaybe<Int_Comparison_Exp>;
  session_order_configs?: InputMaybe<Session_Order_Config_Bool_Exp>;
};

/** unique or primary key constraints on table "order_type" */
export enum Order_Type_Constraint {
  /** unique or primary key constraint */
  OrderTypePkey = 'order_type_pkey'
}

/** input type for incrementing numeric columns in table "order_type" */
export type Order_Type_Inc_Input = {
  position?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "order_type" */
export type Order_Type_Insert_Input = {
  category?: InputMaybe<Scalars['String']>;
  code?: InputMaybe<Scalars['String']>;
  form_type_code?: InputMaybe<Scalars['String']>;
  form_type_id?: InputMaybe<Scalars['String']>;
  is_deleted?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  pond_form_type?: InputMaybe<Pond_Form_Types_Obj_Rel_Insert_Input>;
  position?: InputMaybe<Scalars['Int']>;
  session_order_configs?: InputMaybe<Session_Order_Config_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Order_Type_Max_Fields = {
  __typename?: 'order_type_max_fields';
  category?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  form_type_code?: Maybe<Scalars['String']>;
  form_type_id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "order_type" */
export type Order_Type_Max_Order_By = {
  category?: InputMaybe<Order_By>;
  code?: InputMaybe<Order_By>;
  form_type_code?: InputMaybe<Order_By>;
  form_type_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  position?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Order_Type_Min_Fields = {
  __typename?: 'order_type_min_fields';
  category?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  form_type_code?: Maybe<Scalars['String']>;
  form_type_id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "order_type" */
export type Order_Type_Min_Order_By = {
  category?: InputMaybe<Order_By>;
  code?: InputMaybe<Order_By>;
  form_type_code?: InputMaybe<Order_By>;
  form_type_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  position?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "order_type" */
export type Order_Type_Mutation_Response = {
  __typename?: 'order_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Order_Type>;
};

/** input type for inserting object relation for remote table "order_type" */
export type Order_Type_Obj_Rel_Insert_Input = {
  data: Order_Type_Insert_Input;
  /** on conflict condition */
  on_conflict?: InputMaybe<Order_Type_On_Conflict>;
};

/** on conflict condition type for table "order_type" */
export type Order_Type_On_Conflict = {
  constraint: Order_Type_Constraint;
  update_columns?: Array<Order_Type_Update_Column>;
  where?: InputMaybe<Order_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "order_type". */
export type Order_Type_Order_By = {
  category?: InputMaybe<Order_By>;
  code?: InputMaybe<Order_By>;
  form_type_code?: InputMaybe<Order_By>;
  form_type_id?: InputMaybe<Order_By>;
  is_deleted?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  pond_form_type?: InputMaybe<Pond_Form_Types_Order_By>;
  position?: InputMaybe<Order_By>;
  session_order_configs_aggregate?: InputMaybe<Session_Order_Config_Aggregate_Order_By>;
};

/** primary key columns input for table: order_type */
export type Order_Type_Pk_Columns_Input = {
  code: Scalars['String'];
};

/** select columns of table "order_type" */
export enum Order_Type_Select_Column {
  /** column name */
  Category = 'category',
  /** column name */
  Code = 'code',
  /** column name */
  FormTypeCode = 'form_type_code',
  /** column name */
  FormTypeId = 'form_type_id',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  Name = 'name',
  /** column name */
  Position = 'position'
}

/** input type for updating data in table "order_type" */
export type Order_Type_Set_Input = {
  category?: InputMaybe<Scalars['String']>;
  code?: InputMaybe<Scalars['String']>;
  form_type_code?: InputMaybe<Scalars['String']>;
  form_type_id?: InputMaybe<Scalars['String']>;
  is_deleted?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  position?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Order_Type_Stddev_Fields = {
  __typename?: 'order_type_stddev_fields';
  position?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "order_type" */
export type Order_Type_Stddev_Order_By = {
  position?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Order_Type_Stddev_Pop_Fields = {
  __typename?: 'order_type_stddev_pop_fields';
  position?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "order_type" */
export type Order_Type_Stddev_Pop_Order_By = {
  position?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Order_Type_Stddev_Samp_Fields = {
  __typename?: 'order_type_stddev_samp_fields';
  position?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "order_type" */
export type Order_Type_Stddev_Samp_Order_By = {
  position?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Order_Type_Sum_Fields = {
  __typename?: 'order_type_sum_fields';
  position?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "order_type" */
export type Order_Type_Sum_Order_By = {
  position?: InputMaybe<Order_By>;
};

/** update columns of table "order_type" */
export enum Order_Type_Update_Column {
  /** column name */
  Category = 'category',
  /** column name */
  Code = 'code',
  /** column name */
  FormTypeCode = 'form_type_code',
  /** column name */
  FormTypeId = 'form_type_id',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  Name = 'name',
  /** column name */
  Position = 'position'
}

/** aggregate var_pop on columns */
export type Order_Type_Var_Pop_Fields = {
  __typename?: 'order_type_var_pop_fields';
  position?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "order_type" */
export type Order_Type_Var_Pop_Order_By = {
  position?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Order_Type_Var_Samp_Fields = {
  __typename?: 'order_type_var_samp_fields';
  position?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "order_type" */
export type Order_Type_Var_Samp_Order_By = {
  position?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Order_Type_Variance_Fields = {
  __typename?: 'order_type_variance_fields';
  position?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "order_type" */
export type Order_Type_Variance_Order_By = {
  position?: InputMaybe<Order_By>;
};

/**
 * order category
 *
 *
 * columns and relationships of "order_vision"
 *
 */
export type Order_Vision = {
  __typename?: 'order_vision';
  created_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['Int'];
  is_deleted?: Maybe<Scalars['Boolean']>;
  is_vision_corrected?: Maybe<Scalars['Boolean']>;
  /** An object relationship */
  order: Orders;
  order_id: Scalars['Int'];
  updated_at?: Maybe<Scalars['timestamptz']>;
  vision_left?: Maybe<Scalars['Int']>;
  vision_right?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "order_vision" */
export type Order_Vision_Aggregate = {
  __typename?: 'order_vision_aggregate';
  aggregate?: Maybe<Order_Vision_Aggregate_Fields>;
  nodes: Array<Order_Vision>;
};

/** aggregate fields of "order_vision" */
export type Order_Vision_Aggregate_Fields = {
  __typename?: 'order_vision_aggregate_fields';
  avg?: Maybe<Order_Vision_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Order_Vision_Max_Fields>;
  min?: Maybe<Order_Vision_Min_Fields>;
  stddev?: Maybe<Order_Vision_Stddev_Fields>;
  stddev_pop?: Maybe<Order_Vision_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Order_Vision_Stddev_Samp_Fields>;
  sum?: Maybe<Order_Vision_Sum_Fields>;
  var_pop?: Maybe<Order_Vision_Var_Pop_Fields>;
  var_samp?: Maybe<Order_Vision_Var_Samp_Fields>;
  variance?: Maybe<Order_Vision_Variance_Fields>;
};


/** aggregate fields of "order_vision" */
export type Order_Vision_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Order_Vision_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Order_Vision_Avg_Fields = {
  __typename?: 'order_vision_avg_fields';
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  vision_left?: Maybe<Scalars['Float']>;
  vision_right?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "order_vision". All fields are combined with a logical 'AND'. */
export type Order_Vision_Bool_Exp = {
  _and?: InputMaybe<Array<Order_Vision_Bool_Exp>>;
  _not?: InputMaybe<Order_Vision_Bool_Exp>;
  _or?: InputMaybe<Array<Order_Vision_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  is_deleted?: InputMaybe<Boolean_Comparison_Exp>;
  is_vision_corrected?: InputMaybe<Boolean_Comparison_Exp>;
  order?: InputMaybe<Orders_Bool_Exp>;
  order_id?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  vision_left?: InputMaybe<Int_Comparison_Exp>;
  vision_right?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "order_vision" */
export enum Order_Vision_Constraint {
  /** unique or primary key constraint */
  OrderVisionOrderIdKey = 'order_vision_order_id_key',
  /** unique or primary key constraint */
  OrderVisionPkey = 'order_vision_pkey'
}

/** input type for incrementing numeric columns in table "order_vision" */
export type Order_Vision_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  order_id?: InputMaybe<Scalars['Int']>;
  vision_left?: InputMaybe<Scalars['Int']>;
  vision_right?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "order_vision" */
export type Order_Vision_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  is_deleted?: InputMaybe<Scalars['Boolean']>;
  is_vision_corrected?: InputMaybe<Scalars['Boolean']>;
  order?: InputMaybe<Orders_Obj_Rel_Insert_Input>;
  order_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  vision_left?: InputMaybe<Scalars['Int']>;
  vision_right?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Order_Vision_Max_Fields = {
  __typename?: 'order_vision_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  order_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vision_left?: Maybe<Scalars['Int']>;
  vision_right?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Order_Vision_Min_Fields = {
  __typename?: 'order_vision_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  order_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vision_left?: Maybe<Scalars['Int']>;
  vision_right?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "order_vision" */
export type Order_Vision_Mutation_Response = {
  __typename?: 'order_vision_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Order_Vision>;
};

/** input type for inserting object relation for remote table "order_vision" */
export type Order_Vision_Obj_Rel_Insert_Input = {
  data: Order_Vision_Insert_Input;
  /** on conflict condition */
  on_conflict?: InputMaybe<Order_Vision_On_Conflict>;
};

/** on conflict condition type for table "order_vision" */
export type Order_Vision_On_Conflict = {
  constraint: Order_Vision_Constraint;
  update_columns?: Array<Order_Vision_Update_Column>;
  where?: InputMaybe<Order_Vision_Bool_Exp>;
};

/** Ordering options when selecting data from "order_vision". */
export type Order_Vision_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_deleted?: InputMaybe<Order_By>;
  is_vision_corrected?: InputMaybe<Order_By>;
  order?: InputMaybe<Orders_Order_By>;
  order_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vision_left?: InputMaybe<Order_By>;
  vision_right?: InputMaybe<Order_By>;
};

/** primary key columns input for table: order_vision */
export type Order_Vision_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "order_vision" */
export enum Order_Vision_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  IsVisionCorrected = 'is_vision_corrected',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VisionLeft = 'vision_left',
  /** column name */
  VisionRight = 'vision_right'
}

/** input type for updating data in table "order_vision" */
export type Order_Vision_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  is_deleted?: InputMaybe<Scalars['Boolean']>;
  is_vision_corrected?: InputMaybe<Scalars['Boolean']>;
  order_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  vision_left?: InputMaybe<Scalars['Int']>;
  vision_right?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Order_Vision_Stddev_Fields = {
  __typename?: 'order_vision_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  vision_left?: Maybe<Scalars['Float']>;
  vision_right?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Order_Vision_Stddev_Pop_Fields = {
  __typename?: 'order_vision_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  vision_left?: Maybe<Scalars['Float']>;
  vision_right?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Order_Vision_Stddev_Samp_Fields = {
  __typename?: 'order_vision_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  vision_left?: Maybe<Scalars['Float']>;
  vision_right?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Order_Vision_Sum_Fields = {
  __typename?: 'order_vision_sum_fields';
  id?: Maybe<Scalars['Int']>;
  order_id?: Maybe<Scalars['Int']>;
  vision_left?: Maybe<Scalars['Int']>;
  vision_right?: Maybe<Scalars['Int']>;
};

/** update columns of table "order_vision" */
export enum Order_Vision_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  IsVisionCorrected = 'is_vision_corrected',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VisionLeft = 'vision_left',
  /** column name */
  VisionRight = 'vision_right'
}

/** aggregate var_pop on columns */
export type Order_Vision_Var_Pop_Fields = {
  __typename?: 'order_vision_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  vision_left?: Maybe<Scalars['Float']>;
  vision_right?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Order_Vision_Var_Samp_Fields = {
  __typename?: 'order_vision_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  vision_left?: Maybe<Scalars['Float']>;
  vision_right?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Order_Vision_Variance_Fields = {
  __typename?: 'order_vision_variance_fields';
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  vision_left?: Maybe<Scalars['Float']>;
  vision_right?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "orders" */
export type Orders = {
  __typename?: 'orders';
  completed_at?: Maybe<Scalars['timestamptz']>;
  created_at: Scalars['timestamptz'];
  facilitator_id?: Maybe<Scalars['Int']>;
  follow_up_instructions?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  is_deleted?: Maybe<Scalars['Boolean']>;
  is_flagged: Scalars['Boolean'];
  /** An object relationship */
  order_dshs_covid_test?: Maybe<Order_Dshs_Covid_Test>;
  /** An object relationship */
  order_height_and_weight?: Maybe<Order_Height_And_Weight>;
  /** An object relationship */
  order_medical_assessment?: Maybe<Order_Medical_Assessment>;
  /** An object relationship */
  order_musculoskeletal?: Maybe<Order_Musculoskeletal>;
  /** An object relationship */
  order_type?: Maybe<Order_Type>;
  order_type_code?: Maybe<Scalars['String']>;
  /** An object relationship */
  order_vision?: Maybe<Order_Vision>;
  provider_id?: Maybe<Scalars['Int']>;
  /** An object relationship */
  station_order?: Maybe<Station_Orders>;
  status: Scalars['String'];
  unread: Scalars['Boolean'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  visit: Visits;
  visit_id: Scalars['Int'];
};

/** aggregated selection of "orders" */
export type Orders_Aggregate = {
  __typename?: 'orders_aggregate';
  aggregate?: Maybe<Orders_Aggregate_Fields>;
  nodes: Array<Orders>;
};

/** aggregate fields of "orders" */
export type Orders_Aggregate_Fields = {
  __typename?: 'orders_aggregate_fields';
  avg?: Maybe<Orders_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Orders_Max_Fields>;
  min?: Maybe<Orders_Min_Fields>;
  stddev?: Maybe<Orders_Stddev_Fields>;
  stddev_pop?: Maybe<Orders_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Orders_Stddev_Samp_Fields>;
  sum?: Maybe<Orders_Sum_Fields>;
  var_pop?: Maybe<Orders_Var_Pop_Fields>;
  var_samp?: Maybe<Orders_Var_Samp_Fields>;
  variance?: Maybe<Orders_Variance_Fields>;
};


/** aggregate fields of "orders" */
export type Orders_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Orders_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "orders" */
export type Orders_Aggregate_Order_By = {
  avg?: InputMaybe<Orders_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Orders_Max_Order_By>;
  min?: InputMaybe<Orders_Min_Order_By>;
  stddev?: InputMaybe<Orders_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Orders_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Orders_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Orders_Sum_Order_By>;
  var_pop?: InputMaybe<Orders_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Orders_Var_Samp_Order_By>;
  variance?: InputMaybe<Orders_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "orders" */
export type Orders_Arr_Rel_Insert_Input = {
  data: Array<Orders_Insert_Input>;
  /** on conflict condition */
  on_conflict?: InputMaybe<Orders_On_Conflict>;
};

/** aggregate avg on columns */
export type Orders_Avg_Fields = {
  __typename?: 'orders_avg_fields';
  facilitator_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
  visit_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "orders" */
export type Orders_Avg_Order_By = {
  facilitator_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  provider_id?: InputMaybe<Order_By>;
  visit_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "orders". All fields are combined with a logical 'AND'. */
export type Orders_Bool_Exp = {
  _and?: InputMaybe<Array<Orders_Bool_Exp>>;
  _not?: InputMaybe<Orders_Bool_Exp>;
  _or?: InputMaybe<Array<Orders_Bool_Exp>>;
  completed_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  facilitator_id?: InputMaybe<Int_Comparison_Exp>;
  follow_up_instructions?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  is_deleted?: InputMaybe<Boolean_Comparison_Exp>;
  is_flagged?: InputMaybe<Boolean_Comparison_Exp>;
  order_dshs_covid_test?: InputMaybe<Order_Dshs_Covid_Test_Bool_Exp>;
  order_height_and_weight?: InputMaybe<Order_Height_And_Weight_Bool_Exp>;
  order_medical_assessment?: InputMaybe<Order_Medical_Assessment_Bool_Exp>;
  order_musculoskeletal?: InputMaybe<Order_Musculoskeletal_Bool_Exp>;
  order_type?: InputMaybe<Order_Type_Bool_Exp>;
  order_type_code?: InputMaybe<String_Comparison_Exp>;
  order_vision?: InputMaybe<Order_Vision_Bool_Exp>;
  provider_id?: InputMaybe<Int_Comparison_Exp>;
  station_order?: InputMaybe<Station_Orders_Bool_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  unread?: InputMaybe<Boolean_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  visit?: InputMaybe<Visits_Bool_Exp>;
  visit_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "orders" */
export enum Orders_Constraint {
  /** unique or primary key constraint */
  OrdersPkey = 'orders_pkey'
}

/** input type for incrementing numeric columns in table "orders" */
export type Orders_Inc_Input = {
  facilitator_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  provider_id?: InputMaybe<Scalars['Int']>;
  visit_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "orders" */
export type Orders_Insert_Input = {
  completed_at?: InputMaybe<Scalars['timestamptz']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  facilitator_id?: InputMaybe<Scalars['Int']>;
  follow_up_instructions?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  is_deleted?: InputMaybe<Scalars['Boolean']>;
  is_flagged?: InputMaybe<Scalars['Boolean']>;
  order_dshs_covid_test?: InputMaybe<Order_Dshs_Covid_Test_Obj_Rel_Insert_Input>;
  order_height_and_weight?: InputMaybe<Order_Height_And_Weight_Obj_Rel_Insert_Input>;
  order_medical_assessment?: InputMaybe<Order_Medical_Assessment_Obj_Rel_Insert_Input>;
  order_musculoskeletal?: InputMaybe<Order_Musculoskeletal_Obj_Rel_Insert_Input>;
  order_type?: InputMaybe<Order_Type_Obj_Rel_Insert_Input>;
  order_type_code?: InputMaybe<Scalars['String']>;
  order_vision?: InputMaybe<Order_Vision_Obj_Rel_Insert_Input>;
  provider_id?: InputMaybe<Scalars['Int']>;
  station_order?: InputMaybe<Station_Orders_Obj_Rel_Insert_Input>;
  status?: InputMaybe<Scalars['String']>;
  unread?: InputMaybe<Scalars['Boolean']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  visit?: InputMaybe<Visits_Obj_Rel_Insert_Input>;
  visit_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Orders_Max_Fields = {
  __typename?: 'orders_max_fields';
  completed_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  facilitator_id?: Maybe<Scalars['Int']>;
  follow_up_instructions?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  order_type_code?: Maybe<Scalars['String']>;
  provider_id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  visit_id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "orders" */
export type Orders_Max_Order_By = {
  completed_at?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  facilitator_id?: InputMaybe<Order_By>;
  follow_up_instructions?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order_type_code?: InputMaybe<Order_By>;
  provider_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  visit_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Orders_Min_Fields = {
  __typename?: 'orders_min_fields';
  completed_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  facilitator_id?: Maybe<Scalars['Int']>;
  follow_up_instructions?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  order_type_code?: Maybe<Scalars['String']>;
  provider_id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  visit_id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "orders" */
export type Orders_Min_Order_By = {
  completed_at?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  facilitator_id?: InputMaybe<Order_By>;
  follow_up_instructions?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order_type_code?: InputMaybe<Order_By>;
  provider_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  visit_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "orders" */
export type Orders_Mutation_Response = {
  __typename?: 'orders_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Orders>;
};

/** input type for inserting object relation for remote table "orders" */
export type Orders_Obj_Rel_Insert_Input = {
  data: Orders_Insert_Input;
  /** on conflict condition */
  on_conflict?: InputMaybe<Orders_On_Conflict>;
};

/** on conflict condition type for table "orders" */
export type Orders_On_Conflict = {
  constraint: Orders_Constraint;
  update_columns?: Array<Orders_Update_Column>;
  where?: InputMaybe<Orders_Bool_Exp>;
};

/** Ordering options when selecting data from "orders". */
export type Orders_Order_By = {
  completed_at?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  facilitator_id?: InputMaybe<Order_By>;
  follow_up_instructions?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_deleted?: InputMaybe<Order_By>;
  is_flagged?: InputMaybe<Order_By>;
  order_dshs_covid_test?: InputMaybe<Order_Dshs_Covid_Test_Order_By>;
  order_height_and_weight?: InputMaybe<Order_Height_And_Weight_Order_By>;
  order_medical_assessment?: InputMaybe<Order_Medical_Assessment_Order_By>;
  order_musculoskeletal?: InputMaybe<Order_Musculoskeletal_Order_By>;
  order_type?: InputMaybe<Order_Type_Order_By>;
  order_type_code?: InputMaybe<Order_By>;
  order_vision?: InputMaybe<Order_Vision_Order_By>;
  provider_id?: InputMaybe<Order_By>;
  station_order?: InputMaybe<Station_Orders_Order_By>;
  status?: InputMaybe<Order_By>;
  unread?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  visit?: InputMaybe<Visits_Order_By>;
  visit_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: orders */
export type Orders_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "orders" */
export enum Orders_Select_Column {
  /** column name */
  CompletedAt = 'completed_at',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FacilitatorId = 'facilitator_id',
  /** column name */
  FollowUpInstructions = 'follow_up_instructions',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  IsFlagged = 'is_flagged',
  /** column name */
  OrderTypeCode = 'order_type_code',
  /** column name */
  ProviderId = 'provider_id',
  /** column name */
  Status = 'status',
  /** column name */
  Unread = 'unread',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VisitId = 'visit_id'
}

/** input type for updating data in table "orders" */
export type Orders_Set_Input = {
  completed_at?: InputMaybe<Scalars['timestamptz']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  facilitator_id?: InputMaybe<Scalars['Int']>;
  follow_up_instructions?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  is_deleted?: InputMaybe<Scalars['Boolean']>;
  is_flagged?: InputMaybe<Scalars['Boolean']>;
  order_type_code?: InputMaybe<Scalars['String']>;
  provider_id?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
  unread?: InputMaybe<Scalars['Boolean']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  visit_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Orders_Stddev_Fields = {
  __typename?: 'orders_stddev_fields';
  facilitator_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
  visit_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "orders" */
export type Orders_Stddev_Order_By = {
  facilitator_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  provider_id?: InputMaybe<Order_By>;
  visit_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Orders_Stddev_Pop_Fields = {
  __typename?: 'orders_stddev_pop_fields';
  facilitator_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
  visit_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "orders" */
export type Orders_Stddev_Pop_Order_By = {
  facilitator_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  provider_id?: InputMaybe<Order_By>;
  visit_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Orders_Stddev_Samp_Fields = {
  __typename?: 'orders_stddev_samp_fields';
  facilitator_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
  visit_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "orders" */
export type Orders_Stddev_Samp_Order_By = {
  facilitator_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  provider_id?: InputMaybe<Order_By>;
  visit_id?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Orders_Sum_Fields = {
  __typename?: 'orders_sum_fields';
  facilitator_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  provider_id?: Maybe<Scalars['Int']>;
  visit_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "orders" */
export type Orders_Sum_Order_By = {
  facilitator_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  provider_id?: InputMaybe<Order_By>;
  visit_id?: InputMaybe<Order_By>;
};

/** update columns of table "orders" */
export enum Orders_Update_Column {
  /** column name */
  CompletedAt = 'completed_at',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FacilitatorId = 'facilitator_id',
  /** column name */
  FollowUpInstructions = 'follow_up_instructions',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  IsFlagged = 'is_flagged',
  /** column name */
  OrderTypeCode = 'order_type_code',
  /** column name */
  ProviderId = 'provider_id',
  /** column name */
  Status = 'status',
  /** column name */
  Unread = 'unread',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VisitId = 'visit_id'
}

/** aggregate var_pop on columns */
export type Orders_Var_Pop_Fields = {
  __typename?: 'orders_var_pop_fields';
  facilitator_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
  visit_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "orders" */
export type Orders_Var_Pop_Order_By = {
  facilitator_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  provider_id?: InputMaybe<Order_By>;
  visit_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Orders_Var_Samp_Fields = {
  __typename?: 'orders_var_samp_fields';
  facilitator_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
  visit_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "orders" */
export type Orders_Var_Samp_Order_By = {
  facilitator_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  provider_id?: InputMaybe<Order_By>;
  visit_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Orders_Variance_Fields = {
  __typename?: 'orders_variance_fields';
  facilitator_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
  visit_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "orders" */
export type Orders_Variance_Order_By = {
  facilitator_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  provider_id?: InputMaybe<Order_By>;
  visit_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "organizations.district_days_off" */
export type Organizations_District_Days_Off = {
  __typename?: 'organizations_district_days_off';
  created_ts?: Maybe<Scalars['timestamptz']>;
  days_off_id: Scalars['bigint'];
  days_off_nm?: Maybe<Scalars['String']>;
  from_dt: Scalars['date'];
  organization_id: Scalars['Int'];
  schoolyear_id: Scalars['Int'];
  to_dt: Scalars['date'];
  updated_ts?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "organizations.district_days_off" */
export type Organizations_District_Days_Off_Aggregate = {
  __typename?: 'organizations_district_days_off_aggregate';
  aggregate?: Maybe<Organizations_District_Days_Off_Aggregate_Fields>;
  nodes: Array<Organizations_District_Days_Off>;
};

/** aggregate fields of "organizations.district_days_off" */
export type Organizations_District_Days_Off_Aggregate_Fields = {
  __typename?: 'organizations_district_days_off_aggregate_fields';
  avg?: Maybe<Organizations_District_Days_Off_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Organizations_District_Days_Off_Max_Fields>;
  min?: Maybe<Organizations_District_Days_Off_Min_Fields>;
  stddev?: Maybe<Organizations_District_Days_Off_Stddev_Fields>;
  stddev_pop?: Maybe<Organizations_District_Days_Off_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Organizations_District_Days_Off_Stddev_Samp_Fields>;
  sum?: Maybe<Organizations_District_Days_Off_Sum_Fields>;
  var_pop?: Maybe<Organizations_District_Days_Off_Var_Pop_Fields>;
  var_samp?: Maybe<Organizations_District_Days_Off_Var_Samp_Fields>;
  variance?: Maybe<Organizations_District_Days_Off_Variance_Fields>;
};


/** aggregate fields of "organizations.district_days_off" */
export type Organizations_District_Days_Off_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Organizations_District_Days_Off_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Organizations_District_Days_Off_Avg_Fields = {
  __typename?: 'organizations_district_days_off_avg_fields';
  days_off_id?: Maybe<Scalars['Float']>;
  organization_id?: Maybe<Scalars['Float']>;
  schoolyear_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "organizations.district_days_off". All fields are combined with a logical 'AND'. */
export type Organizations_District_Days_Off_Bool_Exp = {
  _and?: InputMaybe<Array<Organizations_District_Days_Off_Bool_Exp>>;
  _not?: InputMaybe<Organizations_District_Days_Off_Bool_Exp>;
  _or?: InputMaybe<Array<Organizations_District_Days_Off_Bool_Exp>>;
  created_ts?: InputMaybe<Timestamptz_Comparison_Exp>;
  days_off_id?: InputMaybe<Bigint_Comparison_Exp>;
  days_off_nm?: InputMaybe<String_Comparison_Exp>;
  from_dt?: InputMaybe<Date_Comparison_Exp>;
  organization_id?: InputMaybe<Int_Comparison_Exp>;
  schoolyear_id?: InputMaybe<Int_Comparison_Exp>;
  to_dt?: InputMaybe<Date_Comparison_Exp>;
  updated_ts?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "organizations.district_days_off" */
export enum Organizations_District_Days_Off_Constraint {
  /** unique or primary key constraint */
  PkDistrictDaysOff = 'pk_district_days_off'
}

/** input type for incrementing numeric columns in table "organizations.district_days_off" */
export type Organizations_District_Days_Off_Inc_Input = {
  days_off_id?: InputMaybe<Scalars['bigint']>;
  organization_id?: InputMaybe<Scalars['Int']>;
  schoolyear_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "organizations.district_days_off" */
export type Organizations_District_Days_Off_Insert_Input = {
  created_ts?: InputMaybe<Scalars['timestamptz']>;
  days_off_id?: InputMaybe<Scalars['bigint']>;
  days_off_nm?: InputMaybe<Scalars['String']>;
  from_dt?: InputMaybe<Scalars['date']>;
  organization_id?: InputMaybe<Scalars['Int']>;
  schoolyear_id?: InputMaybe<Scalars['Int']>;
  to_dt?: InputMaybe<Scalars['date']>;
  updated_ts?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Organizations_District_Days_Off_Max_Fields = {
  __typename?: 'organizations_district_days_off_max_fields';
  created_ts?: Maybe<Scalars['timestamptz']>;
  days_off_id?: Maybe<Scalars['bigint']>;
  days_off_nm?: Maybe<Scalars['String']>;
  from_dt?: Maybe<Scalars['date']>;
  organization_id?: Maybe<Scalars['Int']>;
  schoolyear_id?: Maybe<Scalars['Int']>;
  to_dt?: Maybe<Scalars['date']>;
  updated_ts?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Organizations_District_Days_Off_Min_Fields = {
  __typename?: 'organizations_district_days_off_min_fields';
  created_ts?: Maybe<Scalars['timestamptz']>;
  days_off_id?: Maybe<Scalars['bigint']>;
  days_off_nm?: Maybe<Scalars['String']>;
  from_dt?: Maybe<Scalars['date']>;
  organization_id?: Maybe<Scalars['Int']>;
  schoolyear_id?: Maybe<Scalars['Int']>;
  to_dt?: Maybe<Scalars['date']>;
  updated_ts?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "organizations.district_days_off" */
export type Organizations_District_Days_Off_Mutation_Response = {
  __typename?: 'organizations_district_days_off_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Organizations_District_Days_Off>;
};

/** on conflict condition type for table "organizations.district_days_off" */
export type Organizations_District_Days_Off_On_Conflict = {
  constraint: Organizations_District_Days_Off_Constraint;
  update_columns?: Array<Organizations_District_Days_Off_Update_Column>;
  where?: InputMaybe<Organizations_District_Days_Off_Bool_Exp>;
};

/** Ordering options when selecting data from "organizations.district_days_off". */
export type Organizations_District_Days_Off_Order_By = {
  created_ts?: InputMaybe<Order_By>;
  days_off_id?: InputMaybe<Order_By>;
  days_off_nm?: InputMaybe<Order_By>;
  from_dt?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  schoolyear_id?: InputMaybe<Order_By>;
  to_dt?: InputMaybe<Order_By>;
  updated_ts?: InputMaybe<Order_By>;
};

/** primary key columns input for table: organizations_district_days_off */
export type Organizations_District_Days_Off_Pk_Columns_Input = {
  days_off_id: Scalars['bigint'];
};

/** select columns of table "organizations.district_days_off" */
export enum Organizations_District_Days_Off_Select_Column {
  /** column name */
  CreatedTs = 'created_ts',
  /** column name */
  DaysOffId = 'days_off_id',
  /** column name */
  DaysOffNm = 'days_off_nm',
  /** column name */
  FromDt = 'from_dt',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  SchoolyearId = 'schoolyear_id',
  /** column name */
  ToDt = 'to_dt',
  /** column name */
  UpdatedTs = 'updated_ts'
}

/** input type for updating data in table "organizations.district_days_off" */
export type Organizations_District_Days_Off_Set_Input = {
  created_ts?: InputMaybe<Scalars['timestamptz']>;
  days_off_id?: InputMaybe<Scalars['bigint']>;
  days_off_nm?: InputMaybe<Scalars['String']>;
  from_dt?: InputMaybe<Scalars['date']>;
  organization_id?: InputMaybe<Scalars['Int']>;
  schoolyear_id?: InputMaybe<Scalars['Int']>;
  to_dt?: InputMaybe<Scalars['date']>;
  updated_ts?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Organizations_District_Days_Off_Stddev_Fields = {
  __typename?: 'organizations_district_days_off_stddev_fields';
  days_off_id?: Maybe<Scalars['Float']>;
  organization_id?: Maybe<Scalars['Float']>;
  schoolyear_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Organizations_District_Days_Off_Stddev_Pop_Fields = {
  __typename?: 'organizations_district_days_off_stddev_pop_fields';
  days_off_id?: Maybe<Scalars['Float']>;
  organization_id?: Maybe<Scalars['Float']>;
  schoolyear_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Organizations_District_Days_Off_Stddev_Samp_Fields = {
  __typename?: 'organizations_district_days_off_stddev_samp_fields';
  days_off_id?: Maybe<Scalars['Float']>;
  organization_id?: Maybe<Scalars['Float']>;
  schoolyear_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Organizations_District_Days_Off_Sum_Fields = {
  __typename?: 'organizations_district_days_off_sum_fields';
  days_off_id?: Maybe<Scalars['bigint']>;
  organization_id?: Maybe<Scalars['Int']>;
  schoolyear_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "organizations.district_days_off" */
export enum Organizations_District_Days_Off_Update_Column {
  /** column name */
  CreatedTs = 'created_ts',
  /** column name */
  DaysOffId = 'days_off_id',
  /** column name */
  DaysOffNm = 'days_off_nm',
  /** column name */
  FromDt = 'from_dt',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  SchoolyearId = 'schoolyear_id',
  /** column name */
  ToDt = 'to_dt',
  /** column name */
  UpdatedTs = 'updated_ts'
}

/** aggregate var_pop on columns */
export type Organizations_District_Days_Off_Var_Pop_Fields = {
  __typename?: 'organizations_district_days_off_var_pop_fields';
  days_off_id?: Maybe<Scalars['Float']>;
  organization_id?: Maybe<Scalars['Float']>;
  schoolyear_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Organizations_District_Days_Off_Var_Samp_Fields = {
  __typename?: 'organizations_district_days_off_var_samp_fields';
  days_off_id?: Maybe<Scalars['Float']>;
  organization_id?: Maybe<Scalars['Float']>;
  schoolyear_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Organizations_District_Days_Off_Variance_Fields = {
  __typename?: 'organizations_district_days_off_variance_fields';
  days_off_id?: Maybe<Scalars['Float']>;
  organization_id?: Maybe<Scalars['Float']>;
  schoolyear_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "organizations.features" */
export type Organizations_Features = {
  __typename?: 'organizations_features';
  /** An array relationship */
  child_features: Array<Organizations_Features>;
  /** An aggregate relationship */
  child_features_aggregate: Organizations_Features_Aggregate;
  configuration?: Maybe<Scalars['jsonb']>;
  id: Scalars['String'];
  /** An object relationship */
  parent_feature?: Maybe<Organizations_Features>;
  parent_id?: Maybe<Scalars['String']>;
};


/** columns and relationships of "organizations.features" */
export type Organizations_FeaturesChild_FeaturesArgs = {
  distinct_on?: InputMaybe<Array<Organizations_Features_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organizations_Features_Order_By>>;
  where?: InputMaybe<Organizations_Features_Bool_Exp>;
};


/** columns and relationships of "organizations.features" */
export type Organizations_FeaturesChild_Features_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organizations_Features_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organizations_Features_Order_By>>;
  where?: InputMaybe<Organizations_Features_Bool_Exp>;
};


/** columns and relationships of "organizations.features" */
export type Organizations_FeaturesConfigurationArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "organizations.features" */
export type Organizations_Features_Aggregate = {
  __typename?: 'organizations_features_aggregate';
  aggregate?: Maybe<Organizations_Features_Aggregate_Fields>;
  nodes: Array<Organizations_Features>;
};

/** aggregate fields of "organizations.features" */
export type Organizations_Features_Aggregate_Fields = {
  __typename?: 'organizations_features_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Organizations_Features_Max_Fields>;
  min?: Maybe<Organizations_Features_Min_Fields>;
};


/** aggregate fields of "organizations.features" */
export type Organizations_Features_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Organizations_Features_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "organizations.features" */
export type Organizations_Features_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Organizations_Features_Max_Order_By>;
  min?: InputMaybe<Organizations_Features_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Organizations_Features_Append_Input = {
  configuration?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "organizations.features" */
export type Organizations_Features_Arr_Rel_Insert_Input = {
  data: Array<Organizations_Features_Insert_Input>;
  /** on conflict condition */
  on_conflict?: InputMaybe<Organizations_Features_On_Conflict>;
};

/** Boolean expression to filter rows from the table "organizations.features". All fields are combined with a logical 'AND'. */
export type Organizations_Features_Bool_Exp = {
  _and?: InputMaybe<Array<Organizations_Features_Bool_Exp>>;
  _not?: InputMaybe<Organizations_Features_Bool_Exp>;
  _or?: InputMaybe<Array<Organizations_Features_Bool_Exp>>;
  child_features?: InputMaybe<Organizations_Features_Bool_Exp>;
  configuration?: InputMaybe<Jsonb_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  parent_feature?: InputMaybe<Organizations_Features_Bool_Exp>;
  parent_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "organizations.features" */
export enum Organizations_Features_Constraint {
  /** unique or primary key constraint */
  FeaturesPkey = 'features_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Organizations_Features_Delete_At_Path_Input = {
  configuration?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Organizations_Features_Delete_Elem_Input = {
  configuration?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Organizations_Features_Delete_Key_Input = {
  configuration?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "organizations.features" */
export type Organizations_Features_Insert_Input = {
  child_features?: InputMaybe<Organizations_Features_Arr_Rel_Insert_Input>;
  configuration?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['String']>;
  parent_feature?: InputMaybe<Organizations_Features_Obj_Rel_Insert_Input>;
  parent_id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Organizations_Features_Max_Fields = {
  __typename?: 'organizations_features_max_fields';
  id?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "organizations.features" */
export type Organizations_Features_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Organizations_Features_Min_Fields = {
  __typename?: 'organizations_features_min_fields';
  id?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "organizations.features" */
export type Organizations_Features_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "organizations.features" */
export type Organizations_Features_Mutation_Response = {
  __typename?: 'organizations_features_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Organizations_Features>;
};

/** input type for inserting object relation for remote table "organizations.features" */
export type Organizations_Features_Obj_Rel_Insert_Input = {
  data: Organizations_Features_Insert_Input;
  /** on conflict condition */
  on_conflict?: InputMaybe<Organizations_Features_On_Conflict>;
};

/** on conflict condition type for table "organizations.features" */
export type Organizations_Features_On_Conflict = {
  constraint: Organizations_Features_Constraint;
  update_columns?: Array<Organizations_Features_Update_Column>;
  where?: InputMaybe<Organizations_Features_Bool_Exp>;
};

/** Ordering options when selecting data from "organizations.features". */
export type Organizations_Features_Order_By = {
  child_features_aggregate?: InputMaybe<Organizations_Features_Aggregate_Order_By>;
  configuration?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  parent_feature?: InputMaybe<Organizations_Features_Order_By>;
  parent_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: organizations_features */
export type Organizations_Features_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Organizations_Features_Prepend_Input = {
  configuration?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "organizations.features" */
export enum Organizations_Features_Select_Column {
  /** column name */
  Configuration = 'configuration',
  /** column name */
  Id = 'id',
  /** column name */
  ParentId = 'parent_id'
}

/** input type for updating data in table "organizations.features" */
export type Organizations_Features_Set_Input = {
  configuration?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['String']>;
  parent_id?: InputMaybe<Scalars['String']>;
};

/** update columns of table "organizations.features" */
export enum Organizations_Features_Update_Column {
  /** column name */
  Configuration = 'configuration',
  /** column name */
  Id = 'id',
  /** column name */
  ParentId = 'parent_id'
}

/** columns and relationships of "organizations.locations" */
export type Organizations_Locations = {
  __typename?: 'organizations_locations';
  alt_location_id?: Maybe<Scalars['String']>;
  dt_patient_available?: Maybe<Scalars['date']>;
  id: Scalars['Int'];
  is_deleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  /** An object relationship */
  organization: Organizations_Organizations;
  organization_id: Scalars['Int'];
};

/** aggregated selection of "organizations.locations" */
export type Organizations_Locations_Aggregate = {
  __typename?: 'organizations_locations_aggregate';
  aggregate?: Maybe<Organizations_Locations_Aggregate_Fields>;
  nodes: Array<Organizations_Locations>;
};

/** aggregate fields of "organizations.locations" */
export type Organizations_Locations_Aggregate_Fields = {
  __typename?: 'organizations_locations_aggregate_fields';
  avg?: Maybe<Organizations_Locations_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Organizations_Locations_Max_Fields>;
  min?: Maybe<Organizations_Locations_Min_Fields>;
  stddev?: Maybe<Organizations_Locations_Stddev_Fields>;
  stddev_pop?: Maybe<Organizations_Locations_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Organizations_Locations_Stddev_Samp_Fields>;
  sum?: Maybe<Organizations_Locations_Sum_Fields>;
  var_pop?: Maybe<Organizations_Locations_Var_Pop_Fields>;
  var_samp?: Maybe<Organizations_Locations_Var_Samp_Fields>;
  variance?: Maybe<Organizations_Locations_Variance_Fields>;
};


/** aggregate fields of "organizations.locations" */
export type Organizations_Locations_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Organizations_Locations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "organizations.locations" */
export type Organizations_Locations_Aggregate_Order_By = {
  avg?: InputMaybe<Organizations_Locations_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Organizations_Locations_Max_Order_By>;
  min?: InputMaybe<Organizations_Locations_Min_Order_By>;
  stddev?: InputMaybe<Organizations_Locations_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Organizations_Locations_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Organizations_Locations_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Organizations_Locations_Sum_Order_By>;
  var_pop?: InputMaybe<Organizations_Locations_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Organizations_Locations_Var_Samp_Order_By>;
  variance?: InputMaybe<Organizations_Locations_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "organizations.locations" */
export type Organizations_Locations_Arr_Rel_Insert_Input = {
  data: Array<Organizations_Locations_Insert_Input>;
  /** on conflict condition */
  on_conflict?: InputMaybe<Organizations_Locations_On_Conflict>;
};

/** aggregate avg on columns */
export type Organizations_Locations_Avg_Fields = {
  __typename?: 'organizations_locations_avg_fields';
  id?: Maybe<Scalars['Float']>;
  organization_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "organizations.locations" */
export type Organizations_Locations_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "organizations.locations". All fields are combined with a logical 'AND'. */
export type Organizations_Locations_Bool_Exp = {
  _and?: InputMaybe<Array<Organizations_Locations_Bool_Exp>>;
  _not?: InputMaybe<Organizations_Locations_Bool_Exp>;
  _or?: InputMaybe<Array<Organizations_Locations_Bool_Exp>>;
  alt_location_id?: InputMaybe<String_Comparison_Exp>;
  dt_patient_available?: InputMaybe<Date_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  is_deleted?: InputMaybe<Boolean_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  organization?: InputMaybe<Organizations_Organizations_Bool_Exp>;
  organization_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "organizations.locations" */
export enum Organizations_Locations_Constraint {
  /** unique or primary key constraint */
  LocationsPkey = 'locations_pkey'
}

/** input type for incrementing numeric columns in table "organizations.locations" */
export type Organizations_Locations_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  organization_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "organizations.locations" */
export type Organizations_Locations_Insert_Input = {
  alt_location_id?: InputMaybe<Scalars['String']>;
  dt_patient_available?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['Int']>;
  is_deleted?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Organizations_Organizations_Obj_Rel_Insert_Input>;
  organization_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Organizations_Locations_Max_Fields = {
  __typename?: 'organizations_locations_max_fields';
  alt_location_id?: Maybe<Scalars['String']>;
  dt_patient_available?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "organizations.locations" */
export type Organizations_Locations_Max_Order_By = {
  alt_location_id?: InputMaybe<Order_By>;
  dt_patient_available?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Organizations_Locations_Min_Fields = {
  __typename?: 'organizations_locations_min_fields';
  alt_location_id?: Maybe<Scalars['String']>;
  dt_patient_available?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "organizations.locations" */
export type Organizations_Locations_Min_Order_By = {
  alt_location_id?: InputMaybe<Order_By>;
  dt_patient_available?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "organizations.locations" */
export type Organizations_Locations_Mutation_Response = {
  __typename?: 'organizations_locations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Organizations_Locations>;
};

/** input type for inserting object relation for remote table "organizations.locations" */
export type Organizations_Locations_Obj_Rel_Insert_Input = {
  data: Organizations_Locations_Insert_Input;
  /** on conflict condition */
  on_conflict?: InputMaybe<Organizations_Locations_On_Conflict>;
};

/** on conflict condition type for table "organizations.locations" */
export type Organizations_Locations_On_Conflict = {
  constraint: Organizations_Locations_Constraint;
  update_columns?: Array<Organizations_Locations_Update_Column>;
  where?: InputMaybe<Organizations_Locations_Bool_Exp>;
};

/** Ordering options when selecting data from "organizations.locations". */
export type Organizations_Locations_Order_By = {
  alt_location_id?: InputMaybe<Order_By>;
  dt_patient_available?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_deleted?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization?: InputMaybe<Organizations_Organizations_Order_By>;
  organization_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: organizations_locations */
export type Organizations_Locations_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "organizations.locations" */
export enum Organizations_Locations_Select_Column {
  /** column name */
  AltLocationId = 'alt_location_id',
  /** column name */
  DtPatientAvailable = 'dt_patient_available',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organization_id'
}

/** input type for updating data in table "organizations.locations" */
export type Organizations_Locations_Set_Input = {
  alt_location_id?: InputMaybe<Scalars['String']>;
  dt_patient_available?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['Int']>;
  is_deleted?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  organization_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Organizations_Locations_Stddev_Fields = {
  __typename?: 'organizations_locations_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  organization_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "organizations.locations" */
export type Organizations_Locations_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Organizations_Locations_Stddev_Pop_Fields = {
  __typename?: 'organizations_locations_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  organization_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "organizations.locations" */
export type Organizations_Locations_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Organizations_Locations_Stddev_Samp_Fields = {
  __typename?: 'organizations_locations_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  organization_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "organizations.locations" */
export type Organizations_Locations_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Organizations_Locations_Sum_Fields = {
  __typename?: 'organizations_locations_sum_fields';
  id?: Maybe<Scalars['Int']>;
  organization_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "organizations.locations" */
export type Organizations_Locations_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
};

/** update columns of table "organizations.locations" */
export enum Organizations_Locations_Update_Column {
  /** column name */
  AltLocationId = 'alt_location_id',
  /** column name */
  DtPatientAvailable = 'dt_patient_available',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organization_id'
}

/** aggregate var_pop on columns */
export type Organizations_Locations_Var_Pop_Fields = {
  __typename?: 'organizations_locations_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  organization_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "organizations.locations" */
export type Organizations_Locations_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Organizations_Locations_Var_Samp_Fields = {
  __typename?: 'organizations_locations_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  organization_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "organizations.locations" */
export type Organizations_Locations_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Organizations_Locations_Variance_Fields = {
  __typename?: 'organizations_locations_variance_fields';
  id?: Maybe<Scalars['Float']>;
  organization_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "organizations.locations" */
export type Organizations_Locations_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "organizations.organizations" */
export type Organizations_Organizations = {
  __typename?: 'organizations_organizations';
  alt_organization_id?: Maybe<Scalars['String']>;
  deactivation_dt?: Maybe<Scalars['date']>;
  /** An array relationship */
  features: Array<Organizations_Organizations_Features>;
  /** An aggregate relationship */
  features_aggregate: Organizations_Organizations_Features_Aggregate;
  id: Scalars['Int'];
  is_active?: Maybe<Scalars['Boolean']>;
  is_deleted?: Maybe<Scalars['Boolean']>;
  /** An array relationship */
  locations: Array<Organizations_Locations>;
  /** An aggregate relationship */
  locations_aggregate: Organizations_Locations_Aggregate;
  name: Scalars['String'];
  /** An object relationship */
  region?: Maybe<Organizations_Regions>;
  region_id?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  state: Scalars['String'];
};


/** columns and relationships of "organizations.organizations" */
export type Organizations_OrganizationsFeaturesArgs = {
  distinct_on?: InputMaybe<Array<Organizations_Organizations_Features_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organizations_Organizations_Features_Order_By>>;
  where?: InputMaybe<Organizations_Organizations_Features_Bool_Exp>;
};


/** columns and relationships of "organizations.organizations" */
export type Organizations_OrganizationsFeatures_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organizations_Organizations_Features_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organizations_Organizations_Features_Order_By>>;
  where?: InputMaybe<Organizations_Organizations_Features_Bool_Exp>;
};


/** columns and relationships of "organizations.organizations" */
export type Organizations_OrganizationsLocationsArgs = {
  distinct_on?: InputMaybe<Array<Organizations_Locations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organizations_Locations_Order_By>>;
  where?: InputMaybe<Organizations_Locations_Bool_Exp>;
};


/** columns and relationships of "organizations.organizations" */
export type Organizations_OrganizationsLocations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organizations_Locations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organizations_Locations_Order_By>>;
  where?: InputMaybe<Organizations_Locations_Bool_Exp>;
};

/** aggregated selection of "organizations.organizations" */
export type Organizations_Organizations_Aggregate = {
  __typename?: 'organizations_organizations_aggregate';
  aggregate?: Maybe<Organizations_Organizations_Aggregate_Fields>;
  nodes: Array<Organizations_Organizations>;
};

/** aggregate fields of "organizations.organizations" */
export type Organizations_Organizations_Aggregate_Fields = {
  __typename?: 'organizations_organizations_aggregate_fields';
  avg?: Maybe<Organizations_Organizations_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Organizations_Organizations_Max_Fields>;
  min?: Maybe<Organizations_Organizations_Min_Fields>;
  stddev?: Maybe<Organizations_Organizations_Stddev_Fields>;
  stddev_pop?: Maybe<Organizations_Organizations_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Organizations_Organizations_Stddev_Samp_Fields>;
  sum?: Maybe<Organizations_Organizations_Sum_Fields>;
  var_pop?: Maybe<Organizations_Organizations_Var_Pop_Fields>;
  var_samp?: Maybe<Organizations_Organizations_Var_Samp_Fields>;
  variance?: Maybe<Organizations_Organizations_Variance_Fields>;
};


/** aggregate fields of "organizations.organizations" */
export type Organizations_Organizations_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Organizations_Organizations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "organizations.organizations" */
export type Organizations_Organizations_Aggregate_Order_By = {
  avg?: InputMaybe<Organizations_Organizations_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Organizations_Organizations_Max_Order_By>;
  min?: InputMaybe<Organizations_Organizations_Min_Order_By>;
  stddev?: InputMaybe<Organizations_Organizations_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Organizations_Organizations_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Organizations_Organizations_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Organizations_Organizations_Sum_Order_By>;
  var_pop?: InputMaybe<Organizations_Organizations_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Organizations_Organizations_Var_Samp_Order_By>;
  variance?: InputMaybe<Organizations_Organizations_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "organizations.organizations" */
export type Organizations_Organizations_Arr_Rel_Insert_Input = {
  data: Array<Organizations_Organizations_Insert_Input>;
  /** on conflict condition */
  on_conflict?: InputMaybe<Organizations_Organizations_On_Conflict>;
};

/** aggregate avg on columns */
export type Organizations_Organizations_Avg_Fields = {
  __typename?: 'organizations_organizations_avg_fields';
  id?: Maybe<Scalars['Float']>;
  region_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "organizations.organizations" */
export type Organizations_Organizations_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  region_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "organizations.organizations". All fields are combined with a logical 'AND'. */
export type Organizations_Organizations_Bool_Exp = {
  _and?: InputMaybe<Array<Organizations_Organizations_Bool_Exp>>;
  _not?: InputMaybe<Organizations_Organizations_Bool_Exp>;
  _or?: InputMaybe<Array<Organizations_Organizations_Bool_Exp>>;
  alt_organization_id?: InputMaybe<String_Comparison_Exp>;
  deactivation_dt?: InputMaybe<Date_Comparison_Exp>;
  features?: InputMaybe<Organizations_Organizations_Features_Bool_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  is_active?: InputMaybe<Boolean_Comparison_Exp>;
  is_deleted?: InputMaybe<Boolean_Comparison_Exp>;
  locations?: InputMaybe<Organizations_Locations_Bool_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  region?: InputMaybe<Organizations_Regions_Bool_Exp>;
  region_id?: InputMaybe<Int_Comparison_Exp>;
  slug?: InputMaybe<String_Comparison_Exp>;
  state?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "organizations.organizations" */
export enum Organizations_Organizations_Constraint {
  /** unique or primary key constraint */
  OrganizationsPkey = 'organizations_pkey',
  /** unique or primary key constraint */
  OrganizationsSlugUnique = 'organizations_slug_unique'
}

/** columns and relationships of "organizations.organizations_features" */
export type Organizations_Organizations_Features = {
  __typename?: 'organizations_organizations_features';
  configuration?: Maybe<Scalars['jsonb']>;
  feature_id: Scalars['String'];
  organization_id: Scalars['Int'];
};


/** columns and relationships of "organizations.organizations_features" */
export type Organizations_Organizations_FeaturesConfigurationArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "organizations.organizations_features" */
export type Organizations_Organizations_Features_Aggregate = {
  __typename?: 'organizations_organizations_features_aggregate';
  aggregate?: Maybe<Organizations_Organizations_Features_Aggregate_Fields>;
  nodes: Array<Organizations_Organizations_Features>;
};

/** aggregate fields of "organizations.organizations_features" */
export type Organizations_Organizations_Features_Aggregate_Fields = {
  __typename?: 'organizations_organizations_features_aggregate_fields';
  avg?: Maybe<Organizations_Organizations_Features_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Organizations_Organizations_Features_Max_Fields>;
  min?: Maybe<Organizations_Organizations_Features_Min_Fields>;
  stddev?: Maybe<Organizations_Organizations_Features_Stddev_Fields>;
  stddev_pop?: Maybe<Organizations_Organizations_Features_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Organizations_Organizations_Features_Stddev_Samp_Fields>;
  sum?: Maybe<Organizations_Organizations_Features_Sum_Fields>;
  var_pop?: Maybe<Organizations_Organizations_Features_Var_Pop_Fields>;
  var_samp?: Maybe<Organizations_Organizations_Features_Var_Samp_Fields>;
  variance?: Maybe<Organizations_Organizations_Features_Variance_Fields>;
};


/** aggregate fields of "organizations.organizations_features" */
export type Organizations_Organizations_Features_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Organizations_Organizations_Features_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "organizations.organizations_features" */
export type Organizations_Organizations_Features_Aggregate_Order_By = {
  avg?: InputMaybe<Organizations_Organizations_Features_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Organizations_Organizations_Features_Max_Order_By>;
  min?: InputMaybe<Organizations_Organizations_Features_Min_Order_By>;
  stddev?: InputMaybe<Organizations_Organizations_Features_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Organizations_Organizations_Features_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Organizations_Organizations_Features_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Organizations_Organizations_Features_Sum_Order_By>;
  var_pop?: InputMaybe<Organizations_Organizations_Features_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Organizations_Organizations_Features_Var_Samp_Order_By>;
  variance?: InputMaybe<Organizations_Organizations_Features_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Organizations_Organizations_Features_Append_Input = {
  configuration?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "organizations.organizations_features" */
export type Organizations_Organizations_Features_Arr_Rel_Insert_Input = {
  data: Array<Organizations_Organizations_Features_Insert_Input>;
  /** on conflict condition */
  on_conflict?: InputMaybe<Organizations_Organizations_Features_On_Conflict>;
};

/** aggregate avg on columns */
export type Organizations_Organizations_Features_Avg_Fields = {
  __typename?: 'organizations_organizations_features_avg_fields';
  organization_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "organizations.organizations_features" */
export type Organizations_Organizations_Features_Avg_Order_By = {
  organization_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "organizations.organizations_features". All fields are combined with a logical 'AND'. */
export type Organizations_Organizations_Features_Bool_Exp = {
  _and?: InputMaybe<Array<Organizations_Organizations_Features_Bool_Exp>>;
  _not?: InputMaybe<Organizations_Organizations_Features_Bool_Exp>;
  _or?: InputMaybe<Array<Organizations_Organizations_Features_Bool_Exp>>;
  configuration?: InputMaybe<Jsonb_Comparison_Exp>;
  feature_id?: InputMaybe<String_Comparison_Exp>;
  organization_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "organizations.organizations_features" */
export enum Organizations_Organizations_Features_Constraint {
  /** unique or primary key constraint */
  OrganizationsFeaturesPkey = 'organizations_features_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Organizations_Organizations_Features_Delete_At_Path_Input = {
  configuration?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Organizations_Organizations_Features_Delete_Elem_Input = {
  configuration?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Organizations_Organizations_Features_Delete_Key_Input = {
  configuration?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "organizations.organizations_features" */
export type Organizations_Organizations_Features_Inc_Input = {
  organization_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "organizations.organizations_features" */
export type Organizations_Organizations_Features_Insert_Input = {
  configuration?: InputMaybe<Scalars['jsonb']>;
  feature_id?: InputMaybe<Scalars['String']>;
  organization_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Organizations_Organizations_Features_Max_Fields = {
  __typename?: 'organizations_organizations_features_max_fields';
  feature_id?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "organizations.organizations_features" */
export type Organizations_Organizations_Features_Max_Order_By = {
  feature_id?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Organizations_Organizations_Features_Min_Fields = {
  __typename?: 'organizations_organizations_features_min_fields';
  feature_id?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "organizations.organizations_features" */
export type Organizations_Organizations_Features_Min_Order_By = {
  feature_id?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "organizations.organizations_features" */
export type Organizations_Organizations_Features_Mutation_Response = {
  __typename?: 'organizations_organizations_features_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Organizations_Organizations_Features>;
};

/** on conflict condition type for table "organizations.organizations_features" */
export type Organizations_Organizations_Features_On_Conflict = {
  constraint: Organizations_Organizations_Features_Constraint;
  update_columns?: Array<Organizations_Organizations_Features_Update_Column>;
  where?: InputMaybe<Organizations_Organizations_Features_Bool_Exp>;
};

/** Ordering options when selecting data from "organizations.organizations_features". */
export type Organizations_Organizations_Features_Order_By = {
  configuration?: InputMaybe<Order_By>;
  feature_id?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: organizations_organizations_features */
export type Organizations_Organizations_Features_Pk_Columns_Input = {
  feature_id: Scalars['String'];
  organization_id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Organizations_Organizations_Features_Prepend_Input = {
  configuration?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "organizations.organizations_features" */
export enum Organizations_Organizations_Features_Select_Column {
  /** column name */
  Configuration = 'configuration',
  /** column name */
  FeatureId = 'feature_id',
  /** column name */
  OrganizationId = 'organization_id'
}

/** input type for updating data in table "organizations.organizations_features" */
export type Organizations_Organizations_Features_Set_Input = {
  configuration?: InputMaybe<Scalars['jsonb']>;
  feature_id?: InputMaybe<Scalars['String']>;
  organization_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Organizations_Organizations_Features_Stddev_Fields = {
  __typename?: 'organizations_organizations_features_stddev_fields';
  organization_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "organizations.organizations_features" */
export type Organizations_Organizations_Features_Stddev_Order_By = {
  organization_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Organizations_Organizations_Features_Stddev_Pop_Fields = {
  __typename?: 'organizations_organizations_features_stddev_pop_fields';
  organization_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "organizations.organizations_features" */
export type Organizations_Organizations_Features_Stddev_Pop_Order_By = {
  organization_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Organizations_Organizations_Features_Stddev_Samp_Fields = {
  __typename?: 'organizations_organizations_features_stddev_samp_fields';
  organization_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "organizations.organizations_features" */
export type Organizations_Organizations_Features_Stddev_Samp_Order_By = {
  organization_id?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Organizations_Organizations_Features_Sum_Fields = {
  __typename?: 'organizations_organizations_features_sum_fields';
  organization_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "organizations.organizations_features" */
export type Organizations_Organizations_Features_Sum_Order_By = {
  organization_id?: InputMaybe<Order_By>;
};

/** update columns of table "organizations.organizations_features" */
export enum Organizations_Organizations_Features_Update_Column {
  /** column name */
  Configuration = 'configuration',
  /** column name */
  FeatureId = 'feature_id',
  /** column name */
  OrganizationId = 'organization_id'
}

/** aggregate var_pop on columns */
export type Organizations_Organizations_Features_Var_Pop_Fields = {
  __typename?: 'organizations_organizations_features_var_pop_fields';
  organization_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "organizations.organizations_features" */
export type Organizations_Organizations_Features_Var_Pop_Order_By = {
  organization_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Organizations_Organizations_Features_Var_Samp_Fields = {
  __typename?: 'organizations_organizations_features_var_samp_fields';
  organization_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "organizations.organizations_features" */
export type Organizations_Organizations_Features_Var_Samp_Order_By = {
  organization_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Organizations_Organizations_Features_Variance_Fields = {
  __typename?: 'organizations_organizations_features_variance_fields';
  organization_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "organizations.organizations_features" */
export type Organizations_Organizations_Features_Variance_Order_By = {
  organization_id?: InputMaybe<Order_By>;
};

/** input type for incrementing numeric columns in table "organizations.organizations" */
export type Organizations_Organizations_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  region_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "organizations.organizations" */
export type Organizations_Organizations_Insert_Input = {
  alt_organization_id?: InputMaybe<Scalars['String']>;
  deactivation_dt?: InputMaybe<Scalars['date']>;
  features?: InputMaybe<Organizations_Organizations_Features_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['Int']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  is_deleted?: InputMaybe<Scalars['Boolean']>;
  locations?: InputMaybe<Organizations_Locations_Arr_Rel_Insert_Input>;
  name?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Organizations_Regions_Obj_Rel_Insert_Input>;
  region_id?: InputMaybe<Scalars['Int']>;
  slug?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Organizations_Organizations_Max_Fields = {
  __typename?: 'organizations_organizations_max_fields';
  alt_organization_id?: Maybe<Scalars['String']>;
  deactivation_dt?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  region_id?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "organizations.organizations" */
export type Organizations_Organizations_Max_Order_By = {
  alt_organization_id?: InputMaybe<Order_By>;
  deactivation_dt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  region_id?: InputMaybe<Order_By>;
  slug?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Organizations_Organizations_Min_Fields = {
  __typename?: 'organizations_organizations_min_fields';
  alt_organization_id?: Maybe<Scalars['String']>;
  deactivation_dt?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  region_id?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "organizations.organizations" */
export type Organizations_Organizations_Min_Order_By = {
  alt_organization_id?: InputMaybe<Order_By>;
  deactivation_dt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  region_id?: InputMaybe<Order_By>;
  slug?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "organizations.organizations" */
export type Organizations_Organizations_Mutation_Response = {
  __typename?: 'organizations_organizations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Organizations_Organizations>;
};

/** input type for inserting object relation for remote table "organizations.organizations" */
export type Organizations_Organizations_Obj_Rel_Insert_Input = {
  data: Organizations_Organizations_Insert_Input;
  /** on conflict condition */
  on_conflict?: InputMaybe<Organizations_Organizations_On_Conflict>;
};

/** on conflict condition type for table "organizations.organizations" */
export type Organizations_Organizations_On_Conflict = {
  constraint: Organizations_Organizations_Constraint;
  update_columns?: Array<Organizations_Organizations_Update_Column>;
  where?: InputMaybe<Organizations_Organizations_Bool_Exp>;
};

/** Ordering options when selecting data from "organizations.organizations". */
export type Organizations_Organizations_Order_By = {
  alt_organization_id?: InputMaybe<Order_By>;
  deactivation_dt?: InputMaybe<Order_By>;
  features_aggregate?: InputMaybe<Organizations_Organizations_Features_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  is_active?: InputMaybe<Order_By>;
  is_deleted?: InputMaybe<Order_By>;
  locations_aggregate?: InputMaybe<Organizations_Locations_Aggregate_Order_By>;
  name?: InputMaybe<Order_By>;
  region?: InputMaybe<Organizations_Regions_Order_By>;
  region_id?: InputMaybe<Order_By>;
  slug?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
};

/** primary key columns input for table: organizations_organizations */
export type Organizations_Organizations_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "organizations.organizations" */
export enum Organizations_Organizations_Select_Column {
  /** column name */
  AltOrganizationId = 'alt_organization_id',
  /** column name */
  DeactivationDt = 'deactivation_dt',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  Name = 'name',
  /** column name */
  RegionId = 'region_id',
  /** column name */
  Slug = 'slug',
  /** column name */
  State = 'state'
}

/** input type for updating data in table "organizations.organizations" */
export type Organizations_Organizations_Set_Input = {
  alt_organization_id?: InputMaybe<Scalars['String']>;
  deactivation_dt?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['Int']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  is_deleted?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  region_id?: InputMaybe<Scalars['Int']>;
  slug?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Organizations_Organizations_Stddev_Fields = {
  __typename?: 'organizations_organizations_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  region_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "organizations.organizations" */
export type Organizations_Organizations_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  region_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Organizations_Organizations_Stddev_Pop_Fields = {
  __typename?: 'organizations_organizations_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  region_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "organizations.organizations" */
export type Organizations_Organizations_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  region_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Organizations_Organizations_Stddev_Samp_Fields = {
  __typename?: 'organizations_organizations_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  region_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "organizations.organizations" */
export type Organizations_Organizations_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  region_id?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Organizations_Organizations_Sum_Fields = {
  __typename?: 'organizations_organizations_sum_fields';
  id?: Maybe<Scalars['Int']>;
  region_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "organizations.organizations" */
export type Organizations_Organizations_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  region_id?: InputMaybe<Order_By>;
};

/** update columns of table "organizations.organizations" */
export enum Organizations_Organizations_Update_Column {
  /** column name */
  AltOrganizationId = 'alt_organization_id',
  /** column name */
  DeactivationDt = 'deactivation_dt',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  Name = 'name',
  /** column name */
  RegionId = 'region_id',
  /** column name */
  Slug = 'slug',
  /** column name */
  State = 'state'
}

/** aggregate var_pop on columns */
export type Organizations_Organizations_Var_Pop_Fields = {
  __typename?: 'organizations_organizations_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  region_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "organizations.organizations" */
export type Organizations_Organizations_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  region_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Organizations_Organizations_Var_Samp_Fields = {
  __typename?: 'organizations_organizations_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  region_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "organizations.organizations" */
export type Organizations_Organizations_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  region_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Organizations_Organizations_Variance_Fields = {
  __typename?: 'organizations_organizations_variance_fields';
  id?: Maybe<Scalars['Float']>;
  region_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "organizations.organizations" */
export type Organizations_Organizations_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  region_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "organizations.regions" */
export type Organizations_Regions = {
  __typename?: 'organizations_regions';
  id: Scalars['Int'];
  is_deleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  /** An array relationship */
  organizations: Array<Organizations_Organizations>;
  /** An aggregate relationship */
  organizations_aggregate: Organizations_Organizations_Aggregate;
};


/** columns and relationships of "organizations.regions" */
export type Organizations_RegionsOrganizationsArgs = {
  distinct_on?: InputMaybe<Array<Organizations_Organizations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organizations_Organizations_Order_By>>;
  where?: InputMaybe<Organizations_Organizations_Bool_Exp>;
};


/** columns and relationships of "organizations.regions" */
export type Organizations_RegionsOrganizations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organizations_Organizations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organizations_Organizations_Order_By>>;
  where?: InputMaybe<Organizations_Organizations_Bool_Exp>;
};

/** aggregated selection of "organizations.regions" */
export type Organizations_Regions_Aggregate = {
  __typename?: 'organizations_regions_aggregate';
  aggregate?: Maybe<Organizations_Regions_Aggregate_Fields>;
  nodes: Array<Organizations_Regions>;
};

/** aggregate fields of "organizations.regions" */
export type Organizations_Regions_Aggregate_Fields = {
  __typename?: 'organizations_regions_aggregate_fields';
  avg?: Maybe<Organizations_Regions_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Organizations_Regions_Max_Fields>;
  min?: Maybe<Organizations_Regions_Min_Fields>;
  stddev?: Maybe<Organizations_Regions_Stddev_Fields>;
  stddev_pop?: Maybe<Organizations_Regions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Organizations_Regions_Stddev_Samp_Fields>;
  sum?: Maybe<Organizations_Regions_Sum_Fields>;
  var_pop?: Maybe<Organizations_Regions_Var_Pop_Fields>;
  var_samp?: Maybe<Organizations_Regions_Var_Samp_Fields>;
  variance?: Maybe<Organizations_Regions_Variance_Fields>;
};


/** aggregate fields of "organizations.regions" */
export type Organizations_Regions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Organizations_Regions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Organizations_Regions_Avg_Fields = {
  __typename?: 'organizations_regions_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "organizations.regions". All fields are combined with a logical 'AND'. */
export type Organizations_Regions_Bool_Exp = {
  _and?: InputMaybe<Array<Organizations_Regions_Bool_Exp>>;
  _not?: InputMaybe<Organizations_Regions_Bool_Exp>;
  _or?: InputMaybe<Array<Organizations_Regions_Bool_Exp>>;
  id?: InputMaybe<Int_Comparison_Exp>;
  is_deleted?: InputMaybe<Boolean_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  organizations?: InputMaybe<Organizations_Organizations_Bool_Exp>;
};

/** unique or primary key constraints on table "organizations.regions" */
export enum Organizations_Regions_Constraint {
  /** unique or primary key constraint */
  RegionsPkey = 'regions_pkey'
}

/** input type for incrementing numeric columns in table "organizations.regions" */
export type Organizations_Regions_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "organizations.regions" */
export type Organizations_Regions_Insert_Input = {
  id?: InputMaybe<Scalars['Int']>;
  is_deleted?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  organizations?: InputMaybe<Organizations_Organizations_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Organizations_Regions_Max_Fields = {
  __typename?: 'organizations_regions_max_fields';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Organizations_Regions_Min_Fields = {
  __typename?: 'organizations_regions_min_fields';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "organizations.regions" */
export type Organizations_Regions_Mutation_Response = {
  __typename?: 'organizations_regions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Organizations_Regions>;
};

/** input type for inserting object relation for remote table "organizations.regions" */
export type Organizations_Regions_Obj_Rel_Insert_Input = {
  data: Organizations_Regions_Insert_Input;
  /** on conflict condition */
  on_conflict?: InputMaybe<Organizations_Regions_On_Conflict>;
};

/** on conflict condition type for table "organizations.regions" */
export type Organizations_Regions_On_Conflict = {
  constraint: Organizations_Regions_Constraint;
  update_columns?: Array<Organizations_Regions_Update_Column>;
  where?: InputMaybe<Organizations_Regions_Bool_Exp>;
};

/** Ordering options when selecting data from "organizations.regions". */
export type Organizations_Regions_Order_By = {
  id?: InputMaybe<Order_By>;
  is_deleted?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organizations_aggregate?: InputMaybe<Organizations_Organizations_Aggregate_Order_By>;
};

/** primary key columns input for table: organizations_regions */
export type Organizations_Regions_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "organizations.regions" */
export enum Organizations_Regions_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "organizations.regions" */
export type Organizations_Regions_Set_Input = {
  id?: InputMaybe<Scalars['Int']>;
  is_deleted?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Organizations_Regions_Stddev_Fields = {
  __typename?: 'organizations_regions_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Organizations_Regions_Stddev_Pop_Fields = {
  __typename?: 'organizations_regions_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Organizations_Regions_Stddev_Samp_Fields = {
  __typename?: 'organizations_regions_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Organizations_Regions_Sum_Fields = {
  __typename?: 'organizations_regions_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "organizations.regions" */
export enum Organizations_Regions_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  Name = 'name'
}

/** aggregate var_pop on columns */
export type Organizations_Regions_Var_Pop_Fields = {
  __typename?: 'organizations_regions_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Organizations_Regions_Var_Samp_Fields = {
  __typename?: 'organizations_regions_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Organizations_Regions_Variance_Fields = {
  __typename?: 'organizations_regions_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "organizations.organization_x_schoolyear" */
export type Organizations_School_Years = {
  __typename?: 'organizations_school_years';
  created_ts?: Maybe<Scalars['timestamptz']>;
  first_day_of_school?: Maybe<Scalars['date']>;
  last_day_of_school?: Maybe<Scalars['date']>;
  organization_id: Scalars['Int'];
  organization_x_schoolyear_id: Scalars['bigint'];
  schoolweek_days_nbr: Scalars['smallint'];
  schoolyear_id: Scalars['Int'];
  updated_ts?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "organizations.organization_x_schoolyear" */
export type Organizations_School_Years_Aggregate = {
  __typename?: 'organizations_school_years_aggregate';
  aggregate?: Maybe<Organizations_School_Years_Aggregate_Fields>;
  nodes: Array<Organizations_School_Years>;
};

/** aggregate fields of "organizations.organization_x_schoolyear" */
export type Organizations_School_Years_Aggregate_Fields = {
  __typename?: 'organizations_school_years_aggregate_fields';
  avg?: Maybe<Organizations_School_Years_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Organizations_School_Years_Max_Fields>;
  min?: Maybe<Organizations_School_Years_Min_Fields>;
  stddev?: Maybe<Organizations_School_Years_Stddev_Fields>;
  stddev_pop?: Maybe<Organizations_School_Years_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Organizations_School_Years_Stddev_Samp_Fields>;
  sum?: Maybe<Organizations_School_Years_Sum_Fields>;
  var_pop?: Maybe<Organizations_School_Years_Var_Pop_Fields>;
  var_samp?: Maybe<Organizations_School_Years_Var_Samp_Fields>;
  variance?: Maybe<Organizations_School_Years_Variance_Fields>;
};


/** aggregate fields of "organizations.organization_x_schoolyear" */
export type Organizations_School_Years_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Organizations_School_Years_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Organizations_School_Years_Avg_Fields = {
  __typename?: 'organizations_school_years_avg_fields';
  organization_id?: Maybe<Scalars['Float']>;
  organization_x_schoolyear_id?: Maybe<Scalars['Float']>;
  schoolweek_days_nbr?: Maybe<Scalars['Float']>;
  schoolyear_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "organizations.organization_x_schoolyear". All fields are combined with a logical 'AND'. */
export type Organizations_School_Years_Bool_Exp = {
  _and?: InputMaybe<Array<Organizations_School_Years_Bool_Exp>>;
  _not?: InputMaybe<Organizations_School_Years_Bool_Exp>;
  _or?: InputMaybe<Array<Organizations_School_Years_Bool_Exp>>;
  created_ts?: InputMaybe<Timestamptz_Comparison_Exp>;
  first_day_of_school?: InputMaybe<Date_Comparison_Exp>;
  last_day_of_school?: InputMaybe<Date_Comparison_Exp>;
  organization_id?: InputMaybe<Int_Comparison_Exp>;
  organization_x_schoolyear_id?: InputMaybe<Bigint_Comparison_Exp>;
  schoolweek_days_nbr?: InputMaybe<Smallint_Comparison_Exp>;
  schoolyear_id?: InputMaybe<Int_Comparison_Exp>;
  updated_ts?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "organizations.organization_x_schoolyear" */
export enum Organizations_School_Years_Constraint {
  /** unique or primary key constraint */
  AkOrganizationXSchoolyear = 'ak_organization_x_schoolyear',
  /** unique or primary key constraint */
  PkOrganizationXSchoolyear = 'pk_organization_x_schoolyear'
}

/** input type for incrementing numeric columns in table "organizations.organization_x_schoolyear" */
export type Organizations_School_Years_Inc_Input = {
  organization_id?: InputMaybe<Scalars['Int']>;
  organization_x_schoolyear_id?: InputMaybe<Scalars['bigint']>;
  schoolweek_days_nbr?: InputMaybe<Scalars['smallint']>;
  schoolyear_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "organizations.organization_x_schoolyear" */
export type Organizations_School_Years_Insert_Input = {
  created_ts?: InputMaybe<Scalars['timestamptz']>;
  first_day_of_school?: InputMaybe<Scalars['date']>;
  last_day_of_school?: InputMaybe<Scalars['date']>;
  organization_id?: InputMaybe<Scalars['Int']>;
  organization_x_schoolyear_id?: InputMaybe<Scalars['bigint']>;
  schoolweek_days_nbr?: InputMaybe<Scalars['smallint']>;
  schoolyear_id?: InputMaybe<Scalars['Int']>;
  updated_ts?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Organizations_School_Years_Max_Fields = {
  __typename?: 'organizations_school_years_max_fields';
  created_ts?: Maybe<Scalars['timestamptz']>;
  first_day_of_school?: Maybe<Scalars['date']>;
  last_day_of_school?: Maybe<Scalars['date']>;
  organization_id?: Maybe<Scalars['Int']>;
  organization_x_schoolyear_id?: Maybe<Scalars['bigint']>;
  schoolweek_days_nbr?: Maybe<Scalars['smallint']>;
  schoolyear_id?: Maybe<Scalars['Int']>;
  updated_ts?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Organizations_School_Years_Min_Fields = {
  __typename?: 'organizations_school_years_min_fields';
  created_ts?: Maybe<Scalars['timestamptz']>;
  first_day_of_school?: Maybe<Scalars['date']>;
  last_day_of_school?: Maybe<Scalars['date']>;
  organization_id?: Maybe<Scalars['Int']>;
  organization_x_schoolyear_id?: Maybe<Scalars['bigint']>;
  schoolweek_days_nbr?: Maybe<Scalars['smallint']>;
  schoolyear_id?: Maybe<Scalars['Int']>;
  updated_ts?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "organizations.organization_x_schoolyear" */
export type Organizations_School_Years_Mutation_Response = {
  __typename?: 'organizations_school_years_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Organizations_School_Years>;
};

/** on conflict condition type for table "organizations.organization_x_schoolyear" */
export type Organizations_School_Years_On_Conflict = {
  constraint: Organizations_School_Years_Constraint;
  update_columns?: Array<Organizations_School_Years_Update_Column>;
  where?: InputMaybe<Organizations_School_Years_Bool_Exp>;
};

/** Ordering options when selecting data from "organizations.organization_x_schoolyear". */
export type Organizations_School_Years_Order_By = {
  created_ts?: InputMaybe<Order_By>;
  first_day_of_school?: InputMaybe<Order_By>;
  last_day_of_school?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  organization_x_schoolyear_id?: InputMaybe<Order_By>;
  schoolweek_days_nbr?: InputMaybe<Order_By>;
  schoolyear_id?: InputMaybe<Order_By>;
  updated_ts?: InputMaybe<Order_By>;
};

/** primary key columns input for table: organizations_school_years */
export type Organizations_School_Years_Pk_Columns_Input = {
  organization_x_schoolyear_id: Scalars['bigint'];
};

/** select columns of table "organizations.organization_x_schoolyear" */
export enum Organizations_School_Years_Select_Column {
  /** column name */
  CreatedTs = 'created_ts',
  /** column name */
  FirstDayOfSchool = 'first_day_of_school',
  /** column name */
  LastDayOfSchool = 'last_day_of_school',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  OrganizationXSchoolyearId = 'organization_x_schoolyear_id',
  /** column name */
  SchoolweekDaysNbr = 'schoolweek_days_nbr',
  /** column name */
  SchoolyearId = 'schoolyear_id',
  /** column name */
  UpdatedTs = 'updated_ts'
}

/** input type for updating data in table "organizations.organization_x_schoolyear" */
export type Organizations_School_Years_Set_Input = {
  created_ts?: InputMaybe<Scalars['timestamptz']>;
  first_day_of_school?: InputMaybe<Scalars['date']>;
  last_day_of_school?: InputMaybe<Scalars['date']>;
  organization_id?: InputMaybe<Scalars['Int']>;
  organization_x_schoolyear_id?: InputMaybe<Scalars['bigint']>;
  schoolweek_days_nbr?: InputMaybe<Scalars['smallint']>;
  schoolyear_id?: InputMaybe<Scalars['Int']>;
  updated_ts?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Organizations_School_Years_Stddev_Fields = {
  __typename?: 'organizations_school_years_stddev_fields';
  organization_id?: Maybe<Scalars['Float']>;
  organization_x_schoolyear_id?: Maybe<Scalars['Float']>;
  schoolweek_days_nbr?: Maybe<Scalars['Float']>;
  schoolyear_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Organizations_School_Years_Stddev_Pop_Fields = {
  __typename?: 'organizations_school_years_stddev_pop_fields';
  organization_id?: Maybe<Scalars['Float']>;
  organization_x_schoolyear_id?: Maybe<Scalars['Float']>;
  schoolweek_days_nbr?: Maybe<Scalars['Float']>;
  schoolyear_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Organizations_School_Years_Stddev_Samp_Fields = {
  __typename?: 'organizations_school_years_stddev_samp_fields';
  organization_id?: Maybe<Scalars['Float']>;
  organization_x_schoolyear_id?: Maybe<Scalars['Float']>;
  schoolweek_days_nbr?: Maybe<Scalars['Float']>;
  schoolyear_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Organizations_School_Years_Sum_Fields = {
  __typename?: 'organizations_school_years_sum_fields';
  organization_id?: Maybe<Scalars['Int']>;
  organization_x_schoolyear_id?: Maybe<Scalars['bigint']>;
  schoolweek_days_nbr?: Maybe<Scalars['smallint']>;
  schoolyear_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "organizations.organization_x_schoolyear" */
export enum Organizations_School_Years_Update_Column {
  /** column name */
  CreatedTs = 'created_ts',
  /** column name */
  FirstDayOfSchool = 'first_day_of_school',
  /** column name */
  LastDayOfSchool = 'last_day_of_school',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  OrganizationXSchoolyearId = 'organization_x_schoolyear_id',
  /** column name */
  SchoolweekDaysNbr = 'schoolweek_days_nbr',
  /** column name */
  SchoolyearId = 'schoolyear_id',
  /** column name */
  UpdatedTs = 'updated_ts'
}

/** aggregate var_pop on columns */
export type Organizations_School_Years_Var_Pop_Fields = {
  __typename?: 'organizations_school_years_var_pop_fields';
  organization_id?: Maybe<Scalars['Float']>;
  organization_x_schoolyear_id?: Maybe<Scalars['Float']>;
  schoolweek_days_nbr?: Maybe<Scalars['Float']>;
  schoolyear_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Organizations_School_Years_Var_Samp_Fields = {
  __typename?: 'organizations_school_years_var_samp_fields';
  organization_id?: Maybe<Scalars['Float']>;
  organization_x_schoolyear_id?: Maybe<Scalars['Float']>;
  schoolweek_days_nbr?: Maybe<Scalars['Float']>;
  schoolyear_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Organizations_School_Years_Variance_Fields = {
  __typename?: 'organizations_school_years_variance_fields';
  organization_id?: Maybe<Scalars['Float']>;
  organization_x_schoolyear_id?: Maybe<Scalars['Float']>;
  schoolweek_days_nbr?: Maybe<Scalars['Float']>;
  schoolyear_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "organizations.location_x_testing_location" */
export type Organizations_Testing_Locations = {
  __typename?: 'organizations_testing_locations';
  created_ts?: Maybe<Scalars['timestamptz']>;
  from_dt: Scalars['date'];
  location_id: Scalars['Int'];
  location_x_testing_location_id: Scalars['bigint'];
  testing_location_id: Scalars['Int'];
  to_dt: Scalars['date'];
  updated_ts?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "organizations.location_x_testing_location" */
export type Organizations_Testing_Locations_Aggregate = {
  __typename?: 'organizations_testing_locations_aggregate';
  aggregate?: Maybe<Organizations_Testing_Locations_Aggregate_Fields>;
  nodes: Array<Organizations_Testing_Locations>;
};

/** aggregate fields of "organizations.location_x_testing_location" */
export type Organizations_Testing_Locations_Aggregate_Fields = {
  __typename?: 'organizations_testing_locations_aggregate_fields';
  avg?: Maybe<Organizations_Testing_Locations_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Organizations_Testing_Locations_Max_Fields>;
  min?: Maybe<Organizations_Testing_Locations_Min_Fields>;
  stddev?: Maybe<Organizations_Testing_Locations_Stddev_Fields>;
  stddev_pop?: Maybe<Organizations_Testing_Locations_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Organizations_Testing_Locations_Stddev_Samp_Fields>;
  sum?: Maybe<Organizations_Testing_Locations_Sum_Fields>;
  var_pop?: Maybe<Organizations_Testing_Locations_Var_Pop_Fields>;
  var_samp?: Maybe<Organizations_Testing_Locations_Var_Samp_Fields>;
  variance?: Maybe<Organizations_Testing_Locations_Variance_Fields>;
};


/** aggregate fields of "organizations.location_x_testing_location" */
export type Organizations_Testing_Locations_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Organizations_Testing_Locations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Organizations_Testing_Locations_Avg_Fields = {
  __typename?: 'organizations_testing_locations_avg_fields';
  location_id?: Maybe<Scalars['Float']>;
  location_x_testing_location_id?: Maybe<Scalars['Float']>;
  testing_location_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "organizations.location_x_testing_location". All fields are combined with a logical 'AND'. */
export type Organizations_Testing_Locations_Bool_Exp = {
  _and?: InputMaybe<Array<Organizations_Testing_Locations_Bool_Exp>>;
  _not?: InputMaybe<Organizations_Testing_Locations_Bool_Exp>;
  _or?: InputMaybe<Array<Organizations_Testing_Locations_Bool_Exp>>;
  created_ts?: InputMaybe<Timestamptz_Comparison_Exp>;
  from_dt?: InputMaybe<Date_Comparison_Exp>;
  location_id?: InputMaybe<Int_Comparison_Exp>;
  location_x_testing_location_id?: InputMaybe<Bigint_Comparison_Exp>;
  testing_location_id?: InputMaybe<Int_Comparison_Exp>;
  to_dt?: InputMaybe<Date_Comparison_Exp>;
  updated_ts?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "organizations.location_x_testing_location" */
export enum Organizations_Testing_Locations_Constraint {
  /** unique or primary key constraint */
  AkLocationXTestingLocation = 'ak_location_x_testing_location',
  /** unique or primary key constraint */
  PkLocationXTestingLocation = 'pk_location_x_testing_location'
}

/** input type for incrementing numeric columns in table "organizations.location_x_testing_location" */
export type Organizations_Testing_Locations_Inc_Input = {
  location_id?: InputMaybe<Scalars['Int']>;
  location_x_testing_location_id?: InputMaybe<Scalars['bigint']>;
  testing_location_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "organizations.location_x_testing_location" */
export type Organizations_Testing_Locations_Insert_Input = {
  created_ts?: InputMaybe<Scalars['timestamptz']>;
  from_dt?: InputMaybe<Scalars['date']>;
  location_id?: InputMaybe<Scalars['Int']>;
  location_x_testing_location_id?: InputMaybe<Scalars['bigint']>;
  testing_location_id?: InputMaybe<Scalars['Int']>;
  to_dt?: InputMaybe<Scalars['date']>;
  updated_ts?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Organizations_Testing_Locations_Max_Fields = {
  __typename?: 'organizations_testing_locations_max_fields';
  created_ts?: Maybe<Scalars['timestamptz']>;
  from_dt?: Maybe<Scalars['date']>;
  location_id?: Maybe<Scalars['Int']>;
  location_x_testing_location_id?: Maybe<Scalars['bigint']>;
  testing_location_id?: Maybe<Scalars['Int']>;
  to_dt?: Maybe<Scalars['date']>;
  updated_ts?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Organizations_Testing_Locations_Min_Fields = {
  __typename?: 'organizations_testing_locations_min_fields';
  created_ts?: Maybe<Scalars['timestamptz']>;
  from_dt?: Maybe<Scalars['date']>;
  location_id?: Maybe<Scalars['Int']>;
  location_x_testing_location_id?: Maybe<Scalars['bigint']>;
  testing_location_id?: Maybe<Scalars['Int']>;
  to_dt?: Maybe<Scalars['date']>;
  updated_ts?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "organizations.location_x_testing_location" */
export type Organizations_Testing_Locations_Mutation_Response = {
  __typename?: 'organizations_testing_locations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Organizations_Testing_Locations>;
};

/** on conflict condition type for table "organizations.location_x_testing_location" */
export type Organizations_Testing_Locations_On_Conflict = {
  constraint: Organizations_Testing_Locations_Constraint;
  update_columns?: Array<Organizations_Testing_Locations_Update_Column>;
  where?: InputMaybe<Organizations_Testing_Locations_Bool_Exp>;
};

/** Ordering options when selecting data from "organizations.location_x_testing_location". */
export type Organizations_Testing_Locations_Order_By = {
  created_ts?: InputMaybe<Order_By>;
  from_dt?: InputMaybe<Order_By>;
  location_id?: InputMaybe<Order_By>;
  location_x_testing_location_id?: InputMaybe<Order_By>;
  testing_location_id?: InputMaybe<Order_By>;
  to_dt?: InputMaybe<Order_By>;
  updated_ts?: InputMaybe<Order_By>;
};

/** primary key columns input for table: organizations_testing_locations */
export type Organizations_Testing_Locations_Pk_Columns_Input = {
  location_x_testing_location_id: Scalars['bigint'];
};

/** select columns of table "organizations.location_x_testing_location" */
export enum Organizations_Testing_Locations_Select_Column {
  /** column name */
  CreatedTs = 'created_ts',
  /** column name */
  FromDt = 'from_dt',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  LocationXTestingLocationId = 'location_x_testing_location_id',
  /** column name */
  TestingLocationId = 'testing_location_id',
  /** column name */
  ToDt = 'to_dt',
  /** column name */
  UpdatedTs = 'updated_ts'
}

/** input type for updating data in table "organizations.location_x_testing_location" */
export type Organizations_Testing_Locations_Set_Input = {
  created_ts?: InputMaybe<Scalars['timestamptz']>;
  from_dt?: InputMaybe<Scalars['date']>;
  location_id?: InputMaybe<Scalars['Int']>;
  location_x_testing_location_id?: InputMaybe<Scalars['bigint']>;
  testing_location_id?: InputMaybe<Scalars['Int']>;
  to_dt?: InputMaybe<Scalars['date']>;
  updated_ts?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Organizations_Testing_Locations_Stddev_Fields = {
  __typename?: 'organizations_testing_locations_stddev_fields';
  location_id?: Maybe<Scalars['Float']>;
  location_x_testing_location_id?: Maybe<Scalars['Float']>;
  testing_location_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Organizations_Testing_Locations_Stddev_Pop_Fields = {
  __typename?: 'organizations_testing_locations_stddev_pop_fields';
  location_id?: Maybe<Scalars['Float']>;
  location_x_testing_location_id?: Maybe<Scalars['Float']>;
  testing_location_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Organizations_Testing_Locations_Stddev_Samp_Fields = {
  __typename?: 'organizations_testing_locations_stddev_samp_fields';
  location_id?: Maybe<Scalars['Float']>;
  location_x_testing_location_id?: Maybe<Scalars['Float']>;
  testing_location_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Organizations_Testing_Locations_Sum_Fields = {
  __typename?: 'organizations_testing_locations_sum_fields';
  location_id?: Maybe<Scalars['Int']>;
  location_x_testing_location_id?: Maybe<Scalars['bigint']>;
  testing_location_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "organizations.location_x_testing_location" */
export enum Organizations_Testing_Locations_Update_Column {
  /** column name */
  CreatedTs = 'created_ts',
  /** column name */
  FromDt = 'from_dt',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  LocationXTestingLocationId = 'location_x_testing_location_id',
  /** column name */
  TestingLocationId = 'testing_location_id',
  /** column name */
  ToDt = 'to_dt',
  /** column name */
  UpdatedTs = 'updated_ts'
}

/** aggregate var_pop on columns */
export type Organizations_Testing_Locations_Var_Pop_Fields = {
  __typename?: 'organizations_testing_locations_var_pop_fields';
  location_id?: Maybe<Scalars['Float']>;
  location_x_testing_location_id?: Maybe<Scalars['Float']>;
  testing_location_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Organizations_Testing_Locations_Var_Samp_Fields = {
  __typename?: 'organizations_testing_locations_var_samp_fields';
  location_id?: Maybe<Scalars['Float']>;
  location_x_testing_location_id?: Maybe<Scalars['Float']>;
  testing_location_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Organizations_Testing_Locations_Variance_Fields = {
  __typename?: 'organizations_testing_locations_variance_fields';
  location_id?: Maybe<Scalars['Float']>;
  location_x_testing_location_id?: Maybe<Scalars['Float']>;
  testing_location_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "mat_tmp_fast_demographics" */
export type Patient_Demographics = {
  __typename?: 'patient_demographics';
  create_ts?: Maybe<Scalars['timestamptz']>;
  ec_address?: Maybe<Scalars['String']>;
  ec_address2?: Maybe<Scalars['String']>;
  ec_address_city?: Maybe<Scalars['String']>;
  ec_address_state?: Maybe<Scalars['String']>;
  ec_address_zip?: Maybe<Scalars['String']>;
  ec_first_name?: Maybe<Scalars['String']>;
  ec_last_name?: Maybe<Scalars['String']>;
  ec_middle_name?: Maybe<Scalars['String']>;
  ec_phone1?: Maybe<Scalars['String']>;
  ec_phone1_type?: Maybe<Scalars['String']>;
  ec_phone2?: Maybe<Scalars['String']>;
  ec_phone2_type?: Maybe<Scalars['String']>;
  guarantor_address?: Maybe<Scalars['String']>;
  guarantor_address2?: Maybe<Scalars['String']>;
  guarantor_address_city?: Maybe<Scalars['String']>;
  guarantor_address_state?: Maybe<Scalars['String']>;
  guarantor_address_zip?: Maybe<Scalars['String']>;
  guarantor_birth_sex?: Maybe<Scalars['String']>;
  guarantor_dl_or_state_id_location?: Maybe<Scalars['String']>;
  guarantor_dob?: Maybe<Scalars['date']>;
  guarantor_email?: Maybe<Scalars['String']>;
  guarantor_first_name?: Maybe<Scalars['String']>;
  guarantor_last_name?: Maybe<Scalars['String']>;
  guarantor_marital_status?: Maybe<Scalars['String']>;
  guarantor_middle_name?: Maybe<Scalars['String']>;
  guarantor_phone1?: Maybe<Scalars['String']>;
  guarantor_phone1_type?: Maybe<Scalars['String']>;
  guarantor_phone2?: Maybe<Scalars['String']>;
  guarantor_phone2_type?: Maybe<Scalars['String']>;
  guarantor_relation_to_patient?: Maybe<Scalars['String']>;
  hispanic_or_latino?: Maybe<Scalars['Boolean']>;
  is_patient_primary_policy_holder?: Maybe<Scalars['String']>;
  is_patient_secondary_policy_holder?: Maybe<Scalars['String']>;
  location_id?: Maybe<Scalars['Int']>;
  location_name?: Maybe<Scalars['String']>;
  master_id?: Maybe<Scalars['String']>;
  no_cost_visit?: Maybe<Scalars['Boolean']>;
  organization_id?: Maybe<Scalars['Int']>;
  patient_address?: Maybe<Scalars['String']>;
  patient_address2?: Maybe<Scalars['String']>;
  patient_address_city?: Maybe<Scalars['String']>;
  patient_address_state?: Maybe<Scalars['String']>;
  patient_address_zip?: Maybe<Scalars['String']>;
  patient_birth_sex?: Maybe<Scalars['String']>;
  patient_current_medications?: Maybe<Scalars['String']>;
  patient_dl_or_state_id_location?: Maybe<Scalars['String']>;
  patient_dob?: Maybe<Scalars['date']>;
  patient_email?: Maybe<Scalars['String']>;
  patient_email_allowed?: Maybe<Scalars['Boolean']>;
  patient_family_medical_history?: Maybe<Scalars['String']>;
  patient_first_name?: Maybe<Scalars['String']>;
  patient_is_pregnant?: Maybe<Scalars['Boolean']>;
  patient_known_allergies?: Maybe<Scalars['String']>;
  patient_known_conditions?: Maybe<Scalars['String']>;
  patient_last_name?: Maybe<Scalars['String']>;
  patient_marital_status?: Maybe<Scalars['String']>;
  patient_medical_history?: Maybe<Scalars['String']>;
  patient_middle_name?: Maybe<Scalars['String']>;
  patient_phone1?: Maybe<Scalars['String']>;
  patient_phone1_type?: Maybe<Scalars['String']>;
  patient_phone2?: Maybe<Scalars['String']>;
  patient_phone2_type?: Maybe<Scalars['String']>;
  patient_pref_pharmacy?: Maybe<Scalars['String']>;
  patient_pref_pharmacy_zip?: Maybe<Scalars['String']>;
  patient_race?: Maybe<Scalars['String']>;
  patient_surgical_history?: Maybe<Scalars['String']>;
  pcp_consent?: Maybe<Scalars['Boolean']>;
  pcp_prov_name?: Maybe<Scalars['String']>;
  pg_address?: Maybe<Scalars['String']>;
  pg_address2?: Maybe<Scalars['String']>;
  pg_address_city?: Maybe<Scalars['String']>;
  pg_address_state?: Maybe<Scalars['String']>;
  pg_address_zip?: Maybe<Scalars['String']>;
  pg_birth_sex?: Maybe<Scalars['String']>;
  pg_dl_or_state_id_location?: Maybe<Scalars['String']>;
  pg_dob?: Maybe<Scalars['date']>;
  pg_email?: Maybe<Scalars['String']>;
  pg_email_allowed?: Maybe<Scalars['Boolean']>;
  pg_first_name?: Maybe<Scalars['String']>;
  pg_last_name?: Maybe<Scalars['String']>;
  pg_marital_status?: Maybe<Scalars['String']>;
  pg_middle_name?: Maybe<Scalars['String']>;
  pg_phone1?: Maybe<Scalars['String']>;
  pg_phone1_type?: Maybe<Scalars['String']>;
  pg_phone2?: Maybe<Scalars['String']>;
  pg_phone2_type?: Maybe<Scalars['String']>;
  pg_relation_to_patient?: Maybe<Scalars['String']>;
  pond_id?: Maybe<Scalars['String']>;
  primary_ins_address_line1?: Maybe<Scalars['String']>;
  primary_ins_carrier?: Maybe<Scalars['String']>;
  primary_ins_group_id?: Maybe<Scalars['String']>;
  primary_ins_id?: Maybe<Scalars['String']>;
  primary_ins_ph_birth_sex?: Maybe<Scalars['String']>;
  primary_ins_ph_dob?: Maybe<Scalars['date']>;
  primary_ins_ph_first_name?: Maybe<Scalars['String']>;
  primary_ins_ph_last_name?: Maybe<Scalars['String']>;
  primary_ins_ph_middle_name?: Maybe<Scalars['String']>;
  primary_ins_ph_patient_relation?: Maybe<Scalars['String']>;
  primary_ins_ph_suffix?: Maybe<Scalars['String']>;
  primary_ins_phone?: Maybe<Scalars['String']>;
  primary_ins_policy_id?: Maybe<Scalars['String']>;
  programs?: Maybe<Scalars['_int4']>;
  secondary_ins_address_line1?: Maybe<Scalars['String']>;
  secondary_ins_carrier?: Maybe<Scalars['String']>;
  secondary_ins_group_number?: Maybe<Scalars['String']>;
  secondary_ins_ph_birth_sex?: Maybe<Scalars['String']>;
  secondary_ins_ph_dob?: Maybe<Scalars['date']>;
  secondary_ins_ph_first_name?: Maybe<Scalars['String']>;
  secondary_ins_ph_last_name?: Maybe<Scalars['String']>;
  secondary_ins_ph_middle_name?: Maybe<Scalars['String']>;
  secondary_ins_ph_patient_relation?: Maybe<Scalars['String']>;
  secondary_ins_ph_suffix?: Maybe<Scalars['String']>;
  secondary_ins_phone?: Maybe<Scalars['String']>;
  secondary_ins_policy_id?: Maybe<Scalars['String']>;
  student_id?: Maybe<Scalars['String']>;
  test_patient?: Maybe<Scalars['Boolean']>;
};

/** aggregated selection of "mat_tmp_fast_demographics" */
export type Patient_Demographics_Aggregate = {
  __typename?: 'patient_demographics_aggregate';
  aggregate?: Maybe<Patient_Demographics_Aggregate_Fields>;
  nodes: Array<Patient_Demographics>;
};

/** aggregate fields of "mat_tmp_fast_demographics" */
export type Patient_Demographics_Aggregate_Fields = {
  __typename?: 'patient_demographics_aggregate_fields';
  avg?: Maybe<Patient_Demographics_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Patient_Demographics_Max_Fields>;
  min?: Maybe<Patient_Demographics_Min_Fields>;
  stddev?: Maybe<Patient_Demographics_Stddev_Fields>;
  stddev_pop?: Maybe<Patient_Demographics_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Patient_Demographics_Stddev_Samp_Fields>;
  sum?: Maybe<Patient_Demographics_Sum_Fields>;
  var_pop?: Maybe<Patient_Demographics_Var_Pop_Fields>;
  var_samp?: Maybe<Patient_Demographics_Var_Samp_Fields>;
  variance?: Maybe<Patient_Demographics_Variance_Fields>;
};


/** aggregate fields of "mat_tmp_fast_demographics" */
export type Patient_Demographics_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Patient_Demographics_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Patient_Demographics_Avg_Fields = {
  __typename?: 'patient_demographics_avg_fields';
  location_id?: Maybe<Scalars['Float']>;
  organization_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "mat_tmp_fast_demographics". All fields are combined with a logical 'AND'. */
export type Patient_Demographics_Bool_Exp = {
  _and?: InputMaybe<Array<Patient_Demographics_Bool_Exp>>;
  _not?: InputMaybe<Patient_Demographics_Bool_Exp>;
  _or?: InputMaybe<Array<Patient_Demographics_Bool_Exp>>;
  create_ts?: InputMaybe<Timestamptz_Comparison_Exp>;
  ec_address?: InputMaybe<String_Comparison_Exp>;
  ec_address2?: InputMaybe<String_Comparison_Exp>;
  ec_address_city?: InputMaybe<String_Comparison_Exp>;
  ec_address_state?: InputMaybe<String_Comparison_Exp>;
  ec_address_zip?: InputMaybe<String_Comparison_Exp>;
  ec_first_name?: InputMaybe<String_Comparison_Exp>;
  ec_last_name?: InputMaybe<String_Comparison_Exp>;
  ec_middle_name?: InputMaybe<String_Comparison_Exp>;
  ec_phone1?: InputMaybe<String_Comparison_Exp>;
  ec_phone1_type?: InputMaybe<String_Comparison_Exp>;
  ec_phone2?: InputMaybe<String_Comparison_Exp>;
  ec_phone2_type?: InputMaybe<String_Comparison_Exp>;
  guarantor_address?: InputMaybe<String_Comparison_Exp>;
  guarantor_address2?: InputMaybe<String_Comparison_Exp>;
  guarantor_address_city?: InputMaybe<String_Comparison_Exp>;
  guarantor_address_state?: InputMaybe<String_Comparison_Exp>;
  guarantor_address_zip?: InputMaybe<String_Comparison_Exp>;
  guarantor_birth_sex?: InputMaybe<String_Comparison_Exp>;
  guarantor_dl_or_state_id_location?: InputMaybe<String_Comparison_Exp>;
  guarantor_dob?: InputMaybe<Date_Comparison_Exp>;
  guarantor_email?: InputMaybe<String_Comparison_Exp>;
  guarantor_first_name?: InputMaybe<String_Comparison_Exp>;
  guarantor_last_name?: InputMaybe<String_Comparison_Exp>;
  guarantor_marital_status?: InputMaybe<String_Comparison_Exp>;
  guarantor_middle_name?: InputMaybe<String_Comparison_Exp>;
  guarantor_phone1?: InputMaybe<String_Comparison_Exp>;
  guarantor_phone1_type?: InputMaybe<String_Comparison_Exp>;
  guarantor_phone2?: InputMaybe<String_Comparison_Exp>;
  guarantor_phone2_type?: InputMaybe<String_Comparison_Exp>;
  guarantor_relation_to_patient?: InputMaybe<String_Comparison_Exp>;
  hispanic_or_latino?: InputMaybe<Boolean_Comparison_Exp>;
  is_patient_primary_policy_holder?: InputMaybe<String_Comparison_Exp>;
  is_patient_secondary_policy_holder?: InputMaybe<String_Comparison_Exp>;
  location_id?: InputMaybe<Int_Comparison_Exp>;
  location_name?: InputMaybe<String_Comparison_Exp>;
  master_id?: InputMaybe<String_Comparison_Exp>;
  no_cost_visit?: InputMaybe<Boolean_Comparison_Exp>;
  organization_id?: InputMaybe<Int_Comparison_Exp>;
  patient_address?: InputMaybe<String_Comparison_Exp>;
  patient_address2?: InputMaybe<String_Comparison_Exp>;
  patient_address_city?: InputMaybe<String_Comparison_Exp>;
  patient_address_state?: InputMaybe<String_Comparison_Exp>;
  patient_address_zip?: InputMaybe<String_Comparison_Exp>;
  patient_birth_sex?: InputMaybe<String_Comparison_Exp>;
  patient_current_medications?: InputMaybe<String_Comparison_Exp>;
  patient_dl_or_state_id_location?: InputMaybe<String_Comparison_Exp>;
  patient_dob?: InputMaybe<Date_Comparison_Exp>;
  patient_email?: InputMaybe<String_Comparison_Exp>;
  patient_email_allowed?: InputMaybe<Boolean_Comparison_Exp>;
  patient_family_medical_history?: InputMaybe<String_Comparison_Exp>;
  patient_first_name?: InputMaybe<String_Comparison_Exp>;
  patient_is_pregnant?: InputMaybe<Boolean_Comparison_Exp>;
  patient_known_allergies?: InputMaybe<String_Comparison_Exp>;
  patient_known_conditions?: InputMaybe<String_Comparison_Exp>;
  patient_last_name?: InputMaybe<String_Comparison_Exp>;
  patient_marital_status?: InputMaybe<String_Comparison_Exp>;
  patient_medical_history?: InputMaybe<String_Comparison_Exp>;
  patient_middle_name?: InputMaybe<String_Comparison_Exp>;
  patient_phone1?: InputMaybe<String_Comparison_Exp>;
  patient_phone1_type?: InputMaybe<String_Comparison_Exp>;
  patient_phone2?: InputMaybe<String_Comparison_Exp>;
  patient_phone2_type?: InputMaybe<String_Comparison_Exp>;
  patient_pref_pharmacy?: InputMaybe<String_Comparison_Exp>;
  patient_pref_pharmacy_zip?: InputMaybe<String_Comparison_Exp>;
  patient_race?: InputMaybe<String_Comparison_Exp>;
  patient_surgical_history?: InputMaybe<String_Comparison_Exp>;
  pcp_consent?: InputMaybe<Boolean_Comparison_Exp>;
  pcp_prov_name?: InputMaybe<String_Comparison_Exp>;
  pg_address?: InputMaybe<String_Comparison_Exp>;
  pg_address2?: InputMaybe<String_Comparison_Exp>;
  pg_address_city?: InputMaybe<String_Comparison_Exp>;
  pg_address_state?: InputMaybe<String_Comparison_Exp>;
  pg_address_zip?: InputMaybe<String_Comparison_Exp>;
  pg_birth_sex?: InputMaybe<String_Comparison_Exp>;
  pg_dl_or_state_id_location?: InputMaybe<String_Comparison_Exp>;
  pg_dob?: InputMaybe<Date_Comparison_Exp>;
  pg_email?: InputMaybe<String_Comparison_Exp>;
  pg_email_allowed?: InputMaybe<Boolean_Comparison_Exp>;
  pg_first_name?: InputMaybe<String_Comparison_Exp>;
  pg_last_name?: InputMaybe<String_Comparison_Exp>;
  pg_marital_status?: InputMaybe<String_Comparison_Exp>;
  pg_middle_name?: InputMaybe<String_Comparison_Exp>;
  pg_phone1?: InputMaybe<String_Comparison_Exp>;
  pg_phone1_type?: InputMaybe<String_Comparison_Exp>;
  pg_phone2?: InputMaybe<String_Comparison_Exp>;
  pg_phone2_type?: InputMaybe<String_Comparison_Exp>;
  pg_relation_to_patient?: InputMaybe<String_Comparison_Exp>;
  pond_id?: InputMaybe<String_Comparison_Exp>;
  primary_ins_address_line1?: InputMaybe<String_Comparison_Exp>;
  primary_ins_carrier?: InputMaybe<String_Comparison_Exp>;
  primary_ins_group_id?: InputMaybe<String_Comparison_Exp>;
  primary_ins_id?: InputMaybe<String_Comparison_Exp>;
  primary_ins_ph_birth_sex?: InputMaybe<String_Comparison_Exp>;
  primary_ins_ph_dob?: InputMaybe<Date_Comparison_Exp>;
  primary_ins_ph_first_name?: InputMaybe<String_Comparison_Exp>;
  primary_ins_ph_last_name?: InputMaybe<String_Comparison_Exp>;
  primary_ins_ph_middle_name?: InputMaybe<String_Comparison_Exp>;
  primary_ins_ph_patient_relation?: InputMaybe<String_Comparison_Exp>;
  primary_ins_ph_suffix?: InputMaybe<String_Comparison_Exp>;
  primary_ins_phone?: InputMaybe<String_Comparison_Exp>;
  primary_ins_policy_id?: InputMaybe<String_Comparison_Exp>;
  programs?: InputMaybe<_Int4_Comparison_Exp>;
  secondary_ins_address_line1?: InputMaybe<String_Comparison_Exp>;
  secondary_ins_carrier?: InputMaybe<String_Comparison_Exp>;
  secondary_ins_group_number?: InputMaybe<String_Comparison_Exp>;
  secondary_ins_ph_birth_sex?: InputMaybe<String_Comparison_Exp>;
  secondary_ins_ph_dob?: InputMaybe<Date_Comparison_Exp>;
  secondary_ins_ph_first_name?: InputMaybe<String_Comparison_Exp>;
  secondary_ins_ph_last_name?: InputMaybe<String_Comparison_Exp>;
  secondary_ins_ph_middle_name?: InputMaybe<String_Comparison_Exp>;
  secondary_ins_ph_patient_relation?: InputMaybe<String_Comparison_Exp>;
  secondary_ins_ph_suffix?: InputMaybe<String_Comparison_Exp>;
  secondary_ins_phone?: InputMaybe<String_Comparison_Exp>;
  secondary_ins_policy_id?: InputMaybe<String_Comparison_Exp>;
  student_id?: InputMaybe<String_Comparison_Exp>;
  test_patient?: InputMaybe<Boolean_Comparison_Exp>;
};

/** unique or primary key constraints on table "mat_tmp_fast_demographics" */
export enum Patient_Demographics_Constraint {
  /** unique or primary key constraint */
  MatTmpFastDemographicsPondIdIdx = 'mat_tmp_fast_demographics_pond_id_idx'
}

/** input type for incrementing numeric columns in table "mat_tmp_fast_demographics" */
export type Patient_Demographics_Inc_Input = {
  location_id?: InputMaybe<Scalars['Int']>;
  organization_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "mat_tmp_fast_demographics" */
export type Patient_Demographics_Insert_Input = {
  create_ts?: InputMaybe<Scalars['timestamptz']>;
  ec_address?: InputMaybe<Scalars['String']>;
  ec_address2?: InputMaybe<Scalars['String']>;
  ec_address_city?: InputMaybe<Scalars['String']>;
  ec_address_state?: InputMaybe<Scalars['String']>;
  ec_address_zip?: InputMaybe<Scalars['String']>;
  ec_first_name?: InputMaybe<Scalars['String']>;
  ec_last_name?: InputMaybe<Scalars['String']>;
  ec_middle_name?: InputMaybe<Scalars['String']>;
  ec_phone1?: InputMaybe<Scalars['String']>;
  ec_phone1_type?: InputMaybe<Scalars['String']>;
  ec_phone2?: InputMaybe<Scalars['String']>;
  ec_phone2_type?: InputMaybe<Scalars['String']>;
  guarantor_address?: InputMaybe<Scalars['String']>;
  guarantor_address2?: InputMaybe<Scalars['String']>;
  guarantor_address_city?: InputMaybe<Scalars['String']>;
  guarantor_address_state?: InputMaybe<Scalars['String']>;
  guarantor_address_zip?: InputMaybe<Scalars['String']>;
  guarantor_birth_sex?: InputMaybe<Scalars['String']>;
  guarantor_dl_or_state_id_location?: InputMaybe<Scalars['String']>;
  guarantor_dob?: InputMaybe<Scalars['date']>;
  guarantor_email?: InputMaybe<Scalars['String']>;
  guarantor_first_name?: InputMaybe<Scalars['String']>;
  guarantor_last_name?: InputMaybe<Scalars['String']>;
  guarantor_marital_status?: InputMaybe<Scalars['String']>;
  guarantor_middle_name?: InputMaybe<Scalars['String']>;
  guarantor_phone1?: InputMaybe<Scalars['String']>;
  guarantor_phone1_type?: InputMaybe<Scalars['String']>;
  guarantor_phone2?: InputMaybe<Scalars['String']>;
  guarantor_phone2_type?: InputMaybe<Scalars['String']>;
  guarantor_relation_to_patient?: InputMaybe<Scalars['String']>;
  hispanic_or_latino?: InputMaybe<Scalars['Boolean']>;
  is_patient_primary_policy_holder?: InputMaybe<Scalars['String']>;
  is_patient_secondary_policy_holder?: InputMaybe<Scalars['String']>;
  location_id?: InputMaybe<Scalars['Int']>;
  location_name?: InputMaybe<Scalars['String']>;
  master_id?: InputMaybe<Scalars['String']>;
  no_cost_visit?: InputMaybe<Scalars['Boolean']>;
  organization_id?: InputMaybe<Scalars['Int']>;
  patient_address?: InputMaybe<Scalars['String']>;
  patient_address2?: InputMaybe<Scalars['String']>;
  patient_address_city?: InputMaybe<Scalars['String']>;
  patient_address_state?: InputMaybe<Scalars['String']>;
  patient_address_zip?: InputMaybe<Scalars['String']>;
  patient_birth_sex?: InputMaybe<Scalars['String']>;
  patient_current_medications?: InputMaybe<Scalars['String']>;
  patient_dl_or_state_id_location?: InputMaybe<Scalars['String']>;
  patient_dob?: InputMaybe<Scalars['date']>;
  patient_email?: InputMaybe<Scalars['String']>;
  patient_email_allowed?: InputMaybe<Scalars['Boolean']>;
  patient_family_medical_history?: InputMaybe<Scalars['String']>;
  patient_first_name?: InputMaybe<Scalars['String']>;
  patient_is_pregnant?: InputMaybe<Scalars['Boolean']>;
  patient_known_allergies?: InputMaybe<Scalars['String']>;
  patient_known_conditions?: InputMaybe<Scalars['String']>;
  patient_last_name?: InputMaybe<Scalars['String']>;
  patient_marital_status?: InputMaybe<Scalars['String']>;
  patient_medical_history?: InputMaybe<Scalars['String']>;
  patient_middle_name?: InputMaybe<Scalars['String']>;
  patient_phone1?: InputMaybe<Scalars['String']>;
  patient_phone1_type?: InputMaybe<Scalars['String']>;
  patient_phone2?: InputMaybe<Scalars['String']>;
  patient_phone2_type?: InputMaybe<Scalars['String']>;
  patient_pref_pharmacy?: InputMaybe<Scalars['String']>;
  patient_pref_pharmacy_zip?: InputMaybe<Scalars['String']>;
  patient_race?: InputMaybe<Scalars['String']>;
  patient_surgical_history?: InputMaybe<Scalars['String']>;
  pcp_consent?: InputMaybe<Scalars['Boolean']>;
  pcp_prov_name?: InputMaybe<Scalars['String']>;
  pg_address?: InputMaybe<Scalars['String']>;
  pg_address2?: InputMaybe<Scalars['String']>;
  pg_address_city?: InputMaybe<Scalars['String']>;
  pg_address_state?: InputMaybe<Scalars['String']>;
  pg_address_zip?: InputMaybe<Scalars['String']>;
  pg_birth_sex?: InputMaybe<Scalars['String']>;
  pg_dl_or_state_id_location?: InputMaybe<Scalars['String']>;
  pg_dob?: InputMaybe<Scalars['date']>;
  pg_email?: InputMaybe<Scalars['String']>;
  pg_email_allowed?: InputMaybe<Scalars['Boolean']>;
  pg_first_name?: InputMaybe<Scalars['String']>;
  pg_last_name?: InputMaybe<Scalars['String']>;
  pg_marital_status?: InputMaybe<Scalars['String']>;
  pg_middle_name?: InputMaybe<Scalars['String']>;
  pg_phone1?: InputMaybe<Scalars['String']>;
  pg_phone1_type?: InputMaybe<Scalars['String']>;
  pg_phone2?: InputMaybe<Scalars['String']>;
  pg_phone2_type?: InputMaybe<Scalars['String']>;
  pg_relation_to_patient?: InputMaybe<Scalars['String']>;
  pond_id?: InputMaybe<Scalars['String']>;
  primary_ins_address_line1?: InputMaybe<Scalars['String']>;
  primary_ins_carrier?: InputMaybe<Scalars['String']>;
  primary_ins_group_id?: InputMaybe<Scalars['String']>;
  primary_ins_id?: InputMaybe<Scalars['String']>;
  primary_ins_ph_birth_sex?: InputMaybe<Scalars['String']>;
  primary_ins_ph_dob?: InputMaybe<Scalars['date']>;
  primary_ins_ph_first_name?: InputMaybe<Scalars['String']>;
  primary_ins_ph_last_name?: InputMaybe<Scalars['String']>;
  primary_ins_ph_middle_name?: InputMaybe<Scalars['String']>;
  primary_ins_ph_patient_relation?: InputMaybe<Scalars['String']>;
  primary_ins_ph_suffix?: InputMaybe<Scalars['String']>;
  primary_ins_phone?: InputMaybe<Scalars['String']>;
  primary_ins_policy_id?: InputMaybe<Scalars['String']>;
  programs?: InputMaybe<Scalars['_int4']>;
  secondary_ins_address_line1?: InputMaybe<Scalars['String']>;
  secondary_ins_carrier?: InputMaybe<Scalars['String']>;
  secondary_ins_group_number?: InputMaybe<Scalars['String']>;
  secondary_ins_ph_birth_sex?: InputMaybe<Scalars['String']>;
  secondary_ins_ph_dob?: InputMaybe<Scalars['date']>;
  secondary_ins_ph_first_name?: InputMaybe<Scalars['String']>;
  secondary_ins_ph_last_name?: InputMaybe<Scalars['String']>;
  secondary_ins_ph_middle_name?: InputMaybe<Scalars['String']>;
  secondary_ins_ph_patient_relation?: InputMaybe<Scalars['String']>;
  secondary_ins_ph_suffix?: InputMaybe<Scalars['String']>;
  secondary_ins_phone?: InputMaybe<Scalars['String']>;
  secondary_ins_policy_id?: InputMaybe<Scalars['String']>;
  student_id?: InputMaybe<Scalars['String']>;
  test_patient?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate max on columns */
export type Patient_Demographics_Max_Fields = {
  __typename?: 'patient_demographics_max_fields';
  create_ts?: Maybe<Scalars['timestamptz']>;
  ec_address?: Maybe<Scalars['String']>;
  ec_address2?: Maybe<Scalars['String']>;
  ec_address_city?: Maybe<Scalars['String']>;
  ec_address_state?: Maybe<Scalars['String']>;
  ec_address_zip?: Maybe<Scalars['String']>;
  ec_first_name?: Maybe<Scalars['String']>;
  ec_last_name?: Maybe<Scalars['String']>;
  ec_middle_name?: Maybe<Scalars['String']>;
  ec_phone1?: Maybe<Scalars['String']>;
  ec_phone1_type?: Maybe<Scalars['String']>;
  ec_phone2?: Maybe<Scalars['String']>;
  ec_phone2_type?: Maybe<Scalars['String']>;
  guarantor_address?: Maybe<Scalars['String']>;
  guarantor_address2?: Maybe<Scalars['String']>;
  guarantor_address_city?: Maybe<Scalars['String']>;
  guarantor_address_state?: Maybe<Scalars['String']>;
  guarantor_address_zip?: Maybe<Scalars['String']>;
  guarantor_birth_sex?: Maybe<Scalars['String']>;
  guarantor_dl_or_state_id_location?: Maybe<Scalars['String']>;
  guarantor_dob?: Maybe<Scalars['date']>;
  guarantor_email?: Maybe<Scalars['String']>;
  guarantor_first_name?: Maybe<Scalars['String']>;
  guarantor_last_name?: Maybe<Scalars['String']>;
  guarantor_marital_status?: Maybe<Scalars['String']>;
  guarantor_middle_name?: Maybe<Scalars['String']>;
  guarantor_phone1?: Maybe<Scalars['String']>;
  guarantor_phone1_type?: Maybe<Scalars['String']>;
  guarantor_phone2?: Maybe<Scalars['String']>;
  guarantor_phone2_type?: Maybe<Scalars['String']>;
  guarantor_relation_to_patient?: Maybe<Scalars['String']>;
  is_patient_primary_policy_holder?: Maybe<Scalars['String']>;
  is_patient_secondary_policy_holder?: Maybe<Scalars['String']>;
  location_id?: Maybe<Scalars['Int']>;
  location_name?: Maybe<Scalars['String']>;
  master_id?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['Int']>;
  patient_address?: Maybe<Scalars['String']>;
  patient_address2?: Maybe<Scalars['String']>;
  patient_address_city?: Maybe<Scalars['String']>;
  patient_address_state?: Maybe<Scalars['String']>;
  patient_address_zip?: Maybe<Scalars['String']>;
  patient_birth_sex?: Maybe<Scalars['String']>;
  patient_current_medications?: Maybe<Scalars['String']>;
  patient_dl_or_state_id_location?: Maybe<Scalars['String']>;
  patient_dob?: Maybe<Scalars['date']>;
  patient_email?: Maybe<Scalars['String']>;
  patient_family_medical_history?: Maybe<Scalars['String']>;
  patient_first_name?: Maybe<Scalars['String']>;
  patient_known_allergies?: Maybe<Scalars['String']>;
  patient_known_conditions?: Maybe<Scalars['String']>;
  patient_last_name?: Maybe<Scalars['String']>;
  patient_marital_status?: Maybe<Scalars['String']>;
  patient_medical_history?: Maybe<Scalars['String']>;
  patient_middle_name?: Maybe<Scalars['String']>;
  patient_phone1?: Maybe<Scalars['String']>;
  patient_phone1_type?: Maybe<Scalars['String']>;
  patient_phone2?: Maybe<Scalars['String']>;
  patient_phone2_type?: Maybe<Scalars['String']>;
  patient_pref_pharmacy?: Maybe<Scalars['String']>;
  patient_pref_pharmacy_zip?: Maybe<Scalars['String']>;
  patient_race?: Maybe<Scalars['String']>;
  patient_surgical_history?: Maybe<Scalars['String']>;
  pcp_prov_name?: Maybe<Scalars['String']>;
  pg_address?: Maybe<Scalars['String']>;
  pg_address2?: Maybe<Scalars['String']>;
  pg_address_city?: Maybe<Scalars['String']>;
  pg_address_state?: Maybe<Scalars['String']>;
  pg_address_zip?: Maybe<Scalars['String']>;
  pg_birth_sex?: Maybe<Scalars['String']>;
  pg_dl_or_state_id_location?: Maybe<Scalars['String']>;
  pg_dob?: Maybe<Scalars['date']>;
  pg_email?: Maybe<Scalars['String']>;
  pg_first_name?: Maybe<Scalars['String']>;
  pg_last_name?: Maybe<Scalars['String']>;
  pg_marital_status?: Maybe<Scalars['String']>;
  pg_middle_name?: Maybe<Scalars['String']>;
  pg_phone1?: Maybe<Scalars['String']>;
  pg_phone1_type?: Maybe<Scalars['String']>;
  pg_phone2?: Maybe<Scalars['String']>;
  pg_phone2_type?: Maybe<Scalars['String']>;
  pg_relation_to_patient?: Maybe<Scalars['String']>;
  pond_id?: Maybe<Scalars['String']>;
  primary_ins_address_line1?: Maybe<Scalars['String']>;
  primary_ins_carrier?: Maybe<Scalars['String']>;
  primary_ins_group_id?: Maybe<Scalars['String']>;
  primary_ins_id?: Maybe<Scalars['String']>;
  primary_ins_ph_birth_sex?: Maybe<Scalars['String']>;
  primary_ins_ph_dob?: Maybe<Scalars['date']>;
  primary_ins_ph_first_name?: Maybe<Scalars['String']>;
  primary_ins_ph_last_name?: Maybe<Scalars['String']>;
  primary_ins_ph_middle_name?: Maybe<Scalars['String']>;
  primary_ins_ph_patient_relation?: Maybe<Scalars['String']>;
  primary_ins_ph_suffix?: Maybe<Scalars['String']>;
  primary_ins_phone?: Maybe<Scalars['String']>;
  primary_ins_policy_id?: Maybe<Scalars['String']>;
  secondary_ins_address_line1?: Maybe<Scalars['String']>;
  secondary_ins_carrier?: Maybe<Scalars['String']>;
  secondary_ins_group_number?: Maybe<Scalars['String']>;
  secondary_ins_ph_birth_sex?: Maybe<Scalars['String']>;
  secondary_ins_ph_dob?: Maybe<Scalars['date']>;
  secondary_ins_ph_first_name?: Maybe<Scalars['String']>;
  secondary_ins_ph_last_name?: Maybe<Scalars['String']>;
  secondary_ins_ph_middle_name?: Maybe<Scalars['String']>;
  secondary_ins_ph_patient_relation?: Maybe<Scalars['String']>;
  secondary_ins_ph_suffix?: Maybe<Scalars['String']>;
  secondary_ins_phone?: Maybe<Scalars['String']>;
  secondary_ins_policy_id?: Maybe<Scalars['String']>;
  student_id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Patient_Demographics_Min_Fields = {
  __typename?: 'patient_demographics_min_fields';
  create_ts?: Maybe<Scalars['timestamptz']>;
  ec_address?: Maybe<Scalars['String']>;
  ec_address2?: Maybe<Scalars['String']>;
  ec_address_city?: Maybe<Scalars['String']>;
  ec_address_state?: Maybe<Scalars['String']>;
  ec_address_zip?: Maybe<Scalars['String']>;
  ec_first_name?: Maybe<Scalars['String']>;
  ec_last_name?: Maybe<Scalars['String']>;
  ec_middle_name?: Maybe<Scalars['String']>;
  ec_phone1?: Maybe<Scalars['String']>;
  ec_phone1_type?: Maybe<Scalars['String']>;
  ec_phone2?: Maybe<Scalars['String']>;
  ec_phone2_type?: Maybe<Scalars['String']>;
  guarantor_address?: Maybe<Scalars['String']>;
  guarantor_address2?: Maybe<Scalars['String']>;
  guarantor_address_city?: Maybe<Scalars['String']>;
  guarantor_address_state?: Maybe<Scalars['String']>;
  guarantor_address_zip?: Maybe<Scalars['String']>;
  guarantor_birth_sex?: Maybe<Scalars['String']>;
  guarantor_dl_or_state_id_location?: Maybe<Scalars['String']>;
  guarantor_dob?: Maybe<Scalars['date']>;
  guarantor_email?: Maybe<Scalars['String']>;
  guarantor_first_name?: Maybe<Scalars['String']>;
  guarantor_last_name?: Maybe<Scalars['String']>;
  guarantor_marital_status?: Maybe<Scalars['String']>;
  guarantor_middle_name?: Maybe<Scalars['String']>;
  guarantor_phone1?: Maybe<Scalars['String']>;
  guarantor_phone1_type?: Maybe<Scalars['String']>;
  guarantor_phone2?: Maybe<Scalars['String']>;
  guarantor_phone2_type?: Maybe<Scalars['String']>;
  guarantor_relation_to_patient?: Maybe<Scalars['String']>;
  is_patient_primary_policy_holder?: Maybe<Scalars['String']>;
  is_patient_secondary_policy_holder?: Maybe<Scalars['String']>;
  location_id?: Maybe<Scalars['Int']>;
  location_name?: Maybe<Scalars['String']>;
  master_id?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['Int']>;
  patient_address?: Maybe<Scalars['String']>;
  patient_address2?: Maybe<Scalars['String']>;
  patient_address_city?: Maybe<Scalars['String']>;
  patient_address_state?: Maybe<Scalars['String']>;
  patient_address_zip?: Maybe<Scalars['String']>;
  patient_birth_sex?: Maybe<Scalars['String']>;
  patient_current_medications?: Maybe<Scalars['String']>;
  patient_dl_or_state_id_location?: Maybe<Scalars['String']>;
  patient_dob?: Maybe<Scalars['date']>;
  patient_email?: Maybe<Scalars['String']>;
  patient_family_medical_history?: Maybe<Scalars['String']>;
  patient_first_name?: Maybe<Scalars['String']>;
  patient_known_allergies?: Maybe<Scalars['String']>;
  patient_known_conditions?: Maybe<Scalars['String']>;
  patient_last_name?: Maybe<Scalars['String']>;
  patient_marital_status?: Maybe<Scalars['String']>;
  patient_medical_history?: Maybe<Scalars['String']>;
  patient_middle_name?: Maybe<Scalars['String']>;
  patient_phone1?: Maybe<Scalars['String']>;
  patient_phone1_type?: Maybe<Scalars['String']>;
  patient_phone2?: Maybe<Scalars['String']>;
  patient_phone2_type?: Maybe<Scalars['String']>;
  patient_pref_pharmacy?: Maybe<Scalars['String']>;
  patient_pref_pharmacy_zip?: Maybe<Scalars['String']>;
  patient_race?: Maybe<Scalars['String']>;
  patient_surgical_history?: Maybe<Scalars['String']>;
  pcp_prov_name?: Maybe<Scalars['String']>;
  pg_address?: Maybe<Scalars['String']>;
  pg_address2?: Maybe<Scalars['String']>;
  pg_address_city?: Maybe<Scalars['String']>;
  pg_address_state?: Maybe<Scalars['String']>;
  pg_address_zip?: Maybe<Scalars['String']>;
  pg_birth_sex?: Maybe<Scalars['String']>;
  pg_dl_or_state_id_location?: Maybe<Scalars['String']>;
  pg_dob?: Maybe<Scalars['date']>;
  pg_email?: Maybe<Scalars['String']>;
  pg_first_name?: Maybe<Scalars['String']>;
  pg_last_name?: Maybe<Scalars['String']>;
  pg_marital_status?: Maybe<Scalars['String']>;
  pg_middle_name?: Maybe<Scalars['String']>;
  pg_phone1?: Maybe<Scalars['String']>;
  pg_phone1_type?: Maybe<Scalars['String']>;
  pg_phone2?: Maybe<Scalars['String']>;
  pg_phone2_type?: Maybe<Scalars['String']>;
  pg_relation_to_patient?: Maybe<Scalars['String']>;
  pond_id?: Maybe<Scalars['String']>;
  primary_ins_address_line1?: Maybe<Scalars['String']>;
  primary_ins_carrier?: Maybe<Scalars['String']>;
  primary_ins_group_id?: Maybe<Scalars['String']>;
  primary_ins_id?: Maybe<Scalars['String']>;
  primary_ins_ph_birth_sex?: Maybe<Scalars['String']>;
  primary_ins_ph_dob?: Maybe<Scalars['date']>;
  primary_ins_ph_first_name?: Maybe<Scalars['String']>;
  primary_ins_ph_last_name?: Maybe<Scalars['String']>;
  primary_ins_ph_middle_name?: Maybe<Scalars['String']>;
  primary_ins_ph_patient_relation?: Maybe<Scalars['String']>;
  primary_ins_ph_suffix?: Maybe<Scalars['String']>;
  primary_ins_phone?: Maybe<Scalars['String']>;
  primary_ins_policy_id?: Maybe<Scalars['String']>;
  secondary_ins_address_line1?: Maybe<Scalars['String']>;
  secondary_ins_carrier?: Maybe<Scalars['String']>;
  secondary_ins_group_number?: Maybe<Scalars['String']>;
  secondary_ins_ph_birth_sex?: Maybe<Scalars['String']>;
  secondary_ins_ph_dob?: Maybe<Scalars['date']>;
  secondary_ins_ph_first_name?: Maybe<Scalars['String']>;
  secondary_ins_ph_last_name?: Maybe<Scalars['String']>;
  secondary_ins_ph_middle_name?: Maybe<Scalars['String']>;
  secondary_ins_ph_patient_relation?: Maybe<Scalars['String']>;
  secondary_ins_ph_suffix?: Maybe<Scalars['String']>;
  secondary_ins_phone?: Maybe<Scalars['String']>;
  secondary_ins_policy_id?: Maybe<Scalars['String']>;
  student_id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "mat_tmp_fast_demographics" */
export type Patient_Demographics_Mutation_Response = {
  __typename?: 'patient_demographics_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Patient_Demographics>;
};

/** on conflict condition type for table "mat_tmp_fast_demographics" */
export type Patient_Demographics_On_Conflict = {
  constraint: Patient_Demographics_Constraint;
  update_columns?: Array<Patient_Demographics_Update_Column>;
  where?: InputMaybe<Patient_Demographics_Bool_Exp>;
};

/** Ordering options when selecting data from "mat_tmp_fast_demographics". */
export type Patient_Demographics_Order_By = {
  create_ts?: InputMaybe<Order_By>;
  ec_address?: InputMaybe<Order_By>;
  ec_address2?: InputMaybe<Order_By>;
  ec_address_city?: InputMaybe<Order_By>;
  ec_address_state?: InputMaybe<Order_By>;
  ec_address_zip?: InputMaybe<Order_By>;
  ec_first_name?: InputMaybe<Order_By>;
  ec_last_name?: InputMaybe<Order_By>;
  ec_middle_name?: InputMaybe<Order_By>;
  ec_phone1?: InputMaybe<Order_By>;
  ec_phone1_type?: InputMaybe<Order_By>;
  ec_phone2?: InputMaybe<Order_By>;
  ec_phone2_type?: InputMaybe<Order_By>;
  guarantor_address?: InputMaybe<Order_By>;
  guarantor_address2?: InputMaybe<Order_By>;
  guarantor_address_city?: InputMaybe<Order_By>;
  guarantor_address_state?: InputMaybe<Order_By>;
  guarantor_address_zip?: InputMaybe<Order_By>;
  guarantor_birth_sex?: InputMaybe<Order_By>;
  guarantor_dl_or_state_id_location?: InputMaybe<Order_By>;
  guarantor_dob?: InputMaybe<Order_By>;
  guarantor_email?: InputMaybe<Order_By>;
  guarantor_first_name?: InputMaybe<Order_By>;
  guarantor_last_name?: InputMaybe<Order_By>;
  guarantor_marital_status?: InputMaybe<Order_By>;
  guarantor_middle_name?: InputMaybe<Order_By>;
  guarantor_phone1?: InputMaybe<Order_By>;
  guarantor_phone1_type?: InputMaybe<Order_By>;
  guarantor_phone2?: InputMaybe<Order_By>;
  guarantor_phone2_type?: InputMaybe<Order_By>;
  guarantor_relation_to_patient?: InputMaybe<Order_By>;
  hispanic_or_latino?: InputMaybe<Order_By>;
  is_patient_primary_policy_holder?: InputMaybe<Order_By>;
  is_patient_secondary_policy_holder?: InputMaybe<Order_By>;
  location_id?: InputMaybe<Order_By>;
  location_name?: InputMaybe<Order_By>;
  master_id?: InputMaybe<Order_By>;
  no_cost_visit?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  patient_address?: InputMaybe<Order_By>;
  patient_address2?: InputMaybe<Order_By>;
  patient_address_city?: InputMaybe<Order_By>;
  patient_address_state?: InputMaybe<Order_By>;
  patient_address_zip?: InputMaybe<Order_By>;
  patient_birth_sex?: InputMaybe<Order_By>;
  patient_current_medications?: InputMaybe<Order_By>;
  patient_dl_or_state_id_location?: InputMaybe<Order_By>;
  patient_dob?: InputMaybe<Order_By>;
  patient_email?: InputMaybe<Order_By>;
  patient_email_allowed?: InputMaybe<Order_By>;
  patient_family_medical_history?: InputMaybe<Order_By>;
  patient_first_name?: InputMaybe<Order_By>;
  patient_is_pregnant?: InputMaybe<Order_By>;
  patient_known_allergies?: InputMaybe<Order_By>;
  patient_known_conditions?: InputMaybe<Order_By>;
  patient_last_name?: InputMaybe<Order_By>;
  patient_marital_status?: InputMaybe<Order_By>;
  patient_medical_history?: InputMaybe<Order_By>;
  patient_middle_name?: InputMaybe<Order_By>;
  patient_phone1?: InputMaybe<Order_By>;
  patient_phone1_type?: InputMaybe<Order_By>;
  patient_phone2?: InputMaybe<Order_By>;
  patient_phone2_type?: InputMaybe<Order_By>;
  patient_pref_pharmacy?: InputMaybe<Order_By>;
  patient_pref_pharmacy_zip?: InputMaybe<Order_By>;
  patient_race?: InputMaybe<Order_By>;
  patient_surgical_history?: InputMaybe<Order_By>;
  pcp_consent?: InputMaybe<Order_By>;
  pcp_prov_name?: InputMaybe<Order_By>;
  pg_address?: InputMaybe<Order_By>;
  pg_address2?: InputMaybe<Order_By>;
  pg_address_city?: InputMaybe<Order_By>;
  pg_address_state?: InputMaybe<Order_By>;
  pg_address_zip?: InputMaybe<Order_By>;
  pg_birth_sex?: InputMaybe<Order_By>;
  pg_dl_or_state_id_location?: InputMaybe<Order_By>;
  pg_dob?: InputMaybe<Order_By>;
  pg_email?: InputMaybe<Order_By>;
  pg_email_allowed?: InputMaybe<Order_By>;
  pg_first_name?: InputMaybe<Order_By>;
  pg_last_name?: InputMaybe<Order_By>;
  pg_marital_status?: InputMaybe<Order_By>;
  pg_middle_name?: InputMaybe<Order_By>;
  pg_phone1?: InputMaybe<Order_By>;
  pg_phone1_type?: InputMaybe<Order_By>;
  pg_phone2?: InputMaybe<Order_By>;
  pg_phone2_type?: InputMaybe<Order_By>;
  pg_relation_to_patient?: InputMaybe<Order_By>;
  pond_id?: InputMaybe<Order_By>;
  primary_ins_address_line1?: InputMaybe<Order_By>;
  primary_ins_carrier?: InputMaybe<Order_By>;
  primary_ins_group_id?: InputMaybe<Order_By>;
  primary_ins_id?: InputMaybe<Order_By>;
  primary_ins_ph_birth_sex?: InputMaybe<Order_By>;
  primary_ins_ph_dob?: InputMaybe<Order_By>;
  primary_ins_ph_first_name?: InputMaybe<Order_By>;
  primary_ins_ph_last_name?: InputMaybe<Order_By>;
  primary_ins_ph_middle_name?: InputMaybe<Order_By>;
  primary_ins_ph_patient_relation?: InputMaybe<Order_By>;
  primary_ins_ph_suffix?: InputMaybe<Order_By>;
  primary_ins_phone?: InputMaybe<Order_By>;
  primary_ins_policy_id?: InputMaybe<Order_By>;
  programs?: InputMaybe<Order_By>;
  secondary_ins_address_line1?: InputMaybe<Order_By>;
  secondary_ins_carrier?: InputMaybe<Order_By>;
  secondary_ins_group_number?: InputMaybe<Order_By>;
  secondary_ins_ph_birth_sex?: InputMaybe<Order_By>;
  secondary_ins_ph_dob?: InputMaybe<Order_By>;
  secondary_ins_ph_first_name?: InputMaybe<Order_By>;
  secondary_ins_ph_last_name?: InputMaybe<Order_By>;
  secondary_ins_ph_middle_name?: InputMaybe<Order_By>;
  secondary_ins_ph_patient_relation?: InputMaybe<Order_By>;
  secondary_ins_ph_suffix?: InputMaybe<Order_By>;
  secondary_ins_phone?: InputMaybe<Order_By>;
  secondary_ins_policy_id?: InputMaybe<Order_By>;
  student_id?: InputMaybe<Order_By>;
  test_patient?: InputMaybe<Order_By>;
};

/** select columns of table "mat_tmp_fast_demographics" */
export enum Patient_Demographics_Select_Column {
  /** column name */
  CreateTs = 'create_ts',
  /** column name */
  EcAddress = 'ec_address',
  /** column name */
  EcAddress2 = 'ec_address2',
  /** column name */
  EcAddressCity = 'ec_address_city',
  /** column name */
  EcAddressState = 'ec_address_state',
  /** column name */
  EcAddressZip = 'ec_address_zip',
  /** column name */
  EcFirstName = 'ec_first_name',
  /** column name */
  EcLastName = 'ec_last_name',
  /** column name */
  EcMiddleName = 'ec_middle_name',
  /** column name */
  EcPhone1 = 'ec_phone1',
  /** column name */
  EcPhone1Type = 'ec_phone1_type',
  /** column name */
  EcPhone2 = 'ec_phone2',
  /** column name */
  EcPhone2Type = 'ec_phone2_type',
  /** column name */
  GuarantorAddress = 'guarantor_address',
  /** column name */
  GuarantorAddress2 = 'guarantor_address2',
  /** column name */
  GuarantorAddressCity = 'guarantor_address_city',
  /** column name */
  GuarantorAddressState = 'guarantor_address_state',
  /** column name */
  GuarantorAddressZip = 'guarantor_address_zip',
  /** column name */
  GuarantorBirthSex = 'guarantor_birth_sex',
  /** column name */
  GuarantorDlOrStateIdLocation = 'guarantor_dl_or_state_id_location',
  /** column name */
  GuarantorDob = 'guarantor_dob',
  /** column name */
  GuarantorEmail = 'guarantor_email',
  /** column name */
  GuarantorFirstName = 'guarantor_first_name',
  /** column name */
  GuarantorLastName = 'guarantor_last_name',
  /** column name */
  GuarantorMaritalStatus = 'guarantor_marital_status',
  /** column name */
  GuarantorMiddleName = 'guarantor_middle_name',
  /** column name */
  GuarantorPhone1 = 'guarantor_phone1',
  /** column name */
  GuarantorPhone1Type = 'guarantor_phone1_type',
  /** column name */
  GuarantorPhone2 = 'guarantor_phone2',
  /** column name */
  GuarantorPhone2Type = 'guarantor_phone2_type',
  /** column name */
  GuarantorRelationToPatient = 'guarantor_relation_to_patient',
  /** column name */
  HispanicOrLatino = 'hispanic_or_latino',
  /** column name */
  IsPatientPrimaryPolicyHolder = 'is_patient_primary_policy_holder',
  /** column name */
  IsPatientSecondaryPolicyHolder = 'is_patient_secondary_policy_holder',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  LocationName = 'location_name',
  /** column name */
  MasterId = 'master_id',
  /** column name */
  NoCostVisit = 'no_cost_visit',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  PatientAddress = 'patient_address',
  /** column name */
  PatientAddress2 = 'patient_address2',
  /** column name */
  PatientAddressCity = 'patient_address_city',
  /** column name */
  PatientAddressState = 'patient_address_state',
  /** column name */
  PatientAddressZip = 'patient_address_zip',
  /** column name */
  PatientBirthSex = 'patient_birth_sex',
  /** column name */
  PatientCurrentMedications = 'patient_current_medications',
  /** column name */
  PatientDlOrStateIdLocation = 'patient_dl_or_state_id_location',
  /** column name */
  PatientDob = 'patient_dob',
  /** column name */
  PatientEmail = 'patient_email',
  /** column name */
  PatientEmailAllowed = 'patient_email_allowed',
  /** column name */
  PatientFamilyMedicalHistory = 'patient_family_medical_history',
  /** column name */
  PatientFirstName = 'patient_first_name',
  /** column name */
  PatientIsPregnant = 'patient_is_pregnant',
  /** column name */
  PatientKnownAllergies = 'patient_known_allergies',
  /** column name */
  PatientKnownConditions = 'patient_known_conditions',
  /** column name */
  PatientLastName = 'patient_last_name',
  /** column name */
  PatientMaritalStatus = 'patient_marital_status',
  /** column name */
  PatientMedicalHistory = 'patient_medical_history',
  /** column name */
  PatientMiddleName = 'patient_middle_name',
  /** column name */
  PatientPhone1 = 'patient_phone1',
  /** column name */
  PatientPhone1Type = 'patient_phone1_type',
  /** column name */
  PatientPhone2 = 'patient_phone2',
  /** column name */
  PatientPhone2Type = 'patient_phone2_type',
  /** column name */
  PatientPrefPharmacy = 'patient_pref_pharmacy',
  /** column name */
  PatientPrefPharmacyZip = 'patient_pref_pharmacy_zip',
  /** column name */
  PatientRace = 'patient_race',
  /** column name */
  PatientSurgicalHistory = 'patient_surgical_history',
  /** column name */
  PcpConsent = 'pcp_consent',
  /** column name */
  PcpProvName = 'pcp_prov_name',
  /** column name */
  PgAddress = 'pg_address',
  /** column name */
  PgAddress2 = 'pg_address2',
  /** column name */
  PgAddressCity = 'pg_address_city',
  /** column name */
  PgAddressState = 'pg_address_state',
  /** column name */
  PgAddressZip = 'pg_address_zip',
  /** column name */
  PgBirthSex = 'pg_birth_sex',
  /** column name */
  PgDlOrStateIdLocation = 'pg_dl_or_state_id_location',
  /** column name */
  PgDob = 'pg_dob',
  /** column name */
  PgEmail = 'pg_email',
  /** column name */
  PgEmailAllowed = 'pg_email_allowed',
  /** column name */
  PgFirstName = 'pg_first_name',
  /** column name */
  PgLastName = 'pg_last_name',
  /** column name */
  PgMaritalStatus = 'pg_marital_status',
  /** column name */
  PgMiddleName = 'pg_middle_name',
  /** column name */
  PgPhone1 = 'pg_phone1',
  /** column name */
  PgPhone1Type = 'pg_phone1_type',
  /** column name */
  PgPhone2 = 'pg_phone2',
  /** column name */
  PgPhone2Type = 'pg_phone2_type',
  /** column name */
  PgRelationToPatient = 'pg_relation_to_patient',
  /** column name */
  PondId = 'pond_id',
  /** column name */
  PrimaryInsAddressLine1 = 'primary_ins_address_line1',
  /** column name */
  PrimaryInsCarrier = 'primary_ins_carrier',
  /** column name */
  PrimaryInsGroupId = 'primary_ins_group_id',
  /** column name */
  PrimaryInsId = 'primary_ins_id',
  /** column name */
  PrimaryInsPhBirthSex = 'primary_ins_ph_birth_sex',
  /** column name */
  PrimaryInsPhDob = 'primary_ins_ph_dob',
  /** column name */
  PrimaryInsPhFirstName = 'primary_ins_ph_first_name',
  /** column name */
  PrimaryInsPhLastName = 'primary_ins_ph_last_name',
  /** column name */
  PrimaryInsPhMiddleName = 'primary_ins_ph_middle_name',
  /** column name */
  PrimaryInsPhPatientRelation = 'primary_ins_ph_patient_relation',
  /** column name */
  PrimaryInsPhSuffix = 'primary_ins_ph_suffix',
  /** column name */
  PrimaryInsPhone = 'primary_ins_phone',
  /** column name */
  PrimaryInsPolicyId = 'primary_ins_policy_id',
  /** column name */
  Programs = 'programs',
  /** column name */
  SecondaryInsAddressLine1 = 'secondary_ins_address_line1',
  /** column name */
  SecondaryInsCarrier = 'secondary_ins_carrier',
  /** column name */
  SecondaryInsGroupNumber = 'secondary_ins_group_number',
  /** column name */
  SecondaryInsPhBirthSex = 'secondary_ins_ph_birth_sex',
  /** column name */
  SecondaryInsPhDob = 'secondary_ins_ph_dob',
  /** column name */
  SecondaryInsPhFirstName = 'secondary_ins_ph_first_name',
  /** column name */
  SecondaryInsPhLastName = 'secondary_ins_ph_last_name',
  /** column name */
  SecondaryInsPhMiddleName = 'secondary_ins_ph_middle_name',
  /** column name */
  SecondaryInsPhPatientRelation = 'secondary_ins_ph_patient_relation',
  /** column name */
  SecondaryInsPhSuffix = 'secondary_ins_ph_suffix',
  /** column name */
  SecondaryInsPhone = 'secondary_ins_phone',
  /** column name */
  SecondaryInsPolicyId = 'secondary_ins_policy_id',
  /** column name */
  StudentId = 'student_id',
  /** column name */
  TestPatient = 'test_patient'
}

/** input type for updating data in table "mat_tmp_fast_demographics" */
export type Patient_Demographics_Set_Input = {
  create_ts?: InputMaybe<Scalars['timestamptz']>;
  ec_address?: InputMaybe<Scalars['String']>;
  ec_address2?: InputMaybe<Scalars['String']>;
  ec_address_city?: InputMaybe<Scalars['String']>;
  ec_address_state?: InputMaybe<Scalars['String']>;
  ec_address_zip?: InputMaybe<Scalars['String']>;
  ec_first_name?: InputMaybe<Scalars['String']>;
  ec_last_name?: InputMaybe<Scalars['String']>;
  ec_middle_name?: InputMaybe<Scalars['String']>;
  ec_phone1?: InputMaybe<Scalars['String']>;
  ec_phone1_type?: InputMaybe<Scalars['String']>;
  ec_phone2?: InputMaybe<Scalars['String']>;
  ec_phone2_type?: InputMaybe<Scalars['String']>;
  guarantor_address?: InputMaybe<Scalars['String']>;
  guarantor_address2?: InputMaybe<Scalars['String']>;
  guarantor_address_city?: InputMaybe<Scalars['String']>;
  guarantor_address_state?: InputMaybe<Scalars['String']>;
  guarantor_address_zip?: InputMaybe<Scalars['String']>;
  guarantor_birth_sex?: InputMaybe<Scalars['String']>;
  guarantor_dl_or_state_id_location?: InputMaybe<Scalars['String']>;
  guarantor_dob?: InputMaybe<Scalars['date']>;
  guarantor_email?: InputMaybe<Scalars['String']>;
  guarantor_first_name?: InputMaybe<Scalars['String']>;
  guarantor_last_name?: InputMaybe<Scalars['String']>;
  guarantor_marital_status?: InputMaybe<Scalars['String']>;
  guarantor_middle_name?: InputMaybe<Scalars['String']>;
  guarantor_phone1?: InputMaybe<Scalars['String']>;
  guarantor_phone1_type?: InputMaybe<Scalars['String']>;
  guarantor_phone2?: InputMaybe<Scalars['String']>;
  guarantor_phone2_type?: InputMaybe<Scalars['String']>;
  guarantor_relation_to_patient?: InputMaybe<Scalars['String']>;
  hispanic_or_latino?: InputMaybe<Scalars['Boolean']>;
  is_patient_primary_policy_holder?: InputMaybe<Scalars['String']>;
  is_patient_secondary_policy_holder?: InputMaybe<Scalars['String']>;
  location_id?: InputMaybe<Scalars['Int']>;
  location_name?: InputMaybe<Scalars['String']>;
  master_id?: InputMaybe<Scalars['String']>;
  no_cost_visit?: InputMaybe<Scalars['Boolean']>;
  organization_id?: InputMaybe<Scalars['Int']>;
  patient_address?: InputMaybe<Scalars['String']>;
  patient_address2?: InputMaybe<Scalars['String']>;
  patient_address_city?: InputMaybe<Scalars['String']>;
  patient_address_state?: InputMaybe<Scalars['String']>;
  patient_address_zip?: InputMaybe<Scalars['String']>;
  patient_birth_sex?: InputMaybe<Scalars['String']>;
  patient_current_medications?: InputMaybe<Scalars['String']>;
  patient_dl_or_state_id_location?: InputMaybe<Scalars['String']>;
  patient_dob?: InputMaybe<Scalars['date']>;
  patient_email?: InputMaybe<Scalars['String']>;
  patient_email_allowed?: InputMaybe<Scalars['Boolean']>;
  patient_family_medical_history?: InputMaybe<Scalars['String']>;
  patient_first_name?: InputMaybe<Scalars['String']>;
  patient_is_pregnant?: InputMaybe<Scalars['Boolean']>;
  patient_known_allergies?: InputMaybe<Scalars['String']>;
  patient_known_conditions?: InputMaybe<Scalars['String']>;
  patient_last_name?: InputMaybe<Scalars['String']>;
  patient_marital_status?: InputMaybe<Scalars['String']>;
  patient_medical_history?: InputMaybe<Scalars['String']>;
  patient_middle_name?: InputMaybe<Scalars['String']>;
  patient_phone1?: InputMaybe<Scalars['String']>;
  patient_phone1_type?: InputMaybe<Scalars['String']>;
  patient_phone2?: InputMaybe<Scalars['String']>;
  patient_phone2_type?: InputMaybe<Scalars['String']>;
  patient_pref_pharmacy?: InputMaybe<Scalars['String']>;
  patient_pref_pharmacy_zip?: InputMaybe<Scalars['String']>;
  patient_race?: InputMaybe<Scalars['String']>;
  patient_surgical_history?: InputMaybe<Scalars['String']>;
  pcp_consent?: InputMaybe<Scalars['Boolean']>;
  pcp_prov_name?: InputMaybe<Scalars['String']>;
  pg_address?: InputMaybe<Scalars['String']>;
  pg_address2?: InputMaybe<Scalars['String']>;
  pg_address_city?: InputMaybe<Scalars['String']>;
  pg_address_state?: InputMaybe<Scalars['String']>;
  pg_address_zip?: InputMaybe<Scalars['String']>;
  pg_birth_sex?: InputMaybe<Scalars['String']>;
  pg_dl_or_state_id_location?: InputMaybe<Scalars['String']>;
  pg_dob?: InputMaybe<Scalars['date']>;
  pg_email?: InputMaybe<Scalars['String']>;
  pg_email_allowed?: InputMaybe<Scalars['Boolean']>;
  pg_first_name?: InputMaybe<Scalars['String']>;
  pg_last_name?: InputMaybe<Scalars['String']>;
  pg_marital_status?: InputMaybe<Scalars['String']>;
  pg_middle_name?: InputMaybe<Scalars['String']>;
  pg_phone1?: InputMaybe<Scalars['String']>;
  pg_phone1_type?: InputMaybe<Scalars['String']>;
  pg_phone2?: InputMaybe<Scalars['String']>;
  pg_phone2_type?: InputMaybe<Scalars['String']>;
  pg_relation_to_patient?: InputMaybe<Scalars['String']>;
  pond_id?: InputMaybe<Scalars['String']>;
  primary_ins_address_line1?: InputMaybe<Scalars['String']>;
  primary_ins_carrier?: InputMaybe<Scalars['String']>;
  primary_ins_group_id?: InputMaybe<Scalars['String']>;
  primary_ins_id?: InputMaybe<Scalars['String']>;
  primary_ins_ph_birth_sex?: InputMaybe<Scalars['String']>;
  primary_ins_ph_dob?: InputMaybe<Scalars['date']>;
  primary_ins_ph_first_name?: InputMaybe<Scalars['String']>;
  primary_ins_ph_last_name?: InputMaybe<Scalars['String']>;
  primary_ins_ph_middle_name?: InputMaybe<Scalars['String']>;
  primary_ins_ph_patient_relation?: InputMaybe<Scalars['String']>;
  primary_ins_ph_suffix?: InputMaybe<Scalars['String']>;
  primary_ins_phone?: InputMaybe<Scalars['String']>;
  primary_ins_policy_id?: InputMaybe<Scalars['String']>;
  programs?: InputMaybe<Scalars['_int4']>;
  secondary_ins_address_line1?: InputMaybe<Scalars['String']>;
  secondary_ins_carrier?: InputMaybe<Scalars['String']>;
  secondary_ins_group_number?: InputMaybe<Scalars['String']>;
  secondary_ins_ph_birth_sex?: InputMaybe<Scalars['String']>;
  secondary_ins_ph_dob?: InputMaybe<Scalars['date']>;
  secondary_ins_ph_first_name?: InputMaybe<Scalars['String']>;
  secondary_ins_ph_last_name?: InputMaybe<Scalars['String']>;
  secondary_ins_ph_middle_name?: InputMaybe<Scalars['String']>;
  secondary_ins_ph_patient_relation?: InputMaybe<Scalars['String']>;
  secondary_ins_ph_suffix?: InputMaybe<Scalars['String']>;
  secondary_ins_phone?: InputMaybe<Scalars['String']>;
  secondary_ins_policy_id?: InputMaybe<Scalars['String']>;
  student_id?: InputMaybe<Scalars['String']>;
  test_patient?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate stddev on columns */
export type Patient_Demographics_Stddev_Fields = {
  __typename?: 'patient_demographics_stddev_fields';
  location_id?: Maybe<Scalars['Float']>;
  organization_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Patient_Demographics_Stddev_Pop_Fields = {
  __typename?: 'patient_demographics_stddev_pop_fields';
  location_id?: Maybe<Scalars['Float']>;
  organization_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Patient_Demographics_Stddev_Samp_Fields = {
  __typename?: 'patient_demographics_stddev_samp_fields';
  location_id?: Maybe<Scalars['Float']>;
  organization_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Patient_Demographics_Sum_Fields = {
  __typename?: 'patient_demographics_sum_fields';
  location_id?: Maybe<Scalars['Int']>;
  organization_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "mat_tmp_fast_demographics" */
export enum Patient_Demographics_Update_Column {
  /** column name */
  CreateTs = 'create_ts',
  /** column name */
  EcAddress = 'ec_address',
  /** column name */
  EcAddress2 = 'ec_address2',
  /** column name */
  EcAddressCity = 'ec_address_city',
  /** column name */
  EcAddressState = 'ec_address_state',
  /** column name */
  EcAddressZip = 'ec_address_zip',
  /** column name */
  EcFirstName = 'ec_first_name',
  /** column name */
  EcLastName = 'ec_last_name',
  /** column name */
  EcMiddleName = 'ec_middle_name',
  /** column name */
  EcPhone1 = 'ec_phone1',
  /** column name */
  EcPhone1Type = 'ec_phone1_type',
  /** column name */
  EcPhone2 = 'ec_phone2',
  /** column name */
  EcPhone2Type = 'ec_phone2_type',
  /** column name */
  GuarantorAddress = 'guarantor_address',
  /** column name */
  GuarantorAddress2 = 'guarantor_address2',
  /** column name */
  GuarantorAddressCity = 'guarantor_address_city',
  /** column name */
  GuarantorAddressState = 'guarantor_address_state',
  /** column name */
  GuarantorAddressZip = 'guarantor_address_zip',
  /** column name */
  GuarantorBirthSex = 'guarantor_birth_sex',
  /** column name */
  GuarantorDlOrStateIdLocation = 'guarantor_dl_or_state_id_location',
  /** column name */
  GuarantorDob = 'guarantor_dob',
  /** column name */
  GuarantorEmail = 'guarantor_email',
  /** column name */
  GuarantorFirstName = 'guarantor_first_name',
  /** column name */
  GuarantorLastName = 'guarantor_last_name',
  /** column name */
  GuarantorMaritalStatus = 'guarantor_marital_status',
  /** column name */
  GuarantorMiddleName = 'guarantor_middle_name',
  /** column name */
  GuarantorPhone1 = 'guarantor_phone1',
  /** column name */
  GuarantorPhone1Type = 'guarantor_phone1_type',
  /** column name */
  GuarantorPhone2 = 'guarantor_phone2',
  /** column name */
  GuarantorPhone2Type = 'guarantor_phone2_type',
  /** column name */
  GuarantorRelationToPatient = 'guarantor_relation_to_patient',
  /** column name */
  HispanicOrLatino = 'hispanic_or_latino',
  /** column name */
  IsPatientPrimaryPolicyHolder = 'is_patient_primary_policy_holder',
  /** column name */
  IsPatientSecondaryPolicyHolder = 'is_patient_secondary_policy_holder',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  LocationName = 'location_name',
  /** column name */
  MasterId = 'master_id',
  /** column name */
  NoCostVisit = 'no_cost_visit',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  PatientAddress = 'patient_address',
  /** column name */
  PatientAddress2 = 'patient_address2',
  /** column name */
  PatientAddressCity = 'patient_address_city',
  /** column name */
  PatientAddressState = 'patient_address_state',
  /** column name */
  PatientAddressZip = 'patient_address_zip',
  /** column name */
  PatientBirthSex = 'patient_birth_sex',
  /** column name */
  PatientCurrentMedications = 'patient_current_medications',
  /** column name */
  PatientDlOrStateIdLocation = 'patient_dl_or_state_id_location',
  /** column name */
  PatientDob = 'patient_dob',
  /** column name */
  PatientEmail = 'patient_email',
  /** column name */
  PatientEmailAllowed = 'patient_email_allowed',
  /** column name */
  PatientFamilyMedicalHistory = 'patient_family_medical_history',
  /** column name */
  PatientFirstName = 'patient_first_name',
  /** column name */
  PatientIsPregnant = 'patient_is_pregnant',
  /** column name */
  PatientKnownAllergies = 'patient_known_allergies',
  /** column name */
  PatientKnownConditions = 'patient_known_conditions',
  /** column name */
  PatientLastName = 'patient_last_name',
  /** column name */
  PatientMaritalStatus = 'patient_marital_status',
  /** column name */
  PatientMedicalHistory = 'patient_medical_history',
  /** column name */
  PatientMiddleName = 'patient_middle_name',
  /** column name */
  PatientPhone1 = 'patient_phone1',
  /** column name */
  PatientPhone1Type = 'patient_phone1_type',
  /** column name */
  PatientPhone2 = 'patient_phone2',
  /** column name */
  PatientPhone2Type = 'patient_phone2_type',
  /** column name */
  PatientPrefPharmacy = 'patient_pref_pharmacy',
  /** column name */
  PatientPrefPharmacyZip = 'patient_pref_pharmacy_zip',
  /** column name */
  PatientRace = 'patient_race',
  /** column name */
  PatientSurgicalHistory = 'patient_surgical_history',
  /** column name */
  PcpConsent = 'pcp_consent',
  /** column name */
  PcpProvName = 'pcp_prov_name',
  /** column name */
  PgAddress = 'pg_address',
  /** column name */
  PgAddress2 = 'pg_address2',
  /** column name */
  PgAddressCity = 'pg_address_city',
  /** column name */
  PgAddressState = 'pg_address_state',
  /** column name */
  PgAddressZip = 'pg_address_zip',
  /** column name */
  PgBirthSex = 'pg_birth_sex',
  /** column name */
  PgDlOrStateIdLocation = 'pg_dl_or_state_id_location',
  /** column name */
  PgDob = 'pg_dob',
  /** column name */
  PgEmail = 'pg_email',
  /** column name */
  PgEmailAllowed = 'pg_email_allowed',
  /** column name */
  PgFirstName = 'pg_first_name',
  /** column name */
  PgLastName = 'pg_last_name',
  /** column name */
  PgMaritalStatus = 'pg_marital_status',
  /** column name */
  PgMiddleName = 'pg_middle_name',
  /** column name */
  PgPhone1 = 'pg_phone1',
  /** column name */
  PgPhone1Type = 'pg_phone1_type',
  /** column name */
  PgPhone2 = 'pg_phone2',
  /** column name */
  PgPhone2Type = 'pg_phone2_type',
  /** column name */
  PgRelationToPatient = 'pg_relation_to_patient',
  /** column name */
  PondId = 'pond_id',
  /** column name */
  PrimaryInsAddressLine1 = 'primary_ins_address_line1',
  /** column name */
  PrimaryInsCarrier = 'primary_ins_carrier',
  /** column name */
  PrimaryInsGroupId = 'primary_ins_group_id',
  /** column name */
  PrimaryInsId = 'primary_ins_id',
  /** column name */
  PrimaryInsPhBirthSex = 'primary_ins_ph_birth_sex',
  /** column name */
  PrimaryInsPhDob = 'primary_ins_ph_dob',
  /** column name */
  PrimaryInsPhFirstName = 'primary_ins_ph_first_name',
  /** column name */
  PrimaryInsPhLastName = 'primary_ins_ph_last_name',
  /** column name */
  PrimaryInsPhMiddleName = 'primary_ins_ph_middle_name',
  /** column name */
  PrimaryInsPhPatientRelation = 'primary_ins_ph_patient_relation',
  /** column name */
  PrimaryInsPhSuffix = 'primary_ins_ph_suffix',
  /** column name */
  PrimaryInsPhone = 'primary_ins_phone',
  /** column name */
  PrimaryInsPolicyId = 'primary_ins_policy_id',
  /** column name */
  Programs = 'programs',
  /** column name */
  SecondaryInsAddressLine1 = 'secondary_ins_address_line1',
  /** column name */
  SecondaryInsCarrier = 'secondary_ins_carrier',
  /** column name */
  SecondaryInsGroupNumber = 'secondary_ins_group_number',
  /** column name */
  SecondaryInsPhBirthSex = 'secondary_ins_ph_birth_sex',
  /** column name */
  SecondaryInsPhDob = 'secondary_ins_ph_dob',
  /** column name */
  SecondaryInsPhFirstName = 'secondary_ins_ph_first_name',
  /** column name */
  SecondaryInsPhLastName = 'secondary_ins_ph_last_name',
  /** column name */
  SecondaryInsPhMiddleName = 'secondary_ins_ph_middle_name',
  /** column name */
  SecondaryInsPhPatientRelation = 'secondary_ins_ph_patient_relation',
  /** column name */
  SecondaryInsPhSuffix = 'secondary_ins_ph_suffix',
  /** column name */
  SecondaryInsPhone = 'secondary_ins_phone',
  /** column name */
  SecondaryInsPolicyId = 'secondary_ins_policy_id',
  /** column name */
  StudentId = 'student_id',
  /** column name */
  TestPatient = 'test_patient'
}

/** aggregate var_pop on columns */
export type Patient_Demographics_Var_Pop_Fields = {
  __typename?: 'patient_demographics_var_pop_fields';
  location_id?: Maybe<Scalars['Float']>;
  organization_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Patient_Demographics_Var_Samp_Fields = {
  __typename?: 'patient_demographics_var_samp_fields';
  location_id?: Maybe<Scalars['Float']>;
  organization_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Patient_Demographics_Variance_Fields = {
  __typename?: 'patient_demographics_variance_fields';
  location_id?: Maybe<Scalars['Float']>;
  organization_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "mat_fast_search_json" */
export type Patients = {
  __typename?: 'patients';
  allergies?: Maybe<Scalars['String']>;
  birth_sex?: Maybe<Scalars['String']>;
  can_email_guardian?: Maybe<Scalars['Boolean']>;
  conditions?: Maybe<Scalars['String']>;
  create_ts?: Maybe<Scalars['timestamptz']>;
  date_of_birth?: Maybe<Scalars['date']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  insurance?: Maybe<Scalars['String']>;
  is_active?: Maybe<Scalars['Boolean']>;
  is_insurance_validated?: Maybe<Scalars['Boolean']>;
  last_name?: Maybe<Scalars['String']>;
  location?: Maybe<Organizations_Locations>;
  location_id?: Maybe<Scalars['Int']>;
  master_id?: Maybe<Scalars['String']>;
  medications?: Maybe<Scalars['String']>;
  middle_name?: Maybe<Scalars['String']>;
  no_cost_visit?: Maybe<Scalars['Boolean']>;
  organization?: Maybe<Organizations_Organizations>;
  organization_id?: Maybe<Scalars['Int']>;
  pharmacy?: Maybe<Scalars['String']>;
  primary_guardian_email?: Maybe<Scalars['String']>;
  primary_guardian_first_name?: Maybe<Scalars['String']>;
  primary_guardian_last_name?: Maybe<Scalars['String']>;
  primary_guardian_phone_number?: Maybe<Scalars['String']>;
  primary_guardian_relationship?: Maybe<Scalars['String']>;
  programs?: Maybe<Scalars['jsonb']>;
  student_id?: Maybe<Scalars['String']>;
  test_patient?: Maybe<Scalars['Boolean']>;
};


/** columns and relationships of "mat_fast_search_json" */
export type PatientsProgramsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "mat_fast_search_json" */
export type Patients_Aggregate = {
  __typename?: 'patients_aggregate';
  aggregate?: Maybe<Patients_Aggregate_Fields>;
  nodes: Array<Patients>;
};

/** aggregate fields of "mat_fast_search_json" */
export type Patients_Aggregate_Fields = {
  __typename?: 'patients_aggregate_fields';
  avg?: Maybe<Patients_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Patients_Max_Fields>;
  min?: Maybe<Patients_Min_Fields>;
  stddev?: Maybe<Patients_Stddev_Fields>;
  stddev_pop?: Maybe<Patients_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Patients_Stddev_Samp_Fields>;
  sum?: Maybe<Patients_Sum_Fields>;
  var_pop?: Maybe<Patients_Var_Pop_Fields>;
  var_samp?: Maybe<Patients_Var_Samp_Fields>;
  variance?: Maybe<Patients_Variance_Fields>;
};


/** aggregate fields of "mat_fast_search_json" */
export type Patients_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Patients_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Patients_Append_Input = {
  programs?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Patients_Avg_Fields = {
  __typename?: 'patients_avg_fields';
  location_id?: Maybe<Scalars['Float']>;
  organization_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "mat_fast_search_json". All fields are combined with a logical 'AND'. */
export type Patients_Bool_Exp = {
  _and?: InputMaybe<Array<Patients_Bool_Exp>>;
  _not?: InputMaybe<Patients_Bool_Exp>;
  _or?: InputMaybe<Array<Patients_Bool_Exp>>;
  allergies?: InputMaybe<String_Comparison_Exp>;
  birth_sex?: InputMaybe<String_Comparison_Exp>;
  can_email_guardian?: InputMaybe<Boolean_Comparison_Exp>;
  conditions?: InputMaybe<String_Comparison_Exp>;
  create_ts?: InputMaybe<Timestamptz_Comparison_Exp>;
  date_of_birth?: InputMaybe<Date_Comparison_Exp>;
  first_name?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  insurance?: InputMaybe<String_Comparison_Exp>;
  is_active?: InputMaybe<Boolean_Comparison_Exp>;
  is_insurance_validated?: InputMaybe<Boolean_Comparison_Exp>;
  last_name?: InputMaybe<String_Comparison_Exp>;
  location_id?: InputMaybe<Int_Comparison_Exp>;
  master_id?: InputMaybe<String_Comparison_Exp>;
  medications?: InputMaybe<String_Comparison_Exp>;
  middle_name?: InputMaybe<String_Comparison_Exp>;
  no_cost_visit?: InputMaybe<Boolean_Comparison_Exp>;
  organization_id?: InputMaybe<Int_Comparison_Exp>;
  pharmacy?: InputMaybe<String_Comparison_Exp>;
  primary_guardian_email?: InputMaybe<String_Comparison_Exp>;
  primary_guardian_first_name?: InputMaybe<String_Comparison_Exp>;
  primary_guardian_last_name?: InputMaybe<String_Comparison_Exp>;
  primary_guardian_phone_number?: InputMaybe<String_Comparison_Exp>;
  primary_guardian_relationship?: InputMaybe<String_Comparison_Exp>;
  programs?: InputMaybe<Jsonb_Comparison_Exp>;
  student_id?: InputMaybe<String_Comparison_Exp>;
  test_patient?: InputMaybe<Boolean_Comparison_Exp>;
};

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Patients_Delete_At_Path_Input = {
  programs?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Patients_Delete_Elem_Input = {
  programs?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Patients_Delete_Key_Input = {
  programs?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "mat_fast_search_json" */
export type Patients_Inc_Input = {
  location_id?: InputMaybe<Scalars['Int']>;
  organization_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "mat_fast_search_json" */
export type Patients_Insert_Input = {
  allergies?: InputMaybe<Scalars['String']>;
  birth_sex?: InputMaybe<Scalars['String']>;
  can_email_guardian?: InputMaybe<Scalars['Boolean']>;
  conditions?: InputMaybe<Scalars['String']>;
  create_ts?: InputMaybe<Scalars['timestamptz']>;
  date_of_birth?: InputMaybe<Scalars['date']>;
  first_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  insurance?: InputMaybe<Scalars['String']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  is_insurance_validated?: InputMaybe<Scalars['Boolean']>;
  last_name?: InputMaybe<Scalars['String']>;
  location_id?: InputMaybe<Scalars['Int']>;
  master_id?: InputMaybe<Scalars['String']>;
  medications?: InputMaybe<Scalars['String']>;
  middle_name?: InputMaybe<Scalars['String']>;
  no_cost_visit?: InputMaybe<Scalars['Boolean']>;
  organization_id?: InputMaybe<Scalars['Int']>;
  pharmacy?: InputMaybe<Scalars['String']>;
  primary_guardian_email?: InputMaybe<Scalars['String']>;
  primary_guardian_first_name?: InputMaybe<Scalars['String']>;
  primary_guardian_last_name?: InputMaybe<Scalars['String']>;
  primary_guardian_phone_number?: InputMaybe<Scalars['String']>;
  primary_guardian_relationship?: InputMaybe<Scalars['String']>;
  programs?: InputMaybe<Scalars['jsonb']>;
  student_id?: InputMaybe<Scalars['String']>;
  test_patient?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate max on columns */
export type Patients_Max_Fields = {
  __typename?: 'patients_max_fields';
  allergies?: Maybe<Scalars['String']>;
  birth_sex?: Maybe<Scalars['String']>;
  conditions?: Maybe<Scalars['String']>;
  create_ts?: Maybe<Scalars['timestamptz']>;
  date_of_birth?: Maybe<Scalars['date']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  insurance?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  location_id?: Maybe<Scalars['Int']>;
  master_id?: Maybe<Scalars['String']>;
  medications?: Maybe<Scalars['String']>;
  middle_name?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['Int']>;
  pharmacy?: Maybe<Scalars['String']>;
  primary_guardian_email?: Maybe<Scalars['String']>;
  primary_guardian_first_name?: Maybe<Scalars['String']>;
  primary_guardian_last_name?: Maybe<Scalars['String']>;
  primary_guardian_phone_number?: Maybe<Scalars['String']>;
  primary_guardian_relationship?: Maybe<Scalars['String']>;
  student_id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Patients_Min_Fields = {
  __typename?: 'patients_min_fields';
  allergies?: Maybe<Scalars['String']>;
  birth_sex?: Maybe<Scalars['String']>;
  conditions?: Maybe<Scalars['String']>;
  create_ts?: Maybe<Scalars['timestamptz']>;
  date_of_birth?: Maybe<Scalars['date']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  insurance?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  location_id?: Maybe<Scalars['Int']>;
  master_id?: Maybe<Scalars['String']>;
  medications?: Maybe<Scalars['String']>;
  middle_name?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['Int']>;
  pharmacy?: Maybe<Scalars['String']>;
  primary_guardian_email?: Maybe<Scalars['String']>;
  primary_guardian_first_name?: Maybe<Scalars['String']>;
  primary_guardian_last_name?: Maybe<Scalars['String']>;
  primary_guardian_phone_number?: Maybe<Scalars['String']>;
  primary_guardian_relationship?: Maybe<Scalars['String']>;
  student_id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "mat_fast_search_json" */
export type Patients_Mutation_Response = {
  __typename?: 'patients_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Patients>;
};

/** Ordering options when selecting data from "mat_fast_search_json". */
export type Patients_Order_By = {
  allergies?: InputMaybe<Order_By>;
  birth_sex?: InputMaybe<Order_By>;
  can_email_guardian?: InputMaybe<Order_By>;
  conditions?: InputMaybe<Order_By>;
  create_ts?: InputMaybe<Order_By>;
  date_of_birth?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  insurance?: InputMaybe<Order_By>;
  is_active?: InputMaybe<Order_By>;
  is_insurance_validated?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  location_id?: InputMaybe<Order_By>;
  master_id?: InputMaybe<Order_By>;
  medications?: InputMaybe<Order_By>;
  middle_name?: InputMaybe<Order_By>;
  no_cost_visit?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  pharmacy?: InputMaybe<Order_By>;
  primary_guardian_email?: InputMaybe<Order_By>;
  primary_guardian_first_name?: InputMaybe<Order_By>;
  primary_guardian_last_name?: InputMaybe<Order_By>;
  primary_guardian_phone_number?: InputMaybe<Order_By>;
  primary_guardian_relationship?: InputMaybe<Order_By>;
  programs?: InputMaybe<Order_By>;
  student_id?: InputMaybe<Order_By>;
  test_patient?: InputMaybe<Order_By>;
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Patients_Prepend_Input = {
  programs?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "mat_fast_search_json" */
export enum Patients_Select_Column {
  /** column name */
  Allergies = 'allergies',
  /** column name */
  BirthSex = 'birth_sex',
  /** column name */
  CanEmailGuardian = 'can_email_guardian',
  /** column name */
  Conditions = 'conditions',
  /** column name */
  CreateTs = 'create_ts',
  /** column name */
  DateOfBirth = 'date_of_birth',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  Insurance = 'insurance',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  IsInsuranceValidated = 'is_insurance_validated',
  /** column name */
  LastName = 'last_name',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  MasterId = 'master_id',
  /** column name */
  Medications = 'medications',
  /** column name */
  MiddleName = 'middle_name',
  /** column name */
  NoCostVisit = 'no_cost_visit',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  Pharmacy = 'pharmacy',
  /** column name */
  PrimaryGuardianEmail = 'primary_guardian_email',
  /** column name */
  PrimaryGuardianFirstName = 'primary_guardian_first_name',
  /** column name */
  PrimaryGuardianLastName = 'primary_guardian_last_name',
  /** column name */
  PrimaryGuardianPhoneNumber = 'primary_guardian_phone_number',
  /** column name */
  PrimaryGuardianRelationship = 'primary_guardian_relationship',
  /** column name */
  Programs = 'programs',
  /** column name */
  StudentId = 'student_id',
  /** column name */
  TestPatient = 'test_patient'
}

/** input type for updating data in table "mat_fast_search_json" */
export type Patients_Set_Input = {
  allergies?: InputMaybe<Scalars['String']>;
  birth_sex?: InputMaybe<Scalars['String']>;
  can_email_guardian?: InputMaybe<Scalars['Boolean']>;
  conditions?: InputMaybe<Scalars['String']>;
  create_ts?: InputMaybe<Scalars['timestamptz']>;
  date_of_birth?: InputMaybe<Scalars['date']>;
  first_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  insurance?: InputMaybe<Scalars['String']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  is_insurance_validated?: InputMaybe<Scalars['Boolean']>;
  last_name?: InputMaybe<Scalars['String']>;
  location_id?: InputMaybe<Scalars['Int']>;
  master_id?: InputMaybe<Scalars['String']>;
  medications?: InputMaybe<Scalars['String']>;
  middle_name?: InputMaybe<Scalars['String']>;
  no_cost_visit?: InputMaybe<Scalars['Boolean']>;
  organization_id?: InputMaybe<Scalars['Int']>;
  pharmacy?: InputMaybe<Scalars['String']>;
  primary_guardian_email?: InputMaybe<Scalars['String']>;
  primary_guardian_first_name?: InputMaybe<Scalars['String']>;
  primary_guardian_last_name?: InputMaybe<Scalars['String']>;
  primary_guardian_phone_number?: InputMaybe<Scalars['String']>;
  primary_guardian_relationship?: InputMaybe<Scalars['String']>;
  programs?: InputMaybe<Scalars['jsonb']>;
  student_id?: InputMaybe<Scalars['String']>;
  test_patient?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate stddev on columns */
export type Patients_Stddev_Fields = {
  __typename?: 'patients_stddev_fields';
  location_id?: Maybe<Scalars['Float']>;
  organization_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Patients_Stddev_Pop_Fields = {
  __typename?: 'patients_stddev_pop_fields';
  location_id?: Maybe<Scalars['Float']>;
  organization_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Patients_Stddev_Samp_Fields = {
  __typename?: 'patients_stddev_samp_fields';
  location_id?: Maybe<Scalars['Float']>;
  organization_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Patients_Sum_Fields = {
  __typename?: 'patients_sum_fields';
  location_id?: Maybe<Scalars['Int']>;
  organization_id?: Maybe<Scalars['Int']>;
};

/** aggregate var_pop on columns */
export type Patients_Var_Pop_Fields = {
  __typename?: 'patients_var_pop_fields';
  location_id?: Maybe<Scalars['Float']>;
  organization_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Patients_Var_Samp_Fields = {
  __typename?: 'patients_var_samp_fields';
  location_id?: Maybe<Scalars['Float']>;
  organization_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Patients_Variance_Fields = {
  __typename?: 'patients_variance_fields';
  location_id?: Maybe<Scalars['Float']>;
  organization_id?: Maybe<Scalars['Float']>;
};

/**
 * User-provided Pharmacies that can be associated to Patients
 *
 *
 * columns and relationships of "pharmacies"
 *
 */
export type Pharmacies = {
  __typename?: 'pharmacies';
  id: Scalars['Int'];
  is_deleted: Scalars['Boolean'];
  /** An array relationship */
  patients: Array<Portal_Patients>;
  /** An aggregate relationship */
  patients_aggregate: Portal_Patients_Aggregate;
  title: Scalars['String'];
  user_id: Scalars['String'];
  zip_code: Scalars['String'];
};


/**
 * User-provided Pharmacies that can be associated to Patients
 *
 *
 * columns and relationships of "pharmacies"
 *
 */
export type PharmaciesPatientsArgs = {
  distinct_on?: InputMaybe<Array<Portal_Patients_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Portal_Patients_Order_By>>;
  where?: InputMaybe<Portal_Patients_Bool_Exp>;
};


/**
 * User-provided Pharmacies that can be associated to Patients
 *
 *
 * columns and relationships of "pharmacies"
 *
 */
export type PharmaciesPatients_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Portal_Patients_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Portal_Patients_Order_By>>;
  where?: InputMaybe<Portal_Patients_Bool_Exp>;
};

/** aggregated selection of "pharmacies" */
export type Pharmacies_Aggregate = {
  __typename?: 'pharmacies_aggregate';
  aggregate?: Maybe<Pharmacies_Aggregate_Fields>;
  nodes: Array<Pharmacies>;
};

/** aggregate fields of "pharmacies" */
export type Pharmacies_Aggregate_Fields = {
  __typename?: 'pharmacies_aggregate_fields';
  avg?: Maybe<Pharmacies_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Pharmacies_Max_Fields>;
  min?: Maybe<Pharmacies_Min_Fields>;
  stddev?: Maybe<Pharmacies_Stddev_Fields>;
  stddev_pop?: Maybe<Pharmacies_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Pharmacies_Stddev_Samp_Fields>;
  sum?: Maybe<Pharmacies_Sum_Fields>;
  var_pop?: Maybe<Pharmacies_Var_Pop_Fields>;
  var_samp?: Maybe<Pharmacies_Var_Samp_Fields>;
  variance?: Maybe<Pharmacies_Variance_Fields>;
};


/** aggregate fields of "pharmacies" */
export type Pharmacies_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Pharmacies_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Pharmacies_Avg_Fields = {
  __typename?: 'pharmacies_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "pharmacies". All fields are combined with a logical 'AND'. */
export type Pharmacies_Bool_Exp = {
  _and?: InputMaybe<Array<Pharmacies_Bool_Exp>>;
  _not?: InputMaybe<Pharmacies_Bool_Exp>;
  _or?: InputMaybe<Array<Pharmacies_Bool_Exp>>;
  id?: InputMaybe<Int_Comparison_Exp>;
  is_deleted?: InputMaybe<Boolean_Comparison_Exp>;
  patients?: InputMaybe<Portal_Patients_Bool_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
  zip_code?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "pharmacies" */
export enum Pharmacies_Constraint {
  /** unique or primary key constraint */
  PharmacyPkey = 'pharmacy_pkey'
}

/** input type for incrementing numeric columns in table "pharmacies" */
export type Pharmacies_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "pharmacies" */
export type Pharmacies_Insert_Input = {
  id?: InputMaybe<Scalars['Int']>;
  is_deleted?: InputMaybe<Scalars['Boolean']>;
  patients?: InputMaybe<Portal_Patients_Arr_Rel_Insert_Input>;
  title?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['String']>;
  zip_code?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Pharmacies_Max_Fields = {
  __typename?: 'pharmacies_max_fields';
  id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
  zip_code?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Pharmacies_Min_Fields = {
  __typename?: 'pharmacies_min_fields';
  id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
  zip_code?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "pharmacies" */
export type Pharmacies_Mutation_Response = {
  __typename?: 'pharmacies_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Pharmacies>;
};

/** input type for inserting object relation for remote table "pharmacies" */
export type Pharmacies_Obj_Rel_Insert_Input = {
  data: Pharmacies_Insert_Input;
  /** on conflict condition */
  on_conflict?: InputMaybe<Pharmacies_On_Conflict>;
};

/** on conflict condition type for table "pharmacies" */
export type Pharmacies_On_Conflict = {
  constraint: Pharmacies_Constraint;
  update_columns?: Array<Pharmacies_Update_Column>;
  where?: InputMaybe<Pharmacies_Bool_Exp>;
};

/** Ordering options when selecting data from "pharmacies". */
export type Pharmacies_Order_By = {
  id?: InputMaybe<Order_By>;
  is_deleted?: InputMaybe<Order_By>;
  patients_aggregate?: InputMaybe<Portal_Patients_Aggregate_Order_By>;
  title?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  zip_code?: InputMaybe<Order_By>;
};

/** primary key columns input for table: pharmacies */
export type Pharmacies_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "pharmacies" */
export enum Pharmacies_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  Title = 'title',
  /** column name */
  UserId = 'user_id',
  /** column name */
  ZipCode = 'zip_code'
}

/** input type for updating data in table "pharmacies" */
export type Pharmacies_Set_Input = {
  id?: InputMaybe<Scalars['Int']>;
  is_deleted?: InputMaybe<Scalars['Boolean']>;
  title?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['String']>;
  zip_code?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Pharmacies_Stddev_Fields = {
  __typename?: 'pharmacies_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Pharmacies_Stddev_Pop_Fields = {
  __typename?: 'pharmacies_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Pharmacies_Stddev_Samp_Fields = {
  __typename?: 'pharmacies_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Pharmacies_Sum_Fields = {
  __typename?: 'pharmacies_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "pharmacies" */
export enum Pharmacies_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  Title = 'title',
  /** column name */
  UserId = 'user_id',
  /** column name */
  ZipCode = 'zip_code'
}

/** aggregate var_pop on columns */
export type Pharmacies_Var_Pop_Fields = {
  __typename?: 'pharmacies_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Pharmacies_Var_Samp_Fields = {
  __typename?: 'pharmacies_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Pharmacies_Variance_Fields = {
  __typename?: 'pharmacies_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "place_of_service" */
export type Place_Of_Service = {
  __typename?: 'place_of_service';
  description: Scalars['String'];
  id: Scalars['Int'];
  is_deleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
};

/** aggregated selection of "place_of_service" */
export type Place_Of_Service_Aggregate = {
  __typename?: 'place_of_service_aggregate';
  aggregate?: Maybe<Place_Of_Service_Aggregate_Fields>;
  nodes: Array<Place_Of_Service>;
};

/** aggregate fields of "place_of_service" */
export type Place_Of_Service_Aggregate_Fields = {
  __typename?: 'place_of_service_aggregate_fields';
  avg?: Maybe<Place_Of_Service_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Place_Of_Service_Max_Fields>;
  min?: Maybe<Place_Of_Service_Min_Fields>;
  stddev?: Maybe<Place_Of_Service_Stddev_Fields>;
  stddev_pop?: Maybe<Place_Of_Service_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Place_Of_Service_Stddev_Samp_Fields>;
  sum?: Maybe<Place_Of_Service_Sum_Fields>;
  var_pop?: Maybe<Place_Of_Service_Var_Pop_Fields>;
  var_samp?: Maybe<Place_Of_Service_Var_Samp_Fields>;
  variance?: Maybe<Place_Of_Service_Variance_Fields>;
};


/** aggregate fields of "place_of_service" */
export type Place_Of_Service_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Place_Of_Service_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Place_Of_Service_Avg_Fields = {
  __typename?: 'place_of_service_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "place_of_service". All fields are combined with a logical 'AND'. */
export type Place_Of_Service_Bool_Exp = {
  _and?: InputMaybe<Array<Place_Of_Service_Bool_Exp>>;
  _not?: InputMaybe<Place_Of_Service_Bool_Exp>;
  _or?: InputMaybe<Array<Place_Of_Service_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  is_deleted?: InputMaybe<Boolean_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "place_of_service" */
export enum Place_Of_Service_Constraint {
  /** unique or primary key constraint */
  PlaceOfServicePkey = 'place_of_service_pkey'
}

/** input type for incrementing numeric columns in table "place_of_service" */
export type Place_Of_Service_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "place_of_service" */
export type Place_Of_Service_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  is_deleted?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Place_Of_Service_Max_Fields = {
  __typename?: 'place_of_service_max_fields';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Place_Of_Service_Min_Fields = {
  __typename?: 'place_of_service_min_fields';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "place_of_service" */
export type Place_Of_Service_Mutation_Response = {
  __typename?: 'place_of_service_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Place_Of_Service>;
};

/** on conflict condition type for table "place_of_service" */
export type Place_Of_Service_On_Conflict = {
  constraint: Place_Of_Service_Constraint;
  update_columns?: Array<Place_Of_Service_Update_Column>;
  where?: InputMaybe<Place_Of_Service_Bool_Exp>;
};

/** Ordering options when selecting data from "place_of_service". */
export type Place_Of_Service_Order_By = {
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_deleted?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: place_of_service */
export type Place_Of_Service_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "place_of_service" */
export enum Place_Of_Service_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "place_of_service" */
export type Place_Of_Service_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  is_deleted?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Place_Of_Service_Stddev_Fields = {
  __typename?: 'place_of_service_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Place_Of_Service_Stddev_Pop_Fields = {
  __typename?: 'place_of_service_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Place_Of_Service_Stddev_Samp_Fields = {
  __typename?: 'place_of_service_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Place_Of_Service_Sum_Fields = {
  __typename?: 'place_of_service_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "place_of_service" */
export enum Place_Of_Service_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  Name = 'name'
}

/** aggregate var_pop on columns */
export type Place_Of_Service_Var_Pop_Fields = {
  __typename?: 'place_of_service_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Place_Of_Service_Var_Samp_Fields = {
  __typename?: 'place_of_service_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Place_Of_Service_Variance_Fields = {
  __typename?: 'place_of_service_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "pond_form_localizations" */
export type Pond_Form_Localizations = {
  __typename?: 'pond_form_localizations';
  dictionary: Scalars['jsonb'];
  form_type_code: Scalars['String'];
  locale: Scalars['String'];
  /** An object relationship */
  pond_form_type: Pond_Form_Types;
};


/** columns and relationships of "pond_form_localizations" */
export type Pond_Form_LocalizationsDictionaryArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "pond_form_localizations" */
export type Pond_Form_Localizations_Aggregate = {
  __typename?: 'pond_form_localizations_aggregate';
  aggregate?: Maybe<Pond_Form_Localizations_Aggregate_Fields>;
  nodes: Array<Pond_Form_Localizations>;
};

/** aggregate fields of "pond_form_localizations" */
export type Pond_Form_Localizations_Aggregate_Fields = {
  __typename?: 'pond_form_localizations_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Pond_Form_Localizations_Max_Fields>;
  min?: Maybe<Pond_Form_Localizations_Min_Fields>;
};


/** aggregate fields of "pond_form_localizations" */
export type Pond_Form_Localizations_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Pond_Form_Localizations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Pond_Form_Localizations_Append_Input = {
  dictionary?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "pond_form_localizations". All fields are combined with a logical 'AND'. */
export type Pond_Form_Localizations_Bool_Exp = {
  _and?: InputMaybe<Array<Pond_Form_Localizations_Bool_Exp>>;
  _not?: InputMaybe<Pond_Form_Localizations_Bool_Exp>;
  _or?: InputMaybe<Array<Pond_Form_Localizations_Bool_Exp>>;
  dictionary?: InputMaybe<Jsonb_Comparison_Exp>;
  form_type_code?: InputMaybe<String_Comparison_Exp>;
  locale?: InputMaybe<String_Comparison_Exp>;
  pond_form_type?: InputMaybe<Pond_Form_Types_Bool_Exp>;
};

/** unique or primary key constraints on table "pond_form_localizations" */
export enum Pond_Form_Localizations_Constraint {
  /** unique or primary key constraint */
  PondFormLocalizationsPkey = 'pond_form_localizations_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Pond_Form_Localizations_Delete_At_Path_Input = {
  dictionary?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Pond_Form_Localizations_Delete_Elem_Input = {
  dictionary?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Pond_Form_Localizations_Delete_Key_Input = {
  dictionary?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "pond_form_localizations" */
export type Pond_Form_Localizations_Insert_Input = {
  dictionary?: InputMaybe<Scalars['jsonb']>;
  form_type_code?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<Scalars['String']>;
  pond_form_type?: InputMaybe<Pond_Form_Types_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Pond_Form_Localizations_Max_Fields = {
  __typename?: 'pond_form_localizations_max_fields';
  form_type_code?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Pond_Form_Localizations_Min_Fields = {
  __typename?: 'pond_form_localizations_min_fields';
  form_type_code?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "pond_form_localizations" */
export type Pond_Form_Localizations_Mutation_Response = {
  __typename?: 'pond_form_localizations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Pond_Form_Localizations>;
};

/** on conflict condition type for table "pond_form_localizations" */
export type Pond_Form_Localizations_On_Conflict = {
  constraint: Pond_Form_Localizations_Constraint;
  update_columns?: Array<Pond_Form_Localizations_Update_Column>;
  where?: InputMaybe<Pond_Form_Localizations_Bool_Exp>;
};

/** Ordering options when selecting data from "pond_form_localizations". */
export type Pond_Form_Localizations_Order_By = {
  dictionary?: InputMaybe<Order_By>;
  form_type_code?: InputMaybe<Order_By>;
  locale?: InputMaybe<Order_By>;
  pond_form_type?: InputMaybe<Pond_Form_Types_Order_By>;
};

/** primary key columns input for table: pond_form_localizations */
export type Pond_Form_Localizations_Pk_Columns_Input = {
  form_type_code: Scalars['String'];
  locale: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Pond_Form_Localizations_Prepend_Input = {
  dictionary?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "pond_form_localizations" */
export enum Pond_Form_Localizations_Select_Column {
  /** column name */
  Dictionary = 'dictionary',
  /** column name */
  FormTypeCode = 'form_type_code',
  /** column name */
  Locale = 'locale'
}

/** input type for updating data in table "pond_form_localizations" */
export type Pond_Form_Localizations_Set_Input = {
  dictionary?: InputMaybe<Scalars['jsonb']>;
  form_type_code?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<Scalars['String']>;
};

/** update columns of table "pond_form_localizations" */
export enum Pond_Form_Localizations_Update_Column {
  /** column name */
  Dictionary = 'dictionary',
  /** column name */
  FormTypeCode = 'form_type_code',
  /** column name */
  Locale = 'locale'
}

/** columns and relationships of "pond_form_types" */
export type Pond_Form_Types = {
  __typename?: 'pond_form_types';
  code: Scalars['String'];
  schema: Scalars['jsonb'];
  ui_schema?: Maybe<Scalars['jsonb']>;
};


/** columns and relationships of "pond_form_types" */
export type Pond_Form_TypesSchemaArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "pond_form_types" */
export type Pond_Form_TypesUi_SchemaArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "pond_form_types" */
export type Pond_Form_Types_Aggregate = {
  __typename?: 'pond_form_types_aggregate';
  aggregate?: Maybe<Pond_Form_Types_Aggregate_Fields>;
  nodes: Array<Pond_Form_Types>;
};

/** aggregate fields of "pond_form_types" */
export type Pond_Form_Types_Aggregate_Fields = {
  __typename?: 'pond_form_types_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Pond_Form_Types_Max_Fields>;
  min?: Maybe<Pond_Form_Types_Min_Fields>;
};


/** aggregate fields of "pond_form_types" */
export type Pond_Form_Types_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Pond_Form_Types_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Pond_Form_Types_Append_Input = {
  schema?: InputMaybe<Scalars['jsonb']>;
  ui_schema?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "pond_form_types". All fields are combined with a logical 'AND'. */
export type Pond_Form_Types_Bool_Exp = {
  _and?: InputMaybe<Array<Pond_Form_Types_Bool_Exp>>;
  _not?: InputMaybe<Pond_Form_Types_Bool_Exp>;
  _or?: InputMaybe<Array<Pond_Form_Types_Bool_Exp>>;
  code?: InputMaybe<String_Comparison_Exp>;
  schema?: InputMaybe<Jsonb_Comparison_Exp>;
  ui_schema?: InputMaybe<Jsonb_Comparison_Exp>;
};

/** unique or primary key constraints on table "pond_form_types" */
export enum Pond_Form_Types_Constraint {
  /** unique or primary key constraint */
  PondFormTypesPkey = 'pond_form_types_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Pond_Form_Types_Delete_At_Path_Input = {
  schema?: InputMaybe<Array<Scalars['String']>>;
  ui_schema?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Pond_Form_Types_Delete_Elem_Input = {
  schema?: InputMaybe<Scalars['Int']>;
  ui_schema?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Pond_Form_Types_Delete_Key_Input = {
  schema?: InputMaybe<Scalars['String']>;
  ui_schema?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "pond_form_types" */
export type Pond_Form_Types_Insert_Input = {
  code?: InputMaybe<Scalars['String']>;
  schema?: InputMaybe<Scalars['jsonb']>;
  ui_schema?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate max on columns */
export type Pond_Form_Types_Max_Fields = {
  __typename?: 'pond_form_types_max_fields';
  code?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Pond_Form_Types_Min_Fields = {
  __typename?: 'pond_form_types_min_fields';
  code?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "pond_form_types" */
export type Pond_Form_Types_Mutation_Response = {
  __typename?: 'pond_form_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Pond_Form_Types>;
};

/** input type for inserting object relation for remote table "pond_form_types" */
export type Pond_Form_Types_Obj_Rel_Insert_Input = {
  data: Pond_Form_Types_Insert_Input;
  /** on conflict condition */
  on_conflict?: InputMaybe<Pond_Form_Types_On_Conflict>;
};

/** on conflict condition type for table "pond_form_types" */
export type Pond_Form_Types_On_Conflict = {
  constraint: Pond_Form_Types_Constraint;
  update_columns?: Array<Pond_Form_Types_Update_Column>;
  where?: InputMaybe<Pond_Form_Types_Bool_Exp>;
};

/** Ordering options when selecting data from "pond_form_types". */
export type Pond_Form_Types_Order_By = {
  code?: InputMaybe<Order_By>;
  schema?: InputMaybe<Order_By>;
  ui_schema?: InputMaybe<Order_By>;
};

/** primary key columns input for table: pond_form_types */
export type Pond_Form_Types_Pk_Columns_Input = {
  code: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Pond_Form_Types_Prepend_Input = {
  schema?: InputMaybe<Scalars['jsonb']>;
  ui_schema?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "pond_form_types" */
export enum Pond_Form_Types_Select_Column {
  /** column name */
  Code = 'code',
  /** column name */
  Schema = 'schema',
  /** column name */
  UiSchema = 'ui_schema'
}

/** input type for updating data in table "pond_form_types" */
export type Pond_Form_Types_Set_Input = {
  code?: InputMaybe<Scalars['String']>;
  schema?: InputMaybe<Scalars['jsonb']>;
  ui_schema?: InputMaybe<Scalars['jsonb']>;
};

/** update columns of table "pond_form_types" */
export enum Pond_Form_Types_Update_Column {
  /** column name */
  Code = 'code',
  /** column name */
  Schema = 'schema',
  /** column name */
  UiSchema = 'ui_schema'
}

/** columns and relationships of "pond_forms" */
export type Pond_Forms = {
  __typename?: 'pond_forms';
  created_at: Scalars['timestamptz'];
  data: Scalars['jsonb'];
  form_type_code: Scalars['String'];
  id: Scalars['Int'];
  order_id: Scalars['Int'];
  patient_id: Scalars['String'];
  /** An object relationship */
  pond_form_type: Pond_Form_Types;
  updated_at: Scalars['timestamptz'];
  user_id: Scalars['String'];
};


/** columns and relationships of "pond_forms" */
export type Pond_FormsDataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "pond_forms" */
export type Pond_Forms_Aggregate = {
  __typename?: 'pond_forms_aggregate';
  aggregate?: Maybe<Pond_Forms_Aggregate_Fields>;
  nodes: Array<Pond_Forms>;
};

/** aggregate fields of "pond_forms" */
export type Pond_Forms_Aggregate_Fields = {
  __typename?: 'pond_forms_aggregate_fields';
  avg?: Maybe<Pond_Forms_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Pond_Forms_Max_Fields>;
  min?: Maybe<Pond_Forms_Min_Fields>;
  stddev?: Maybe<Pond_Forms_Stddev_Fields>;
  stddev_pop?: Maybe<Pond_Forms_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Pond_Forms_Stddev_Samp_Fields>;
  sum?: Maybe<Pond_Forms_Sum_Fields>;
  var_pop?: Maybe<Pond_Forms_Var_Pop_Fields>;
  var_samp?: Maybe<Pond_Forms_Var_Samp_Fields>;
  variance?: Maybe<Pond_Forms_Variance_Fields>;
};


/** aggregate fields of "pond_forms" */
export type Pond_Forms_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Pond_Forms_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Pond_Forms_Append_Input = {
  data?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Pond_Forms_Avg_Fields = {
  __typename?: 'pond_forms_avg_fields';
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "pond_forms". All fields are combined with a logical 'AND'. */
export type Pond_Forms_Bool_Exp = {
  _and?: InputMaybe<Array<Pond_Forms_Bool_Exp>>;
  _not?: InputMaybe<Pond_Forms_Bool_Exp>;
  _or?: InputMaybe<Array<Pond_Forms_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  data?: InputMaybe<Jsonb_Comparison_Exp>;
  form_type_code?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  order_id?: InputMaybe<Int_Comparison_Exp>;
  patient_id?: InputMaybe<String_Comparison_Exp>;
  pond_form_type?: InputMaybe<Pond_Form_Types_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "pond_forms" */
export enum Pond_Forms_Constraint {
  /** unique or primary key constraint */
  PondFormsOrderIdKey = 'pond_forms_order_id_key',
  /** unique or primary key constraint */
  PondFormsPkey = 'pond_forms_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Pond_Forms_Delete_At_Path_Input = {
  data?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Pond_Forms_Delete_Elem_Input = {
  data?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Pond_Forms_Delete_Key_Input = {
  data?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "pond_forms" */
export type Pond_Forms_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  order_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "pond_forms" */
export type Pond_Forms_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  data?: InputMaybe<Scalars['jsonb']>;
  form_type_code?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  order_id?: InputMaybe<Scalars['Int']>;
  patient_id?: InputMaybe<Scalars['String']>;
  pond_form_type?: InputMaybe<Pond_Form_Types_Obj_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Pond_Forms_Max_Fields = {
  __typename?: 'pond_forms_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  form_type_code?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  order_id?: Maybe<Scalars['Int']>;
  patient_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Pond_Forms_Min_Fields = {
  __typename?: 'pond_forms_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  form_type_code?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  order_id?: Maybe<Scalars['Int']>;
  patient_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "pond_forms" */
export type Pond_Forms_Mutation_Response = {
  __typename?: 'pond_forms_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Pond_Forms>;
};

/** on conflict condition type for table "pond_forms" */
export type Pond_Forms_On_Conflict = {
  constraint: Pond_Forms_Constraint;
  update_columns?: Array<Pond_Forms_Update_Column>;
  where?: InputMaybe<Pond_Forms_Bool_Exp>;
};

/** Ordering options when selecting data from "pond_forms". */
export type Pond_Forms_Order_By = {
  created_at?: InputMaybe<Order_By>;
  data?: InputMaybe<Order_By>;
  form_type_code?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  patient_id?: InputMaybe<Order_By>;
  pond_form_type?: InputMaybe<Pond_Form_Types_Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: pond_forms */
export type Pond_Forms_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Pond_Forms_Prepend_Input = {
  data?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "pond_forms" */
export enum Pond_Forms_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Data = 'data',
  /** column name */
  FormTypeCode = 'form_type_code',
  /** column name */
  Id = 'id',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  PatientId = 'patient_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "pond_forms" */
export type Pond_Forms_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  data?: InputMaybe<Scalars['jsonb']>;
  form_type_code?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  order_id?: InputMaybe<Scalars['Int']>;
  patient_id?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Pond_Forms_Stddev_Fields = {
  __typename?: 'pond_forms_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Pond_Forms_Stddev_Pop_Fields = {
  __typename?: 'pond_forms_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Pond_Forms_Stddev_Samp_Fields = {
  __typename?: 'pond_forms_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Pond_Forms_Sum_Fields = {
  __typename?: 'pond_forms_sum_fields';
  id?: Maybe<Scalars['Int']>;
  order_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "pond_forms" */
export enum Pond_Forms_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Data = 'data',
  /** column name */
  FormTypeCode = 'form_type_code',
  /** column name */
  Id = 'id',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  PatientId = 'patient_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** aggregate var_pop on columns */
export type Pond_Forms_Var_Pop_Fields = {
  __typename?: 'pond_forms_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Pond_Forms_Var_Samp_Fields = {
  __typename?: 'pond_forms_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Pond_Forms_Variance_Fields = {
  __typename?: 'pond_forms_variance_fields';
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
};

/**
 * User-scope based Patients
 *
 *
 * columns and relationships of "patients"
 *
 */
export type Portal_Patients = {
  __typename?: 'portal_patients';
  /** An array relationship */
  additional_care_providers: Array<Additional_Care_Providers>;
  /** An aggregate relationship */
  additional_care_providers_aggregate: Additional_Care_Providers_Aggregate;
  /** An object relationship */
  allergies?: Maybe<Allergies>;
  birth_sex?: Maybe<Scalars['String']>;
  consent_signature?: Maybe<Scalars['String']>;
  /** An array relationship */
  contacts: Array<Contacts_To_Patients>;
  /** An aggregate relationship */
  contacts_aggregate: Contacts_To_Patients_Aggregate;
  date_of_birth: Scalars['String'];
  first_name: Scalars['String'];
  /** fetch data from the table: "forms" */
  forms: Array<Forms>;
  /** fetch aggregated fields from the table: "forms" */
  forms_aggregate: Forms_Aggregate;
  id: Scalars['Int'];
  /** An object relationship */
  insurance?: Maybe<Insurances>;
  insurance_id?: Maybe<Scalars['Int']>;
  insurance_type?: Maybe<Scalars['String']>;
  is_deleted: Scalars['Boolean'];
  last_name: Scalars['String'];
  location?: Maybe<Organizations_Locations>;
  location_id?: Maybe<Scalars['Int']>;
  master_id?: Maybe<Scalars['String']>;
  master_patient?: Maybe<Patients>;
  organization?: Maybe<Organizations_Organizations>;
  organization_id?: Maybe<Scalars['Int']>;
  /** An object relationship */
  pharmacy?: Maybe<Pharmacies>;
  pharmacy_id?: Maybe<Scalars['Int']>;
  primary_care_physician_id?: Maybe<Scalars['Int']>;
  /** An object relationship */
  primary_care_provider?: Maybe<Providers>;
  /** An object relationship */
  primary_guardian?: Maybe<Contacts>;
  primary_guardian_id?: Maybe<Scalars['Int']>;
  primary_guardian_relation?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  type: Scalars['String'];
  user_id: Scalars['String'];
};


/**
 * User-scope based Patients
 *
 *
 * columns and relationships of "patients"
 *
 */
export type Portal_PatientsAdditional_Care_ProvidersArgs = {
  distinct_on?: InputMaybe<Array<Additional_Care_Providers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Additional_Care_Providers_Order_By>>;
  where?: InputMaybe<Additional_Care_Providers_Bool_Exp>;
};


/**
 * User-scope based Patients
 *
 *
 * columns and relationships of "patients"
 *
 */
export type Portal_PatientsAdditional_Care_Providers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Additional_Care_Providers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Additional_Care_Providers_Order_By>>;
  where?: InputMaybe<Additional_Care_Providers_Bool_Exp>;
};


/**
 * User-scope based Patients
 *
 *
 * columns and relationships of "patients"
 *
 */
export type Portal_PatientsContactsArgs = {
  distinct_on?: InputMaybe<Array<Contacts_To_Patients_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contacts_To_Patients_Order_By>>;
  where?: InputMaybe<Contacts_To_Patients_Bool_Exp>;
};


/**
 * User-scope based Patients
 *
 *
 * columns and relationships of "patients"
 *
 */
export type Portal_PatientsContacts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contacts_To_Patients_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contacts_To_Patients_Order_By>>;
  where?: InputMaybe<Contacts_To_Patients_Bool_Exp>;
};


/**
 * User-scope based Patients
 *
 *
 * columns and relationships of "patients"
 *
 */
export type Portal_PatientsFormsArgs = {
  distinct_on?: InputMaybe<Array<Forms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Forms_Order_By>>;
  where?: InputMaybe<Forms_Bool_Exp>;
};


/**
 * User-scope based Patients
 *
 *
 * columns and relationships of "patients"
 *
 */
export type Portal_PatientsForms_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Forms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Forms_Order_By>>;
  where?: InputMaybe<Forms_Bool_Exp>;
};

/** aggregated selection of "patients" */
export type Portal_Patients_Aggregate = {
  __typename?: 'portal_patients_aggregate';
  aggregate?: Maybe<Portal_Patients_Aggregate_Fields>;
  nodes: Array<Portal_Patients>;
};

/** aggregate fields of "patients" */
export type Portal_Patients_Aggregate_Fields = {
  __typename?: 'portal_patients_aggregate_fields';
  avg?: Maybe<Portal_Patients_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Portal_Patients_Max_Fields>;
  min?: Maybe<Portal_Patients_Min_Fields>;
  stddev?: Maybe<Portal_Patients_Stddev_Fields>;
  stddev_pop?: Maybe<Portal_Patients_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Portal_Patients_Stddev_Samp_Fields>;
  sum?: Maybe<Portal_Patients_Sum_Fields>;
  var_pop?: Maybe<Portal_Patients_Var_Pop_Fields>;
  var_samp?: Maybe<Portal_Patients_Var_Samp_Fields>;
  variance?: Maybe<Portal_Patients_Variance_Fields>;
};


/** aggregate fields of "patients" */
export type Portal_Patients_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Portal_Patients_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "patients" */
export type Portal_Patients_Aggregate_Order_By = {
  avg?: InputMaybe<Portal_Patients_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Portal_Patients_Max_Order_By>;
  min?: InputMaybe<Portal_Patients_Min_Order_By>;
  stddev?: InputMaybe<Portal_Patients_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Portal_Patients_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Portal_Patients_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Portal_Patients_Sum_Order_By>;
  var_pop?: InputMaybe<Portal_Patients_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Portal_Patients_Var_Samp_Order_By>;
  variance?: InputMaybe<Portal_Patients_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "patients" */
export type Portal_Patients_Arr_Rel_Insert_Input = {
  data: Array<Portal_Patients_Insert_Input>;
  /** on conflict condition */
  on_conflict?: InputMaybe<Portal_Patients_On_Conflict>;
};

/** aggregate avg on columns */
export type Portal_Patients_Avg_Fields = {
  __typename?: 'portal_patients_avg_fields';
  id?: Maybe<Scalars['Float']>;
  insurance_id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
  organization_id?: Maybe<Scalars['Float']>;
  pharmacy_id?: Maybe<Scalars['Float']>;
  primary_care_physician_id?: Maybe<Scalars['Float']>;
  primary_guardian_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "patients" */
export type Portal_Patients_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  insurance_id?: InputMaybe<Order_By>;
  location_id?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  pharmacy_id?: InputMaybe<Order_By>;
  primary_care_physician_id?: InputMaybe<Order_By>;
  primary_guardian_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "patients". All fields are combined with a logical 'AND'. */
export type Portal_Patients_Bool_Exp = {
  _and?: InputMaybe<Array<Portal_Patients_Bool_Exp>>;
  _not?: InputMaybe<Portal_Patients_Bool_Exp>;
  _or?: InputMaybe<Array<Portal_Patients_Bool_Exp>>;
  additional_care_providers?: InputMaybe<Additional_Care_Providers_Bool_Exp>;
  allergies?: InputMaybe<Allergies_Bool_Exp>;
  birth_sex?: InputMaybe<String_Comparison_Exp>;
  consent_signature?: InputMaybe<String_Comparison_Exp>;
  contacts?: InputMaybe<Contacts_To_Patients_Bool_Exp>;
  date_of_birth?: InputMaybe<String_Comparison_Exp>;
  first_name?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  insurance?: InputMaybe<Insurances_Bool_Exp>;
  insurance_id?: InputMaybe<Int_Comparison_Exp>;
  insurance_type?: InputMaybe<String_Comparison_Exp>;
  is_deleted?: InputMaybe<Boolean_Comparison_Exp>;
  last_name?: InputMaybe<String_Comparison_Exp>;
  location_id?: InputMaybe<Int_Comparison_Exp>;
  master_id?: InputMaybe<String_Comparison_Exp>;
  organization_id?: InputMaybe<Int_Comparison_Exp>;
  pharmacy?: InputMaybe<Pharmacies_Bool_Exp>;
  pharmacy_id?: InputMaybe<Int_Comparison_Exp>;
  primary_care_physician_id?: InputMaybe<Int_Comparison_Exp>;
  primary_care_provider?: InputMaybe<Providers_Bool_Exp>;
  primary_guardian?: InputMaybe<Contacts_Bool_Exp>;
  primary_guardian_id?: InputMaybe<Int_Comparison_Exp>;
  primary_guardian_relation?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "patients" */
export enum Portal_Patients_Constraint {
  /** unique or primary key constraint */
  PatientPkey = 'patient_pkey'
}

/** input type for incrementing numeric columns in table "patients" */
export type Portal_Patients_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  insurance_id?: InputMaybe<Scalars['Int']>;
  location_id?: InputMaybe<Scalars['Int']>;
  organization_id?: InputMaybe<Scalars['Int']>;
  pharmacy_id?: InputMaybe<Scalars['Int']>;
  primary_care_physician_id?: InputMaybe<Scalars['Int']>;
  primary_guardian_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "patients" */
export type Portal_Patients_Insert_Input = {
  additional_care_providers?: InputMaybe<Additional_Care_Providers_Arr_Rel_Insert_Input>;
  allergies?: InputMaybe<Allergies_Obj_Rel_Insert_Input>;
  birth_sex?: InputMaybe<Scalars['String']>;
  consent_signature?: InputMaybe<Scalars['String']>;
  contacts?: InputMaybe<Contacts_To_Patients_Arr_Rel_Insert_Input>;
  date_of_birth?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  insurance?: InputMaybe<Insurances_Obj_Rel_Insert_Input>;
  insurance_id?: InputMaybe<Scalars['Int']>;
  insurance_type?: InputMaybe<Scalars['String']>;
  is_deleted?: InputMaybe<Scalars['Boolean']>;
  last_name?: InputMaybe<Scalars['String']>;
  location_id?: InputMaybe<Scalars['Int']>;
  master_id?: InputMaybe<Scalars['String']>;
  organization_id?: InputMaybe<Scalars['Int']>;
  pharmacy?: InputMaybe<Pharmacies_Obj_Rel_Insert_Input>;
  pharmacy_id?: InputMaybe<Scalars['Int']>;
  primary_care_physician_id?: InputMaybe<Scalars['Int']>;
  primary_care_provider?: InputMaybe<Providers_Obj_Rel_Insert_Input>;
  primary_guardian?: InputMaybe<Contacts_Obj_Rel_Insert_Input>;
  primary_guardian_id?: InputMaybe<Scalars['Int']>;
  primary_guardian_relation?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Portal_Patients_Max_Fields = {
  __typename?: 'portal_patients_max_fields';
  birth_sex?: Maybe<Scalars['String']>;
  consent_signature?: Maybe<Scalars['String']>;
  date_of_birth?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  insurance_id?: Maybe<Scalars['Int']>;
  insurance_type?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  location_id?: Maybe<Scalars['Int']>;
  master_id?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['Int']>;
  pharmacy_id?: Maybe<Scalars['Int']>;
  primary_care_physician_id?: Maybe<Scalars['Int']>;
  primary_guardian_id?: Maybe<Scalars['Int']>;
  primary_guardian_relation?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "patients" */
export type Portal_Patients_Max_Order_By = {
  birth_sex?: InputMaybe<Order_By>;
  consent_signature?: InputMaybe<Order_By>;
  date_of_birth?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  insurance_id?: InputMaybe<Order_By>;
  insurance_type?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  location_id?: InputMaybe<Order_By>;
  master_id?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  pharmacy_id?: InputMaybe<Order_By>;
  primary_care_physician_id?: InputMaybe<Order_By>;
  primary_guardian_id?: InputMaybe<Order_By>;
  primary_guardian_relation?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Portal_Patients_Min_Fields = {
  __typename?: 'portal_patients_min_fields';
  birth_sex?: Maybe<Scalars['String']>;
  consent_signature?: Maybe<Scalars['String']>;
  date_of_birth?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  insurance_id?: Maybe<Scalars['Int']>;
  insurance_type?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  location_id?: Maybe<Scalars['Int']>;
  master_id?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['Int']>;
  pharmacy_id?: Maybe<Scalars['Int']>;
  primary_care_physician_id?: Maybe<Scalars['Int']>;
  primary_guardian_id?: Maybe<Scalars['Int']>;
  primary_guardian_relation?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "patients" */
export type Portal_Patients_Min_Order_By = {
  birth_sex?: InputMaybe<Order_By>;
  consent_signature?: InputMaybe<Order_By>;
  date_of_birth?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  insurance_id?: InputMaybe<Order_By>;
  insurance_type?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  location_id?: InputMaybe<Order_By>;
  master_id?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  pharmacy_id?: InputMaybe<Order_By>;
  primary_care_physician_id?: InputMaybe<Order_By>;
  primary_guardian_id?: InputMaybe<Order_By>;
  primary_guardian_relation?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "patients" */
export type Portal_Patients_Mutation_Response = {
  __typename?: 'portal_patients_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Portal_Patients>;
};

/** input type for inserting object relation for remote table "patients" */
export type Portal_Patients_Obj_Rel_Insert_Input = {
  data: Portal_Patients_Insert_Input;
  /** on conflict condition */
  on_conflict?: InputMaybe<Portal_Patients_On_Conflict>;
};

/** on conflict condition type for table "patients" */
export type Portal_Patients_On_Conflict = {
  constraint: Portal_Patients_Constraint;
  update_columns?: Array<Portal_Patients_Update_Column>;
  where?: InputMaybe<Portal_Patients_Bool_Exp>;
};

/** Ordering options when selecting data from "patients". */
export type Portal_Patients_Order_By = {
  additional_care_providers_aggregate?: InputMaybe<Additional_Care_Providers_Aggregate_Order_By>;
  allergies?: InputMaybe<Allergies_Order_By>;
  birth_sex?: InputMaybe<Order_By>;
  consent_signature?: InputMaybe<Order_By>;
  contacts_aggregate?: InputMaybe<Contacts_To_Patients_Aggregate_Order_By>;
  date_of_birth?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  insurance?: InputMaybe<Insurances_Order_By>;
  insurance_id?: InputMaybe<Order_By>;
  insurance_type?: InputMaybe<Order_By>;
  is_deleted?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  location_id?: InputMaybe<Order_By>;
  master_id?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  pharmacy?: InputMaybe<Pharmacies_Order_By>;
  pharmacy_id?: InputMaybe<Order_By>;
  primary_care_physician_id?: InputMaybe<Order_By>;
  primary_care_provider?: InputMaybe<Providers_Order_By>;
  primary_guardian?: InputMaybe<Contacts_Order_By>;
  primary_guardian_id?: InputMaybe<Order_By>;
  primary_guardian_relation?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: portal_patients */
export type Portal_Patients_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "patients" */
export enum Portal_Patients_Select_Column {
  /** column name */
  BirthSex = 'birth_sex',
  /** column name */
  ConsentSignature = 'consent_signature',
  /** column name */
  DateOfBirth = 'date_of_birth',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  InsuranceId = 'insurance_id',
  /** column name */
  InsuranceType = 'insurance_type',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  LastName = 'last_name',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  MasterId = 'master_id',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  PharmacyId = 'pharmacy_id',
  /** column name */
  PrimaryCarePhysicianId = 'primary_care_physician_id',
  /** column name */
  PrimaryGuardianId = 'primary_guardian_id',
  /** column name */
  PrimaryGuardianRelation = 'primary_guardian_relation',
  /** column name */
  Status = 'status',
  /** column name */
  Type = 'type',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "patients" */
export type Portal_Patients_Set_Input = {
  birth_sex?: InputMaybe<Scalars['String']>;
  consent_signature?: InputMaybe<Scalars['String']>;
  date_of_birth?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  insurance_id?: InputMaybe<Scalars['Int']>;
  insurance_type?: InputMaybe<Scalars['String']>;
  is_deleted?: InputMaybe<Scalars['Boolean']>;
  last_name?: InputMaybe<Scalars['String']>;
  location_id?: InputMaybe<Scalars['Int']>;
  master_id?: InputMaybe<Scalars['String']>;
  organization_id?: InputMaybe<Scalars['Int']>;
  pharmacy_id?: InputMaybe<Scalars['Int']>;
  primary_care_physician_id?: InputMaybe<Scalars['Int']>;
  primary_guardian_id?: InputMaybe<Scalars['Int']>;
  primary_guardian_relation?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Portal_Patients_Stddev_Fields = {
  __typename?: 'portal_patients_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  insurance_id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
  organization_id?: Maybe<Scalars['Float']>;
  pharmacy_id?: Maybe<Scalars['Float']>;
  primary_care_physician_id?: Maybe<Scalars['Float']>;
  primary_guardian_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "patients" */
export type Portal_Patients_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  insurance_id?: InputMaybe<Order_By>;
  location_id?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  pharmacy_id?: InputMaybe<Order_By>;
  primary_care_physician_id?: InputMaybe<Order_By>;
  primary_guardian_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Portal_Patients_Stddev_Pop_Fields = {
  __typename?: 'portal_patients_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  insurance_id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
  organization_id?: Maybe<Scalars['Float']>;
  pharmacy_id?: Maybe<Scalars['Float']>;
  primary_care_physician_id?: Maybe<Scalars['Float']>;
  primary_guardian_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "patients" */
export type Portal_Patients_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  insurance_id?: InputMaybe<Order_By>;
  location_id?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  pharmacy_id?: InputMaybe<Order_By>;
  primary_care_physician_id?: InputMaybe<Order_By>;
  primary_guardian_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Portal_Patients_Stddev_Samp_Fields = {
  __typename?: 'portal_patients_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  insurance_id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
  organization_id?: Maybe<Scalars['Float']>;
  pharmacy_id?: Maybe<Scalars['Float']>;
  primary_care_physician_id?: Maybe<Scalars['Float']>;
  primary_guardian_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "patients" */
export type Portal_Patients_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  insurance_id?: InputMaybe<Order_By>;
  location_id?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  pharmacy_id?: InputMaybe<Order_By>;
  primary_care_physician_id?: InputMaybe<Order_By>;
  primary_guardian_id?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Portal_Patients_Sum_Fields = {
  __typename?: 'portal_patients_sum_fields';
  id?: Maybe<Scalars['Int']>;
  insurance_id?: Maybe<Scalars['Int']>;
  location_id?: Maybe<Scalars['Int']>;
  organization_id?: Maybe<Scalars['Int']>;
  pharmacy_id?: Maybe<Scalars['Int']>;
  primary_care_physician_id?: Maybe<Scalars['Int']>;
  primary_guardian_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "patients" */
export type Portal_Patients_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  insurance_id?: InputMaybe<Order_By>;
  location_id?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  pharmacy_id?: InputMaybe<Order_By>;
  primary_care_physician_id?: InputMaybe<Order_By>;
  primary_guardian_id?: InputMaybe<Order_By>;
};

/** update columns of table "patients" */
export enum Portal_Patients_Update_Column {
  /** column name */
  BirthSex = 'birth_sex',
  /** column name */
  ConsentSignature = 'consent_signature',
  /** column name */
  DateOfBirth = 'date_of_birth',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  InsuranceId = 'insurance_id',
  /** column name */
  InsuranceType = 'insurance_type',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  LastName = 'last_name',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  MasterId = 'master_id',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  PharmacyId = 'pharmacy_id',
  /** column name */
  PrimaryCarePhysicianId = 'primary_care_physician_id',
  /** column name */
  PrimaryGuardianId = 'primary_guardian_id',
  /** column name */
  PrimaryGuardianRelation = 'primary_guardian_relation',
  /** column name */
  Status = 'status',
  /** column name */
  Type = 'type',
  /** column name */
  UserId = 'user_id'
}

/** aggregate var_pop on columns */
export type Portal_Patients_Var_Pop_Fields = {
  __typename?: 'portal_patients_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  insurance_id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
  organization_id?: Maybe<Scalars['Float']>;
  pharmacy_id?: Maybe<Scalars['Float']>;
  primary_care_physician_id?: Maybe<Scalars['Float']>;
  primary_guardian_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "patients" */
export type Portal_Patients_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  insurance_id?: InputMaybe<Order_By>;
  location_id?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  pharmacy_id?: InputMaybe<Order_By>;
  primary_care_physician_id?: InputMaybe<Order_By>;
  primary_guardian_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Portal_Patients_Var_Samp_Fields = {
  __typename?: 'portal_patients_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  insurance_id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
  organization_id?: Maybe<Scalars['Float']>;
  pharmacy_id?: Maybe<Scalars['Float']>;
  primary_care_physician_id?: Maybe<Scalars['Float']>;
  primary_guardian_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "patients" */
export type Portal_Patients_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  insurance_id?: InputMaybe<Order_By>;
  location_id?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  pharmacy_id?: InputMaybe<Order_By>;
  primary_care_physician_id?: InputMaybe<Order_By>;
  primary_guardian_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Portal_Patients_Variance_Fields = {
  __typename?: 'portal_patients_variance_fields';
  id?: Maybe<Scalars['Float']>;
  insurance_id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
  organization_id?: Maybe<Scalars['Float']>;
  pharmacy_id?: Maybe<Scalars['Float']>;
  primary_care_physician_id?: Maybe<Scalars['Float']>;
  primary_guardian_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "patients" */
export type Portal_Patients_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  insurance_id?: InputMaybe<Order_By>;
  location_id?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  pharmacy_id?: InputMaybe<Order_By>;
  primary_care_physician_id?: InputMaybe<Order_By>;
  primary_guardian_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "programs" */
export type Programs = {
  __typename?: 'programs';
  admin_email?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  is_deleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregated selection of "programs" */
export type Programs_Aggregate = {
  __typename?: 'programs_aggregate';
  aggregate?: Maybe<Programs_Aggregate_Fields>;
  nodes: Array<Programs>;
};

/** aggregate fields of "programs" */
export type Programs_Aggregate_Fields = {
  __typename?: 'programs_aggregate_fields';
  avg?: Maybe<Programs_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Programs_Max_Fields>;
  min?: Maybe<Programs_Min_Fields>;
  stddev?: Maybe<Programs_Stddev_Fields>;
  stddev_pop?: Maybe<Programs_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Programs_Stddev_Samp_Fields>;
  sum?: Maybe<Programs_Sum_Fields>;
  var_pop?: Maybe<Programs_Var_Pop_Fields>;
  var_samp?: Maybe<Programs_Var_Samp_Fields>;
  variance?: Maybe<Programs_Variance_Fields>;
};


/** aggregate fields of "programs" */
export type Programs_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Programs_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Programs_Avg_Fields = {
  __typename?: 'programs_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "programs". All fields are combined with a logical 'AND'. */
export type Programs_Bool_Exp = {
  _and?: InputMaybe<Array<Programs_Bool_Exp>>;
  _not?: InputMaybe<Programs_Bool_Exp>;
  _or?: InputMaybe<Array<Programs_Bool_Exp>>;
  admin_email?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  is_deleted?: InputMaybe<Boolean_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "programs" */
export enum Programs_Constraint {
  /** unique or primary key constraint */
  ProgramsPkey = 'programs_pkey'
}

/** input type for incrementing numeric columns in table "programs" */
export type Programs_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "programs" */
export type Programs_Insert_Input = {
  admin_email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  is_deleted?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Programs_Max_Fields = {
  __typename?: 'programs_max_fields';
  admin_email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Programs_Min_Fields = {
  __typename?: 'programs_min_fields';
  admin_email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "programs" */
export type Programs_Mutation_Response = {
  __typename?: 'programs_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Programs>;
};

/** on conflict condition type for table "programs" */
export type Programs_On_Conflict = {
  constraint: Programs_Constraint;
  update_columns?: Array<Programs_Update_Column>;
  where?: InputMaybe<Programs_Bool_Exp>;
};

/** Ordering options when selecting data from "programs". */
export type Programs_Order_By = {
  admin_email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_deleted?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: programs */
export type Programs_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "programs" */
export enum Programs_Select_Column {
  /** column name */
  AdminEmail = 'admin_email',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "programs" */
export type Programs_Set_Input = {
  admin_email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  is_deleted?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Programs_Stddev_Fields = {
  __typename?: 'programs_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Programs_Stddev_Pop_Fields = {
  __typename?: 'programs_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Programs_Stddev_Samp_Fields = {
  __typename?: 'programs_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Programs_Sum_Fields = {
  __typename?: 'programs_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "programs" */
export enum Programs_Update_Column {
  /** column name */
  AdminEmail = 'admin_email',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  Name = 'name'
}

/** aggregate var_pop on columns */
export type Programs_Var_Pop_Fields = {
  __typename?: 'programs_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Programs_Var_Samp_Fields = {
  __typename?: 'programs_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Programs_Variance_Fields = {
  __typename?: 'programs_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/**
 * User-provided Providers (Physicians) that can be associated to patients directly (PCP relationship), or as an additional care relationship.
 *
 *
 * columns and relationships of "providers"
 *
 */
export type Providers = {
  __typename?: 'providers';
  /** An array relationship */
  additional_care_providers: Array<Additional_Care_Providers>;
  /** An aggregate relationship */
  additional_care_providers_aggregate: Additional_Care_Providers_Aggregate;
  consent_to_share: Scalars['Boolean'];
  first_name: Scalars['String'];
  id: Scalars['Int'];
  is_deleted: Scalars['Boolean'];
  last_name: Scalars['String'];
  phone_number: Scalars['String'];
  phone_number_2?: Maybe<Scalars['String']>;
  user_id: Scalars['String'];
};


/**
 * User-provided Providers (Physicians) that can be associated to patients directly (PCP relationship), or as an additional care relationship.
 *
 *
 * columns and relationships of "providers"
 *
 */
export type ProvidersAdditional_Care_ProvidersArgs = {
  distinct_on?: InputMaybe<Array<Additional_Care_Providers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Additional_Care_Providers_Order_By>>;
  where?: InputMaybe<Additional_Care_Providers_Bool_Exp>;
};


/**
 * User-provided Providers (Physicians) that can be associated to patients directly (PCP relationship), or as an additional care relationship.
 *
 *
 * columns and relationships of "providers"
 *
 */
export type ProvidersAdditional_Care_Providers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Additional_Care_Providers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Additional_Care_Providers_Order_By>>;
  where?: InputMaybe<Additional_Care_Providers_Bool_Exp>;
};

/** aggregated selection of "providers" */
export type Providers_Aggregate = {
  __typename?: 'providers_aggregate';
  aggregate?: Maybe<Providers_Aggregate_Fields>;
  nodes: Array<Providers>;
};

/** aggregate fields of "providers" */
export type Providers_Aggregate_Fields = {
  __typename?: 'providers_aggregate_fields';
  avg?: Maybe<Providers_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Providers_Max_Fields>;
  min?: Maybe<Providers_Min_Fields>;
  stddev?: Maybe<Providers_Stddev_Fields>;
  stddev_pop?: Maybe<Providers_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Providers_Stddev_Samp_Fields>;
  sum?: Maybe<Providers_Sum_Fields>;
  var_pop?: Maybe<Providers_Var_Pop_Fields>;
  var_samp?: Maybe<Providers_Var_Samp_Fields>;
  variance?: Maybe<Providers_Variance_Fields>;
};


/** aggregate fields of "providers" */
export type Providers_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Providers_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Providers_Avg_Fields = {
  __typename?: 'providers_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "providers". All fields are combined with a logical 'AND'. */
export type Providers_Bool_Exp = {
  _and?: InputMaybe<Array<Providers_Bool_Exp>>;
  _not?: InputMaybe<Providers_Bool_Exp>;
  _or?: InputMaybe<Array<Providers_Bool_Exp>>;
  additional_care_providers?: InputMaybe<Additional_Care_Providers_Bool_Exp>;
  consent_to_share?: InputMaybe<Boolean_Comparison_Exp>;
  first_name?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  is_deleted?: InputMaybe<Boolean_Comparison_Exp>;
  last_name?: InputMaybe<String_Comparison_Exp>;
  phone_number?: InputMaybe<String_Comparison_Exp>;
  phone_number_2?: InputMaybe<String_Comparison_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "providers" */
export enum Providers_Constraint {
  /** unique or primary key constraint */
  ProviderPkey = 'provider_pkey'
}

/** input type for incrementing numeric columns in table "providers" */
export type Providers_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "providers" */
export type Providers_Insert_Input = {
  additional_care_providers?: InputMaybe<Additional_Care_Providers_Arr_Rel_Insert_Input>;
  consent_to_share?: InputMaybe<Scalars['Boolean']>;
  first_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  is_deleted?: InputMaybe<Scalars['Boolean']>;
  last_name?: InputMaybe<Scalars['String']>;
  phone_number?: InputMaybe<Scalars['String']>;
  phone_number_2?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Providers_Max_Fields = {
  __typename?: 'providers_max_fields';
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  last_name?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  phone_number_2?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Providers_Min_Fields = {
  __typename?: 'providers_min_fields';
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  last_name?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  phone_number_2?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "providers" */
export type Providers_Mutation_Response = {
  __typename?: 'providers_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Providers>;
};

/** input type for inserting object relation for remote table "providers" */
export type Providers_Obj_Rel_Insert_Input = {
  data: Providers_Insert_Input;
  /** on conflict condition */
  on_conflict?: InputMaybe<Providers_On_Conflict>;
};

/** on conflict condition type for table "providers" */
export type Providers_On_Conflict = {
  constraint: Providers_Constraint;
  update_columns?: Array<Providers_Update_Column>;
  where?: InputMaybe<Providers_Bool_Exp>;
};

/** Ordering options when selecting data from "providers". */
export type Providers_Order_By = {
  additional_care_providers_aggregate?: InputMaybe<Additional_Care_Providers_Aggregate_Order_By>;
  consent_to_share?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_deleted?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  phone_number?: InputMaybe<Order_By>;
  phone_number_2?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: providers */
export type Providers_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "providers" */
export enum Providers_Select_Column {
  /** column name */
  ConsentToShare = 'consent_to_share',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  LastName = 'last_name',
  /** column name */
  PhoneNumber = 'phone_number',
  /** column name */
  PhoneNumber_2 = 'phone_number_2',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "providers" */
export type Providers_Set_Input = {
  consent_to_share?: InputMaybe<Scalars['Boolean']>;
  first_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  is_deleted?: InputMaybe<Scalars['Boolean']>;
  last_name?: InputMaybe<Scalars['String']>;
  phone_number?: InputMaybe<Scalars['String']>;
  phone_number_2?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Providers_Stddev_Fields = {
  __typename?: 'providers_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Providers_Stddev_Pop_Fields = {
  __typename?: 'providers_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Providers_Stddev_Samp_Fields = {
  __typename?: 'providers_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Providers_Sum_Fields = {
  __typename?: 'providers_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "providers" */
export enum Providers_Update_Column {
  /** column name */
  ConsentToShare = 'consent_to_share',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  LastName = 'last_name',
  /** column name */
  PhoneNumber = 'phone_number',
  /** column name */
  PhoneNumber_2 = 'phone_number_2',
  /** column name */
  UserId = 'user_id'
}

/** aggregate var_pop on columns */
export type Providers_Var_Pop_Fields = {
  __typename?: 'providers_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Providers_Var_Samp_Fields = {
  __typename?: 'providers_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Providers_Variance_Fields = {
  __typename?: 'providers_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Query_Root = {
  __typename?: 'query_root';
  /** An array relationship */
  additional_care_providers: Array<Additional_Care_Providers>;
  /** An aggregate relationship */
  additional_care_providers_aggregate: Additional_Care_Providers_Aggregate;
  /** fetch data from the table: "additional_care_providers" using primary key columns */
  additional_care_providers_by_pk?: Maybe<Additional_Care_Providers>;
  /** fetch data from the table: "allergies" */
  allergies: Array<Allergies>;
  /** fetch aggregated fields from the table: "allergies" */
  allergies_aggregate: Allergies_Aggregate;
  /** fetch data from the table: "allergies" using primary key columns */
  allergies_by_pk?: Maybe<Allergies>;
  /** fetch data from the table: "clearance_notes" */
  clearance_notes: Array<Clearance_Notes>;
  /** fetch aggregated fields from the table: "clearance_notes" */
  clearance_notes_aggregate: Clearance_Notes_Aggregate;
  /** fetch data from the table: "clearance_notes" using primary key columns */
  clearance_notes_by_pk?: Maybe<Clearance_Notes>;
  /** fetch data from the table: "contacts" */
  contacts: Array<Contacts>;
  /** fetch aggregated fields from the table: "contacts" */
  contacts_aggregate: Contacts_Aggregate;
  /** fetch data from the table: "contacts" using primary key columns */
  contacts_by_pk?: Maybe<Contacts>;
  /** fetch data from the table: "contacts_to_patients" */
  contacts_to_patients: Array<Contacts_To_Patients>;
  /** fetch aggregated fields from the table: "contacts_to_patients" */
  contacts_to_patients_aggregate: Contacts_To_Patients_Aggregate;
  /** fetch data from the table: "contacts_to_patients" using primary key columns */
  contacts_to_patients_by_pk?: Maybe<Contacts_To_Patients>;
  /** fetch data from the table: "discharges" */
  discharges: Array<Discharges>;
  /** fetch aggregated fields from the table: "discharges" */
  discharges_aggregate: Discharges_Aggregate;
  /** fetch data from the table: "discharges" using primary key columns */
  discharges_by_pk?: Maybe<Discharges>;
  /** fetch data from the table: "enrollments" */
  enrollments: Array<Enrollments>;
  /** fetch aggregated fields from the table: "enrollments" */
  enrollments_aggregate: Enrollments_Aggregate;
  /** fetch data from the table: "enrollments" using primary key columns */
  enrollments_by_pk?: Maybe<Enrollments>;
  /** fetch data from the table: "event_types" */
  event_types: Array<Event_Types>;
  /** fetch aggregated fields from the table: "event_types" */
  event_types_aggregate: Event_Types_Aggregate;
  /** fetch data from the table: "event_types" using primary key columns */
  event_types_by_pk?: Maybe<Event_Types>;
  /** An array relationship */
  events: Array<Events>;
  /** An aggregate relationship */
  events_aggregate: Events_Aggregate;
  /** fetch data from the table: "events" using primary key columns */
  events_by_pk?: Maybe<Events>;
  form_images: Array<Form_Images>;
  /** fetch data from the table: "form_localizations" */
  form_localizations: Array<Form_Localizations>;
  /** fetch aggregated fields from the table: "form_localizations" */
  form_localizations_aggregate: Form_Localizations_Aggregate;
  /** fetch data from the table: "form_localizations" using primary key columns */
  form_localizations_by_pk?: Maybe<Form_Localizations>;
  /** fetch data from the table: "form_types" */
  form_types: Array<Form_Types>;
  /** fetch aggregated fields from the table: "form_types" */
  form_types_aggregate: Form_Types_Aggregate;
  /** fetch data from the table: "form_types" using primary key columns */
  form_types_by_pk?: Maybe<Form_Types>;
  /** fetch data from the table: "forms" */
  forms: Array<Forms>;
  /** fetch aggregated fields from the table: "forms" */
  forms_aggregate: Forms_Aggregate;
  /** fetch data from the table: "forms" using primary key columns */
  forms_by_pk?: Maybe<Forms>;
  /** Get Video */
  getVideo?: Maybe<Video>;
  /** fetch data from the table: "insurance_vendors" */
  insurance_vendors: Array<Insurance_Vendors>;
  /** fetch aggregated fields from the table: "insurance_vendors" */
  insurance_vendors_aggregate: Insurance_Vendors_Aggregate;
  /** fetch data from the table: "insurance_vendors" using primary key columns */
  insurance_vendors_by_pk?: Maybe<Insurance_Vendors>;
  /** fetch data from the table: "insurances" */
  insurances: Array<Insurances>;
  /** fetch aggregated fields from the table: "insurances" */
  insurances_aggregate: Insurances_Aggregate;
  /** fetch data from the table: "insurances" using primary key columns */
  insurances_by_pk?: Maybe<Insurances>;
  /** An array relationship */
  medication_orders: Array<Medication_Orders>;
  /** An aggregate relationship */
  medication_orders_aggregate: Medication_Orders_Aggregate;
  /** fetch data from the table: "medication_orders" using primary key columns */
  medication_orders_by_pk?: Maybe<Medication_Orders>;
  /** fetch data from the table: "order_dshs_covid_test" */
  order_dshs_covid_test: Array<Order_Dshs_Covid_Test>;
  /** fetch aggregated fields from the table: "order_dshs_covid_test" */
  order_dshs_covid_test_aggregate: Order_Dshs_Covid_Test_Aggregate;
  /** fetch data from the table: "order_dshs_covid_test" using primary key columns */
  order_dshs_covid_test_by_pk?: Maybe<Order_Dshs_Covid_Test>;
  /** fetch data from the table: "order_height_and_weight" */
  order_height_and_weight: Array<Order_Height_And_Weight>;
  /** fetch aggregated fields from the table: "order_height_and_weight" */
  order_height_and_weight_aggregate: Order_Height_And_Weight_Aggregate;
  /** fetch data from the table: "order_height_and_weight" using primary key columns */
  order_height_and_weight_by_pk?: Maybe<Order_Height_And_Weight>;
  /** fetch data from the table: "order_medical_assessment" */
  order_medical_assessment: Array<Order_Medical_Assessment>;
  /** fetch aggregated fields from the table: "order_medical_assessment" */
  order_medical_assessment_aggregate: Order_Medical_Assessment_Aggregate;
  /** fetch data from the table: "order_medical_assessment" using primary key columns */
  order_medical_assessment_by_pk?: Maybe<Order_Medical_Assessment>;
  /** fetch data from the table: "order_musculoskeletal" */
  order_musculoskeletal: Array<Order_Musculoskeletal>;
  /** fetch aggregated fields from the table: "order_musculoskeletal" */
  order_musculoskeletal_aggregate: Order_Musculoskeletal_Aggregate;
  /** fetch data from the table: "order_musculoskeletal" using primary key columns */
  order_musculoskeletal_by_pk?: Maybe<Order_Musculoskeletal>;
  /** fetch data from the table: "order_type" */
  order_type: Array<Order_Type>;
  /** fetch aggregated fields from the table: "order_type" */
  order_type_aggregate: Order_Type_Aggregate;
  /** fetch data from the table: "order_type" using primary key columns */
  order_type_by_pk?: Maybe<Order_Type>;
  /** fetch data from the table: "order_vision" */
  order_vision: Array<Order_Vision>;
  /** fetch aggregated fields from the table: "order_vision" */
  order_vision_aggregate: Order_Vision_Aggregate;
  /** fetch data from the table: "order_vision" using primary key columns */
  order_vision_by_pk?: Maybe<Order_Vision>;
  /** fetch data from the table: "orders" */
  orders: Array<Orders>;
  /** An aggregate relationship */
  orders_aggregate: Orders_Aggregate;
  /** fetch data from the table: "orders" using primary key columns */
  orders_by_pk?: Maybe<Orders>;
  /** fetch data from the table: "organizations.district_days_off" */
  organizations_district_days_off: Array<Organizations_District_Days_Off>;
  /** fetch aggregated fields from the table: "organizations.district_days_off" */
  organizations_district_days_off_aggregate: Organizations_District_Days_Off_Aggregate;
  /** fetch data from the table: "organizations.district_days_off" using primary key columns */
  organizations_district_days_off_by_pk?: Maybe<Organizations_District_Days_Off>;
  /** fetch data from the table: "organizations.features" */
  organizations_features: Array<Organizations_Features>;
  /** fetch aggregated fields from the table: "organizations.features" */
  organizations_features_aggregate: Organizations_Features_Aggregate;
  /** fetch data from the table: "organizations.features" using primary key columns */
  organizations_features_by_pk?: Maybe<Organizations_Features>;
  /** fetch data from the table: "organizations.locations" */
  organizations_locations: Array<Organizations_Locations>;
  /** fetch aggregated fields from the table: "organizations.locations" */
  organizations_locations_aggregate: Organizations_Locations_Aggregate;
  /** fetch data from the table: "organizations.locations" using primary key columns */
  organizations_locations_by_pk?: Maybe<Organizations_Locations>;
  /** fetch data from the table: "organizations.organizations" */
  organizations_organizations: Array<Organizations_Organizations>;
  /** fetch aggregated fields from the table: "organizations.organizations" */
  organizations_organizations_aggregate: Organizations_Organizations_Aggregate;
  /** fetch data from the table: "organizations.organizations" using primary key columns */
  organizations_organizations_by_pk?: Maybe<Organizations_Organizations>;
  /** fetch data from the table: "organizations.organizations_features" */
  organizations_organizations_features: Array<Organizations_Organizations_Features>;
  /** fetch aggregated fields from the table: "organizations.organizations_features" */
  organizations_organizations_features_aggregate: Organizations_Organizations_Features_Aggregate;
  /** fetch data from the table: "organizations.organizations_features" using primary key columns */
  organizations_organizations_features_by_pk?: Maybe<Organizations_Organizations_Features>;
  /** fetch data from the table: "organizations.regions" */
  organizations_regions: Array<Organizations_Regions>;
  /** fetch aggregated fields from the table: "organizations.regions" */
  organizations_regions_aggregate: Organizations_Regions_Aggregate;
  /** fetch data from the table: "organizations.regions" using primary key columns */
  organizations_regions_by_pk?: Maybe<Organizations_Regions>;
  /** fetch data from the table: "organizations.organization_x_schoolyear" */
  organizations_school_years: Array<Organizations_School_Years>;
  /** fetch aggregated fields from the table: "organizations.organization_x_schoolyear" */
  organizations_school_years_aggregate: Organizations_School_Years_Aggregate;
  /** fetch data from the table: "organizations.organization_x_schoolyear" using primary key columns */
  organizations_school_years_by_pk?: Maybe<Organizations_School_Years>;
  /** fetch data from the table: "organizations.location_x_testing_location" */
  organizations_testing_locations: Array<Organizations_Testing_Locations>;
  /** fetch aggregated fields from the table: "organizations.location_x_testing_location" */
  organizations_testing_locations_aggregate: Organizations_Testing_Locations_Aggregate;
  /** fetch data from the table: "organizations.location_x_testing_location" using primary key columns */
  organizations_testing_locations_by_pk?: Maybe<Organizations_Testing_Locations>;
  /** fetch data from the table: "mat_tmp_fast_demographics" */
  patient_demographics: Array<Patient_Demographics>;
  /** fetch aggregated fields from the table: "mat_tmp_fast_demographics" */
  patient_demographics_aggregate: Patient_Demographics_Aggregate;
  /** fetch data from the table: "mat_fast_search_json" */
  patients: Array<Patients>;
  /** fetch aggregated fields from the table: "mat_fast_search_json" */
  patients_aggregate: Patients_Aggregate;
  /** fetch data from the table: "pharmacies" */
  pharmacies: Array<Pharmacies>;
  /** fetch aggregated fields from the table: "pharmacies" */
  pharmacies_aggregate: Pharmacies_Aggregate;
  /** fetch data from the table: "pharmacies" using primary key columns */
  pharmacies_by_pk?: Maybe<Pharmacies>;
  /** fetch data from the table: "place_of_service" */
  place_of_service: Array<Place_Of_Service>;
  /** fetch aggregated fields from the table: "place_of_service" */
  place_of_service_aggregate: Place_Of_Service_Aggregate;
  /** fetch data from the table: "place_of_service" using primary key columns */
  place_of_service_by_pk?: Maybe<Place_Of_Service>;
  /** fetch data from the table: "pond_form_localizations" */
  pond_form_localizations: Array<Pond_Form_Localizations>;
  /** fetch aggregated fields from the table: "pond_form_localizations" */
  pond_form_localizations_aggregate: Pond_Form_Localizations_Aggregate;
  /** fetch data from the table: "pond_form_localizations" using primary key columns */
  pond_form_localizations_by_pk?: Maybe<Pond_Form_Localizations>;
  /** fetch data from the table: "pond_form_types" */
  pond_form_types: Array<Pond_Form_Types>;
  /** fetch aggregated fields from the table: "pond_form_types" */
  pond_form_types_aggregate: Pond_Form_Types_Aggregate;
  /** fetch data from the table: "pond_form_types" using primary key columns */
  pond_form_types_by_pk?: Maybe<Pond_Form_Types>;
  /** fetch data from the table: "pond_forms" */
  pond_forms: Array<Pond_Forms>;
  /** fetch aggregated fields from the table: "pond_forms" */
  pond_forms_aggregate: Pond_Forms_Aggregate;
  /** fetch data from the table: "pond_forms" using primary key columns */
  pond_forms_by_pk?: Maybe<Pond_Forms>;
  /** fetch data from the table: "patients" */
  portal_patients: Array<Portal_Patients>;
  /** fetch aggregated fields from the table: "patients" */
  portal_patients_aggregate: Portal_Patients_Aggregate;
  /** fetch data from the table: "patients" using primary key columns */
  portal_patients_by_pk?: Maybe<Portal_Patients>;
  /** fetch data from the table: "programs" */
  programs: Array<Programs>;
  /** fetch aggregated fields from the table: "programs" */
  programs_aggregate: Programs_Aggregate;
  /** fetch data from the table: "programs" using primary key columns */
  programs_by_pk?: Maybe<Programs>;
  /** fetch data from the table: "providers" */
  providers: Array<Providers>;
  /** fetch aggregated fields from the table: "providers" */
  providers_aggregate: Providers_Aggregate;
  /** fetch data from the table: "providers" using primary key columns */
  providers_by_pk?: Maybe<Providers>;
  /** fetch data from the table: "roles" */
  roles: Array<Roles>;
  /** fetch aggregated fields from the table: "roles" */
  roles_aggregate: Roles_Aggregate;
  /** fetch data from the table: "roles" using primary key columns */
  roles_by_pk?: Maybe<Roles>;
  /** An array relationship */
  session_logs: Array<Session_Logs>;
  /** An aggregate relationship */
  session_logs_aggregate: Session_Logs_Aggregate;
  /** fetch data from the table: "session_logs" using primary key columns */
  session_logs_by_pk?: Maybe<Session_Logs>;
  /** fetch data from the table: "session_order_config" */
  session_order_config: Array<Session_Order_Config>;
  /** fetch aggregated fields from the table: "session_order_config" */
  session_order_config_aggregate: Session_Order_Config_Aggregate;
  /** fetch data from the table: "session_order_config" using primary key columns */
  session_order_config_by_pk?: Maybe<Session_Order_Config>;
  /** fetch data from the table: "session_station_config" */
  session_station_config: Array<Session_Station_Config>;
  /** fetch aggregated fields from the table: "session_station_config" */
  session_station_config_aggregate: Session_Station_Config_Aggregate;
  /** fetch data from the table: "session_station_config" using primary key columns */
  session_station_config_by_pk?: Maybe<Session_Station_Config>;
  /** fetch data from the table: "session_type" */
  session_type: Array<Session_Type>;
  /** fetch aggregated fields from the table: "session_type" */
  session_type_aggregate: Session_Type_Aggregate;
  /** fetch data from the table: "session_type" using primary key columns */
  session_type_by_pk?: Maybe<Session_Type>;
  /** fetch data from the table: "sessions" */
  sessions: Array<Sessions>;
  /** fetch aggregated fields from the table: "sessions" */
  sessions_aggregate: Sessions_Aggregate;
  /** fetch data from the table: "sessions" using primary key columns */
  sessions_by_pk?: Maybe<Sessions>;
  /** An array relationship */
  station_orders: Array<Station_Orders>;
  /** An aggregate relationship */
  station_orders_aggregate: Station_Orders_Aggregate;
  /** fetch data from the table: "station_orders" using primary key columns */
  station_orders_by_pk?: Maybe<Station_Orders>;
  /** fetch data from the table: "station_type" */
  station_type: Array<Station_Type>;
  /** fetch aggregated fields from the table: "station_type" */
  station_type_aggregate: Station_Type_Aggregate;
  /** fetch data from the table: "station_type" using primary key columns */
  station_type_by_pk?: Maybe<Station_Type>;
  /** An array relationship */
  station_visits: Array<Station_Visits>;
  /** An aggregate relationship */
  station_visits_aggregate: Station_Visits_Aggregate;
  /** fetch data from the table: "station_visits" using primary key columns */
  station_visits_by_pk?: Maybe<Station_Visits>;
  /** An array relationship */
  stations: Array<Stations>;
  /** An aggregate relationship */
  stations_aggregate: Stations_Aggregate;
  /** fetch data from the table: "stations" using primary key columns */
  stations_by_pk?: Maybe<Stations>;
  /** An array relationship */
  test_orders: Array<Test_Orders>;
  /** An aggregate relationship */
  test_orders_aggregate: Test_Orders_Aggregate;
  /** fetch data from the table: "test_orders" using primary key columns */
  test_orders_by_pk?: Maybe<Test_Orders>;
  /** fetch data from the table: "users" */
  users: Array<Users>;
  /** fetch aggregated fields from the table: "users" */
  users_aggregate: Users_Aggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>;
  /** fetch data from the table: "video_calls" */
  video_calls: Array<Video_Calls>;
  /** fetch aggregated fields from the table: "video_calls" */
  video_calls_aggregate: Video_Calls_Aggregate;
  /** fetch data from the table: "video_calls" using primary key columns */
  video_calls_by_pk?: Maybe<Video_Calls>;
  /** An array relationship */
  visits: Array<Visits>;
  /** An aggregate relationship */
  visits_aggregate: Visits_Aggregate;
  /** fetch data from the table: "visits" using primary key columns */
  visits_by_pk?: Maybe<Visits>;
};


export type Query_RootAdditional_Care_ProvidersArgs = {
  distinct_on?: InputMaybe<Array<Additional_Care_Providers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Additional_Care_Providers_Order_By>>;
  where?: InputMaybe<Additional_Care_Providers_Bool_Exp>;
};


export type Query_RootAdditional_Care_Providers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Additional_Care_Providers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Additional_Care_Providers_Order_By>>;
  where?: InputMaybe<Additional_Care_Providers_Bool_Exp>;
};


export type Query_RootAdditional_Care_Providers_By_PkArgs = {
  patient_id: Scalars['Int'];
  provider_id: Scalars['Int'];
};


export type Query_RootAllergiesArgs = {
  distinct_on?: InputMaybe<Array<Allergies_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Allergies_Order_By>>;
  where?: InputMaybe<Allergies_Bool_Exp>;
};


export type Query_RootAllergies_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Allergies_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Allergies_Order_By>>;
  where?: InputMaybe<Allergies_Bool_Exp>;
};


export type Query_RootAllergies_By_PkArgs = {
  patient_id: Scalars['Int'];
};


export type Query_RootClearance_NotesArgs = {
  distinct_on?: InputMaybe<Array<Clearance_Notes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Clearance_Notes_Order_By>>;
  where?: InputMaybe<Clearance_Notes_Bool_Exp>;
};


export type Query_RootClearance_Notes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Clearance_Notes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Clearance_Notes_Order_By>>;
  where?: InputMaybe<Clearance_Notes_Bool_Exp>;
};


export type Query_RootClearance_Notes_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootContactsArgs = {
  distinct_on?: InputMaybe<Array<Contacts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contacts_Order_By>>;
  where?: InputMaybe<Contacts_Bool_Exp>;
};


export type Query_RootContacts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contacts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contacts_Order_By>>;
  where?: InputMaybe<Contacts_Bool_Exp>;
};


export type Query_RootContacts_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootContacts_To_PatientsArgs = {
  distinct_on?: InputMaybe<Array<Contacts_To_Patients_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contacts_To_Patients_Order_By>>;
  where?: InputMaybe<Contacts_To_Patients_Bool_Exp>;
};


export type Query_RootContacts_To_Patients_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contacts_To_Patients_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contacts_To_Patients_Order_By>>;
  where?: InputMaybe<Contacts_To_Patients_Bool_Exp>;
};


export type Query_RootContacts_To_Patients_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootDischargesArgs = {
  distinct_on?: InputMaybe<Array<Discharges_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Discharges_Order_By>>;
  where?: InputMaybe<Discharges_Bool_Exp>;
};


export type Query_RootDischarges_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Discharges_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Discharges_Order_By>>;
  where?: InputMaybe<Discharges_Bool_Exp>;
};


export type Query_RootDischarges_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootEnrollmentsArgs = {
  distinct_on?: InputMaybe<Array<Enrollments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enrollments_Order_By>>;
  where?: InputMaybe<Enrollments_Bool_Exp>;
};


export type Query_RootEnrollments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enrollments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enrollments_Order_By>>;
  where?: InputMaybe<Enrollments_Bool_Exp>;
};


export type Query_RootEnrollments_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootEvent_TypesArgs = {
  distinct_on?: InputMaybe<Array<Event_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Event_Types_Order_By>>;
  where?: InputMaybe<Event_Types_Bool_Exp>;
};


export type Query_RootEvent_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Event_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Event_Types_Order_By>>;
  where?: InputMaybe<Event_Types_Bool_Exp>;
};


export type Query_RootEvent_Types_By_PkArgs = {
  code: Scalars['String'];
};


export type Query_RootEventsArgs = {
  distinct_on?: InputMaybe<Array<Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Events_Order_By>>;
  where?: InputMaybe<Events_Bool_Exp>;
};


export type Query_RootEvents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Events_Order_By>>;
  where?: InputMaybe<Events_Bool_Exp>;
};


export type Query_RootEvents_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootForm_ImagesArgs = {
  patient_id: Scalars['String'];
};


export type Query_RootForm_LocalizationsArgs = {
  distinct_on?: InputMaybe<Array<Form_Localizations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Form_Localizations_Order_By>>;
  where?: InputMaybe<Form_Localizations_Bool_Exp>;
};


export type Query_RootForm_Localizations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Form_Localizations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Form_Localizations_Order_By>>;
  where?: InputMaybe<Form_Localizations_Bool_Exp>;
};


export type Query_RootForm_Localizations_By_PkArgs = {
  locale: Scalars['String'];
  type: Scalars['String'];
};


export type Query_RootForm_TypesArgs = {
  distinct_on?: InputMaybe<Array<Form_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Form_Types_Order_By>>;
  where?: InputMaybe<Form_Types_Bool_Exp>;
};


export type Query_RootForm_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Form_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Form_Types_Order_By>>;
  where?: InputMaybe<Form_Types_Bool_Exp>;
};


export type Query_RootForm_Types_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootFormsArgs = {
  distinct_on?: InputMaybe<Array<Forms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Forms_Order_By>>;
  where?: InputMaybe<Forms_Bool_Exp>;
};


export type Query_RootForms_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Forms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Forms_Order_By>>;
  where?: InputMaybe<Forms_Bool_Exp>;
};


export type Query_RootForms_By_PkArgs = {
  patient_id: Scalars['String'];
  type: Scalars['String'];
};


export type Query_RootGetVideoArgs = {
  visitId: Scalars['Int'];
};


export type Query_RootInsurance_VendorsArgs = {
  distinct_on?: InputMaybe<Array<Insurance_Vendors_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Insurance_Vendors_Order_By>>;
  where?: InputMaybe<Insurance_Vendors_Bool_Exp>;
};


export type Query_RootInsurance_Vendors_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Insurance_Vendors_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Insurance_Vendors_Order_By>>;
  where?: InputMaybe<Insurance_Vendors_Bool_Exp>;
};


export type Query_RootInsurance_Vendors_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootInsurancesArgs = {
  distinct_on?: InputMaybe<Array<Insurances_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Insurances_Order_By>>;
  where?: InputMaybe<Insurances_Bool_Exp>;
};


export type Query_RootInsurances_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Insurances_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Insurances_Order_By>>;
  where?: InputMaybe<Insurances_Bool_Exp>;
};


export type Query_RootInsurances_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootMedication_OrdersArgs = {
  distinct_on?: InputMaybe<Array<Medication_Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Medication_Orders_Order_By>>;
  where?: InputMaybe<Medication_Orders_Bool_Exp>;
};


export type Query_RootMedication_Orders_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Medication_Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Medication_Orders_Order_By>>;
  where?: InputMaybe<Medication_Orders_Bool_Exp>;
};


export type Query_RootMedication_Orders_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootOrder_Dshs_Covid_TestArgs = {
  distinct_on?: InputMaybe<Array<Order_Dshs_Covid_Test_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Order_Dshs_Covid_Test_Order_By>>;
  where?: InputMaybe<Order_Dshs_Covid_Test_Bool_Exp>;
};


export type Query_RootOrder_Dshs_Covid_Test_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Order_Dshs_Covid_Test_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Order_Dshs_Covid_Test_Order_By>>;
  where?: InputMaybe<Order_Dshs_Covid_Test_Bool_Exp>;
};


export type Query_RootOrder_Dshs_Covid_Test_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootOrder_Height_And_WeightArgs = {
  distinct_on?: InputMaybe<Array<Order_Height_And_Weight_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Order_Height_And_Weight_Order_By>>;
  where?: InputMaybe<Order_Height_And_Weight_Bool_Exp>;
};


export type Query_RootOrder_Height_And_Weight_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Order_Height_And_Weight_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Order_Height_And_Weight_Order_By>>;
  where?: InputMaybe<Order_Height_And_Weight_Bool_Exp>;
};


export type Query_RootOrder_Height_And_Weight_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootOrder_Medical_AssessmentArgs = {
  distinct_on?: InputMaybe<Array<Order_Medical_Assessment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Order_Medical_Assessment_Order_By>>;
  where?: InputMaybe<Order_Medical_Assessment_Bool_Exp>;
};


export type Query_RootOrder_Medical_Assessment_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Order_Medical_Assessment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Order_Medical_Assessment_Order_By>>;
  where?: InputMaybe<Order_Medical_Assessment_Bool_Exp>;
};


export type Query_RootOrder_Medical_Assessment_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootOrder_MusculoskeletalArgs = {
  distinct_on?: InputMaybe<Array<Order_Musculoskeletal_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Order_Musculoskeletal_Order_By>>;
  where?: InputMaybe<Order_Musculoskeletal_Bool_Exp>;
};


export type Query_RootOrder_Musculoskeletal_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Order_Musculoskeletal_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Order_Musculoskeletal_Order_By>>;
  where?: InputMaybe<Order_Musculoskeletal_Bool_Exp>;
};


export type Query_RootOrder_Musculoskeletal_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootOrder_TypeArgs = {
  distinct_on?: InputMaybe<Array<Order_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Order_Type_Order_By>>;
  where?: InputMaybe<Order_Type_Bool_Exp>;
};


export type Query_RootOrder_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Order_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Order_Type_Order_By>>;
  where?: InputMaybe<Order_Type_Bool_Exp>;
};


export type Query_RootOrder_Type_By_PkArgs = {
  code: Scalars['String'];
};


export type Query_RootOrder_VisionArgs = {
  distinct_on?: InputMaybe<Array<Order_Vision_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Order_Vision_Order_By>>;
  where?: InputMaybe<Order_Vision_Bool_Exp>;
};


export type Query_RootOrder_Vision_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Order_Vision_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Order_Vision_Order_By>>;
  where?: InputMaybe<Order_Vision_Bool_Exp>;
};


export type Query_RootOrder_Vision_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootOrdersArgs = {
  distinct_on?: InputMaybe<Array<Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Orders_Order_By>>;
  where?: InputMaybe<Orders_Bool_Exp>;
};


export type Query_RootOrders_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Orders_Order_By>>;
  where?: InputMaybe<Orders_Bool_Exp>;
};


export type Query_RootOrders_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootOrganizations_District_Days_OffArgs = {
  distinct_on?: InputMaybe<Array<Organizations_District_Days_Off_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organizations_District_Days_Off_Order_By>>;
  where?: InputMaybe<Organizations_District_Days_Off_Bool_Exp>;
};


export type Query_RootOrganizations_District_Days_Off_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organizations_District_Days_Off_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organizations_District_Days_Off_Order_By>>;
  where?: InputMaybe<Organizations_District_Days_Off_Bool_Exp>;
};


export type Query_RootOrganizations_District_Days_Off_By_PkArgs = {
  days_off_id: Scalars['bigint'];
};


export type Query_RootOrganizations_FeaturesArgs = {
  distinct_on?: InputMaybe<Array<Organizations_Features_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organizations_Features_Order_By>>;
  where?: InputMaybe<Organizations_Features_Bool_Exp>;
};


export type Query_RootOrganizations_Features_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organizations_Features_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organizations_Features_Order_By>>;
  where?: InputMaybe<Organizations_Features_Bool_Exp>;
};


export type Query_RootOrganizations_Features_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootOrganizations_LocationsArgs = {
  distinct_on?: InputMaybe<Array<Organizations_Locations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organizations_Locations_Order_By>>;
  where?: InputMaybe<Organizations_Locations_Bool_Exp>;
};


export type Query_RootOrganizations_Locations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organizations_Locations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organizations_Locations_Order_By>>;
  where?: InputMaybe<Organizations_Locations_Bool_Exp>;
};


export type Query_RootOrganizations_Locations_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootOrganizations_OrganizationsArgs = {
  distinct_on?: InputMaybe<Array<Organizations_Organizations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organizations_Organizations_Order_By>>;
  where?: InputMaybe<Organizations_Organizations_Bool_Exp>;
};


export type Query_RootOrganizations_Organizations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organizations_Organizations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organizations_Organizations_Order_By>>;
  where?: InputMaybe<Organizations_Organizations_Bool_Exp>;
};


export type Query_RootOrganizations_Organizations_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootOrganizations_Organizations_FeaturesArgs = {
  distinct_on?: InputMaybe<Array<Organizations_Organizations_Features_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organizations_Organizations_Features_Order_By>>;
  where?: InputMaybe<Organizations_Organizations_Features_Bool_Exp>;
};


export type Query_RootOrganizations_Organizations_Features_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organizations_Organizations_Features_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organizations_Organizations_Features_Order_By>>;
  where?: InputMaybe<Organizations_Organizations_Features_Bool_Exp>;
};


export type Query_RootOrganizations_Organizations_Features_By_PkArgs = {
  feature_id: Scalars['String'];
  organization_id: Scalars['Int'];
};


export type Query_RootOrganizations_RegionsArgs = {
  distinct_on?: InputMaybe<Array<Organizations_Regions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organizations_Regions_Order_By>>;
  where?: InputMaybe<Organizations_Regions_Bool_Exp>;
};


export type Query_RootOrganizations_Regions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organizations_Regions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organizations_Regions_Order_By>>;
  where?: InputMaybe<Organizations_Regions_Bool_Exp>;
};


export type Query_RootOrganizations_Regions_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootOrganizations_School_YearsArgs = {
  distinct_on?: InputMaybe<Array<Organizations_School_Years_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organizations_School_Years_Order_By>>;
  where?: InputMaybe<Organizations_School_Years_Bool_Exp>;
};


export type Query_RootOrganizations_School_Years_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organizations_School_Years_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organizations_School_Years_Order_By>>;
  where?: InputMaybe<Organizations_School_Years_Bool_Exp>;
};


export type Query_RootOrganizations_School_Years_By_PkArgs = {
  organization_x_schoolyear_id: Scalars['bigint'];
};


export type Query_RootOrganizations_Testing_LocationsArgs = {
  distinct_on?: InputMaybe<Array<Organizations_Testing_Locations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organizations_Testing_Locations_Order_By>>;
  where?: InputMaybe<Organizations_Testing_Locations_Bool_Exp>;
};


export type Query_RootOrganizations_Testing_Locations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organizations_Testing_Locations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organizations_Testing_Locations_Order_By>>;
  where?: InputMaybe<Organizations_Testing_Locations_Bool_Exp>;
};


export type Query_RootOrganizations_Testing_Locations_By_PkArgs = {
  location_x_testing_location_id: Scalars['bigint'];
};


export type Query_RootPatient_DemographicsArgs = {
  distinct_on?: InputMaybe<Array<Patient_Demographics_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Patient_Demographics_Order_By>>;
  where?: InputMaybe<Patient_Demographics_Bool_Exp>;
};


export type Query_RootPatient_Demographics_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Patient_Demographics_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Patient_Demographics_Order_By>>;
  where?: InputMaybe<Patient_Demographics_Bool_Exp>;
};


export type Query_RootPatientsArgs = {
  distinct_on?: InputMaybe<Array<Patients_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Patients_Order_By>>;
  where?: InputMaybe<Patients_Bool_Exp>;
};


export type Query_RootPatients_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Patients_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Patients_Order_By>>;
  where?: InputMaybe<Patients_Bool_Exp>;
};


export type Query_RootPharmaciesArgs = {
  distinct_on?: InputMaybe<Array<Pharmacies_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Pharmacies_Order_By>>;
  where?: InputMaybe<Pharmacies_Bool_Exp>;
};


export type Query_RootPharmacies_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Pharmacies_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Pharmacies_Order_By>>;
  where?: InputMaybe<Pharmacies_Bool_Exp>;
};


export type Query_RootPharmacies_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootPlace_Of_ServiceArgs = {
  distinct_on?: InputMaybe<Array<Place_Of_Service_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Place_Of_Service_Order_By>>;
  where?: InputMaybe<Place_Of_Service_Bool_Exp>;
};


export type Query_RootPlace_Of_Service_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Place_Of_Service_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Place_Of_Service_Order_By>>;
  where?: InputMaybe<Place_Of_Service_Bool_Exp>;
};


export type Query_RootPlace_Of_Service_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootPond_Form_LocalizationsArgs = {
  distinct_on?: InputMaybe<Array<Pond_Form_Localizations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Pond_Form_Localizations_Order_By>>;
  where?: InputMaybe<Pond_Form_Localizations_Bool_Exp>;
};


export type Query_RootPond_Form_Localizations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Pond_Form_Localizations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Pond_Form_Localizations_Order_By>>;
  where?: InputMaybe<Pond_Form_Localizations_Bool_Exp>;
};


export type Query_RootPond_Form_Localizations_By_PkArgs = {
  form_type_code: Scalars['String'];
  locale: Scalars['String'];
};


export type Query_RootPond_Form_TypesArgs = {
  distinct_on?: InputMaybe<Array<Pond_Form_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Pond_Form_Types_Order_By>>;
  where?: InputMaybe<Pond_Form_Types_Bool_Exp>;
};


export type Query_RootPond_Form_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Pond_Form_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Pond_Form_Types_Order_By>>;
  where?: InputMaybe<Pond_Form_Types_Bool_Exp>;
};


export type Query_RootPond_Form_Types_By_PkArgs = {
  code: Scalars['String'];
};


export type Query_RootPond_FormsArgs = {
  distinct_on?: InputMaybe<Array<Pond_Forms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Pond_Forms_Order_By>>;
  where?: InputMaybe<Pond_Forms_Bool_Exp>;
};


export type Query_RootPond_Forms_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Pond_Forms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Pond_Forms_Order_By>>;
  where?: InputMaybe<Pond_Forms_Bool_Exp>;
};


export type Query_RootPond_Forms_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootPortal_PatientsArgs = {
  distinct_on?: InputMaybe<Array<Portal_Patients_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Portal_Patients_Order_By>>;
  where?: InputMaybe<Portal_Patients_Bool_Exp>;
};


export type Query_RootPortal_Patients_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Portal_Patients_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Portal_Patients_Order_By>>;
  where?: InputMaybe<Portal_Patients_Bool_Exp>;
};


export type Query_RootPortal_Patients_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootProgramsArgs = {
  distinct_on?: InputMaybe<Array<Programs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Programs_Order_By>>;
  where?: InputMaybe<Programs_Bool_Exp>;
};


export type Query_RootPrograms_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Programs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Programs_Order_By>>;
  where?: InputMaybe<Programs_Bool_Exp>;
};


export type Query_RootPrograms_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootProvidersArgs = {
  distinct_on?: InputMaybe<Array<Providers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Providers_Order_By>>;
  where?: InputMaybe<Providers_Bool_Exp>;
};


export type Query_RootProviders_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Providers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Providers_Order_By>>;
  where?: InputMaybe<Providers_Bool_Exp>;
};


export type Query_RootProviders_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootRolesArgs = {
  distinct_on?: InputMaybe<Array<Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Roles_Order_By>>;
  where?: InputMaybe<Roles_Bool_Exp>;
};


export type Query_RootRoles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Roles_Order_By>>;
  where?: InputMaybe<Roles_Bool_Exp>;
};


export type Query_RootRoles_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootSession_LogsArgs = {
  distinct_on?: InputMaybe<Array<Session_Logs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Session_Logs_Order_By>>;
  where?: InputMaybe<Session_Logs_Bool_Exp>;
};


export type Query_RootSession_Logs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Session_Logs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Session_Logs_Order_By>>;
  where?: InputMaybe<Session_Logs_Bool_Exp>;
};


export type Query_RootSession_Logs_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootSession_Order_ConfigArgs = {
  distinct_on?: InputMaybe<Array<Session_Order_Config_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Session_Order_Config_Order_By>>;
  where?: InputMaybe<Session_Order_Config_Bool_Exp>;
};


export type Query_RootSession_Order_Config_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Session_Order_Config_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Session_Order_Config_Order_By>>;
  where?: InputMaybe<Session_Order_Config_Bool_Exp>;
};


export type Query_RootSession_Order_Config_By_PkArgs = {
  order_code: Scalars['String'];
  session_code: Scalars['String'];
};


export type Query_RootSession_Station_ConfigArgs = {
  distinct_on?: InputMaybe<Array<Session_Station_Config_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Session_Station_Config_Order_By>>;
  where?: InputMaybe<Session_Station_Config_Bool_Exp>;
};


export type Query_RootSession_Station_Config_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Session_Station_Config_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Session_Station_Config_Order_By>>;
  where?: InputMaybe<Session_Station_Config_Bool_Exp>;
};


export type Query_RootSession_Station_Config_By_PkArgs = {
  session_code: Scalars['String'];
  station_code: Scalars['String'];
};


export type Query_RootSession_TypeArgs = {
  distinct_on?: InputMaybe<Array<Session_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Session_Type_Order_By>>;
  where?: InputMaybe<Session_Type_Bool_Exp>;
};


export type Query_RootSession_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Session_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Session_Type_Order_By>>;
  where?: InputMaybe<Session_Type_Bool_Exp>;
};


export type Query_RootSession_Type_By_PkArgs = {
  code: Scalars['String'];
};


export type Query_RootSessionsArgs = {
  distinct_on?: InputMaybe<Array<Sessions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sessions_Order_By>>;
  where?: InputMaybe<Sessions_Bool_Exp>;
};


export type Query_RootSessions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Sessions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sessions_Order_By>>;
  where?: InputMaybe<Sessions_Bool_Exp>;
};


export type Query_RootSessions_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootStation_OrdersArgs = {
  distinct_on?: InputMaybe<Array<Station_Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Station_Orders_Order_By>>;
  where?: InputMaybe<Station_Orders_Bool_Exp>;
};


export type Query_RootStation_Orders_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Station_Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Station_Orders_Order_By>>;
  where?: InputMaybe<Station_Orders_Bool_Exp>;
};


export type Query_RootStation_Orders_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootStation_TypeArgs = {
  distinct_on?: InputMaybe<Array<Station_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Station_Type_Order_By>>;
  where?: InputMaybe<Station_Type_Bool_Exp>;
};


export type Query_RootStation_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Station_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Station_Type_Order_By>>;
  where?: InputMaybe<Station_Type_Bool_Exp>;
};


export type Query_RootStation_Type_By_PkArgs = {
  code: Scalars['String'];
};


export type Query_RootStation_VisitsArgs = {
  distinct_on?: InputMaybe<Array<Station_Visits_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Station_Visits_Order_By>>;
  where?: InputMaybe<Station_Visits_Bool_Exp>;
};


export type Query_RootStation_Visits_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Station_Visits_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Station_Visits_Order_By>>;
  where?: InputMaybe<Station_Visits_Bool_Exp>;
};


export type Query_RootStation_Visits_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootStationsArgs = {
  distinct_on?: InputMaybe<Array<Stations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stations_Order_By>>;
  where?: InputMaybe<Stations_Bool_Exp>;
};


export type Query_RootStations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Stations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stations_Order_By>>;
  where?: InputMaybe<Stations_Bool_Exp>;
};


export type Query_RootStations_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootTest_OrdersArgs = {
  distinct_on?: InputMaybe<Array<Test_Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Test_Orders_Order_By>>;
  where?: InputMaybe<Test_Orders_Bool_Exp>;
};


export type Query_RootTest_Orders_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Test_Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Test_Orders_Order_By>>;
  where?: InputMaybe<Test_Orders_Bool_Exp>;
};


export type Query_RootTest_Orders_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootUsersArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Query_RootUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Query_RootUsers_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootVideo_CallsArgs = {
  distinct_on?: InputMaybe<Array<Video_Calls_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Video_Calls_Order_By>>;
  where?: InputMaybe<Video_Calls_Bool_Exp>;
};


export type Query_RootVideo_Calls_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Video_Calls_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Video_Calls_Order_By>>;
  where?: InputMaybe<Video_Calls_Bool_Exp>;
};


export type Query_RootVideo_Calls_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootVisitsArgs = {
  distinct_on?: InputMaybe<Array<Visits_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Visits_Order_By>>;
  where?: InputMaybe<Visits_Bool_Exp>;
};


export type Query_RootVisits_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Visits_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Visits_Order_By>>;
  where?: InputMaybe<Visits_Bool_Exp>;
};


export type Query_RootVisits_By_PkArgs = {
  id: Scalars['Int'];
};

/**
 * Auth0 user roles mapping
 *
 *
 * columns and relationships of "roles"
 *
 */
export type Roles = {
  __typename?: 'roles';
  id: Scalars['String'];
  is_deleted: Scalars['Boolean'];
  title: Scalars['String'];
};

/** aggregated selection of "roles" */
export type Roles_Aggregate = {
  __typename?: 'roles_aggregate';
  aggregate?: Maybe<Roles_Aggregate_Fields>;
  nodes: Array<Roles>;
};

/** aggregate fields of "roles" */
export type Roles_Aggregate_Fields = {
  __typename?: 'roles_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Roles_Max_Fields>;
  min?: Maybe<Roles_Min_Fields>;
};


/** aggregate fields of "roles" */
export type Roles_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Roles_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "roles". All fields are combined with a logical 'AND'. */
export type Roles_Bool_Exp = {
  _and?: InputMaybe<Array<Roles_Bool_Exp>>;
  _not?: InputMaybe<Roles_Bool_Exp>;
  _or?: InputMaybe<Array<Roles_Bool_Exp>>;
  id?: InputMaybe<String_Comparison_Exp>;
  is_deleted?: InputMaybe<Boolean_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "roles" */
export enum Roles_Constraint {
  /** unique or primary key constraint */
  RolesPkey = 'roles_pkey'
}

/** input type for inserting data into table "roles" */
export type Roles_Insert_Input = {
  id?: InputMaybe<Scalars['String']>;
  is_deleted?: InputMaybe<Scalars['Boolean']>;
  title?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Roles_Max_Fields = {
  __typename?: 'roles_max_fields';
  id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Roles_Min_Fields = {
  __typename?: 'roles_min_fields';
  id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "roles" */
export type Roles_Mutation_Response = {
  __typename?: 'roles_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Roles>;
};

/** on conflict condition type for table "roles" */
export type Roles_On_Conflict = {
  constraint: Roles_Constraint;
  update_columns?: Array<Roles_Update_Column>;
  where?: InputMaybe<Roles_Bool_Exp>;
};

/** Ordering options when selecting data from "roles". */
export type Roles_Order_By = {
  id?: InputMaybe<Order_By>;
  is_deleted?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
};

/** primary key columns input for table: roles */
export type Roles_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "roles" */
export enum Roles_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  Title = 'title'
}

/** input type for updating data in table "roles" */
export type Roles_Set_Input = {
  id?: InputMaybe<Scalars['String']>;
  is_deleted?: InputMaybe<Scalars['Boolean']>;
  title?: InputMaybe<Scalars['String']>;
};

/** update columns of table "roles" */
export enum Roles_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  Title = 'title'
}

export type Sendbird_Response = {
  __typename?: 'sendbird_response';
  expires_at: Scalars['Int'];
  token: Scalars['String'];
};

/** columns and relationships of "session_logs" */
export type Session_Logs = {
  __typename?: 'session_logs';
  created_at?: Maybe<Scalars['timestamptz']>;
  description: Scalars['String'];
  id: Scalars['Int'];
  is_deleted?: Maybe<Scalars['Boolean']>;
  /** An object relationship */
  session?: Maybe<Sessions>;
  session_id?: Maybe<Scalars['Int']>;
  source?: Maybe<Scalars['String']>;
  source_id?: Maybe<Scalars['String']>;
  source_update?: Maybe<Scalars['jsonb']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id: Scalars['Int'];
  /** An object relationship */
  visit?: Maybe<Visits>;
  visit_id?: Maybe<Scalars['Int']>;
};


/** columns and relationships of "session_logs" */
export type Session_LogsSource_UpdateArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "session_logs" */
export type Session_Logs_Aggregate = {
  __typename?: 'session_logs_aggregate';
  aggregate?: Maybe<Session_Logs_Aggregate_Fields>;
  nodes: Array<Session_Logs>;
};

/** aggregate fields of "session_logs" */
export type Session_Logs_Aggregate_Fields = {
  __typename?: 'session_logs_aggregate_fields';
  avg?: Maybe<Session_Logs_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Session_Logs_Max_Fields>;
  min?: Maybe<Session_Logs_Min_Fields>;
  stddev?: Maybe<Session_Logs_Stddev_Fields>;
  stddev_pop?: Maybe<Session_Logs_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Session_Logs_Stddev_Samp_Fields>;
  sum?: Maybe<Session_Logs_Sum_Fields>;
  var_pop?: Maybe<Session_Logs_Var_Pop_Fields>;
  var_samp?: Maybe<Session_Logs_Var_Samp_Fields>;
  variance?: Maybe<Session_Logs_Variance_Fields>;
};


/** aggregate fields of "session_logs" */
export type Session_Logs_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Session_Logs_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "session_logs" */
export type Session_Logs_Aggregate_Order_By = {
  avg?: InputMaybe<Session_Logs_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Session_Logs_Max_Order_By>;
  min?: InputMaybe<Session_Logs_Min_Order_By>;
  stddev?: InputMaybe<Session_Logs_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Session_Logs_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Session_Logs_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Session_Logs_Sum_Order_By>;
  var_pop?: InputMaybe<Session_Logs_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Session_Logs_Var_Samp_Order_By>;
  variance?: InputMaybe<Session_Logs_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Session_Logs_Append_Input = {
  source_update?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "session_logs" */
export type Session_Logs_Arr_Rel_Insert_Input = {
  data: Array<Session_Logs_Insert_Input>;
  /** on conflict condition */
  on_conflict?: InputMaybe<Session_Logs_On_Conflict>;
};

/** aggregate avg on columns */
export type Session_Logs_Avg_Fields = {
  __typename?: 'session_logs_avg_fields';
  id?: Maybe<Scalars['Float']>;
  session_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
  visit_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "session_logs" */
export type Session_Logs_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  session_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  visit_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "session_logs". All fields are combined with a logical 'AND'. */
export type Session_Logs_Bool_Exp = {
  _and?: InputMaybe<Array<Session_Logs_Bool_Exp>>;
  _not?: InputMaybe<Session_Logs_Bool_Exp>;
  _or?: InputMaybe<Array<Session_Logs_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  is_deleted?: InputMaybe<Boolean_Comparison_Exp>;
  session?: InputMaybe<Sessions_Bool_Exp>;
  session_id?: InputMaybe<Int_Comparison_Exp>;
  source?: InputMaybe<String_Comparison_Exp>;
  source_id?: InputMaybe<String_Comparison_Exp>;
  source_update?: InputMaybe<Jsonb_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Int_Comparison_Exp>;
  visit?: InputMaybe<Visits_Bool_Exp>;
  visit_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "session_logs" */
export enum Session_Logs_Constraint {
  /** unique or primary key constraint */
  SessionLogsPkey = 'session_logs_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Session_Logs_Delete_At_Path_Input = {
  source_update?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Session_Logs_Delete_Elem_Input = {
  source_update?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Session_Logs_Delete_Key_Input = {
  source_update?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "session_logs" */
export type Session_Logs_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  session_id?: InputMaybe<Scalars['Int']>;
  user_id?: InputMaybe<Scalars['Int']>;
  visit_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "session_logs" */
export type Session_Logs_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  is_deleted?: InputMaybe<Scalars['Boolean']>;
  session?: InputMaybe<Sessions_Obj_Rel_Insert_Input>;
  session_id?: InputMaybe<Scalars['Int']>;
  source?: InputMaybe<Scalars['String']>;
  source_id?: InputMaybe<Scalars['String']>;
  source_update?: InputMaybe<Scalars['jsonb']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['Int']>;
  visit?: InputMaybe<Visits_Obj_Rel_Insert_Input>;
  visit_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Session_Logs_Max_Fields = {
  __typename?: 'session_logs_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  session_id?: Maybe<Scalars['Int']>;
  source?: Maybe<Scalars['String']>;
  source_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['Int']>;
  visit_id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "session_logs" */
export type Session_Logs_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  session_id?: InputMaybe<Order_By>;
  source?: InputMaybe<Order_By>;
  source_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  visit_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Session_Logs_Min_Fields = {
  __typename?: 'session_logs_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  session_id?: Maybe<Scalars['Int']>;
  source?: Maybe<Scalars['String']>;
  source_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['Int']>;
  visit_id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "session_logs" */
export type Session_Logs_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  session_id?: InputMaybe<Order_By>;
  source?: InputMaybe<Order_By>;
  source_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  visit_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "session_logs" */
export type Session_Logs_Mutation_Response = {
  __typename?: 'session_logs_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Session_Logs>;
};

/** on conflict condition type for table "session_logs" */
export type Session_Logs_On_Conflict = {
  constraint: Session_Logs_Constraint;
  update_columns?: Array<Session_Logs_Update_Column>;
  where?: InputMaybe<Session_Logs_Bool_Exp>;
};

/** Ordering options when selecting data from "session_logs". */
export type Session_Logs_Order_By = {
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_deleted?: InputMaybe<Order_By>;
  session?: InputMaybe<Sessions_Order_By>;
  session_id?: InputMaybe<Order_By>;
  source?: InputMaybe<Order_By>;
  source_id?: InputMaybe<Order_By>;
  source_update?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  visit?: InputMaybe<Visits_Order_By>;
  visit_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: session_logs */
export type Session_Logs_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Session_Logs_Prepend_Input = {
  source_update?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "session_logs" */
export enum Session_Logs_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  SessionId = 'session_id',
  /** column name */
  Source = 'source',
  /** column name */
  SourceId = 'source_id',
  /** column name */
  SourceUpdate = 'source_update',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  VisitId = 'visit_id'
}

/** input type for updating data in table "session_logs" */
export type Session_Logs_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  is_deleted?: InputMaybe<Scalars['Boolean']>;
  session_id?: InputMaybe<Scalars['Int']>;
  source?: InputMaybe<Scalars['String']>;
  source_id?: InputMaybe<Scalars['String']>;
  source_update?: InputMaybe<Scalars['jsonb']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['Int']>;
  visit_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Session_Logs_Stddev_Fields = {
  __typename?: 'session_logs_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  session_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
  visit_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "session_logs" */
export type Session_Logs_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  session_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  visit_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Session_Logs_Stddev_Pop_Fields = {
  __typename?: 'session_logs_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  session_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
  visit_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "session_logs" */
export type Session_Logs_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  session_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  visit_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Session_Logs_Stddev_Samp_Fields = {
  __typename?: 'session_logs_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  session_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
  visit_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "session_logs" */
export type Session_Logs_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  session_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  visit_id?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Session_Logs_Sum_Fields = {
  __typename?: 'session_logs_sum_fields';
  id?: Maybe<Scalars['Int']>;
  session_id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['Int']>;
  visit_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "session_logs" */
export type Session_Logs_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  session_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  visit_id?: InputMaybe<Order_By>;
};

/** update columns of table "session_logs" */
export enum Session_Logs_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  SessionId = 'session_id',
  /** column name */
  Source = 'source',
  /** column name */
  SourceId = 'source_id',
  /** column name */
  SourceUpdate = 'source_update',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  VisitId = 'visit_id'
}

/** aggregate var_pop on columns */
export type Session_Logs_Var_Pop_Fields = {
  __typename?: 'session_logs_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  session_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
  visit_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "session_logs" */
export type Session_Logs_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  session_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  visit_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Session_Logs_Var_Samp_Fields = {
  __typename?: 'session_logs_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  session_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
  visit_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "session_logs" */
export type Session_Logs_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  session_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  visit_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Session_Logs_Variance_Fields = {
  __typename?: 'session_logs_variance_fields';
  id?: Maybe<Scalars['Float']>;
  session_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
  visit_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "session_logs" */
export type Session_Logs_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  session_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  visit_id?: InputMaybe<Order_By>;
};

/**
 * session template configuration session_type -> order_type/s
 *
 *
 * columns and relationships of "session_order_config"
 *
 */
export type Session_Order_Config = {
  __typename?: 'session_order_config';
  is_deleted?: Maybe<Scalars['Boolean']>;
  order_code: Scalars['String'];
  /** An object relationship */
  order_type: Order_Type;
  session_code: Scalars['String'];
  /** An object relationship */
  session_type: Session_Type;
};

/** aggregated selection of "session_order_config" */
export type Session_Order_Config_Aggregate = {
  __typename?: 'session_order_config_aggregate';
  aggregate?: Maybe<Session_Order_Config_Aggregate_Fields>;
  nodes: Array<Session_Order_Config>;
};

/** aggregate fields of "session_order_config" */
export type Session_Order_Config_Aggregate_Fields = {
  __typename?: 'session_order_config_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Session_Order_Config_Max_Fields>;
  min?: Maybe<Session_Order_Config_Min_Fields>;
};


/** aggregate fields of "session_order_config" */
export type Session_Order_Config_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Session_Order_Config_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "session_order_config" */
export type Session_Order_Config_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Session_Order_Config_Max_Order_By>;
  min?: InputMaybe<Session_Order_Config_Min_Order_By>;
};

/** input type for inserting array relation for remote table "session_order_config" */
export type Session_Order_Config_Arr_Rel_Insert_Input = {
  data: Array<Session_Order_Config_Insert_Input>;
  /** on conflict condition */
  on_conflict?: InputMaybe<Session_Order_Config_On_Conflict>;
};

/** Boolean expression to filter rows from the table "session_order_config". All fields are combined with a logical 'AND'. */
export type Session_Order_Config_Bool_Exp = {
  _and?: InputMaybe<Array<Session_Order_Config_Bool_Exp>>;
  _not?: InputMaybe<Session_Order_Config_Bool_Exp>;
  _or?: InputMaybe<Array<Session_Order_Config_Bool_Exp>>;
  is_deleted?: InputMaybe<Boolean_Comparison_Exp>;
  order_code?: InputMaybe<String_Comparison_Exp>;
  order_type?: InputMaybe<Order_Type_Bool_Exp>;
  session_code?: InputMaybe<String_Comparison_Exp>;
  session_type?: InputMaybe<Session_Type_Bool_Exp>;
};

/** unique or primary key constraints on table "session_order_config" */
export enum Session_Order_Config_Constraint {
  /** unique or primary key constraint */
  SessionOrderConfigPkey = 'session_order_config_pkey'
}

/** input type for inserting data into table "session_order_config" */
export type Session_Order_Config_Insert_Input = {
  is_deleted?: InputMaybe<Scalars['Boolean']>;
  order_code?: InputMaybe<Scalars['String']>;
  order_type?: InputMaybe<Order_Type_Obj_Rel_Insert_Input>;
  session_code?: InputMaybe<Scalars['String']>;
  session_type?: InputMaybe<Session_Type_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Session_Order_Config_Max_Fields = {
  __typename?: 'session_order_config_max_fields';
  order_code?: Maybe<Scalars['String']>;
  session_code?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "session_order_config" */
export type Session_Order_Config_Max_Order_By = {
  order_code?: InputMaybe<Order_By>;
  session_code?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Session_Order_Config_Min_Fields = {
  __typename?: 'session_order_config_min_fields';
  order_code?: Maybe<Scalars['String']>;
  session_code?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "session_order_config" */
export type Session_Order_Config_Min_Order_By = {
  order_code?: InputMaybe<Order_By>;
  session_code?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "session_order_config" */
export type Session_Order_Config_Mutation_Response = {
  __typename?: 'session_order_config_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Session_Order_Config>;
};

/** on conflict condition type for table "session_order_config" */
export type Session_Order_Config_On_Conflict = {
  constraint: Session_Order_Config_Constraint;
  update_columns?: Array<Session_Order_Config_Update_Column>;
  where?: InputMaybe<Session_Order_Config_Bool_Exp>;
};

/** Ordering options when selecting data from "session_order_config". */
export type Session_Order_Config_Order_By = {
  is_deleted?: InputMaybe<Order_By>;
  order_code?: InputMaybe<Order_By>;
  order_type?: InputMaybe<Order_Type_Order_By>;
  session_code?: InputMaybe<Order_By>;
  session_type?: InputMaybe<Session_Type_Order_By>;
};

/** primary key columns input for table: session_order_config */
export type Session_Order_Config_Pk_Columns_Input = {
  order_code: Scalars['String'];
  session_code: Scalars['String'];
};

/** select columns of table "session_order_config" */
export enum Session_Order_Config_Select_Column {
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  OrderCode = 'order_code',
  /** column name */
  SessionCode = 'session_code'
}

/** input type for updating data in table "session_order_config" */
export type Session_Order_Config_Set_Input = {
  is_deleted?: InputMaybe<Scalars['Boolean']>;
  order_code?: InputMaybe<Scalars['String']>;
  session_code?: InputMaybe<Scalars['String']>;
};

/** update columns of table "session_order_config" */
export enum Session_Order_Config_Update_Column {
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  OrderCode = 'order_code',
  /** column name */
  SessionCode = 'session_code'
}

/**
 * session template configuration session_type -> station_type/s
 *
 *
 * columns and relationships of "session_station_config"
 *
 */
export type Session_Station_Config = {
  __typename?: 'session_station_config';
  is_deleted?: Maybe<Scalars['Boolean']>;
  position?: Maybe<Scalars['Int']>;
  session_code: Scalars['String'];
  /** An object relationship */
  session_type: Session_Type;
  station_code: Scalars['String'];
  /** An object relationship */
  station_type: Station_Type;
};

/** aggregated selection of "session_station_config" */
export type Session_Station_Config_Aggregate = {
  __typename?: 'session_station_config_aggregate';
  aggregate?: Maybe<Session_Station_Config_Aggregate_Fields>;
  nodes: Array<Session_Station_Config>;
};

/** aggregate fields of "session_station_config" */
export type Session_Station_Config_Aggregate_Fields = {
  __typename?: 'session_station_config_aggregate_fields';
  avg?: Maybe<Session_Station_Config_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Session_Station_Config_Max_Fields>;
  min?: Maybe<Session_Station_Config_Min_Fields>;
  stddev?: Maybe<Session_Station_Config_Stddev_Fields>;
  stddev_pop?: Maybe<Session_Station_Config_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Session_Station_Config_Stddev_Samp_Fields>;
  sum?: Maybe<Session_Station_Config_Sum_Fields>;
  var_pop?: Maybe<Session_Station_Config_Var_Pop_Fields>;
  var_samp?: Maybe<Session_Station_Config_Var_Samp_Fields>;
  variance?: Maybe<Session_Station_Config_Variance_Fields>;
};


/** aggregate fields of "session_station_config" */
export type Session_Station_Config_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Session_Station_Config_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "session_station_config" */
export type Session_Station_Config_Aggregate_Order_By = {
  avg?: InputMaybe<Session_Station_Config_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Session_Station_Config_Max_Order_By>;
  min?: InputMaybe<Session_Station_Config_Min_Order_By>;
  stddev?: InputMaybe<Session_Station_Config_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Session_Station_Config_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Session_Station_Config_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Session_Station_Config_Sum_Order_By>;
  var_pop?: InputMaybe<Session_Station_Config_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Session_Station_Config_Var_Samp_Order_By>;
  variance?: InputMaybe<Session_Station_Config_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "session_station_config" */
export type Session_Station_Config_Arr_Rel_Insert_Input = {
  data: Array<Session_Station_Config_Insert_Input>;
  /** on conflict condition */
  on_conflict?: InputMaybe<Session_Station_Config_On_Conflict>;
};

/** aggregate avg on columns */
export type Session_Station_Config_Avg_Fields = {
  __typename?: 'session_station_config_avg_fields';
  position?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "session_station_config" */
export type Session_Station_Config_Avg_Order_By = {
  position?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "session_station_config". All fields are combined with a logical 'AND'. */
export type Session_Station_Config_Bool_Exp = {
  _and?: InputMaybe<Array<Session_Station_Config_Bool_Exp>>;
  _not?: InputMaybe<Session_Station_Config_Bool_Exp>;
  _or?: InputMaybe<Array<Session_Station_Config_Bool_Exp>>;
  is_deleted?: InputMaybe<Boolean_Comparison_Exp>;
  position?: InputMaybe<Int_Comparison_Exp>;
  session_code?: InputMaybe<String_Comparison_Exp>;
  session_type?: InputMaybe<Session_Type_Bool_Exp>;
  station_code?: InputMaybe<String_Comparison_Exp>;
  station_type?: InputMaybe<Station_Type_Bool_Exp>;
};

/** unique or primary key constraints on table "session_station_config" */
export enum Session_Station_Config_Constraint {
  /** unique or primary key constraint */
  SessionStationConfigPkey = 'session_station_config_pkey'
}

/** input type for incrementing numeric columns in table "session_station_config" */
export type Session_Station_Config_Inc_Input = {
  position?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "session_station_config" */
export type Session_Station_Config_Insert_Input = {
  is_deleted?: InputMaybe<Scalars['Boolean']>;
  position?: InputMaybe<Scalars['Int']>;
  session_code?: InputMaybe<Scalars['String']>;
  session_type?: InputMaybe<Session_Type_Obj_Rel_Insert_Input>;
  station_code?: InputMaybe<Scalars['String']>;
  station_type?: InputMaybe<Station_Type_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Session_Station_Config_Max_Fields = {
  __typename?: 'session_station_config_max_fields';
  position?: Maybe<Scalars['Int']>;
  session_code?: Maybe<Scalars['String']>;
  station_code?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "session_station_config" */
export type Session_Station_Config_Max_Order_By = {
  position?: InputMaybe<Order_By>;
  session_code?: InputMaybe<Order_By>;
  station_code?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Session_Station_Config_Min_Fields = {
  __typename?: 'session_station_config_min_fields';
  position?: Maybe<Scalars['Int']>;
  session_code?: Maybe<Scalars['String']>;
  station_code?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "session_station_config" */
export type Session_Station_Config_Min_Order_By = {
  position?: InputMaybe<Order_By>;
  session_code?: InputMaybe<Order_By>;
  station_code?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "session_station_config" */
export type Session_Station_Config_Mutation_Response = {
  __typename?: 'session_station_config_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Session_Station_Config>;
};

/** on conflict condition type for table "session_station_config" */
export type Session_Station_Config_On_Conflict = {
  constraint: Session_Station_Config_Constraint;
  update_columns?: Array<Session_Station_Config_Update_Column>;
  where?: InputMaybe<Session_Station_Config_Bool_Exp>;
};

/** Ordering options when selecting data from "session_station_config". */
export type Session_Station_Config_Order_By = {
  is_deleted?: InputMaybe<Order_By>;
  position?: InputMaybe<Order_By>;
  session_code?: InputMaybe<Order_By>;
  session_type?: InputMaybe<Session_Type_Order_By>;
  station_code?: InputMaybe<Order_By>;
  station_type?: InputMaybe<Station_Type_Order_By>;
};

/** primary key columns input for table: session_station_config */
export type Session_Station_Config_Pk_Columns_Input = {
  session_code: Scalars['String'];
  station_code: Scalars['String'];
};

/** select columns of table "session_station_config" */
export enum Session_Station_Config_Select_Column {
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  Position = 'position',
  /** column name */
  SessionCode = 'session_code',
  /** column name */
  StationCode = 'station_code'
}

/** input type for updating data in table "session_station_config" */
export type Session_Station_Config_Set_Input = {
  is_deleted?: InputMaybe<Scalars['Boolean']>;
  position?: InputMaybe<Scalars['Int']>;
  session_code?: InputMaybe<Scalars['String']>;
  station_code?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Session_Station_Config_Stddev_Fields = {
  __typename?: 'session_station_config_stddev_fields';
  position?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "session_station_config" */
export type Session_Station_Config_Stddev_Order_By = {
  position?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Session_Station_Config_Stddev_Pop_Fields = {
  __typename?: 'session_station_config_stddev_pop_fields';
  position?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "session_station_config" */
export type Session_Station_Config_Stddev_Pop_Order_By = {
  position?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Session_Station_Config_Stddev_Samp_Fields = {
  __typename?: 'session_station_config_stddev_samp_fields';
  position?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "session_station_config" */
export type Session_Station_Config_Stddev_Samp_Order_By = {
  position?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Session_Station_Config_Sum_Fields = {
  __typename?: 'session_station_config_sum_fields';
  position?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "session_station_config" */
export type Session_Station_Config_Sum_Order_By = {
  position?: InputMaybe<Order_By>;
};

/** update columns of table "session_station_config" */
export enum Session_Station_Config_Update_Column {
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  Position = 'position',
  /** column name */
  SessionCode = 'session_code',
  /** column name */
  StationCode = 'station_code'
}

/** aggregate var_pop on columns */
export type Session_Station_Config_Var_Pop_Fields = {
  __typename?: 'session_station_config_var_pop_fields';
  position?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "session_station_config" */
export type Session_Station_Config_Var_Pop_Order_By = {
  position?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Session_Station_Config_Var_Samp_Fields = {
  __typename?: 'session_station_config_var_samp_fields';
  position?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "session_station_config" */
export type Session_Station_Config_Var_Samp_Order_By = {
  position?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Session_Station_Config_Variance_Fields = {
  __typename?: 'session_station_config_variance_fields';
  position?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "session_station_config" */
export type Session_Station_Config_Variance_Order_By = {
  position?: InputMaybe<Order_By>;
};

/** columns and relationships of "session_type" */
export type Session_Type = {
  __typename?: 'session_type';
  code: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  is_active: Scalars['Boolean'];
  is_deleted?: Maybe<Scalars['Boolean']>;
  is_kanban_flow?: Maybe<Scalars['Boolean']>;
  program_id?: Maybe<Scalars['Int']>;
  /** An array relationship */
  session_order_configs: Array<Session_Order_Config>;
  /** An aggregate relationship */
  session_order_configs_aggregate: Session_Order_Config_Aggregate;
  /** An array relationship */
  session_station_configs: Array<Session_Station_Config>;
  /** An aggregate relationship */
  session_station_configs_aggregate: Session_Station_Config_Aggregate;
  title?: Maybe<Scalars['String']>;
};


/** columns and relationships of "session_type" */
export type Session_TypeSession_Order_ConfigsArgs = {
  distinct_on?: InputMaybe<Array<Session_Order_Config_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Session_Order_Config_Order_By>>;
  where?: InputMaybe<Session_Order_Config_Bool_Exp>;
};


/** columns and relationships of "session_type" */
export type Session_TypeSession_Order_Configs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Session_Order_Config_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Session_Order_Config_Order_By>>;
  where?: InputMaybe<Session_Order_Config_Bool_Exp>;
};


/** columns and relationships of "session_type" */
export type Session_TypeSession_Station_ConfigsArgs = {
  distinct_on?: InputMaybe<Array<Session_Station_Config_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Session_Station_Config_Order_By>>;
  where?: InputMaybe<Session_Station_Config_Bool_Exp>;
};


/** columns and relationships of "session_type" */
export type Session_TypeSession_Station_Configs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Session_Station_Config_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Session_Station_Config_Order_By>>;
  where?: InputMaybe<Session_Station_Config_Bool_Exp>;
};

/** aggregated selection of "session_type" */
export type Session_Type_Aggregate = {
  __typename?: 'session_type_aggregate';
  aggregate?: Maybe<Session_Type_Aggregate_Fields>;
  nodes: Array<Session_Type>;
};

/** aggregate fields of "session_type" */
export type Session_Type_Aggregate_Fields = {
  __typename?: 'session_type_aggregate_fields';
  avg?: Maybe<Session_Type_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Session_Type_Max_Fields>;
  min?: Maybe<Session_Type_Min_Fields>;
  stddev?: Maybe<Session_Type_Stddev_Fields>;
  stddev_pop?: Maybe<Session_Type_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Session_Type_Stddev_Samp_Fields>;
  sum?: Maybe<Session_Type_Sum_Fields>;
  var_pop?: Maybe<Session_Type_Var_Pop_Fields>;
  var_samp?: Maybe<Session_Type_Var_Samp_Fields>;
  variance?: Maybe<Session_Type_Variance_Fields>;
};


/** aggregate fields of "session_type" */
export type Session_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Session_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Session_Type_Avg_Fields = {
  __typename?: 'session_type_avg_fields';
  program_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "session_type". All fields are combined with a logical 'AND'. */
export type Session_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Session_Type_Bool_Exp>>;
  _not?: InputMaybe<Session_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Session_Type_Bool_Exp>>;
  code?: InputMaybe<String_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  is_active?: InputMaybe<Boolean_Comparison_Exp>;
  is_deleted?: InputMaybe<Boolean_Comparison_Exp>;
  is_kanban_flow?: InputMaybe<Boolean_Comparison_Exp>;
  program_id?: InputMaybe<Int_Comparison_Exp>;
  session_order_configs?: InputMaybe<Session_Order_Config_Bool_Exp>;
  session_station_configs?: InputMaybe<Session_Station_Config_Bool_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "session_type" */
export enum Session_Type_Constraint {
  /** unique or primary key constraint */
  SessionTypePkey = 'session_type_pkey'
}

/** input type for incrementing numeric columns in table "session_type" */
export type Session_Type_Inc_Input = {
  program_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "session_type" */
export type Session_Type_Insert_Input = {
  code?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  is_deleted?: InputMaybe<Scalars['Boolean']>;
  is_kanban_flow?: InputMaybe<Scalars['Boolean']>;
  program_id?: InputMaybe<Scalars['Int']>;
  session_order_configs?: InputMaybe<Session_Order_Config_Arr_Rel_Insert_Input>;
  session_station_configs?: InputMaybe<Session_Station_Config_Arr_Rel_Insert_Input>;
  title?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Session_Type_Max_Fields = {
  __typename?: 'session_type_max_fields';
  code?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  program_id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Session_Type_Min_Fields = {
  __typename?: 'session_type_min_fields';
  code?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  program_id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "session_type" */
export type Session_Type_Mutation_Response = {
  __typename?: 'session_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Session_Type>;
};

/** input type for inserting object relation for remote table "session_type" */
export type Session_Type_Obj_Rel_Insert_Input = {
  data: Session_Type_Insert_Input;
  /** on conflict condition */
  on_conflict?: InputMaybe<Session_Type_On_Conflict>;
};

/** on conflict condition type for table "session_type" */
export type Session_Type_On_Conflict = {
  constraint: Session_Type_Constraint;
  update_columns?: Array<Session_Type_Update_Column>;
  where?: InputMaybe<Session_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "session_type". */
export type Session_Type_Order_By = {
  code?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  is_active?: InputMaybe<Order_By>;
  is_deleted?: InputMaybe<Order_By>;
  is_kanban_flow?: InputMaybe<Order_By>;
  program_id?: InputMaybe<Order_By>;
  session_order_configs_aggregate?: InputMaybe<Session_Order_Config_Aggregate_Order_By>;
  session_station_configs_aggregate?: InputMaybe<Session_Station_Config_Aggregate_Order_By>;
  title?: InputMaybe<Order_By>;
};

/** primary key columns input for table: session_type */
export type Session_Type_Pk_Columns_Input = {
  code: Scalars['String'];
};

/** select columns of table "session_type" */
export enum Session_Type_Select_Column {
  /** column name */
  Code = 'code',
  /** column name */
  Description = 'description',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  IsKanbanFlow = 'is_kanban_flow',
  /** column name */
  ProgramId = 'program_id',
  /** column name */
  Title = 'title'
}

/** input type for updating data in table "session_type" */
export type Session_Type_Set_Input = {
  code?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  is_deleted?: InputMaybe<Scalars['Boolean']>;
  is_kanban_flow?: InputMaybe<Scalars['Boolean']>;
  program_id?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Session_Type_Stddev_Fields = {
  __typename?: 'session_type_stddev_fields';
  program_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Session_Type_Stddev_Pop_Fields = {
  __typename?: 'session_type_stddev_pop_fields';
  program_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Session_Type_Stddev_Samp_Fields = {
  __typename?: 'session_type_stddev_samp_fields';
  program_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Session_Type_Sum_Fields = {
  __typename?: 'session_type_sum_fields';
  program_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "session_type" */
export enum Session_Type_Update_Column {
  /** column name */
  Code = 'code',
  /** column name */
  Description = 'description',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  IsKanbanFlow = 'is_kanban_flow',
  /** column name */
  ProgramId = 'program_id',
  /** column name */
  Title = 'title'
}

/** aggregate var_pop on columns */
export type Session_Type_Var_Pop_Fields = {
  __typename?: 'session_type_var_pop_fields';
  program_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Session_Type_Var_Samp_Fields = {
  __typename?: 'session_type_var_samp_fields';
  program_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Session_Type_Variance_Fields = {
  __typename?: 'session_type_variance_fields';
  program_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "sessions" */
export type Sessions = {
  __typename?: 'sessions';
  appointment_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  facilitator_id?: Maybe<Scalars['Int']>;
  frequency_type?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  is_deleted?: Maybe<Scalars['Boolean']>;
  language: Scalars['String'];
  /** An object relationship */
  location?: Maybe<Organizations_Locations>;
  location_id?: Maybe<Scalars['Int']>;
  location_name?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  /** An object relationship */
  organization?: Maybe<Organizations_Organizations>;
  organization_id?: Maybe<Scalars['Int']>;
  program_id?: Maybe<Scalars['Int']>;
  provider_id?: Maybe<Scalars['Int']>;
  /** An array relationship */
  session_logs: Array<Session_Logs>;
  /** An aggregate relationship */
  session_logs_aggregate: Session_Logs_Aggregate;
  session_start_date?: Maybe<Scalars['timestamptz']>;
  session_time?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  session_type?: Maybe<Session_Type>;
  session_type_code?: Maybe<Scalars['String']>;
  /** An array relationship */
  stations: Array<Stations>;
  /** An aggregate relationship */
  stations_aggregate: Stations_Aggregate;
  status: Scalars['String'];
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  video_call?: Maybe<Video_Calls>;
  video_participants?: Maybe<Scalars['json']>;
  /** An array relationship */
  visits: Array<Visits>;
  /** An aggregate relationship */
  visits_aggregate: Visits_Aggregate;
};


/** columns and relationships of "sessions" */
export type SessionsSession_LogsArgs = {
  distinct_on?: InputMaybe<Array<Session_Logs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Session_Logs_Order_By>>;
  where?: InputMaybe<Session_Logs_Bool_Exp>;
};


/** columns and relationships of "sessions" */
export type SessionsSession_Logs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Session_Logs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Session_Logs_Order_By>>;
  where?: InputMaybe<Session_Logs_Bool_Exp>;
};


/** columns and relationships of "sessions" */
export type SessionsStationsArgs = {
  distinct_on?: InputMaybe<Array<Stations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stations_Order_By>>;
  where?: InputMaybe<Stations_Bool_Exp>;
};


/** columns and relationships of "sessions" */
export type SessionsStations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Stations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stations_Order_By>>;
  where?: InputMaybe<Stations_Bool_Exp>;
};


/** columns and relationships of "sessions" */
export type SessionsVideo_ParticipantsArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "sessions" */
export type SessionsVisitsArgs = {
  distinct_on?: InputMaybe<Array<Visits_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Visits_Order_By>>;
  where?: InputMaybe<Visits_Bool_Exp>;
};


/** columns and relationships of "sessions" */
export type SessionsVisits_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Visits_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Visits_Order_By>>;
  where?: InputMaybe<Visits_Bool_Exp>;
};

/** aggregated selection of "sessions" */
export type Sessions_Aggregate = {
  __typename?: 'sessions_aggregate';
  aggregate?: Maybe<Sessions_Aggregate_Fields>;
  nodes: Array<Sessions>;
};

/** aggregate fields of "sessions" */
export type Sessions_Aggregate_Fields = {
  __typename?: 'sessions_aggregate_fields';
  avg?: Maybe<Sessions_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Sessions_Max_Fields>;
  min?: Maybe<Sessions_Min_Fields>;
  stddev?: Maybe<Sessions_Stddev_Fields>;
  stddev_pop?: Maybe<Sessions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sessions_Stddev_Samp_Fields>;
  sum?: Maybe<Sessions_Sum_Fields>;
  var_pop?: Maybe<Sessions_Var_Pop_Fields>;
  var_samp?: Maybe<Sessions_Var_Samp_Fields>;
  variance?: Maybe<Sessions_Variance_Fields>;
};


/** aggregate fields of "sessions" */
export type Sessions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Sessions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Sessions_Avg_Fields = {
  __typename?: 'sessions_avg_fields';
  appointment_id?: Maybe<Scalars['Float']>;
  facilitator_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
  organization_id?: Maybe<Scalars['Float']>;
  program_id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "sessions". All fields are combined with a logical 'AND'. */
export type Sessions_Bool_Exp = {
  _and?: InputMaybe<Array<Sessions_Bool_Exp>>;
  _not?: InputMaybe<Sessions_Bool_Exp>;
  _or?: InputMaybe<Array<Sessions_Bool_Exp>>;
  appointment_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  facilitator_id?: InputMaybe<Int_Comparison_Exp>;
  frequency_type?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  is_deleted?: InputMaybe<Boolean_Comparison_Exp>;
  language?: InputMaybe<String_Comparison_Exp>;
  location?: InputMaybe<Organizations_Locations_Bool_Exp>;
  location_id?: InputMaybe<Int_Comparison_Exp>;
  location_name?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  organization?: InputMaybe<Organizations_Organizations_Bool_Exp>;
  organization_id?: InputMaybe<Int_Comparison_Exp>;
  program_id?: InputMaybe<Int_Comparison_Exp>;
  provider_id?: InputMaybe<Int_Comparison_Exp>;
  session_logs?: InputMaybe<Session_Logs_Bool_Exp>;
  session_start_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  session_time?: InputMaybe<Timestamptz_Comparison_Exp>;
  session_type?: InputMaybe<Session_Type_Bool_Exp>;
  session_type_code?: InputMaybe<String_Comparison_Exp>;
  stations?: InputMaybe<Stations_Bool_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  video_call?: InputMaybe<Video_Calls_Bool_Exp>;
  video_participants?: InputMaybe<Json_Comparison_Exp>;
  visits?: InputMaybe<Visits_Bool_Exp>;
};

/** unique or primary key constraints on table "sessions" */
export enum Sessions_Constraint {
  /** unique or primary key constraint */
  SessionsPkey = 'sessions_pkey'
}

/** input type for incrementing numeric columns in table "sessions" */
export type Sessions_Inc_Input = {
  appointment_id?: InputMaybe<Scalars['Int']>;
  facilitator_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  location_id?: InputMaybe<Scalars['Int']>;
  organization_id?: InputMaybe<Scalars['Int']>;
  program_id?: InputMaybe<Scalars['Int']>;
  provider_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "sessions" */
export type Sessions_Insert_Input = {
  appointment_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  facilitator_id?: InputMaybe<Scalars['Int']>;
  frequency_type?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  is_deleted?: InputMaybe<Scalars['Boolean']>;
  language?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Organizations_Locations_Obj_Rel_Insert_Input>;
  location_id?: InputMaybe<Scalars['Int']>;
  location_name?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Organizations_Organizations_Obj_Rel_Insert_Input>;
  organization_id?: InputMaybe<Scalars['Int']>;
  program_id?: InputMaybe<Scalars['Int']>;
  provider_id?: InputMaybe<Scalars['Int']>;
  session_logs?: InputMaybe<Session_Logs_Arr_Rel_Insert_Input>;
  session_start_date?: InputMaybe<Scalars['timestamptz']>;
  session_time?: InputMaybe<Scalars['timestamptz']>;
  session_type?: InputMaybe<Session_Type_Obj_Rel_Insert_Input>;
  session_type_code?: InputMaybe<Scalars['String']>;
  stations?: InputMaybe<Stations_Arr_Rel_Insert_Input>;
  status?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  video_call?: InputMaybe<Video_Calls_Obj_Rel_Insert_Input>;
  video_participants?: InputMaybe<Scalars['json']>;
  visits?: InputMaybe<Visits_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Sessions_Max_Fields = {
  __typename?: 'sessions_max_fields';
  appointment_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  facilitator_id?: Maybe<Scalars['Int']>;
  frequency_type?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  language?: Maybe<Scalars['String']>;
  location_id?: Maybe<Scalars['Int']>;
  location_name?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['Int']>;
  program_id?: Maybe<Scalars['Int']>;
  provider_id?: Maybe<Scalars['Int']>;
  session_start_date?: Maybe<Scalars['timestamptz']>;
  session_time?: Maybe<Scalars['timestamptz']>;
  session_type_code?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Sessions_Min_Fields = {
  __typename?: 'sessions_min_fields';
  appointment_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  facilitator_id?: Maybe<Scalars['Int']>;
  frequency_type?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  language?: Maybe<Scalars['String']>;
  location_id?: Maybe<Scalars['Int']>;
  location_name?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['Int']>;
  program_id?: Maybe<Scalars['Int']>;
  provider_id?: Maybe<Scalars['Int']>;
  session_start_date?: Maybe<Scalars['timestamptz']>;
  session_time?: Maybe<Scalars['timestamptz']>;
  session_type_code?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "sessions" */
export type Sessions_Mutation_Response = {
  __typename?: 'sessions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Sessions>;
};

/** input type for inserting object relation for remote table "sessions" */
export type Sessions_Obj_Rel_Insert_Input = {
  data: Sessions_Insert_Input;
  /** on conflict condition */
  on_conflict?: InputMaybe<Sessions_On_Conflict>;
};

/** on conflict condition type for table "sessions" */
export type Sessions_On_Conflict = {
  constraint: Sessions_Constraint;
  update_columns?: Array<Sessions_Update_Column>;
  where?: InputMaybe<Sessions_Bool_Exp>;
};

/** Ordering options when selecting data from "sessions". */
export type Sessions_Order_By = {
  appointment_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  facilitator_id?: InputMaybe<Order_By>;
  frequency_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_deleted?: InputMaybe<Order_By>;
  language?: InputMaybe<Order_By>;
  location?: InputMaybe<Organizations_Locations_Order_By>;
  location_id?: InputMaybe<Order_By>;
  location_name?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization?: InputMaybe<Organizations_Organizations_Order_By>;
  organization_id?: InputMaybe<Order_By>;
  program_id?: InputMaybe<Order_By>;
  provider_id?: InputMaybe<Order_By>;
  session_logs_aggregate?: InputMaybe<Session_Logs_Aggregate_Order_By>;
  session_start_date?: InputMaybe<Order_By>;
  session_time?: InputMaybe<Order_By>;
  session_type?: InputMaybe<Session_Type_Order_By>;
  session_type_code?: InputMaybe<Order_By>;
  stations_aggregate?: InputMaybe<Stations_Aggregate_Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  video_call?: InputMaybe<Video_Calls_Order_By>;
  video_participants?: InputMaybe<Order_By>;
  visits_aggregate?: InputMaybe<Visits_Aggregate_Order_By>;
};

/** primary key columns input for table: sessions */
export type Sessions_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "sessions" */
export enum Sessions_Select_Column {
  /** column name */
  AppointmentId = 'appointment_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FacilitatorId = 'facilitator_id',
  /** column name */
  FrequencyType = 'frequency_type',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  Language = 'language',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  LocationName = 'location_name',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  ProgramId = 'program_id',
  /** column name */
  ProviderId = 'provider_id',
  /** column name */
  SessionStartDate = 'session_start_date',
  /** column name */
  SessionTime = 'session_time',
  /** column name */
  SessionTypeCode = 'session_type_code',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VideoParticipants = 'video_participants'
}

/** input type for updating data in table "sessions" */
export type Sessions_Set_Input = {
  appointment_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  facilitator_id?: InputMaybe<Scalars['Int']>;
  frequency_type?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  is_deleted?: InputMaybe<Scalars['Boolean']>;
  language?: InputMaybe<Scalars['String']>;
  location_id?: InputMaybe<Scalars['Int']>;
  location_name?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization_id?: InputMaybe<Scalars['Int']>;
  program_id?: InputMaybe<Scalars['Int']>;
  provider_id?: InputMaybe<Scalars['Int']>;
  session_start_date?: InputMaybe<Scalars['timestamptz']>;
  session_time?: InputMaybe<Scalars['timestamptz']>;
  session_type_code?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  video_participants?: InputMaybe<Scalars['json']>;
};

/** aggregate stddev on columns */
export type Sessions_Stddev_Fields = {
  __typename?: 'sessions_stddev_fields';
  appointment_id?: Maybe<Scalars['Float']>;
  facilitator_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
  organization_id?: Maybe<Scalars['Float']>;
  program_id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Sessions_Stddev_Pop_Fields = {
  __typename?: 'sessions_stddev_pop_fields';
  appointment_id?: Maybe<Scalars['Float']>;
  facilitator_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
  organization_id?: Maybe<Scalars['Float']>;
  program_id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Sessions_Stddev_Samp_Fields = {
  __typename?: 'sessions_stddev_samp_fields';
  appointment_id?: Maybe<Scalars['Float']>;
  facilitator_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
  organization_id?: Maybe<Scalars['Float']>;
  program_id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Sessions_Sum_Fields = {
  __typename?: 'sessions_sum_fields';
  appointment_id?: Maybe<Scalars['Int']>;
  facilitator_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  location_id?: Maybe<Scalars['Int']>;
  organization_id?: Maybe<Scalars['Int']>;
  program_id?: Maybe<Scalars['Int']>;
  provider_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "sessions" */
export enum Sessions_Update_Column {
  /** column name */
  AppointmentId = 'appointment_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FacilitatorId = 'facilitator_id',
  /** column name */
  FrequencyType = 'frequency_type',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  Language = 'language',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  LocationName = 'location_name',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  ProgramId = 'program_id',
  /** column name */
  ProviderId = 'provider_id',
  /** column name */
  SessionStartDate = 'session_start_date',
  /** column name */
  SessionTime = 'session_time',
  /** column name */
  SessionTypeCode = 'session_type_code',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VideoParticipants = 'video_participants'
}

/** aggregate var_pop on columns */
export type Sessions_Var_Pop_Fields = {
  __typename?: 'sessions_var_pop_fields';
  appointment_id?: Maybe<Scalars['Float']>;
  facilitator_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
  organization_id?: Maybe<Scalars['Float']>;
  program_id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Sessions_Var_Samp_Fields = {
  __typename?: 'sessions_var_samp_fields';
  appointment_id?: Maybe<Scalars['Float']>;
  facilitator_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
  organization_id?: Maybe<Scalars['Float']>;
  program_id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Sessions_Variance_Fields = {
  __typename?: 'sessions_variance_fields';
  appointment_id?: Maybe<Scalars['Float']>;
  facilitator_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
  organization_id?: Maybe<Scalars['Float']>;
  program_id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to compare columns of type "smallint". All fields are combined with logical 'AND'. */
export type Smallint_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['smallint']>;
  _gt?: InputMaybe<Scalars['smallint']>;
  _gte?: InputMaybe<Scalars['smallint']>;
  _in?: InputMaybe<Array<Scalars['smallint']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['smallint']>;
  _lte?: InputMaybe<Scalars['smallint']>;
  _neq?: InputMaybe<Scalars['smallint']>;
  _nin?: InputMaybe<Array<Scalars['smallint']>>;
};

/** columns and relationships of "station_orders" */
export type Station_Orders = {
  __typename?: 'station_orders';
  id: Scalars['Int'];
  is_deleted?: Maybe<Scalars['Boolean']>;
  /** An object relationship */
  order: Orders;
  order_id: Scalars['Int'];
  /** An object relationship */
  station: Stations;
  station_id: Scalars['Int'];
  /** An object relationship */
  visit?: Maybe<Visits>;
  visit_id?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "station_orders" */
export type Station_Orders_Aggregate = {
  __typename?: 'station_orders_aggregate';
  aggregate?: Maybe<Station_Orders_Aggregate_Fields>;
  nodes: Array<Station_Orders>;
};

/** aggregate fields of "station_orders" */
export type Station_Orders_Aggregate_Fields = {
  __typename?: 'station_orders_aggregate_fields';
  avg?: Maybe<Station_Orders_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Station_Orders_Max_Fields>;
  min?: Maybe<Station_Orders_Min_Fields>;
  stddev?: Maybe<Station_Orders_Stddev_Fields>;
  stddev_pop?: Maybe<Station_Orders_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Station_Orders_Stddev_Samp_Fields>;
  sum?: Maybe<Station_Orders_Sum_Fields>;
  var_pop?: Maybe<Station_Orders_Var_Pop_Fields>;
  var_samp?: Maybe<Station_Orders_Var_Samp_Fields>;
  variance?: Maybe<Station_Orders_Variance_Fields>;
};


/** aggregate fields of "station_orders" */
export type Station_Orders_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Station_Orders_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "station_orders" */
export type Station_Orders_Aggregate_Order_By = {
  avg?: InputMaybe<Station_Orders_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Station_Orders_Max_Order_By>;
  min?: InputMaybe<Station_Orders_Min_Order_By>;
  stddev?: InputMaybe<Station_Orders_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Station_Orders_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Station_Orders_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Station_Orders_Sum_Order_By>;
  var_pop?: InputMaybe<Station_Orders_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Station_Orders_Var_Samp_Order_By>;
  variance?: InputMaybe<Station_Orders_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "station_orders" */
export type Station_Orders_Arr_Rel_Insert_Input = {
  data: Array<Station_Orders_Insert_Input>;
  /** on conflict condition */
  on_conflict?: InputMaybe<Station_Orders_On_Conflict>;
};

/** aggregate avg on columns */
export type Station_Orders_Avg_Fields = {
  __typename?: 'station_orders_avg_fields';
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  station_id?: Maybe<Scalars['Float']>;
  visit_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "station_orders" */
export type Station_Orders_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  station_id?: InputMaybe<Order_By>;
  visit_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "station_orders". All fields are combined with a logical 'AND'. */
export type Station_Orders_Bool_Exp = {
  _and?: InputMaybe<Array<Station_Orders_Bool_Exp>>;
  _not?: InputMaybe<Station_Orders_Bool_Exp>;
  _or?: InputMaybe<Array<Station_Orders_Bool_Exp>>;
  id?: InputMaybe<Int_Comparison_Exp>;
  is_deleted?: InputMaybe<Boolean_Comparison_Exp>;
  order?: InputMaybe<Orders_Bool_Exp>;
  order_id?: InputMaybe<Int_Comparison_Exp>;
  station?: InputMaybe<Stations_Bool_Exp>;
  station_id?: InputMaybe<Int_Comparison_Exp>;
  visit?: InputMaybe<Visits_Bool_Exp>;
  visit_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "station_orders" */
export enum Station_Orders_Constraint {
  /** unique or primary key constraint */
  StationOrdersOrderIdKey = 'station_orders_order_id_key',
  /** unique or primary key constraint */
  StationOrdersPkey = 'station_orders_pkey'
}

/** input type for incrementing numeric columns in table "station_orders" */
export type Station_Orders_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  order_id?: InputMaybe<Scalars['Int']>;
  station_id?: InputMaybe<Scalars['Int']>;
  visit_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "station_orders" */
export type Station_Orders_Insert_Input = {
  id?: InputMaybe<Scalars['Int']>;
  is_deleted?: InputMaybe<Scalars['Boolean']>;
  order?: InputMaybe<Orders_Obj_Rel_Insert_Input>;
  order_id?: InputMaybe<Scalars['Int']>;
  station?: InputMaybe<Stations_Obj_Rel_Insert_Input>;
  station_id?: InputMaybe<Scalars['Int']>;
  visit?: InputMaybe<Visits_Obj_Rel_Insert_Input>;
  visit_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Station_Orders_Max_Fields = {
  __typename?: 'station_orders_max_fields';
  id?: Maybe<Scalars['Int']>;
  order_id?: Maybe<Scalars['Int']>;
  station_id?: Maybe<Scalars['Int']>;
  visit_id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "station_orders" */
export type Station_Orders_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  station_id?: InputMaybe<Order_By>;
  visit_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Station_Orders_Min_Fields = {
  __typename?: 'station_orders_min_fields';
  id?: Maybe<Scalars['Int']>;
  order_id?: Maybe<Scalars['Int']>;
  station_id?: Maybe<Scalars['Int']>;
  visit_id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "station_orders" */
export type Station_Orders_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  station_id?: InputMaybe<Order_By>;
  visit_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "station_orders" */
export type Station_Orders_Mutation_Response = {
  __typename?: 'station_orders_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Station_Orders>;
};

/** input type for inserting object relation for remote table "station_orders" */
export type Station_Orders_Obj_Rel_Insert_Input = {
  data: Station_Orders_Insert_Input;
  /** on conflict condition */
  on_conflict?: InputMaybe<Station_Orders_On_Conflict>;
};

/** on conflict condition type for table "station_orders" */
export type Station_Orders_On_Conflict = {
  constraint: Station_Orders_Constraint;
  update_columns?: Array<Station_Orders_Update_Column>;
  where?: InputMaybe<Station_Orders_Bool_Exp>;
};

/** Ordering options when selecting data from "station_orders". */
export type Station_Orders_Order_By = {
  id?: InputMaybe<Order_By>;
  is_deleted?: InputMaybe<Order_By>;
  order?: InputMaybe<Orders_Order_By>;
  order_id?: InputMaybe<Order_By>;
  station?: InputMaybe<Stations_Order_By>;
  station_id?: InputMaybe<Order_By>;
  visit?: InputMaybe<Visits_Order_By>;
  visit_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: station_orders */
export type Station_Orders_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "station_orders" */
export enum Station_Orders_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  StationId = 'station_id',
  /** column name */
  VisitId = 'visit_id'
}

/** input type for updating data in table "station_orders" */
export type Station_Orders_Set_Input = {
  id?: InputMaybe<Scalars['Int']>;
  is_deleted?: InputMaybe<Scalars['Boolean']>;
  order_id?: InputMaybe<Scalars['Int']>;
  station_id?: InputMaybe<Scalars['Int']>;
  visit_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Station_Orders_Stddev_Fields = {
  __typename?: 'station_orders_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  station_id?: Maybe<Scalars['Float']>;
  visit_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "station_orders" */
export type Station_Orders_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  station_id?: InputMaybe<Order_By>;
  visit_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Station_Orders_Stddev_Pop_Fields = {
  __typename?: 'station_orders_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  station_id?: Maybe<Scalars['Float']>;
  visit_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "station_orders" */
export type Station_Orders_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  station_id?: InputMaybe<Order_By>;
  visit_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Station_Orders_Stddev_Samp_Fields = {
  __typename?: 'station_orders_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  station_id?: Maybe<Scalars['Float']>;
  visit_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "station_orders" */
export type Station_Orders_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  station_id?: InputMaybe<Order_By>;
  visit_id?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Station_Orders_Sum_Fields = {
  __typename?: 'station_orders_sum_fields';
  id?: Maybe<Scalars['Int']>;
  order_id?: Maybe<Scalars['Int']>;
  station_id?: Maybe<Scalars['Int']>;
  visit_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "station_orders" */
export type Station_Orders_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  station_id?: InputMaybe<Order_By>;
  visit_id?: InputMaybe<Order_By>;
};

/** update columns of table "station_orders" */
export enum Station_Orders_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  StationId = 'station_id',
  /** column name */
  VisitId = 'visit_id'
}

/** aggregate var_pop on columns */
export type Station_Orders_Var_Pop_Fields = {
  __typename?: 'station_orders_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  station_id?: Maybe<Scalars['Float']>;
  visit_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "station_orders" */
export type Station_Orders_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  station_id?: InputMaybe<Order_By>;
  visit_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Station_Orders_Var_Samp_Fields = {
  __typename?: 'station_orders_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  station_id?: Maybe<Scalars['Float']>;
  visit_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "station_orders" */
export type Station_Orders_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  station_id?: InputMaybe<Order_By>;
  visit_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Station_Orders_Variance_Fields = {
  __typename?: 'station_orders_variance_fields';
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  station_id?: Maybe<Scalars['Float']>;
  visit_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "station_orders" */
export type Station_Orders_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  station_id?: InputMaybe<Order_By>;
  visit_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "station_type" */
export type Station_Type = {
  __typename?: 'station_type';
  code: Scalars['String'];
  is_deleted?: Maybe<Scalars['Boolean']>;
  /** An array relationship */
  session_station_configs: Array<Session_Station_Config>;
  /** An aggregate relationship */
  session_station_configs_aggregate: Session_Station_Config_Aggregate;
  title: Scalars['String'];
  visit_action_name?: Maybe<Scalars['String']>;
};


/** columns and relationships of "station_type" */
export type Station_TypeSession_Station_ConfigsArgs = {
  distinct_on?: InputMaybe<Array<Session_Station_Config_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Session_Station_Config_Order_By>>;
  where?: InputMaybe<Session_Station_Config_Bool_Exp>;
};


/** columns and relationships of "station_type" */
export type Station_TypeSession_Station_Configs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Session_Station_Config_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Session_Station_Config_Order_By>>;
  where?: InputMaybe<Session_Station_Config_Bool_Exp>;
};

/** aggregated selection of "station_type" */
export type Station_Type_Aggregate = {
  __typename?: 'station_type_aggregate';
  aggregate?: Maybe<Station_Type_Aggregate_Fields>;
  nodes: Array<Station_Type>;
};

/** aggregate fields of "station_type" */
export type Station_Type_Aggregate_Fields = {
  __typename?: 'station_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Station_Type_Max_Fields>;
  min?: Maybe<Station_Type_Min_Fields>;
};


/** aggregate fields of "station_type" */
export type Station_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Station_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "station_type". All fields are combined with a logical 'AND'. */
export type Station_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Station_Type_Bool_Exp>>;
  _not?: InputMaybe<Station_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Station_Type_Bool_Exp>>;
  code?: InputMaybe<String_Comparison_Exp>;
  is_deleted?: InputMaybe<Boolean_Comparison_Exp>;
  session_station_configs?: InputMaybe<Session_Station_Config_Bool_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  visit_action_name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "station_type" */
export enum Station_Type_Constraint {
  /** unique or primary key constraint */
  StationTypePkey = 'station_type_pkey'
}

/** input type for inserting data into table "station_type" */
export type Station_Type_Insert_Input = {
  code?: InputMaybe<Scalars['String']>;
  is_deleted?: InputMaybe<Scalars['Boolean']>;
  session_station_configs?: InputMaybe<Session_Station_Config_Arr_Rel_Insert_Input>;
  title?: InputMaybe<Scalars['String']>;
  visit_action_name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Station_Type_Max_Fields = {
  __typename?: 'station_type_max_fields';
  code?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  visit_action_name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Station_Type_Min_Fields = {
  __typename?: 'station_type_min_fields';
  code?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  visit_action_name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "station_type" */
export type Station_Type_Mutation_Response = {
  __typename?: 'station_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Station_Type>;
};

/** input type for inserting object relation for remote table "station_type" */
export type Station_Type_Obj_Rel_Insert_Input = {
  data: Station_Type_Insert_Input;
  /** on conflict condition */
  on_conflict?: InputMaybe<Station_Type_On_Conflict>;
};

/** on conflict condition type for table "station_type" */
export type Station_Type_On_Conflict = {
  constraint: Station_Type_Constraint;
  update_columns?: Array<Station_Type_Update_Column>;
  where?: InputMaybe<Station_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "station_type". */
export type Station_Type_Order_By = {
  code?: InputMaybe<Order_By>;
  is_deleted?: InputMaybe<Order_By>;
  session_station_configs_aggregate?: InputMaybe<Session_Station_Config_Aggregate_Order_By>;
  title?: InputMaybe<Order_By>;
  visit_action_name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: station_type */
export type Station_Type_Pk_Columns_Input = {
  code: Scalars['String'];
};

/** select columns of table "station_type" */
export enum Station_Type_Select_Column {
  /** column name */
  Code = 'code',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  Title = 'title',
  /** column name */
  VisitActionName = 'visit_action_name'
}

/** input type for updating data in table "station_type" */
export type Station_Type_Set_Input = {
  code?: InputMaybe<Scalars['String']>;
  is_deleted?: InputMaybe<Scalars['Boolean']>;
  title?: InputMaybe<Scalars['String']>;
  visit_action_name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "station_type" */
export enum Station_Type_Update_Column {
  /** column name */
  Code = 'code',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  Title = 'title',
  /** column name */
  VisitActionName = 'visit_action_name'
}

/** columns and relationships of "station_visits" */
export type Station_Visits = {
  __typename?: 'station_visits';
  id: Scalars['Int'];
  is_deleted?: Maybe<Scalars['Boolean']>;
  station_id: Scalars['Int'];
  /** An object relationship */
  visit: Visits;
  visit_id: Scalars['Int'];
};

/** aggregated selection of "station_visits" */
export type Station_Visits_Aggregate = {
  __typename?: 'station_visits_aggregate';
  aggregate?: Maybe<Station_Visits_Aggregate_Fields>;
  nodes: Array<Station_Visits>;
};

/** aggregate fields of "station_visits" */
export type Station_Visits_Aggregate_Fields = {
  __typename?: 'station_visits_aggregate_fields';
  avg?: Maybe<Station_Visits_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Station_Visits_Max_Fields>;
  min?: Maybe<Station_Visits_Min_Fields>;
  stddev?: Maybe<Station_Visits_Stddev_Fields>;
  stddev_pop?: Maybe<Station_Visits_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Station_Visits_Stddev_Samp_Fields>;
  sum?: Maybe<Station_Visits_Sum_Fields>;
  var_pop?: Maybe<Station_Visits_Var_Pop_Fields>;
  var_samp?: Maybe<Station_Visits_Var_Samp_Fields>;
  variance?: Maybe<Station_Visits_Variance_Fields>;
};


/** aggregate fields of "station_visits" */
export type Station_Visits_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Station_Visits_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "station_visits" */
export type Station_Visits_Aggregate_Order_By = {
  avg?: InputMaybe<Station_Visits_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Station_Visits_Max_Order_By>;
  min?: InputMaybe<Station_Visits_Min_Order_By>;
  stddev?: InputMaybe<Station_Visits_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Station_Visits_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Station_Visits_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Station_Visits_Sum_Order_By>;
  var_pop?: InputMaybe<Station_Visits_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Station_Visits_Var_Samp_Order_By>;
  variance?: InputMaybe<Station_Visits_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "station_visits" */
export type Station_Visits_Arr_Rel_Insert_Input = {
  data: Array<Station_Visits_Insert_Input>;
  /** on conflict condition */
  on_conflict?: InputMaybe<Station_Visits_On_Conflict>;
};

/** aggregate avg on columns */
export type Station_Visits_Avg_Fields = {
  __typename?: 'station_visits_avg_fields';
  id?: Maybe<Scalars['Float']>;
  station_id?: Maybe<Scalars['Float']>;
  visit_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "station_visits" */
export type Station_Visits_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  station_id?: InputMaybe<Order_By>;
  visit_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "station_visits". All fields are combined with a logical 'AND'. */
export type Station_Visits_Bool_Exp = {
  _and?: InputMaybe<Array<Station_Visits_Bool_Exp>>;
  _not?: InputMaybe<Station_Visits_Bool_Exp>;
  _or?: InputMaybe<Array<Station_Visits_Bool_Exp>>;
  id?: InputMaybe<Int_Comparison_Exp>;
  is_deleted?: InputMaybe<Boolean_Comparison_Exp>;
  station_id?: InputMaybe<Int_Comparison_Exp>;
  visit?: InputMaybe<Visits_Bool_Exp>;
  visit_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "station_visits" */
export enum Station_Visits_Constraint {
  /** unique or primary key constraint */
  StationVisitsPkey = 'station_visits_pkey',
  /** unique or primary key constraint */
  StationVisitsVisitIdKey = 'station_visits_visit_id_key'
}

/** input type for incrementing numeric columns in table "station_visits" */
export type Station_Visits_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  station_id?: InputMaybe<Scalars['Int']>;
  visit_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "station_visits" */
export type Station_Visits_Insert_Input = {
  id?: InputMaybe<Scalars['Int']>;
  is_deleted?: InputMaybe<Scalars['Boolean']>;
  station_id?: InputMaybe<Scalars['Int']>;
  visit?: InputMaybe<Visits_Obj_Rel_Insert_Input>;
  visit_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Station_Visits_Max_Fields = {
  __typename?: 'station_visits_max_fields';
  id?: Maybe<Scalars['Int']>;
  station_id?: Maybe<Scalars['Int']>;
  visit_id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "station_visits" */
export type Station_Visits_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  station_id?: InputMaybe<Order_By>;
  visit_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Station_Visits_Min_Fields = {
  __typename?: 'station_visits_min_fields';
  id?: Maybe<Scalars['Int']>;
  station_id?: Maybe<Scalars['Int']>;
  visit_id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "station_visits" */
export type Station_Visits_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  station_id?: InputMaybe<Order_By>;
  visit_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "station_visits" */
export type Station_Visits_Mutation_Response = {
  __typename?: 'station_visits_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Station_Visits>;
};

/** input type for inserting object relation for remote table "station_visits" */
export type Station_Visits_Obj_Rel_Insert_Input = {
  data: Station_Visits_Insert_Input;
  /** on conflict condition */
  on_conflict?: InputMaybe<Station_Visits_On_Conflict>;
};

/** on conflict condition type for table "station_visits" */
export type Station_Visits_On_Conflict = {
  constraint: Station_Visits_Constraint;
  update_columns?: Array<Station_Visits_Update_Column>;
  where?: InputMaybe<Station_Visits_Bool_Exp>;
};

/** Ordering options when selecting data from "station_visits". */
export type Station_Visits_Order_By = {
  id?: InputMaybe<Order_By>;
  is_deleted?: InputMaybe<Order_By>;
  station_id?: InputMaybe<Order_By>;
  visit?: InputMaybe<Visits_Order_By>;
  visit_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: station_visits */
export type Station_Visits_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "station_visits" */
export enum Station_Visits_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  StationId = 'station_id',
  /** column name */
  VisitId = 'visit_id'
}

/** input type for updating data in table "station_visits" */
export type Station_Visits_Set_Input = {
  id?: InputMaybe<Scalars['Int']>;
  is_deleted?: InputMaybe<Scalars['Boolean']>;
  station_id?: InputMaybe<Scalars['Int']>;
  visit_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Station_Visits_Stddev_Fields = {
  __typename?: 'station_visits_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  station_id?: Maybe<Scalars['Float']>;
  visit_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "station_visits" */
export type Station_Visits_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  station_id?: InputMaybe<Order_By>;
  visit_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Station_Visits_Stddev_Pop_Fields = {
  __typename?: 'station_visits_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  station_id?: Maybe<Scalars['Float']>;
  visit_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "station_visits" */
export type Station_Visits_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  station_id?: InputMaybe<Order_By>;
  visit_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Station_Visits_Stddev_Samp_Fields = {
  __typename?: 'station_visits_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  station_id?: Maybe<Scalars['Float']>;
  visit_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "station_visits" */
export type Station_Visits_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  station_id?: InputMaybe<Order_By>;
  visit_id?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Station_Visits_Sum_Fields = {
  __typename?: 'station_visits_sum_fields';
  id?: Maybe<Scalars['Int']>;
  station_id?: Maybe<Scalars['Int']>;
  visit_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "station_visits" */
export type Station_Visits_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  station_id?: InputMaybe<Order_By>;
  visit_id?: InputMaybe<Order_By>;
};

/** update columns of table "station_visits" */
export enum Station_Visits_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  StationId = 'station_id',
  /** column name */
  VisitId = 'visit_id'
}

/** aggregate var_pop on columns */
export type Station_Visits_Var_Pop_Fields = {
  __typename?: 'station_visits_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  station_id?: Maybe<Scalars['Float']>;
  visit_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "station_visits" */
export type Station_Visits_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  station_id?: InputMaybe<Order_By>;
  visit_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Station_Visits_Var_Samp_Fields = {
  __typename?: 'station_visits_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  station_id?: Maybe<Scalars['Float']>;
  visit_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "station_visits" */
export type Station_Visits_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  station_id?: InputMaybe<Order_By>;
  visit_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Station_Visits_Variance_Fields = {
  __typename?: 'station_visits_variance_fields';
  id?: Maybe<Scalars['Float']>;
  station_id?: Maybe<Scalars['Float']>;
  visit_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "station_visits" */
export type Station_Visits_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  station_id?: InputMaybe<Order_By>;
  visit_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "stations" */
export type Stations = {
  __typename?: 'stations';
  code: Scalars['String'];
  id: Scalars['Int'];
  is_deleted?: Maybe<Scalars['Boolean']>;
  /** An array relationship */
  order_types: Array<Order_Type>;
  /** An aggregate relationship */
  order_types_aggregate: Order_Type_Aggregate;
  position: Scalars['Int'];
  /** An object relationship */
  session: Sessions;
  session_id: Scalars['Int'];
  /** An array relationship */
  station_orders: Array<Station_Orders>;
  /** An aggregate relationship */
  station_orders_aggregate: Station_Orders_Aggregate;
  /** An object relationship */
  station_type: Station_Type;
  /** An array relationship */
  station_visits: Array<Station_Visits>;
  /** An aggregate relationship */
  station_visits_aggregate: Station_Visits_Aggregate;
  title: Scalars['String'];
  visit_action_name?: Maybe<Scalars['String']>;
};


/** columns and relationships of "stations" */
export type StationsOrder_TypesArgs = {
  distinct_on?: InputMaybe<Array<Order_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Order_Type_Order_By>>;
  where?: InputMaybe<Order_Type_Bool_Exp>;
};


/** columns and relationships of "stations" */
export type StationsOrder_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Order_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Order_Type_Order_By>>;
  where?: InputMaybe<Order_Type_Bool_Exp>;
};


/** columns and relationships of "stations" */
export type StationsStation_OrdersArgs = {
  distinct_on?: InputMaybe<Array<Station_Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Station_Orders_Order_By>>;
  where?: InputMaybe<Station_Orders_Bool_Exp>;
};


/** columns and relationships of "stations" */
export type StationsStation_Orders_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Station_Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Station_Orders_Order_By>>;
  where?: InputMaybe<Station_Orders_Bool_Exp>;
};


/** columns and relationships of "stations" */
export type StationsStation_VisitsArgs = {
  distinct_on?: InputMaybe<Array<Station_Visits_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Station_Visits_Order_By>>;
  where?: InputMaybe<Station_Visits_Bool_Exp>;
};


/** columns and relationships of "stations" */
export type StationsStation_Visits_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Station_Visits_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Station_Visits_Order_By>>;
  where?: InputMaybe<Station_Visits_Bool_Exp>;
};

/** aggregated selection of "stations" */
export type Stations_Aggregate = {
  __typename?: 'stations_aggregate';
  aggregate?: Maybe<Stations_Aggregate_Fields>;
  nodes: Array<Stations>;
};

/** aggregate fields of "stations" */
export type Stations_Aggregate_Fields = {
  __typename?: 'stations_aggregate_fields';
  avg?: Maybe<Stations_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Stations_Max_Fields>;
  min?: Maybe<Stations_Min_Fields>;
  stddev?: Maybe<Stations_Stddev_Fields>;
  stddev_pop?: Maybe<Stations_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Stations_Stddev_Samp_Fields>;
  sum?: Maybe<Stations_Sum_Fields>;
  var_pop?: Maybe<Stations_Var_Pop_Fields>;
  var_samp?: Maybe<Stations_Var_Samp_Fields>;
  variance?: Maybe<Stations_Variance_Fields>;
};


/** aggregate fields of "stations" */
export type Stations_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Stations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "stations" */
export type Stations_Aggregate_Order_By = {
  avg?: InputMaybe<Stations_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Stations_Max_Order_By>;
  min?: InputMaybe<Stations_Min_Order_By>;
  stddev?: InputMaybe<Stations_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Stations_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Stations_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Stations_Sum_Order_By>;
  var_pop?: InputMaybe<Stations_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Stations_Var_Samp_Order_By>;
  variance?: InputMaybe<Stations_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "stations" */
export type Stations_Arr_Rel_Insert_Input = {
  data: Array<Stations_Insert_Input>;
  /** on conflict condition */
  on_conflict?: InputMaybe<Stations_On_Conflict>;
};

/** aggregate avg on columns */
export type Stations_Avg_Fields = {
  __typename?: 'stations_avg_fields';
  id?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
  session_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "stations" */
export type Stations_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  position?: InputMaybe<Order_By>;
  session_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "stations". All fields are combined with a logical 'AND'. */
export type Stations_Bool_Exp = {
  _and?: InputMaybe<Array<Stations_Bool_Exp>>;
  _not?: InputMaybe<Stations_Bool_Exp>;
  _or?: InputMaybe<Array<Stations_Bool_Exp>>;
  code?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  is_deleted?: InputMaybe<Boolean_Comparison_Exp>;
  order_types?: InputMaybe<Order_Type_Bool_Exp>;
  position?: InputMaybe<Int_Comparison_Exp>;
  session?: InputMaybe<Sessions_Bool_Exp>;
  session_id?: InputMaybe<Int_Comparison_Exp>;
  station_orders?: InputMaybe<Station_Orders_Bool_Exp>;
  station_type?: InputMaybe<Station_Type_Bool_Exp>;
  station_visits?: InputMaybe<Station_Visits_Bool_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  visit_action_name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "stations" */
export enum Stations_Constraint {
  /** unique or primary key constraint */
  StationsPkey = 'stations_pkey'
}

/** input type for incrementing numeric columns in table "stations" */
export type Stations_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  position?: InputMaybe<Scalars['Int']>;
  session_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "stations" */
export type Stations_Insert_Input = {
  code?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  is_deleted?: InputMaybe<Scalars['Boolean']>;
  order_types?: InputMaybe<Order_Type_Arr_Rel_Insert_Input>;
  position?: InputMaybe<Scalars['Int']>;
  session?: InputMaybe<Sessions_Obj_Rel_Insert_Input>;
  session_id?: InputMaybe<Scalars['Int']>;
  station_orders?: InputMaybe<Station_Orders_Arr_Rel_Insert_Input>;
  station_type?: InputMaybe<Station_Type_Obj_Rel_Insert_Input>;
  station_visits?: InputMaybe<Station_Visits_Arr_Rel_Insert_Input>;
  title?: InputMaybe<Scalars['String']>;
  visit_action_name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Stations_Max_Fields = {
  __typename?: 'stations_max_fields';
  code?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  position?: Maybe<Scalars['Int']>;
  session_id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  visit_action_name?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "stations" */
export type Stations_Max_Order_By = {
  code?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  position?: InputMaybe<Order_By>;
  session_id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  visit_action_name?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Stations_Min_Fields = {
  __typename?: 'stations_min_fields';
  code?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  position?: Maybe<Scalars['Int']>;
  session_id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  visit_action_name?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "stations" */
export type Stations_Min_Order_By = {
  code?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  position?: InputMaybe<Order_By>;
  session_id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  visit_action_name?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "stations" */
export type Stations_Mutation_Response = {
  __typename?: 'stations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Stations>;
};

/** input type for inserting object relation for remote table "stations" */
export type Stations_Obj_Rel_Insert_Input = {
  data: Stations_Insert_Input;
  /** on conflict condition */
  on_conflict?: InputMaybe<Stations_On_Conflict>;
};

/** on conflict condition type for table "stations" */
export type Stations_On_Conflict = {
  constraint: Stations_Constraint;
  update_columns?: Array<Stations_Update_Column>;
  where?: InputMaybe<Stations_Bool_Exp>;
};

/** Ordering options when selecting data from "stations". */
export type Stations_Order_By = {
  code?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_deleted?: InputMaybe<Order_By>;
  order_types_aggregate?: InputMaybe<Order_Type_Aggregate_Order_By>;
  position?: InputMaybe<Order_By>;
  session?: InputMaybe<Sessions_Order_By>;
  session_id?: InputMaybe<Order_By>;
  station_orders_aggregate?: InputMaybe<Station_Orders_Aggregate_Order_By>;
  station_type?: InputMaybe<Station_Type_Order_By>;
  station_visits_aggregate?: InputMaybe<Station_Visits_Aggregate_Order_By>;
  title?: InputMaybe<Order_By>;
  visit_action_name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: stations */
export type Stations_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "stations" */
export enum Stations_Select_Column {
  /** column name */
  Code = 'code',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  Position = 'position',
  /** column name */
  SessionId = 'session_id',
  /** column name */
  Title = 'title',
  /** column name */
  VisitActionName = 'visit_action_name'
}

/** input type for updating data in table "stations" */
export type Stations_Set_Input = {
  code?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  is_deleted?: InputMaybe<Scalars['Boolean']>;
  position?: InputMaybe<Scalars['Int']>;
  session_id?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
  visit_action_name?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Stations_Stddev_Fields = {
  __typename?: 'stations_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
  session_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "stations" */
export type Stations_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  position?: InputMaybe<Order_By>;
  session_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Stations_Stddev_Pop_Fields = {
  __typename?: 'stations_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
  session_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "stations" */
export type Stations_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  position?: InputMaybe<Order_By>;
  session_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Stations_Stddev_Samp_Fields = {
  __typename?: 'stations_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
  session_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "stations" */
export type Stations_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  position?: InputMaybe<Order_By>;
  session_id?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Stations_Sum_Fields = {
  __typename?: 'stations_sum_fields';
  id?: Maybe<Scalars['Int']>;
  position?: Maybe<Scalars['Int']>;
  session_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "stations" */
export type Stations_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  position?: InputMaybe<Order_By>;
  session_id?: InputMaybe<Order_By>;
};

/** update columns of table "stations" */
export enum Stations_Update_Column {
  /** column name */
  Code = 'code',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  Position = 'position',
  /** column name */
  SessionId = 'session_id',
  /** column name */
  Title = 'title',
  /** column name */
  VisitActionName = 'visit_action_name'
}

/** aggregate var_pop on columns */
export type Stations_Var_Pop_Fields = {
  __typename?: 'stations_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
  session_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "stations" */
export type Stations_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  position?: InputMaybe<Order_By>;
  session_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Stations_Var_Samp_Fields = {
  __typename?: 'stations_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
  session_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "stations" */
export type Stations_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  position?: InputMaybe<Order_By>;
  session_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Stations_Variance_Fields = {
  __typename?: 'stations_variance_fields';
  id?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
  session_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "stations" */
export type Stations_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  position?: InputMaybe<Order_By>;
  session_id?: InputMaybe<Order_By>;
};

export type Submit_Patient_Registration_Response = {
  __typename?: 'submit_patient_registration_response';
  master_id: Scalars['String'];
};

export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** An array relationship */
  additional_care_providers: Array<Additional_Care_Providers>;
  /** An aggregate relationship */
  additional_care_providers_aggregate: Additional_Care_Providers_Aggregate;
  /** fetch data from the table: "additional_care_providers" using primary key columns */
  additional_care_providers_by_pk?: Maybe<Additional_Care_Providers>;
  /** fetch data from the table: "allergies" */
  allergies: Array<Allergies>;
  /** fetch aggregated fields from the table: "allergies" */
  allergies_aggregate: Allergies_Aggregate;
  /** fetch data from the table: "allergies" using primary key columns */
  allergies_by_pk?: Maybe<Allergies>;
  /** fetch data from the table: "clearance_notes" */
  clearance_notes: Array<Clearance_Notes>;
  /** fetch aggregated fields from the table: "clearance_notes" */
  clearance_notes_aggregate: Clearance_Notes_Aggregate;
  /** fetch data from the table: "clearance_notes" using primary key columns */
  clearance_notes_by_pk?: Maybe<Clearance_Notes>;
  /** fetch data from the table: "contacts" */
  contacts: Array<Contacts>;
  /** fetch aggregated fields from the table: "contacts" */
  contacts_aggregate: Contacts_Aggregate;
  /** fetch data from the table: "contacts" using primary key columns */
  contacts_by_pk?: Maybe<Contacts>;
  /** fetch data from the table: "contacts_to_patients" */
  contacts_to_patients: Array<Contacts_To_Patients>;
  /** fetch aggregated fields from the table: "contacts_to_patients" */
  contacts_to_patients_aggregate: Contacts_To_Patients_Aggregate;
  /** fetch data from the table: "contacts_to_patients" using primary key columns */
  contacts_to_patients_by_pk?: Maybe<Contacts_To_Patients>;
  /** fetch data from the table: "discharges" */
  discharges: Array<Discharges>;
  /** fetch aggregated fields from the table: "discharges" */
  discharges_aggregate: Discharges_Aggregate;
  /** fetch data from the table: "discharges" using primary key columns */
  discharges_by_pk?: Maybe<Discharges>;
  /** fetch data from the table: "enrollments" */
  enrollments: Array<Enrollments>;
  /** fetch aggregated fields from the table: "enrollments" */
  enrollments_aggregate: Enrollments_Aggregate;
  /** fetch data from the table: "enrollments" using primary key columns */
  enrollments_by_pk?: Maybe<Enrollments>;
  /** fetch data from the table: "event_types" */
  event_types: Array<Event_Types>;
  /** fetch aggregated fields from the table: "event_types" */
  event_types_aggregate: Event_Types_Aggregate;
  /** fetch data from the table: "event_types" using primary key columns */
  event_types_by_pk?: Maybe<Event_Types>;
  /** An array relationship */
  events: Array<Events>;
  /** An aggregate relationship */
  events_aggregate: Events_Aggregate;
  /** fetch data from the table: "events" using primary key columns */
  events_by_pk?: Maybe<Events>;
  /** fetch data from the table: "form_localizations" */
  form_localizations: Array<Form_Localizations>;
  /** fetch aggregated fields from the table: "form_localizations" */
  form_localizations_aggregate: Form_Localizations_Aggregate;
  /** fetch data from the table: "form_localizations" using primary key columns */
  form_localizations_by_pk?: Maybe<Form_Localizations>;
  /** fetch data from the table: "form_types" */
  form_types: Array<Form_Types>;
  /** fetch aggregated fields from the table: "form_types" */
  form_types_aggregate: Form_Types_Aggregate;
  /** fetch data from the table: "form_types" using primary key columns */
  form_types_by_pk?: Maybe<Form_Types>;
  /** fetch data from the table: "forms" */
  forms: Array<Forms>;
  /** fetch aggregated fields from the table: "forms" */
  forms_aggregate: Forms_Aggregate;
  /** fetch data from the table: "forms" using primary key columns */
  forms_by_pk?: Maybe<Forms>;
  /** fetch data from the table: "insurance_vendors" */
  insurance_vendors: Array<Insurance_Vendors>;
  /** fetch aggregated fields from the table: "insurance_vendors" */
  insurance_vendors_aggregate: Insurance_Vendors_Aggregate;
  /** fetch data from the table: "insurance_vendors" using primary key columns */
  insurance_vendors_by_pk?: Maybe<Insurance_Vendors>;
  /** fetch data from the table: "insurances" */
  insurances: Array<Insurances>;
  /** fetch aggregated fields from the table: "insurances" */
  insurances_aggregate: Insurances_Aggregate;
  /** fetch data from the table: "insurances" using primary key columns */
  insurances_by_pk?: Maybe<Insurances>;
  /** An array relationship */
  medication_orders: Array<Medication_Orders>;
  /** An aggregate relationship */
  medication_orders_aggregate: Medication_Orders_Aggregate;
  /** fetch data from the table: "medication_orders" using primary key columns */
  medication_orders_by_pk?: Maybe<Medication_Orders>;
  /** fetch data from the table: "order_dshs_covid_test" */
  order_dshs_covid_test: Array<Order_Dshs_Covid_Test>;
  /** fetch aggregated fields from the table: "order_dshs_covid_test" */
  order_dshs_covid_test_aggregate: Order_Dshs_Covid_Test_Aggregate;
  /** fetch data from the table: "order_dshs_covid_test" using primary key columns */
  order_dshs_covid_test_by_pk?: Maybe<Order_Dshs_Covid_Test>;
  /** fetch data from the table: "order_height_and_weight" */
  order_height_and_weight: Array<Order_Height_And_Weight>;
  /** fetch aggregated fields from the table: "order_height_and_weight" */
  order_height_and_weight_aggregate: Order_Height_And_Weight_Aggregate;
  /** fetch data from the table: "order_height_and_weight" using primary key columns */
  order_height_and_weight_by_pk?: Maybe<Order_Height_And_Weight>;
  /** fetch data from the table: "order_medical_assessment" */
  order_medical_assessment: Array<Order_Medical_Assessment>;
  /** fetch aggregated fields from the table: "order_medical_assessment" */
  order_medical_assessment_aggregate: Order_Medical_Assessment_Aggregate;
  /** fetch data from the table: "order_medical_assessment" using primary key columns */
  order_medical_assessment_by_pk?: Maybe<Order_Medical_Assessment>;
  /** fetch data from the table: "order_musculoskeletal" */
  order_musculoskeletal: Array<Order_Musculoskeletal>;
  /** fetch aggregated fields from the table: "order_musculoskeletal" */
  order_musculoskeletal_aggregate: Order_Musculoskeletal_Aggregate;
  /** fetch data from the table: "order_musculoskeletal" using primary key columns */
  order_musculoskeletal_by_pk?: Maybe<Order_Musculoskeletal>;
  /** fetch data from the table: "order_type" */
  order_type: Array<Order_Type>;
  /** fetch aggregated fields from the table: "order_type" */
  order_type_aggregate: Order_Type_Aggregate;
  /** fetch data from the table: "order_type" using primary key columns */
  order_type_by_pk?: Maybe<Order_Type>;
  /** fetch data from the table: "order_vision" */
  order_vision: Array<Order_Vision>;
  /** fetch aggregated fields from the table: "order_vision" */
  order_vision_aggregate: Order_Vision_Aggregate;
  /** fetch data from the table: "order_vision" using primary key columns */
  order_vision_by_pk?: Maybe<Order_Vision>;
  /** fetch data from the table: "orders" */
  orders: Array<Orders>;
  /** An aggregate relationship */
  orders_aggregate: Orders_Aggregate;
  /** fetch data from the table: "orders" using primary key columns */
  orders_by_pk?: Maybe<Orders>;
  /** fetch data from the table: "organizations.district_days_off" */
  organizations_district_days_off: Array<Organizations_District_Days_Off>;
  /** fetch aggregated fields from the table: "organizations.district_days_off" */
  organizations_district_days_off_aggregate: Organizations_District_Days_Off_Aggregate;
  /** fetch data from the table: "organizations.district_days_off" using primary key columns */
  organizations_district_days_off_by_pk?: Maybe<Organizations_District_Days_Off>;
  /** fetch data from the table: "organizations.features" */
  organizations_features: Array<Organizations_Features>;
  /** fetch aggregated fields from the table: "organizations.features" */
  organizations_features_aggregate: Organizations_Features_Aggregate;
  /** fetch data from the table: "organizations.features" using primary key columns */
  organizations_features_by_pk?: Maybe<Organizations_Features>;
  /** fetch data from the table: "organizations.locations" */
  organizations_locations: Array<Organizations_Locations>;
  /** fetch aggregated fields from the table: "organizations.locations" */
  organizations_locations_aggregate: Organizations_Locations_Aggregate;
  /** fetch data from the table: "organizations.locations" using primary key columns */
  organizations_locations_by_pk?: Maybe<Organizations_Locations>;
  /** fetch data from the table: "organizations.organizations" */
  organizations_organizations: Array<Organizations_Organizations>;
  /** fetch aggregated fields from the table: "organizations.organizations" */
  organizations_organizations_aggregate: Organizations_Organizations_Aggregate;
  /** fetch data from the table: "organizations.organizations" using primary key columns */
  organizations_organizations_by_pk?: Maybe<Organizations_Organizations>;
  /** fetch data from the table: "organizations.organizations_features" */
  organizations_organizations_features: Array<Organizations_Organizations_Features>;
  /** fetch aggregated fields from the table: "organizations.organizations_features" */
  organizations_organizations_features_aggregate: Organizations_Organizations_Features_Aggregate;
  /** fetch data from the table: "organizations.organizations_features" using primary key columns */
  organizations_organizations_features_by_pk?: Maybe<Organizations_Organizations_Features>;
  /** fetch data from the table: "organizations.regions" */
  organizations_regions: Array<Organizations_Regions>;
  /** fetch aggregated fields from the table: "organizations.regions" */
  organizations_regions_aggregate: Organizations_Regions_Aggregate;
  /** fetch data from the table: "organizations.regions" using primary key columns */
  organizations_regions_by_pk?: Maybe<Organizations_Regions>;
  /** fetch data from the table: "organizations.organization_x_schoolyear" */
  organizations_school_years: Array<Organizations_School_Years>;
  /** fetch aggregated fields from the table: "organizations.organization_x_schoolyear" */
  organizations_school_years_aggregate: Organizations_School_Years_Aggregate;
  /** fetch data from the table: "organizations.organization_x_schoolyear" using primary key columns */
  organizations_school_years_by_pk?: Maybe<Organizations_School_Years>;
  /** fetch data from the table: "organizations.location_x_testing_location" */
  organizations_testing_locations: Array<Organizations_Testing_Locations>;
  /** fetch aggregated fields from the table: "organizations.location_x_testing_location" */
  organizations_testing_locations_aggregate: Organizations_Testing_Locations_Aggregate;
  /** fetch data from the table: "organizations.location_x_testing_location" using primary key columns */
  organizations_testing_locations_by_pk?: Maybe<Organizations_Testing_Locations>;
  /** fetch data from the table: "mat_tmp_fast_demographics" */
  patient_demographics: Array<Patient_Demographics>;
  /** fetch aggregated fields from the table: "mat_tmp_fast_demographics" */
  patient_demographics_aggregate: Patient_Demographics_Aggregate;
  /** fetch data from the table: "mat_fast_search_json" */
  patients: Array<Patients>;
  /** fetch aggregated fields from the table: "mat_fast_search_json" */
  patients_aggregate: Patients_Aggregate;
  /** fetch data from the table: "pharmacies" */
  pharmacies: Array<Pharmacies>;
  /** fetch aggregated fields from the table: "pharmacies" */
  pharmacies_aggregate: Pharmacies_Aggregate;
  /** fetch data from the table: "pharmacies" using primary key columns */
  pharmacies_by_pk?: Maybe<Pharmacies>;
  /** fetch data from the table: "place_of_service" */
  place_of_service: Array<Place_Of_Service>;
  /** fetch aggregated fields from the table: "place_of_service" */
  place_of_service_aggregate: Place_Of_Service_Aggregate;
  /** fetch data from the table: "place_of_service" using primary key columns */
  place_of_service_by_pk?: Maybe<Place_Of_Service>;
  /** fetch data from the table: "pond_form_localizations" */
  pond_form_localizations: Array<Pond_Form_Localizations>;
  /** fetch aggregated fields from the table: "pond_form_localizations" */
  pond_form_localizations_aggregate: Pond_Form_Localizations_Aggregate;
  /** fetch data from the table: "pond_form_localizations" using primary key columns */
  pond_form_localizations_by_pk?: Maybe<Pond_Form_Localizations>;
  /** fetch data from the table: "pond_form_types" */
  pond_form_types: Array<Pond_Form_Types>;
  /** fetch aggregated fields from the table: "pond_form_types" */
  pond_form_types_aggregate: Pond_Form_Types_Aggregate;
  /** fetch data from the table: "pond_form_types" using primary key columns */
  pond_form_types_by_pk?: Maybe<Pond_Form_Types>;
  /** fetch data from the table: "pond_forms" */
  pond_forms: Array<Pond_Forms>;
  /** fetch aggregated fields from the table: "pond_forms" */
  pond_forms_aggregate: Pond_Forms_Aggregate;
  /** fetch data from the table: "pond_forms" using primary key columns */
  pond_forms_by_pk?: Maybe<Pond_Forms>;
  /** fetch data from the table: "patients" */
  portal_patients: Array<Portal_Patients>;
  /** fetch aggregated fields from the table: "patients" */
  portal_patients_aggregate: Portal_Patients_Aggregate;
  /** fetch data from the table: "patients" using primary key columns */
  portal_patients_by_pk?: Maybe<Portal_Patients>;
  /** fetch data from the table: "programs" */
  programs: Array<Programs>;
  /** fetch aggregated fields from the table: "programs" */
  programs_aggregate: Programs_Aggregate;
  /** fetch data from the table: "programs" using primary key columns */
  programs_by_pk?: Maybe<Programs>;
  /** fetch data from the table: "providers" */
  providers: Array<Providers>;
  /** fetch aggregated fields from the table: "providers" */
  providers_aggregate: Providers_Aggregate;
  /** fetch data from the table: "providers" using primary key columns */
  providers_by_pk?: Maybe<Providers>;
  /** fetch data from the table: "roles" */
  roles: Array<Roles>;
  /** fetch aggregated fields from the table: "roles" */
  roles_aggregate: Roles_Aggregate;
  /** fetch data from the table: "roles" using primary key columns */
  roles_by_pk?: Maybe<Roles>;
  /** An array relationship */
  session_logs: Array<Session_Logs>;
  /** An aggregate relationship */
  session_logs_aggregate: Session_Logs_Aggregate;
  /** fetch data from the table: "session_logs" using primary key columns */
  session_logs_by_pk?: Maybe<Session_Logs>;
  /** fetch data from the table: "session_order_config" */
  session_order_config: Array<Session_Order_Config>;
  /** fetch aggregated fields from the table: "session_order_config" */
  session_order_config_aggregate: Session_Order_Config_Aggregate;
  /** fetch data from the table: "session_order_config" using primary key columns */
  session_order_config_by_pk?: Maybe<Session_Order_Config>;
  /** fetch data from the table: "session_station_config" */
  session_station_config: Array<Session_Station_Config>;
  /** fetch aggregated fields from the table: "session_station_config" */
  session_station_config_aggregate: Session_Station_Config_Aggregate;
  /** fetch data from the table: "session_station_config" using primary key columns */
  session_station_config_by_pk?: Maybe<Session_Station_Config>;
  /** fetch data from the table: "session_type" */
  session_type: Array<Session_Type>;
  /** fetch aggregated fields from the table: "session_type" */
  session_type_aggregate: Session_Type_Aggregate;
  /** fetch data from the table: "session_type" using primary key columns */
  session_type_by_pk?: Maybe<Session_Type>;
  /** fetch data from the table: "sessions" */
  sessions: Array<Sessions>;
  /** fetch aggregated fields from the table: "sessions" */
  sessions_aggregate: Sessions_Aggregate;
  /** fetch data from the table: "sessions" using primary key columns */
  sessions_by_pk?: Maybe<Sessions>;
  /** An array relationship */
  station_orders: Array<Station_Orders>;
  /** An aggregate relationship */
  station_orders_aggregate: Station_Orders_Aggregate;
  /** fetch data from the table: "station_orders" using primary key columns */
  station_orders_by_pk?: Maybe<Station_Orders>;
  /** fetch data from the table: "station_type" */
  station_type: Array<Station_Type>;
  /** fetch aggregated fields from the table: "station_type" */
  station_type_aggregate: Station_Type_Aggregate;
  /** fetch data from the table: "station_type" using primary key columns */
  station_type_by_pk?: Maybe<Station_Type>;
  /** An array relationship */
  station_visits: Array<Station_Visits>;
  /** An aggregate relationship */
  station_visits_aggregate: Station_Visits_Aggregate;
  /** fetch data from the table: "station_visits" using primary key columns */
  station_visits_by_pk?: Maybe<Station_Visits>;
  /** An array relationship */
  stations: Array<Stations>;
  /** An aggregate relationship */
  stations_aggregate: Stations_Aggregate;
  /** fetch data from the table: "stations" using primary key columns */
  stations_by_pk?: Maybe<Stations>;
  /** An array relationship */
  test_orders: Array<Test_Orders>;
  /** An aggregate relationship */
  test_orders_aggregate: Test_Orders_Aggregate;
  /** fetch data from the table: "test_orders" using primary key columns */
  test_orders_by_pk?: Maybe<Test_Orders>;
  /** fetch data from the table: "users" */
  users: Array<Users>;
  /** fetch aggregated fields from the table: "users" */
  users_aggregate: Users_Aggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>;
  /** fetch data from the table: "video_calls" */
  video_calls: Array<Video_Calls>;
  /** fetch aggregated fields from the table: "video_calls" */
  video_calls_aggregate: Video_Calls_Aggregate;
  /** fetch data from the table: "video_calls" using primary key columns */
  video_calls_by_pk?: Maybe<Video_Calls>;
  /** An array relationship */
  visits: Array<Visits>;
  /** An aggregate relationship */
  visits_aggregate: Visits_Aggregate;
  /** fetch data from the table: "visits" using primary key columns */
  visits_by_pk?: Maybe<Visits>;
};


export type Subscription_RootAdditional_Care_ProvidersArgs = {
  distinct_on?: InputMaybe<Array<Additional_Care_Providers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Additional_Care_Providers_Order_By>>;
  where?: InputMaybe<Additional_Care_Providers_Bool_Exp>;
};


export type Subscription_RootAdditional_Care_Providers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Additional_Care_Providers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Additional_Care_Providers_Order_By>>;
  where?: InputMaybe<Additional_Care_Providers_Bool_Exp>;
};


export type Subscription_RootAdditional_Care_Providers_By_PkArgs = {
  patient_id: Scalars['Int'];
  provider_id: Scalars['Int'];
};


export type Subscription_RootAllergiesArgs = {
  distinct_on?: InputMaybe<Array<Allergies_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Allergies_Order_By>>;
  where?: InputMaybe<Allergies_Bool_Exp>;
};


export type Subscription_RootAllergies_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Allergies_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Allergies_Order_By>>;
  where?: InputMaybe<Allergies_Bool_Exp>;
};


export type Subscription_RootAllergies_By_PkArgs = {
  patient_id: Scalars['Int'];
};


export type Subscription_RootClearance_NotesArgs = {
  distinct_on?: InputMaybe<Array<Clearance_Notes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Clearance_Notes_Order_By>>;
  where?: InputMaybe<Clearance_Notes_Bool_Exp>;
};


export type Subscription_RootClearance_Notes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Clearance_Notes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Clearance_Notes_Order_By>>;
  where?: InputMaybe<Clearance_Notes_Bool_Exp>;
};


export type Subscription_RootClearance_Notes_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootContactsArgs = {
  distinct_on?: InputMaybe<Array<Contacts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contacts_Order_By>>;
  where?: InputMaybe<Contacts_Bool_Exp>;
};


export type Subscription_RootContacts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contacts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contacts_Order_By>>;
  where?: InputMaybe<Contacts_Bool_Exp>;
};


export type Subscription_RootContacts_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootContacts_To_PatientsArgs = {
  distinct_on?: InputMaybe<Array<Contacts_To_Patients_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contacts_To_Patients_Order_By>>;
  where?: InputMaybe<Contacts_To_Patients_Bool_Exp>;
};


export type Subscription_RootContacts_To_Patients_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contacts_To_Patients_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contacts_To_Patients_Order_By>>;
  where?: InputMaybe<Contacts_To_Patients_Bool_Exp>;
};


export type Subscription_RootContacts_To_Patients_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootDischargesArgs = {
  distinct_on?: InputMaybe<Array<Discharges_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Discharges_Order_By>>;
  where?: InputMaybe<Discharges_Bool_Exp>;
};


export type Subscription_RootDischarges_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Discharges_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Discharges_Order_By>>;
  where?: InputMaybe<Discharges_Bool_Exp>;
};


export type Subscription_RootDischarges_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootEnrollmentsArgs = {
  distinct_on?: InputMaybe<Array<Enrollments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enrollments_Order_By>>;
  where?: InputMaybe<Enrollments_Bool_Exp>;
};


export type Subscription_RootEnrollments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enrollments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enrollments_Order_By>>;
  where?: InputMaybe<Enrollments_Bool_Exp>;
};


export type Subscription_RootEnrollments_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootEvent_TypesArgs = {
  distinct_on?: InputMaybe<Array<Event_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Event_Types_Order_By>>;
  where?: InputMaybe<Event_Types_Bool_Exp>;
};


export type Subscription_RootEvent_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Event_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Event_Types_Order_By>>;
  where?: InputMaybe<Event_Types_Bool_Exp>;
};


export type Subscription_RootEvent_Types_By_PkArgs = {
  code: Scalars['String'];
};


export type Subscription_RootEventsArgs = {
  distinct_on?: InputMaybe<Array<Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Events_Order_By>>;
  where?: InputMaybe<Events_Bool_Exp>;
};


export type Subscription_RootEvents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Events_Order_By>>;
  where?: InputMaybe<Events_Bool_Exp>;
};


export type Subscription_RootEvents_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootForm_LocalizationsArgs = {
  distinct_on?: InputMaybe<Array<Form_Localizations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Form_Localizations_Order_By>>;
  where?: InputMaybe<Form_Localizations_Bool_Exp>;
};


export type Subscription_RootForm_Localizations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Form_Localizations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Form_Localizations_Order_By>>;
  where?: InputMaybe<Form_Localizations_Bool_Exp>;
};


export type Subscription_RootForm_Localizations_By_PkArgs = {
  locale: Scalars['String'];
  type: Scalars['String'];
};


export type Subscription_RootForm_TypesArgs = {
  distinct_on?: InputMaybe<Array<Form_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Form_Types_Order_By>>;
  where?: InputMaybe<Form_Types_Bool_Exp>;
};


export type Subscription_RootForm_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Form_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Form_Types_Order_By>>;
  where?: InputMaybe<Form_Types_Bool_Exp>;
};


export type Subscription_RootForm_Types_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootFormsArgs = {
  distinct_on?: InputMaybe<Array<Forms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Forms_Order_By>>;
  where?: InputMaybe<Forms_Bool_Exp>;
};


export type Subscription_RootForms_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Forms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Forms_Order_By>>;
  where?: InputMaybe<Forms_Bool_Exp>;
};


export type Subscription_RootForms_By_PkArgs = {
  patient_id: Scalars['String'];
  type: Scalars['String'];
};


export type Subscription_RootInsurance_VendorsArgs = {
  distinct_on?: InputMaybe<Array<Insurance_Vendors_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Insurance_Vendors_Order_By>>;
  where?: InputMaybe<Insurance_Vendors_Bool_Exp>;
};


export type Subscription_RootInsurance_Vendors_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Insurance_Vendors_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Insurance_Vendors_Order_By>>;
  where?: InputMaybe<Insurance_Vendors_Bool_Exp>;
};


export type Subscription_RootInsurance_Vendors_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootInsurancesArgs = {
  distinct_on?: InputMaybe<Array<Insurances_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Insurances_Order_By>>;
  where?: InputMaybe<Insurances_Bool_Exp>;
};


export type Subscription_RootInsurances_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Insurances_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Insurances_Order_By>>;
  where?: InputMaybe<Insurances_Bool_Exp>;
};


export type Subscription_RootInsurances_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootMedication_OrdersArgs = {
  distinct_on?: InputMaybe<Array<Medication_Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Medication_Orders_Order_By>>;
  where?: InputMaybe<Medication_Orders_Bool_Exp>;
};


export type Subscription_RootMedication_Orders_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Medication_Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Medication_Orders_Order_By>>;
  where?: InputMaybe<Medication_Orders_Bool_Exp>;
};


export type Subscription_RootMedication_Orders_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootOrder_Dshs_Covid_TestArgs = {
  distinct_on?: InputMaybe<Array<Order_Dshs_Covid_Test_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Order_Dshs_Covid_Test_Order_By>>;
  where?: InputMaybe<Order_Dshs_Covid_Test_Bool_Exp>;
};


export type Subscription_RootOrder_Dshs_Covid_Test_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Order_Dshs_Covid_Test_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Order_Dshs_Covid_Test_Order_By>>;
  where?: InputMaybe<Order_Dshs_Covid_Test_Bool_Exp>;
};


export type Subscription_RootOrder_Dshs_Covid_Test_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootOrder_Height_And_WeightArgs = {
  distinct_on?: InputMaybe<Array<Order_Height_And_Weight_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Order_Height_And_Weight_Order_By>>;
  where?: InputMaybe<Order_Height_And_Weight_Bool_Exp>;
};


export type Subscription_RootOrder_Height_And_Weight_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Order_Height_And_Weight_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Order_Height_And_Weight_Order_By>>;
  where?: InputMaybe<Order_Height_And_Weight_Bool_Exp>;
};


export type Subscription_RootOrder_Height_And_Weight_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootOrder_Medical_AssessmentArgs = {
  distinct_on?: InputMaybe<Array<Order_Medical_Assessment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Order_Medical_Assessment_Order_By>>;
  where?: InputMaybe<Order_Medical_Assessment_Bool_Exp>;
};


export type Subscription_RootOrder_Medical_Assessment_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Order_Medical_Assessment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Order_Medical_Assessment_Order_By>>;
  where?: InputMaybe<Order_Medical_Assessment_Bool_Exp>;
};


export type Subscription_RootOrder_Medical_Assessment_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootOrder_MusculoskeletalArgs = {
  distinct_on?: InputMaybe<Array<Order_Musculoskeletal_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Order_Musculoskeletal_Order_By>>;
  where?: InputMaybe<Order_Musculoskeletal_Bool_Exp>;
};


export type Subscription_RootOrder_Musculoskeletal_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Order_Musculoskeletal_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Order_Musculoskeletal_Order_By>>;
  where?: InputMaybe<Order_Musculoskeletal_Bool_Exp>;
};


export type Subscription_RootOrder_Musculoskeletal_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootOrder_TypeArgs = {
  distinct_on?: InputMaybe<Array<Order_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Order_Type_Order_By>>;
  where?: InputMaybe<Order_Type_Bool_Exp>;
};


export type Subscription_RootOrder_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Order_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Order_Type_Order_By>>;
  where?: InputMaybe<Order_Type_Bool_Exp>;
};


export type Subscription_RootOrder_Type_By_PkArgs = {
  code: Scalars['String'];
};


export type Subscription_RootOrder_VisionArgs = {
  distinct_on?: InputMaybe<Array<Order_Vision_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Order_Vision_Order_By>>;
  where?: InputMaybe<Order_Vision_Bool_Exp>;
};


export type Subscription_RootOrder_Vision_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Order_Vision_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Order_Vision_Order_By>>;
  where?: InputMaybe<Order_Vision_Bool_Exp>;
};


export type Subscription_RootOrder_Vision_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootOrdersArgs = {
  distinct_on?: InputMaybe<Array<Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Orders_Order_By>>;
  where?: InputMaybe<Orders_Bool_Exp>;
};


export type Subscription_RootOrders_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Orders_Order_By>>;
  where?: InputMaybe<Orders_Bool_Exp>;
};


export type Subscription_RootOrders_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootOrganizations_District_Days_OffArgs = {
  distinct_on?: InputMaybe<Array<Organizations_District_Days_Off_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organizations_District_Days_Off_Order_By>>;
  where?: InputMaybe<Organizations_District_Days_Off_Bool_Exp>;
};


export type Subscription_RootOrganizations_District_Days_Off_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organizations_District_Days_Off_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organizations_District_Days_Off_Order_By>>;
  where?: InputMaybe<Organizations_District_Days_Off_Bool_Exp>;
};


export type Subscription_RootOrganizations_District_Days_Off_By_PkArgs = {
  days_off_id: Scalars['bigint'];
};


export type Subscription_RootOrganizations_FeaturesArgs = {
  distinct_on?: InputMaybe<Array<Organizations_Features_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organizations_Features_Order_By>>;
  where?: InputMaybe<Organizations_Features_Bool_Exp>;
};


export type Subscription_RootOrganizations_Features_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organizations_Features_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organizations_Features_Order_By>>;
  where?: InputMaybe<Organizations_Features_Bool_Exp>;
};


export type Subscription_RootOrganizations_Features_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootOrganizations_LocationsArgs = {
  distinct_on?: InputMaybe<Array<Organizations_Locations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organizations_Locations_Order_By>>;
  where?: InputMaybe<Organizations_Locations_Bool_Exp>;
};


export type Subscription_RootOrganizations_Locations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organizations_Locations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organizations_Locations_Order_By>>;
  where?: InputMaybe<Organizations_Locations_Bool_Exp>;
};


export type Subscription_RootOrganizations_Locations_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootOrganizations_OrganizationsArgs = {
  distinct_on?: InputMaybe<Array<Organizations_Organizations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organizations_Organizations_Order_By>>;
  where?: InputMaybe<Organizations_Organizations_Bool_Exp>;
};


export type Subscription_RootOrganizations_Organizations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organizations_Organizations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organizations_Organizations_Order_By>>;
  where?: InputMaybe<Organizations_Organizations_Bool_Exp>;
};


export type Subscription_RootOrganizations_Organizations_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootOrganizations_Organizations_FeaturesArgs = {
  distinct_on?: InputMaybe<Array<Organizations_Organizations_Features_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organizations_Organizations_Features_Order_By>>;
  where?: InputMaybe<Organizations_Organizations_Features_Bool_Exp>;
};


export type Subscription_RootOrganizations_Organizations_Features_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organizations_Organizations_Features_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organizations_Organizations_Features_Order_By>>;
  where?: InputMaybe<Organizations_Organizations_Features_Bool_Exp>;
};


export type Subscription_RootOrganizations_Organizations_Features_By_PkArgs = {
  feature_id: Scalars['String'];
  organization_id: Scalars['Int'];
};


export type Subscription_RootOrganizations_RegionsArgs = {
  distinct_on?: InputMaybe<Array<Organizations_Regions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organizations_Regions_Order_By>>;
  where?: InputMaybe<Organizations_Regions_Bool_Exp>;
};


export type Subscription_RootOrganizations_Regions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organizations_Regions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organizations_Regions_Order_By>>;
  where?: InputMaybe<Organizations_Regions_Bool_Exp>;
};


export type Subscription_RootOrganizations_Regions_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootOrganizations_School_YearsArgs = {
  distinct_on?: InputMaybe<Array<Organizations_School_Years_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organizations_School_Years_Order_By>>;
  where?: InputMaybe<Organizations_School_Years_Bool_Exp>;
};


export type Subscription_RootOrganizations_School_Years_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organizations_School_Years_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organizations_School_Years_Order_By>>;
  where?: InputMaybe<Organizations_School_Years_Bool_Exp>;
};


export type Subscription_RootOrganizations_School_Years_By_PkArgs = {
  organization_x_schoolyear_id: Scalars['bigint'];
};


export type Subscription_RootOrganizations_Testing_LocationsArgs = {
  distinct_on?: InputMaybe<Array<Organizations_Testing_Locations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organizations_Testing_Locations_Order_By>>;
  where?: InputMaybe<Organizations_Testing_Locations_Bool_Exp>;
};


export type Subscription_RootOrganizations_Testing_Locations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organizations_Testing_Locations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organizations_Testing_Locations_Order_By>>;
  where?: InputMaybe<Organizations_Testing_Locations_Bool_Exp>;
};


export type Subscription_RootOrganizations_Testing_Locations_By_PkArgs = {
  location_x_testing_location_id: Scalars['bigint'];
};


export type Subscription_RootPatient_DemographicsArgs = {
  distinct_on?: InputMaybe<Array<Patient_Demographics_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Patient_Demographics_Order_By>>;
  where?: InputMaybe<Patient_Demographics_Bool_Exp>;
};


export type Subscription_RootPatient_Demographics_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Patient_Demographics_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Patient_Demographics_Order_By>>;
  where?: InputMaybe<Patient_Demographics_Bool_Exp>;
};


export type Subscription_RootPatientsArgs = {
  distinct_on?: InputMaybe<Array<Patients_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Patients_Order_By>>;
  where?: InputMaybe<Patients_Bool_Exp>;
};


export type Subscription_RootPatients_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Patients_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Patients_Order_By>>;
  where?: InputMaybe<Patients_Bool_Exp>;
};


export type Subscription_RootPharmaciesArgs = {
  distinct_on?: InputMaybe<Array<Pharmacies_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Pharmacies_Order_By>>;
  where?: InputMaybe<Pharmacies_Bool_Exp>;
};


export type Subscription_RootPharmacies_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Pharmacies_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Pharmacies_Order_By>>;
  where?: InputMaybe<Pharmacies_Bool_Exp>;
};


export type Subscription_RootPharmacies_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootPlace_Of_ServiceArgs = {
  distinct_on?: InputMaybe<Array<Place_Of_Service_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Place_Of_Service_Order_By>>;
  where?: InputMaybe<Place_Of_Service_Bool_Exp>;
};


export type Subscription_RootPlace_Of_Service_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Place_Of_Service_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Place_Of_Service_Order_By>>;
  where?: InputMaybe<Place_Of_Service_Bool_Exp>;
};


export type Subscription_RootPlace_Of_Service_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootPond_Form_LocalizationsArgs = {
  distinct_on?: InputMaybe<Array<Pond_Form_Localizations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Pond_Form_Localizations_Order_By>>;
  where?: InputMaybe<Pond_Form_Localizations_Bool_Exp>;
};


export type Subscription_RootPond_Form_Localizations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Pond_Form_Localizations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Pond_Form_Localizations_Order_By>>;
  where?: InputMaybe<Pond_Form_Localizations_Bool_Exp>;
};


export type Subscription_RootPond_Form_Localizations_By_PkArgs = {
  form_type_code: Scalars['String'];
  locale: Scalars['String'];
};


export type Subscription_RootPond_Form_TypesArgs = {
  distinct_on?: InputMaybe<Array<Pond_Form_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Pond_Form_Types_Order_By>>;
  where?: InputMaybe<Pond_Form_Types_Bool_Exp>;
};


export type Subscription_RootPond_Form_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Pond_Form_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Pond_Form_Types_Order_By>>;
  where?: InputMaybe<Pond_Form_Types_Bool_Exp>;
};


export type Subscription_RootPond_Form_Types_By_PkArgs = {
  code: Scalars['String'];
};


export type Subscription_RootPond_FormsArgs = {
  distinct_on?: InputMaybe<Array<Pond_Forms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Pond_Forms_Order_By>>;
  where?: InputMaybe<Pond_Forms_Bool_Exp>;
};


export type Subscription_RootPond_Forms_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Pond_Forms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Pond_Forms_Order_By>>;
  where?: InputMaybe<Pond_Forms_Bool_Exp>;
};


export type Subscription_RootPond_Forms_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootPortal_PatientsArgs = {
  distinct_on?: InputMaybe<Array<Portal_Patients_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Portal_Patients_Order_By>>;
  where?: InputMaybe<Portal_Patients_Bool_Exp>;
};


export type Subscription_RootPortal_Patients_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Portal_Patients_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Portal_Patients_Order_By>>;
  where?: InputMaybe<Portal_Patients_Bool_Exp>;
};


export type Subscription_RootPortal_Patients_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootProgramsArgs = {
  distinct_on?: InputMaybe<Array<Programs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Programs_Order_By>>;
  where?: InputMaybe<Programs_Bool_Exp>;
};


export type Subscription_RootPrograms_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Programs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Programs_Order_By>>;
  where?: InputMaybe<Programs_Bool_Exp>;
};


export type Subscription_RootPrograms_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootProvidersArgs = {
  distinct_on?: InputMaybe<Array<Providers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Providers_Order_By>>;
  where?: InputMaybe<Providers_Bool_Exp>;
};


export type Subscription_RootProviders_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Providers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Providers_Order_By>>;
  where?: InputMaybe<Providers_Bool_Exp>;
};


export type Subscription_RootProviders_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootRolesArgs = {
  distinct_on?: InputMaybe<Array<Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Roles_Order_By>>;
  where?: InputMaybe<Roles_Bool_Exp>;
};


export type Subscription_RootRoles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Roles_Order_By>>;
  where?: InputMaybe<Roles_Bool_Exp>;
};


export type Subscription_RootRoles_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootSession_LogsArgs = {
  distinct_on?: InputMaybe<Array<Session_Logs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Session_Logs_Order_By>>;
  where?: InputMaybe<Session_Logs_Bool_Exp>;
};


export type Subscription_RootSession_Logs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Session_Logs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Session_Logs_Order_By>>;
  where?: InputMaybe<Session_Logs_Bool_Exp>;
};


export type Subscription_RootSession_Logs_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootSession_Order_ConfigArgs = {
  distinct_on?: InputMaybe<Array<Session_Order_Config_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Session_Order_Config_Order_By>>;
  where?: InputMaybe<Session_Order_Config_Bool_Exp>;
};


export type Subscription_RootSession_Order_Config_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Session_Order_Config_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Session_Order_Config_Order_By>>;
  where?: InputMaybe<Session_Order_Config_Bool_Exp>;
};


export type Subscription_RootSession_Order_Config_By_PkArgs = {
  order_code: Scalars['String'];
  session_code: Scalars['String'];
};


export type Subscription_RootSession_Station_ConfigArgs = {
  distinct_on?: InputMaybe<Array<Session_Station_Config_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Session_Station_Config_Order_By>>;
  where?: InputMaybe<Session_Station_Config_Bool_Exp>;
};


export type Subscription_RootSession_Station_Config_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Session_Station_Config_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Session_Station_Config_Order_By>>;
  where?: InputMaybe<Session_Station_Config_Bool_Exp>;
};


export type Subscription_RootSession_Station_Config_By_PkArgs = {
  session_code: Scalars['String'];
  station_code: Scalars['String'];
};


export type Subscription_RootSession_TypeArgs = {
  distinct_on?: InputMaybe<Array<Session_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Session_Type_Order_By>>;
  where?: InputMaybe<Session_Type_Bool_Exp>;
};


export type Subscription_RootSession_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Session_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Session_Type_Order_By>>;
  where?: InputMaybe<Session_Type_Bool_Exp>;
};


export type Subscription_RootSession_Type_By_PkArgs = {
  code: Scalars['String'];
};


export type Subscription_RootSessionsArgs = {
  distinct_on?: InputMaybe<Array<Sessions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sessions_Order_By>>;
  where?: InputMaybe<Sessions_Bool_Exp>;
};


export type Subscription_RootSessions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Sessions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sessions_Order_By>>;
  where?: InputMaybe<Sessions_Bool_Exp>;
};


export type Subscription_RootSessions_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootStation_OrdersArgs = {
  distinct_on?: InputMaybe<Array<Station_Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Station_Orders_Order_By>>;
  where?: InputMaybe<Station_Orders_Bool_Exp>;
};


export type Subscription_RootStation_Orders_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Station_Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Station_Orders_Order_By>>;
  where?: InputMaybe<Station_Orders_Bool_Exp>;
};


export type Subscription_RootStation_Orders_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootStation_TypeArgs = {
  distinct_on?: InputMaybe<Array<Station_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Station_Type_Order_By>>;
  where?: InputMaybe<Station_Type_Bool_Exp>;
};


export type Subscription_RootStation_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Station_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Station_Type_Order_By>>;
  where?: InputMaybe<Station_Type_Bool_Exp>;
};


export type Subscription_RootStation_Type_By_PkArgs = {
  code: Scalars['String'];
};


export type Subscription_RootStation_VisitsArgs = {
  distinct_on?: InputMaybe<Array<Station_Visits_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Station_Visits_Order_By>>;
  where?: InputMaybe<Station_Visits_Bool_Exp>;
};


export type Subscription_RootStation_Visits_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Station_Visits_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Station_Visits_Order_By>>;
  where?: InputMaybe<Station_Visits_Bool_Exp>;
};


export type Subscription_RootStation_Visits_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootStationsArgs = {
  distinct_on?: InputMaybe<Array<Stations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stations_Order_By>>;
  where?: InputMaybe<Stations_Bool_Exp>;
};


export type Subscription_RootStations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Stations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stations_Order_By>>;
  where?: InputMaybe<Stations_Bool_Exp>;
};


export type Subscription_RootStations_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootTest_OrdersArgs = {
  distinct_on?: InputMaybe<Array<Test_Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Test_Orders_Order_By>>;
  where?: InputMaybe<Test_Orders_Bool_Exp>;
};


export type Subscription_RootTest_Orders_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Test_Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Test_Orders_Order_By>>;
  where?: InputMaybe<Test_Orders_Bool_Exp>;
};


export type Subscription_RootTest_Orders_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootUsersArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Subscription_RootUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Subscription_RootUsers_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootVideo_CallsArgs = {
  distinct_on?: InputMaybe<Array<Video_Calls_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Video_Calls_Order_By>>;
  where?: InputMaybe<Video_Calls_Bool_Exp>;
};


export type Subscription_RootVideo_Calls_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Video_Calls_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Video_Calls_Order_By>>;
  where?: InputMaybe<Video_Calls_Bool_Exp>;
};


export type Subscription_RootVideo_Calls_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootVisitsArgs = {
  distinct_on?: InputMaybe<Array<Visits_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Visits_Order_By>>;
  where?: InputMaybe<Visits_Bool_Exp>;
};


export type Subscription_RootVisits_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Visits_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Visits_Order_By>>;
  where?: InputMaybe<Visits_Bool_Exp>;
};


export type Subscription_RootVisits_By_PkArgs = {
  id: Scalars['Int'];
};

/** columns and relationships of "test_orders" */
export type Test_Orders = {
  __typename?: 'test_orders';
  administration_notes?: Maybe<Scalars['String']>;
  completed_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  facilitator_id?: Maybe<Scalars['Int']>;
  follow_up_instructions?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  is_deleted?: Maybe<Scalars['Boolean']>;
  provider_id: Scalars['Int'];
  provider_orders?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  test_outcome?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  unread?: Maybe<Scalars['Boolean']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  visit_id: Scalars['Int'];
};

/** aggregated selection of "test_orders" */
export type Test_Orders_Aggregate = {
  __typename?: 'test_orders_aggregate';
  aggregate?: Maybe<Test_Orders_Aggregate_Fields>;
  nodes: Array<Test_Orders>;
};

/** aggregate fields of "test_orders" */
export type Test_Orders_Aggregate_Fields = {
  __typename?: 'test_orders_aggregate_fields';
  avg?: Maybe<Test_Orders_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Test_Orders_Max_Fields>;
  min?: Maybe<Test_Orders_Min_Fields>;
  stddev?: Maybe<Test_Orders_Stddev_Fields>;
  stddev_pop?: Maybe<Test_Orders_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Test_Orders_Stddev_Samp_Fields>;
  sum?: Maybe<Test_Orders_Sum_Fields>;
  var_pop?: Maybe<Test_Orders_Var_Pop_Fields>;
  var_samp?: Maybe<Test_Orders_Var_Samp_Fields>;
  variance?: Maybe<Test_Orders_Variance_Fields>;
};


/** aggregate fields of "test_orders" */
export type Test_Orders_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Test_Orders_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "test_orders" */
export type Test_Orders_Aggregate_Order_By = {
  avg?: InputMaybe<Test_Orders_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Test_Orders_Max_Order_By>;
  min?: InputMaybe<Test_Orders_Min_Order_By>;
  stddev?: InputMaybe<Test_Orders_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Test_Orders_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Test_Orders_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Test_Orders_Sum_Order_By>;
  var_pop?: InputMaybe<Test_Orders_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Test_Orders_Var_Samp_Order_By>;
  variance?: InputMaybe<Test_Orders_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "test_orders" */
export type Test_Orders_Arr_Rel_Insert_Input = {
  data: Array<Test_Orders_Insert_Input>;
  /** on conflict condition */
  on_conflict?: InputMaybe<Test_Orders_On_Conflict>;
};

/** aggregate avg on columns */
export type Test_Orders_Avg_Fields = {
  __typename?: 'test_orders_avg_fields';
  facilitator_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
  visit_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "test_orders" */
export type Test_Orders_Avg_Order_By = {
  facilitator_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  provider_id?: InputMaybe<Order_By>;
  visit_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "test_orders". All fields are combined with a logical 'AND'. */
export type Test_Orders_Bool_Exp = {
  _and?: InputMaybe<Array<Test_Orders_Bool_Exp>>;
  _not?: InputMaybe<Test_Orders_Bool_Exp>;
  _or?: InputMaybe<Array<Test_Orders_Bool_Exp>>;
  administration_notes?: InputMaybe<String_Comparison_Exp>;
  completed_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  facilitator_id?: InputMaybe<Int_Comparison_Exp>;
  follow_up_instructions?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  is_deleted?: InputMaybe<Boolean_Comparison_Exp>;
  provider_id?: InputMaybe<Int_Comparison_Exp>;
  provider_orders?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  test_outcome?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  unread?: InputMaybe<Boolean_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  visit_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "test_orders" */
export enum Test_Orders_Constraint {
  /** unique or primary key constraint */
  TestOrdersPkey = 'test_orders_pkey'
}

/** input type for incrementing numeric columns in table "test_orders" */
export type Test_Orders_Inc_Input = {
  facilitator_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  provider_id?: InputMaybe<Scalars['Int']>;
  visit_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "test_orders" */
export type Test_Orders_Insert_Input = {
  administration_notes?: InputMaybe<Scalars['String']>;
  completed_at?: InputMaybe<Scalars['timestamptz']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  facilitator_id?: InputMaybe<Scalars['Int']>;
  follow_up_instructions?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  is_deleted?: InputMaybe<Scalars['Boolean']>;
  provider_id?: InputMaybe<Scalars['Int']>;
  provider_orders?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  test_outcome?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  unread?: InputMaybe<Scalars['Boolean']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  visit_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Test_Orders_Max_Fields = {
  __typename?: 'test_orders_max_fields';
  administration_notes?: Maybe<Scalars['String']>;
  completed_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  facilitator_id?: Maybe<Scalars['Int']>;
  follow_up_instructions?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  provider_id?: Maybe<Scalars['Int']>;
  provider_orders?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  test_outcome?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  visit_id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "test_orders" */
export type Test_Orders_Max_Order_By = {
  administration_notes?: InputMaybe<Order_By>;
  completed_at?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  facilitator_id?: InputMaybe<Order_By>;
  follow_up_instructions?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  provider_id?: InputMaybe<Order_By>;
  provider_orders?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  test_outcome?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  visit_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Test_Orders_Min_Fields = {
  __typename?: 'test_orders_min_fields';
  administration_notes?: Maybe<Scalars['String']>;
  completed_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  facilitator_id?: Maybe<Scalars['Int']>;
  follow_up_instructions?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  provider_id?: Maybe<Scalars['Int']>;
  provider_orders?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  test_outcome?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  visit_id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "test_orders" */
export type Test_Orders_Min_Order_By = {
  administration_notes?: InputMaybe<Order_By>;
  completed_at?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  facilitator_id?: InputMaybe<Order_By>;
  follow_up_instructions?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  provider_id?: InputMaybe<Order_By>;
  provider_orders?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  test_outcome?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  visit_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "test_orders" */
export type Test_Orders_Mutation_Response = {
  __typename?: 'test_orders_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Test_Orders>;
};

/** on conflict condition type for table "test_orders" */
export type Test_Orders_On_Conflict = {
  constraint: Test_Orders_Constraint;
  update_columns?: Array<Test_Orders_Update_Column>;
  where?: InputMaybe<Test_Orders_Bool_Exp>;
};

/** Ordering options when selecting data from "test_orders". */
export type Test_Orders_Order_By = {
  administration_notes?: InputMaybe<Order_By>;
  completed_at?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  facilitator_id?: InputMaybe<Order_By>;
  follow_up_instructions?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_deleted?: InputMaybe<Order_By>;
  provider_id?: InputMaybe<Order_By>;
  provider_orders?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  test_outcome?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  unread?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  visit_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: test_orders */
export type Test_Orders_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "test_orders" */
export enum Test_Orders_Select_Column {
  /** column name */
  AdministrationNotes = 'administration_notes',
  /** column name */
  CompletedAt = 'completed_at',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FacilitatorId = 'facilitator_id',
  /** column name */
  FollowUpInstructions = 'follow_up_instructions',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  ProviderId = 'provider_id',
  /** column name */
  ProviderOrders = 'provider_orders',
  /** column name */
  Status = 'status',
  /** column name */
  TestOutcome = 'test_outcome',
  /** column name */
  Type = 'type',
  /** column name */
  Unread = 'unread',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VisitId = 'visit_id'
}

/** input type for updating data in table "test_orders" */
export type Test_Orders_Set_Input = {
  administration_notes?: InputMaybe<Scalars['String']>;
  completed_at?: InputMaybe<Scalars['timestamptz']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  facilitator_id?: InputMaybe<Scalars['Int']>;
  follow_up_instructions?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  is_deleted?: InputMaybe<Scalars['Boolean']>;
  provider_id?: InputMaybe<Scalars['Int']>;
  provider_orders?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  test_outcome?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  unread?: InputMaybe<Scalars['Boolean']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  visit_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Test_Orders_Stddev_Fields = {
  __typename?: 'test_orders_stddev_fields';
  facilitator_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
  visit_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "test_orders" */
export type Test_Orders_Stddev_Order_By = {
  facilitator_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  provider_id?: InputMaybe<Order_By>;
  visit_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Test_Orders_Stddev_Pop_Fields = {
  __typename?: 'test_orders_stddev_pop_fields';
  facilitator_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
  visit_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "test_orders" */
export type Test_Orders_Stddev_Pop_Order_By = {
  facilitator_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  provider_id?: InputMaybe<Order_By>;
  visit_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Test_Orders_Stddev_Samp_Fields = {
  __typename?: 'test_orders_stddev_samp_fields';
  facilitator_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
  visit_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "test_orders" */
export type Test_Orders_Stddev_Samp_Order_By = {
  facilitator_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  provider_id?: InputMaybe<Order_By>;
  visit_id?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Test_Orders_Sum_Fields = {
  __typename?: 'test_orders_sum_fields';
  facilitator_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  provider_id?: Maybe<Scalars['Int']>;
  visit_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "test_orders" */
export type Test_Orders_Sum_Order_By = {
  facilitator_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  provider_id?: InputMaybe<Order_By>;
  visit_id?: InputMaybe<Order_By>;
};

/** update columns of table "test_orders" */
export enum Test_Orders_Update_Column {
  /** column name */
  AdministrationNotes = 'administration_notes',
  /** column name */
  CompletedAt = 'completed_at',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FacilitatorId = 'facilitator_id',
  /** column name */
  FollowUpInstructions = 'follow_up_instructions',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  ProviderId = 'provider_id',
  /** column name */
  ProviderOrders = 'provider_orders',
  /** column name */
  Status = 'status',
  /** column name */
  TestOutcome = 'test_outcome',
  /** column name */
  Type = 'type',
  /** column name */
  Unread = 'unread',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VisitId = 'visit_id'
}

/** aggregate var_pop on columns */
export type Test_Orders_Var_Pop_Fields = {
  __typename?: 'test_orders_var_pop_fields';
  facilitator_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
  visit_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "test_orders" */
export type Test_Orders_Var_Pop_Order_By = {
  facilitator_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  provider_id?: InputMaybe<Order_By>;
  visit_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Test_Orders_Var_Samp_Fields = {
  __typename?: 'test_orders_var_samp_fields';
  facilitator_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
  visit_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "test_orders" */
export type Test_Orders_Var_Samp_Order_By = {
  facilitator_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  provider_id?: InputMaybe<Order_By>;
  visit_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Test_Orders_Variance_Fields = {
  __typename?: 'test_orders_variance_fields';
  facilitator_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
  visit_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "test_orders" */
export type Test_Orders_Variance_Order_By = {
  facilitator_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  provider_id?: InputMaybe<Order_By>;
  visit_id?: InputMaybe<Order_By>;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamptz']>;
  _gt?: InputMaybe<Scalars['timestamptz']>;
  _gte?: InputMaybe<Scalars['timestamptz']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timestamptz']>;
  _lte?: InputMaybe<Scalars['timestamptz']>;
  _neq?: InputMaybe<Scalars['timestamptz']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']>>;
};

/** columns and relationships of "users" */
export type Users = {
  __typename?: 'users';
  approved?: Maybe<Scalars['Boolean']>;
  auth0_id: Scalars['String'];
  created_at?: Maybe<Scalars['timestamptz']>;
  credentials?: Maybe<Scalars['String']>;
  date_of_birth?: Maybe<Scalars['date']>;
  default_location_id?: Maybe<Scalars['Int']>;
  do_notifications_enabled?: Maybe<Scalars['Boolean']>;
  email: Scalars['String'];
  first_name: Scalars['String'];
  id: Scalars['Int'];
  is_deleted?: Maybe<Scalars['Boolean']>;
  last_name: Scalars['String'];
  location_id?: Maybe<Scalars['Int']>;
  organization_id?: Maybe<Scalars['Int']>;
  phone_number: Scalars['String'];
  prefix?: Maybe<Scalars['String']>;
  program_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "users" */
export type Users_Aggregate = {
  __typename?: 'users_aggregate';
  aggregate?: Maybe<Users_Aggregate_Fields>;
  nodes: Array<Users>;
};

/** aggregate fields of "users" */
export type Users_Aggregate_Fields = {
  __typename?: 'users_aggregate_fields';
  avg?: Maybe<Users_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Users_Max_Fields>;
  min?: Maybe<Users_Min_Fields>;
  stddev?: Maybe<Users_Stddev_Fields>;
  stddev_pop?: Maybe<Users_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Users_Stddev_Samp_Fields>;
  sum?: Maybe<Users_Sum_Fields>;
  var_pop?: Maybe<Users_Var_Pop_Fields>;
  var_samp?: Maybe<Users_Var_Samp_Fields>;
  variance?: Maybe<Users_Variance_Fields>;
};


/** aggregate fields of "users" */
export type Users_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Users_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Users_Avg_Fields = {
  __typename?: 'users_avg_fields';
  default_location_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
  organization_id?: Maybe<Scalars['Float']>;
  program_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "users". All fields are combined with a logical 'AND'. */
export type Users_Bool_Exp = {
  _and?: InputMaybe<Array<Users_Bool_Exp>>;
  _not?: InputMaybe<Users_Bool_Exp>;
  _or?: InputMaybe<Array<Users_Bool_Exp>>;
  approved?: InputMaybe<Boolean_Comparison_Exp>;
  auth0_id?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  credentials?: InputMaybe<String_Comparison_Exp>;
  date_of_birth?: InputMaybe<Date_Comparison_Exp>;
  default_location_id?: InputMaybe<Int_Comparison_Exp>;
  do_notifications_enabled?: InputMaybe<Boolean_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  first_name?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  is_deleted?: InputMaybe<Boolean_Comparison_Exp>;
  last_name?: InputMaybe<String_Comparison_Exp>;
  location_id?: InputMaybe<Int_Comparison_Exp>;
  organization_id?: InputMaybe<Int_Comparison_Exp>;
  phone_number?: InputMaybe<String_Comparison_Exp>;
  prefix?: InputMaybe<String_Comparison_Exp>;
  program_id?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "users" */
export enum Users_Constraint {
  /** unique or primary key constraint */
  UsersPkey = 'users_pkey'
}

/** input type for incrementing numeric columns in table "users" */
export type Users_Inc_Input = {
  default_location_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  location_id?: InputMaybe<Scalars['Int']>;
  organization_id?: InputMaybe<Scalars['Int']>;
  program_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "users" */
export type Users_Insert_Input = {
  approved?: InputMaybe<Scalars['Boolean']>;
  auth0_id?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  credentials?: InputMaybe<Scalars['String']>;
  date_of_birth?: InputMaybe<Scalars['date']>;
  default_location_id?: InputMaybe<Scalars['Int']>;
  do_notifications_enabled?: InputMaybe<Scalars['Boolean']>;
  email?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  is_deleted?: InputMaybe<Scalars['Boolean']>;
  last_name?: InputMaybe<Scalars['String']>;
  location_id?: InputMaybe<Scalars['Int']>;
  organization_id?: InputMaybe<Scalars['Int']>;
  phone_number?: InputMaybe<Scalars['String']>;
  prefix?: InputMaybe<Scalars['String']>;
  program_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Users_Max_Fields = {
  __typename?: 'users_max_fields';
  auth0_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  credentials?: Maybe<Scalars['String']>;
  date_of_birth?: Maybe<Scalars['date']>;
  default_location_id?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  last_name?: Maybe<Scalars['String']>;
  location_id?: Maybe<Scalars['Int']>;
  organization_id?: Maybe<Scalars['Int']>;
  phone_number?: Maybe<Scalars['String']>;
  prefix?: Maybe<Scalars['String']>;
  program_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Users_Min_Fields = {
  __typename?: 'users_min_fields';
  auth0_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  credentials?: Maybe<Scalars['String']>;
  date_of_birth?: Maybe<Scalars['date']>;
  default_location_id?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  last_name?: Maybe<Scalars['String']>;
  location_id?: Maybe<Scalars['Int']>;
  organization_id?: Maybe<Scalars['Int']>;
  phone_number?: Maybe<Scalars['String']>;
  prefix?: Maybe<Scalars['String']>;
  program_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "users" */
export type Users_Mutation_Response = {
  __typename?: 'users_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Users>;
};

/** input type for inserting object relation for remote table "users" */
export type Users_Obj_Rel_Insert_Input = {
  data: Users_Insert_Input;
  /** on conflict condition */
  on_conflict?: InputMaybe<Users_On_Conflict>;
};

/** on conflict condition type for table "users" */
export type Users_On_Conflict = {
  constraint: Users_Constraint;
  update_columns?: Array<Users_Update_Column>;
  where?: InputMaybe<Users_Bool_Exp>;
};

/** Ordering options when selecting data from "users". */
export type Users_Order_By = {
  approved?: InputMaybe<Order_By>;
  auth0_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  credentials?: InputMaybe<Order_By>;
  date_of_birth?: InputMaybe<Order_By>;
  default_location_id?: InputMaybe<Order_By>;
  do_notifications_enabled?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_deleted?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  location_id?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  phone_number?: InputMaybe<Order_By>;
  prefix?: InputMaybe<Order_By>;
  program_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: users */
export type Users_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "users" */
export enum Users_Select_Column {
  /** column name */
  Approved = 'approved',
  /** column name */
  Auth0Id = 'auth0_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Credentials = 'credentials',
  /** column name */
  DateOfBirth = 'date_of_birth',
  /** column name */
  DefaultLocationId = 'default_location_id',
  /** column name */
  DoNotificationsEnabled = 'do_notifications_enabled',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  LastName = 'last_name',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  PhoneNumber = 'phone_number',
  /** column name */
  Prefix = 'prefix',
  /** column name */
  ProgramId = 'program_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "users" */
export type Users_Set_Input = {
  approved?: InputMaybe<Scalars['Boolean']>;
  auth0_id?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  credentials?: InputMaybe<Scalars['String']>;
  date_of_birth?: InputMaybe<Scalars['date']>;
  default_location_id?: InputMaybe<Scalars['Int']>;
  do_notifications_enabled?: InputMaybe<Scalars['Boolean']>;
  email?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  is_deleted?: InputMaybe<Scalars['Boolean']>;
  last_name?: InputMaybe<Scalars['String']>;
  location_id?: InputMaybe<Scalars['Int']>;
  organization_id?: InputMaybe<Scalars['Int']>;
  phone_number?: InputMaybe<Scalars['String']>;
  prefix?: InputMaybe<Scalars['String']>;
  program_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Users_Stddev_Fields = {
  __typename?: 'users_stddev_fields';
  default_location_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
  organization_id?: Maybe<Scalars['Float']>;
  program_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Users_Stddev_Pop_Fields = {
  __typename?: 'users_stddev_pop_fields';
  default_location_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
  organization_id?: Maybe<Scalars['Float']>;
  program_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Users_Stddev_Samp_Fields = {
  __typename?: 'users_stddev_samp_fields';
  default_location_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
  organization_id?: Maybe<Scalars['Float']>;
  program_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Users_Sum_Fields = {
  __typename?: 'users_sum_fields';
  default_location_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  location_id?: Maybe<Scalars['Int']>;
  organization_id?: Maybe<Scalars['Int']>;
  program_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "users" */
export enum Users_Update_Column {
  /** column name */
  Approved = 'approved',
  /** column name */
  Auth0Id = 'auth0_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Credentials = 'credentials',
  /** column name */
  DateOfBirth = 'date_of_birth',
  /** column name */
  DefaultLocationId = 'default_location_id',
  /** column name */
  DoNotificationsEnabled = 'do_notifications_enabled',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  LastName = 'last_name',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  PhoneNumber = 'phone_number',
  /** column name */
  Prefix = 'prefix',
  /** column name */
  ProgramId = 'program_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Users_Var_Pop_Fields = {
  __typename?: 'users_var_pop_fields';
  default_location_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
  organization_id?: Maybe<Scalars['Float']>;
  program_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Users_Var_Samp_Fields = {
  __typename?: 'users_var_samp_fields';
  default_location_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
  organization_id?: Maybe<Scalars['Float']>;
  program_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Users_Variance_Fields = {
  __typename?: 'users_variance_fields';
  default_location_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
  organization_id?: Maybe<Scalars['Float']>;
  program_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "video_calls" */
export type Video_Calls = {
  __typename?: 'video_calls';
  events?: Maybe<Scalars['jsonb']>;
  id: Scalars['Int'];
  is_deleted?: Maybe<Scalars['Boolean']>;
  participants?: Maybe<Scalars['jsonb']>;
  /** An object relationship */
  session: Sessions;
  session_id: Scalars['Int'];
};


/** columns and relationships of "video_calls" */
export type Video_CallsEventsArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "video_calls" */
export type Video_CallsParticipantsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "video_calls" */
export type Video_Calls_Aggregate = {
  __typename?: 'video_calls_aggregate';
  aggregate?: Maybe<Video_Calls_Aggregate_Fields>;
  nodes: Array<Video_Calls>;
};

/** aggregate fields of "video_calls" */
export type Video_Calls_Aggregate_Fields = {
  __typename?: 'video_calls_aggregate_fields';
  avg?: Maybe<Video_Calls_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Video_Calls_Max_Fields>;
  min?: Maybe<Video_Calls_Min_Fields>;
  stddev?: Maybe<Video_Calls_Stddev_Fields>;
  stddev_pop?: Maybe<Video_Calls_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Video_Calls_Stddev_Samp_Fields>;
  sum?: Maybe<Video_Calls_Sum_Fields>;
  var_pop?: Maybe<Video_Calls_Var_Pop_Fields>;
  var_samp?: Maybe<Video_Calls_Var_Samp_Fields>;
  variance?: Maybe<Video_Calls_Variance_Fields>;
};


/** aggregate fields of "video_calls" */
export type Video_Calls_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Video_Calls_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Video_Calls_Append_Input = {
  events?: InputMaybe<Scalars['jsonb']>;
  participants?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Video_Calls_Avg_Fields = {
  __typename?: 'video_calls_avg_fields';
  id?: Maybe<Scalars['Float']>;
  session_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "video_calls". All fields are combined with a logical 'AND'. */
export type Video_Calls_Bool_Exp = {
  _and?: InputMaybe<Array<Video_Calls_Bool_Exp>>;
  _not?: InputMaybe<Video_Calls_Bool_Exp>;
  _or?: InputMaybe<Array<Video_Calls_Bool_Exp>>;
  events?: InputMaybe<Jsonb_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  is_deleted?: InputMaybe<Boolean_Comparison_Exp>;
  participants?: InputMaybe<Jsonb_Comparison_Exp>;
  session?: InputMaybe<Sessions_Bool_Exp>;
  session_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "video_calls" */
export enum Video_Calls_Constraint {
  /** unique or primary key constraint */
  VideoCallsPkey = 'video_calls_pkey',
  /** unique or primary key constraint */
  VideocallsSessionidUnique = 'videocalls_sessionid_unique'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Video_Calls_Delete_At_Path_Input = {
  events?: InputMaybe<Array<Scalars['String']>>;
  participants?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Video_Calls_Delete_Elem_Input = {
  events?: InputMaybe<Scalars['Int']>;
  participants?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Video_Calls_Delete_Key_Input = {
  events?: InputMaybe<Scalars['String']>;
  participants?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "video_calls" */
export type Video_Calls_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  session_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "video_calls" */
export type Video_Calls_Insert_Input = {
  events?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['Int']>;
  is_deleted?: InputMaybe<Scalars['Boolean']>;
  participants?: InputMaybe<Scalars['jsonb']>;
  session?: InputMaybe<Sessions_Obj_Rel_Insert_Input>;
  session_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Video_Calls_Max_Fields = {
  __typename?: 'video_calls_max_fields';
  id?: Maybe<Scalars['Int']>;
  session_id?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Video_Calls_Min_Fields = {
  __typename?: 'video_calls_min_fields';
  id?: Maybe<Scalars['Int']>;
  session_id?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "video_calls" */
export type Video_Calls_Mutation_Response = {
  __typename?: 'video_calls_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Video_Calls>;
};

/** input type for inserting object relation for remote table "video_calls" */
export type Video_Calls_Obj_Rel_Insert_Input = {
  data: Video_Calls_Insert_Input;
  /** on conflict condition */
  on_conflict?: InputMaybe<Video_Calls_On_Conflict>;
};

/** on conflict condition type for table "video_calls" */
export type Video_Calls_On_Conflict = {
  constraint: Video_Calls_Constraint;
  update_columns?: Array<Video_Calls_Update_Column>;
  where?: InputMaybe<Video_Calls_Bool_Exp>;
};

/** Ordering options when selecting data from "video_calls". */
export type Video_Calls_Order_By = {
  events?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_deleted?: InputMaybe<Order_By>;
  participants?: InputMaybe<Order_By>;
  session?: InputMaybe<Sessions_Order_By>;
  session_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: video_calls */
export type Video_Calls_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Video_Calls_Prepend_Input = {
  events?: InputMaybe<Scalars['jsonb']>;
  participants?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "video_calls" */
export enum Video_Calls_Select_Column {
  /** column name */
  Events = 'events',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  Participants = 'participants',
  /** column name */
  SessionId = 'session_id'
}

/** input type for updating data in table "video_calls" */
export type Video_Calls_Set_Input = {
  events?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['Int']>;
  is_deleted?: InputMaybe<Scalars['Boolean']>;
  participants?: InputMaybe<Scalars['jsonb']>;
  session_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Video_Calls_Stddev_Fields = {
  __typename?: 'video_calls_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  session_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Video_Calls_Stddev_Pop_Fields = {
  __typename?: 'video_calls_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  session_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Video_Calls_Stddev_Samp_Fields = {
  __typename?: 'video_calls_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  session_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Video_Calls_Sum_Fields = {
  __typename?: 'video_calls_sum_fields';
  id?: Maybe<Scalars['Int']>;
  session_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "video_calls" */
export enum Video_Calls_Update_Column {
  /** column name */
  Events = 'events',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  Participants = 'participants',
  /** column name */
  SessionId = 'session_id'
}

/** aggregate var_pop on columns */
export type Video_Calls_Var_Pop_Fields = {
  __typename?: 'video_calls_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  session_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Video_Calls_Var_Samp_Fields = {
  __typename?: 'video_calls_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  session_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Video_Calls_Variance_Fields = {
  __typename?: 'video_calls_variance_fields';
  id?: Maybe<Scalars['Float']>;
  session_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "visits" */
export type Visits = {
  __typename?: 'visits';
  additional_notes?: Maybe<Scalars['String']>;
  allergies?: Maybe<Scalars['String']>;
  birth_sex?: Maybe<Scalars['String']>;
  body_temperature?: Maybe<Scalars['numeric']>;
  can_email?: Maybe<Scalars['Boolean']>;
  /** An object relationship */
  clearance_note?: Maybe<Clearance_Notes>;
  conditions?: Maybe<Scalars['String']>;
  consumer_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  date?: Maybe<Scalars['timestamptz']>;
  date_of_birth?: Maybe<Scalars['date']>;
  date_of_documentation?: Maybe<Scalars['date']>;
  date_of_service?: Maybe<Scalars['date']>;
  diastolic_pressure?: Maybe<Scalars['Int']>;
  /** An object relationship */
  discharge?: Maybe<Discharges>;
  email?: Maybe<Scalars['String']>;
  /** An object relationship */
  facilitator?: Maybe<Users>;
  facilitator_completed: Scalars['Boolean'];
  facilitator_id?: Maybe<Scalars['Int']>;
  first_name?: Maybe<Scalars['String']>;
  /** fetch data from the table: "forms" */
  forms: Array<Forms>;
  /** fetch aggregated fields from the table: "forms" */
  forms_aggregate: Forms_Aggregate;
  has_uil_printed: Scalars['Boolean'];
  id: Scalars['Int'];
  is_deleted?: Maybe<Scalars['Boolean']>;
  language: Scalars['String'];
  last_name?: Maybe<Scalars['String']>;
  /** An object relationship */
  location?: Maybe<Organizations_Locations>;
  location_id?: Maybe<Scalars['Int']>;
  /** An array relationship */
  medication_orders: Array<Medication_Orders>;
  /** An aggregate relationship */
  medication_orders_aggregate: Medication_Orders_Aggregate;
  medications?: Maybe<Scalars['String']>;
  /** fetch data from the table: "orders" */
  orders: Array<Orders>;
  /** An aggregate relationship */
  orders_aggregate: Orders_Aggregate;
  /** An object relationship */
  organization: Organizations_Organizations;
  organization_id: Scalars['Int'];
  patient_id: Scalars['String'];
  pharmacy_address?: Maybe<Scalars['String']>;
  pharmacy_address2?: Maybe<Scalars['String']>;
  pharmacy_city?: Maybe<Scalars['String']>;
  pharmacy_latitude?: Maybe<Scalars['numeric']>;
  pharmacy_longitude?: Maybe<Scalars['numeric']>;
  pharmacy_name?: Maybe<Scalars['String']>;
  pharmacy_state?: Maybe<Scalars['String']>;
  pharmacy_zipcode?: Maybe<Scalars['String']>;
  place_of_service_id?: Maybe<Scalars['Int']>;
  /** An object relationship */
  provider?: Maybe<Users>;
  provider_completed: Scalars['Boolean'];
  provider_id?: Maybe<Scalars['Int']>;
  pulse_ox?: Maybe<Scalars['Int']>;
  pulse_rate?: Maybe<Scalars['Int']>;
  respiration_rate?: Maybe<Scalars['Int']>;
  /** An object relationship */
  session?: Maybe<Sessions>;
  session_id?: Maybe<Scalars['Int']>;
  /** An array relationship */
  session_logs: Array<Session_Logs>;
  /** An aggregate relationship */
  session_logs_aggregate: Session_Logs_Aggregate;
  /** An object relationship */
  station_visit?: Maybe<Station_Visits>;
  status: Scalars['String'];
  student_id?: Maybe<Scalars['String']>;
  systolic_pressure?: Maybe<Scalars['Int']>;
  test_admin_id?: Maybe<Scalars['String']>;
  /** An array relationship */
  test_orders: Array<Test_Orders>;
  /** An aggregate relationship */
  test_orders_aggregate: Test_Orders_Aggregate;
  test_scheduler_id?: Maybe<Scalars['String']>;
  total_time?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  visit_metadata?: Maybe<Scalars['jsonb']>;
  visit_reason: Scalars['String'];
  visit_type_code?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Int']>;
};


/** columns and relationships of "visits" */
export type VisitsFormsArgs = {
  distinct_on?: InputMaybe<Array<Forms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Forms_Order_By>>;
  where?: InputMaybe<Forms_Bool_Exp>;
};


/** columns and relationships of "visits" */
export type VisitsForms_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Forms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Forms_Order_By>>;
  where?: InputMaybe<Forms_Bool_Exp>;
};


/** columns and relationships of "visits" */
export type VisitsMedication_OrdersArgs = {
  distinct_on?: InputMaybe<Array<Medication_Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Medication_Orders_Order_By>>;
  where?: InputMaybe<Medication_Orders_Bool_Exp>;
};


/** columns and relationships of "visits" */
export type VisitsMedication_Orders_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Medication_Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Medication_Orders_Order_By>>;
  where?: InputMaybe<Medication_Orders_Bool_Exp>;
};


/** columns and relationships of "visits" */
export type VisitsOrdersArgs = {
  distinct_on?: InputMaybe<Array<Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Orders_Order_By>>;
  where?: InputMaybe<Orders_Bool_Exp>;
};


/** columns and relationships of "visits" */
export type VisitsOrders_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Orders_Order_By>>;
  where?: InputMaybe<Orders_Bool_Exp>;
};


/** columns and relationships of "visits" */
export type VisitsSession_LogsArgs = {
  distinct_on?: InputMaybe<Array<Session_Logs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Session_Logs_Order_By>>;
  where?: InputMaybe<Session_Logs_Bool_Exp>;
};


/** columns and relationships of "visits" */
export type VisitsSession_Logs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Session_Logs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Session_Logs_Order_By>>;
  where?: InputMaybe<Session_Logs_Bool_Exp>;
};


/** columns and relationships of "visits" */
export type VisitsTest_OrdersArgs = {
  distinct_on?: InputMaybe<Array<Test_Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Test_Orders_Order_By>>;
  where?: InputMaybe<Test_Orders_Bool_Exp>;
};


/** columns and relationships of "visits" */
export type VisitsTest_Orders_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Test_Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Test_Orders_Order_By>>;
  where?: InputMaybe<Test_Orders_Bool_Exp>;
};


/** columns and relationships of "visits" */
export type VisitsVisit_MetadataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "visits" */
export type Visits_Aggregate = {
  __typename?: 'visits_aggregate';
  aggregate?: Maybe<Visits_Aggregate_Fields>;
  nodes: Array<Visits>;
};

/** aggregate fields of "visits" */
export type Visits_Aggregate_Fields = {
  __typename?: 'visits_aggregate_fields';
  avg?: Maybe<Visits_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Visits_Max_Fields>;
  min?: Maybe<Visits_Min_Fields>;
  stddev?: Maybe<Visits_Stddev_Fields>;
  stddev_pop?: Maybe<Visits_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Visits_Stddev_Samp_Fields>;
  sum?: Maybe<Visits_Sum_Fields>;
  var_pop?: Maybe<Visits_Var_Pop_Fields>;
  var_samp?: Maybe<Visits_Var_Samp_Fields>;
  variance?: Maybe<Visits_Variance_Fields>;
};


/** aggregate fields of "visits" */
export type Visits_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Visits_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "visits" */
export type Visits_Aggregate_Order_By = {
  avg?: InputMaybe<Visits_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Visits_Max_Order_By>;
  min?: InputMaybe<Visits_Min_Order_By>;
  stddev?: InputMaybe<Visits_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Visits_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Visits_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Visits_Sum_Order_By>;
  var_pop?: InputMaybe<Visits_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Visits_Var_Samp_Order_By>;
  variance?: InputMaybe<Visits_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Visits_Append_Input = {
  visit_metadata?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "visits" */
export type Visits_Arr_Rel_Insert_Input = {
  data: Array<Visits_Insert_Input>;
  /** on conflict condition */
  on_conflict?: InputMaybe<Visits_On_Conflict>;
};

/** aggregate avg on columns */
export type Visits_Avg_Fields = {
  __typename?: 'visits_avg_fields';
  body_temperature?: Maybe<Scalars['Float']>;
  diastolic_pressure?: Maybe<Scalars['Float']>;
  facilitator_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
  organization_id?: Maybe<Scalars['Float']>;
  pharmacy_latitude?: Maybe<Scalars['Float']>;
  pharmacy_longitude?: Maybe<Scalars['Float']>;
  place_of_service_id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
  pulse_ox?: Maybe<Scalars['Float']>;
  pulse_rate?: Maybe<Scalars['Float']>;
  respiration_rate?: Maybe<Scalars['Float']>;
  session_id?: Maybe<Scalars['Float']>;
  systolic_pressure?: Maybe<Scalars['Float']>;
  total_time?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "visits" */
export type Visits_Avg_Order_By = {
  body_temperature?: InputMaybe<Order_By>;
  diastolic_pressure?: InputMaybe<Order_By>;
  facilitator_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  location_id?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  pharmacy_latitude?: InputMaybe<Order_By>;
  pharmacy_longitude?: InputMaybe<Order_By>;
  place_of_service_id?: InputMaybe<Order_By>;
  provider_id?: InputMaybe<Order_By>;
  pulse_ox?: InputMaybe<Order_By>;
  pulse_rate?: InputMaybe<Order_By>;
  respiration_rate?: InputMaybe<Order_By>;
  session_id?: InputMaybe<Order_By>;
  systolic_pressure?: InputMaybe<Order_By>;
  total_time?: InputMaybe<Order_By>;
  weight?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "visits". All fields are combined with a logical 'AND'. */
export type Visits_Bool_Exp = {
  _and?: InputMaybe<Array<Visits_Bool_Exp>>;
  _not?: InputMaybe<Visits_Bool_Exp>;
  _or?: InputMaybe<Array<Visits_Bool_Exp>>;
  additional_notes?: InputMaybe<String_Comparison_Exp>;
  allergies?: InputMaybe<String_Comparison_Exp>;
  birth_sex?: InputMaybe<String_Comparison_Exp>;
  body_temperature?: InputMaybe<Numeric_Comparison_Exp>;
  can_email?: InputMaybe<Boolean_Comparison_Exp>;
  clearance_note?: InputMaybe<Clearance_Notes_Bool_Exp>;
  conditions?: InputMaybe<String_Comparison_Exp>;
  consumer_id?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  date?: InputMaybe<Timestamptz_Comparison_Exp>;
  date_of_birth?: InputMaybe<Date_Comparison_Exp>;
  date_of_documentation?: InputMaybe<Date_Comparison_Exp>;
  date_of_service?: InputMaybe<Date_Comparison_Exp>;
  diastolic_pressure?: InputMaybe<Int_Comparison_Exp>;
  discharge?: InputMaybe<Discharges_Bool_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  facilitator?: InputMaybe<Users_Bool_Exp>;
  facilitator_completed?: InputMaybe<Boolean_Comparison_Exp>;
  facilitator_id?: InputMaybe<Int_Comparison_Exp>;
  first_name?: InputMaybe<String_Comparison_Exp>;
  has_uil_printed?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  is_deleted?: InputMaybe<Boolean_Comparison_Exp>;
  language?: InputMaybe<String_Comparison_Exp>;
  last_name?: InputMaybe<String_Comparison_Exp>;
  location?: InputMaybe<Organizations_Locations_Bool_Exp>;
  location_id?: InputMaybe<Int_Comparison_Exp>;
  medication_orders?: InputMaybe<Medication_Orders_Bool_Exp>;
  medications?: InputMaybe<String_Comparison_Exp>;
  orders?: InputMaybe<Orders_Bool_Exp>;
  organization?: InputMaybe<Organizations_Organizations_Bool_Exp>;
  organization_id?: InputMaybe<Int_Comparison_Exp>;
  patient_id?: InputMaybe<String_Comparison_Exp>;
  pharmacy_address?: InputMaybe<String_Comparison_Exp>;
  pharmacy_address2?: InputMaybe<String_Comparison_Exp>;
  pharmacy_city?: InputMaybe<String_Comparison_Exp>;
  pharmacy_latitude?: InputMaybe<Numeric_Comparison_Exp>;
  pharmacy_longitude?: InputMaybe<Numeric_Comparison_Exp>;
  pharmacy_name?: InputMaybe<String_Comparison_Exp>;
  pharmacy_state?: InputMaybe<String_Comparison_Exp>;
  pharmacy_zipcode?: InputMaybe<String_Comparison_Exp>;
  place_of_service_id?: InputMaybe<Int_Comparison_Exp>;
  provider?: InputMaybe<Users_Bool_Exp>;
  provider_completed?: InputMaybe<Boolean_Comparison_Exp>;
  provider_id?: InputMaybe<Int_Comparison_Exp>;
  pulse_ox?: InputMaybe<Int_Comparison_Exp>;
  pulse_rate?: InputMaybe<Int_Comparison_Exp>;
  respiration_rate?: InputMaybe<Int_Comparison_Exp>;
  session?: InputMaybe<Sessions_Bool_Exp>;
  session_id?: InputMaybe<Int_Comparison_Exp>;
  session_logs?: InputMaybe<Session_Logs_Bool_Exp>;
  station_visit?: InputMaybe<Station_Visits_Bool_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  student_id?: InputMaybe<String_Comparison_Exp>;
  systolic_pressure?: InputMaybe<Int_Comparison_Exp>;
  test_admin_id?: InputMaybe<String_Comparison_Exp>;
  test_orders?: InputMaybe<Test_Orders_Bool_Exp>;
  test_scheduler_id?: InputMaybe<String_Comparison_Exp>;
  total_time?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  visit_metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  visit_reason?: InputMaybe<String_Comparison_Exp>;
  visit_type_code?: InputMaybe<String_Comparison_Exp>;
  weight?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "visits" */
export enum Visits_Constraint {
  /** unique or primary key constraint */
  VisitsPkey = 'visits_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Visits_Delete_At_Path_Input = {
  visit_metadata?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Visits_Delete_Elem_Input = {
  visit_metadata?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Visits_Delete_Key_Input = {
  visit_metadata?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "visits" */
export type Visits_Inc_Input = {
  body_temperature?: InputMaybe<Scalars['numeric']>;
  diastolic_pressure?: InputMaybe<Scalars['Int']>;
  facilitator_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  location_id?: InputMaybe<Scalars['Int']>;
  organization_id?: InputMaybe<Scalars['Int']>;
  pharmacy_latitude?: InputMaybe<Scalars['numeric']>;
  pharmacy_longitude?: InputMaybe<Scalars['numeric']>;
  place_of_service_id?: InputMaybe<Scalars['Int']>;
  provider_id?: InputMaybe<Scalars['Int']>;
  pulse_ox?: InputMaybe<Scalars['Int']>;
  pulse_rate?: InputMaybe<Scalars['Int']>;
  respiration_rate?: InputMaybe<Scalars['Int']>;
  session_id?: InputMaybe<Scalars['Int']>;
  systolic_pressure?: InputMaybe<Scalars['Int']>;
  total_time?: InputMaybe<Scalars['Int']>;
  weight?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "visits" */
export type Visits_Insert_Input = {
  additional_notes?: InputMaybe<Scalars['String']>;
  allergies?: InputMaybe<Scalars['String']>;
  birth_sex?: InputMaybe<Scalars['String']>;
  body_temperature?: InputMaybe<Scalars['numeric']>;
  can_email?: InputMaybe<Scalars['Boolean']>;
  clearance_note?: InputMaybe<Clearance_Notes_Obj_Rel_Insert_Input>;
  conditions?: InputMaybe<Scalars['String']>;
  consumer_id?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  date?: InputMaybe<Scalars['timestamptz']>;
  date_of_birth?: InputMaybe<Scalars['date']>;
  date_of_documentation?: InputMaybe<Scalars['date']>;
  date_of_service?: InputMaybe<Scalars['date']>;
  diastolic_pressure?: InputMaybe<Scalars['Int']>;
  discharge?: InputMaybe<Discharges_Obj_Rel_Insert_Input>;
  email?: InputMaybe<Scalars['String']>;
  facilitator?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  facilitator_completed?: InputMaybe<Scalars['Boolean']>;
  facilitator_id?: InputMaybe<Scalars['Int']>;
  first_name?: InputMaybe<Scalars['String']>;
  has_uil_printed?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  is_deleted?: InputMaybe<Scalars['Boolean']>;
  language?: InputMaybe<Scalars['String']>;
  last_name?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Organizations_Locations_Obj_Rel_Insert_Input>;
  location_id?: InputMaybe<Scalars['Int']>;
  medication_orders?: InputMaybe<Medication_Orders_Arr_Rel_Insert_Input>;
  medications?: InputMaybe<Scalars['String']>;
  orders?: InputMaybe<Orders_Arr_Rel_Insert_Input>;
  organization?: InputMaybe<Organizations_Organizations_Obj_Rel_Insert_Input>;
  organization_id?: InputMaybe<Scalars['Int']>;
  patient_id?: InputMaybe<Scalars['String']>;
  pharmacy_address?: InputMaybe<Scalars['String']>;
  pharmacy_address2?: InputMaybe<Scalars['String']>;
  pharmacy_city?: InputMaybe<Scalars['String']>;
  pharmacy_latitude?: InputMaybe<Scalars['numeric']>;
  pharmacy_longitude?: InputMaybe<Scalars['numeric']>;
  pharmacy_name?: InputMaybe<Scalars['String']>;
  pharmacy_state?: InputMaybe<Scalars['String']>;
  pharmacy_zipcode?: InputMaybe<Scalars['String']>;
  place_of_service_id?: InputMaybe<Scalars['Int']>;
  provider?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  provider_completed?: InputMaybe<Scalars['Boolean']>;
  provider_id?: InputMaybe<Scalars['Int']>;
  pulse_ox?: InputMaybe<Scalars['Int']>;
  pulse_rate?: InputMaybe<Scalars['Int']>;
  respiration_rate?: InputMaybe<Scalars['Int']>;
  session?: InputMaybe<Sessions_Obj_Rel_Insert_Input>;
  session_id?: InputMaybe<Scalars['Int']>;
  session_logs?: InputMaybe<Session_Logs_Arr_Rel_Insert_Input>;
  station_visit?: InputMaybe<Station_Visits_Obj_Rel_Insert_Input>;
  status?: InputMaybe<Scalars['String']>;
  student_id?: InputMaybe<Scalars['String']>;
  systolic_pressure?: InputMaybe<Scalars['Int']>;
  test_admin_id?: InputMaybe<Scalars['String']>;
  test_orders?: InputMaybe<Test_Orders_Arr_Rel_Insert_Input>;
  test_scheduler_id?: InputMaybe<Scalars['String']>;
  total_time?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  visit_metadata?: InputMaybe<Scalars['jsonb']>;
  visit_reason?: InputMaybe<Scalars['String']>;
  visit_type_code?: InputMaybe<Scalars['String']>;
  weight?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Visits_Max_Fields = {
  __typename?: 'visits_max_fields';
  additional_notes?: Maybe<Scalars['String']>;
  allergies?: Maybe<Scalars['String']>;
  birth_sex?: Maybe<Scalars['String']>;
  body_temperature?: Maybe<Scalars['numeric']>;
  conditions?: Maybe<Scalars['String']>;
  consumer_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  date?: Maybe<Scalars['timestamptz']>;
  date_of_birth?: Maybe<Scalars['date']>;
  date_of_documentation?: Maybe<Scalars['date']>;
  date_of_service?: Maybe<Scalars['date']>;
  diastolic_pressure?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  facilitator_id?: Maybe<Scalars['Int']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  language?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  location_id?: Maybe<Scalars['Int']>;
  medications?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['Int']>;
  patient_id?: Maybe<Scalars['String']>;
  pharmacy_address?: Maybe<Scalars['String']>;
  pharmacy_address2?: Maybe<Scalars['String']>;
  pharmacy_city?: Maybe<Scalars['String']>;
  pharmacy_latitude?: Maybe<Scalars['numeric']>;
  pharmacy_longitude?: Maybe<Scalars['numeric']>;
  pharmacy_name?: Maybe<Scalars['String']>;
  pharmacy_state?: Maybe<Scalars['String']>;
  pharmacy_zipcode?: Maybe<Scalars['String']>;
  place_of_service_id?: Maybe<Scalars['Int']>;
  provider_id?: Maybe<Scalars['Int']>;
  pulse_ox?: Maybe<Scalars['Int']>;
  pulse_rate?: Maybe<Scalars['Int']>;
  respiration_rate?: Maybe<Scalars['Int']>;
  session_id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  student_id?: Maybe<Scalars['String']>;
  systolic_pressure?: Maybe<Scalars['Int']>;
  test_admin_id?: Maybe<Scalars['String']>;
  test_scheduler_id?: Maybe<Scalars['String']>;
  total_time?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  visit_reason?: Maybe<Scalars['String']>;
  visit_type_code?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "visits" */
export type Visits_Max_Order_By = {
  additional_notes?: InputMaybe<Order_By>;
  allergies?: InputMaybe<Order_By>;
  birth_sex?: InputMaybe<Order_By>;
  body_temperature?: InputMaybe<Order_By>;
  conditions?: InputMaybe<Order_By>;
  consumer_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  date_of_birth?: InputMaybe<Order_By>;
  date_of_documentation?: InputMaybe<Order_By>;
  date_of_service?: InputMaybe<Order_By>;
  diastolic_pressure?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  facilitator_id?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  language?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  location_id?: InputMaybe<Order_By>;
  medications?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  patient_id?: InputMaybe<Order_By>;
  pharmacy_address?: InputMaybe<Order_By>;
  pharmacy_address2?: InputMaybe<Order_By>;
  pharmacy_city?: InputMaybe<Order_By>;
  pharmacy_latitude?: InputMaybe<Order_By>;
  pharmacy_longitude?: InputMaybe<Order_By>;
  pharmacy_name?: InputMaybe<Order_By>;
  pharmacy_state?: InputMaybe<Order_By>;
  pharmacy_zipcode?: InputMaybe<Order_By>;
  place_of_service_id?: InputMaybe<Order_By>;
  provider_id?: InputMaybe<Order_By>;
  pulse_ox?: InputMaybe<Order_By>;
  pulse_rate?: InputMaybe<Order_By>;
  respiration_rate?: InputMaybe<Order_By>;
  session_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  student_id?: InputMaybe<Order_By>;
  systolic_pressure?: InputMaybe<Order_By>;
  test_admin_id?: InputMaybe<Order_By>;
  test_scheduler_id?: InputMaybe<Order_By>;
  total_time?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  visit_reason?: InputMaybe<Order_By>;
  visit_type_code?: InputMaybe<Order_By>;
  weight?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Visits_Min_Fields = {
  __typename?: 'visits_min_fields';
  additional_notes?: Maybe<Scalars['String']>;
  allergies?: Maybe<Scalars['String']>;
  birth_sex?: Maybe<Scalars['String']>;
  body_temperature?: Maybe<Scalars['numeric']>;
  conditions?: Maybe<Scalars['String']>;
  consumer_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  date?: Maybe<Scalars['timestamptz']>;
  date_of_birth?: Maybe<Scalars['date']>;
  date_of_documentation?: Maybe<Scalars['date']>;
  date_of_service?: Maybe<Scalars['date']>;
  diastolic_pressure?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  facilitator_id?: Maybe<Scalars['Int']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  language?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  location_id?: Maybe<Scalars['Int']>;
  medications?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['Int']>;
  patient_id?: Maybe<Scalars['String']>;
  pharmacy_address?: Maybe<Scalars['String']>;
  pharmacy_address2?: Maybe<Scalars['String']>;
  pharmacy_city?: Maybe<Scalars['String']>;
  pharmacy_latitude?: Maybe<Scalars['numeric']>;
  pharmacy_longitude?: Maybe<Scalars['numeric']>;
  pharmacy_name?: Maybe<Scalars['String']>;
  pharmacy_state?: Maybe<Scalars['String']>;
  pharmacy_zipcode?: Maybe<Scalars['String']>;
  place_of_service_id?: Maybe<Scalars['Int']>;
  provider_id?: Maybe<Scalars['Int']>;
  pulse_ox?: Maybe<Scalars['Int']>;
  pulse_rate?: Maybe<Scalars['Int']>;
  respiration_rate?: Maybe<Scalars['Int']>;
  session_id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  student_id?: Maybe<Scalars['String']>;
  systolic_pressure?: Maybe<Scalars['Int']>;
  test_admin_id?: Maybe<Scalars['String']>;
  test_scheduler_id?: Maybe<Scalars['String']>;
  total_time?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  visit_reason?: Maybe<Scalars['String']>;
  visit_type_code?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "visits" */
export type Visits_Min_Order_By = {
  additional_notes?: InputMaybe<Order_By>;
  allergies?: InputMaybe<Order_By>;
  birth_sex?: InputMaybe<Order_By>;
  body_temperature?: InputMaybe<Order_By>;
  conditions?: InputMaybe<Order_By>;
  consumer_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  date_of_birth?: InputMaybe<Order_By>;
  date_of_documentation?: InputMaybe<Order_By>;
  date_of_service?: InputMaybe<Order_By>;
  diastolic_pressure?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  facilitator_id?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  language?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  location_id?: InputMaybe<Order_By>;
  medications?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  patient_id?: InputMaybe<Order_By>;
  pharmacy_address?: InputMaybe<Order_By>;
  pharmacy_address2?: InputMaybe<Order_By>;
  pharmacy_city?: InputMaybe<Order_By>;
  pharmacy_latitude?: InputMaybe<Order_By>;
  pharmacy_longitude?: InputMaybe<Order_By>;
  pharmacy_name?: InputMaybe<Order_By>;
  pharmacy_state?: InputMaybe<Order_By>;
  pharmacy_zipcode?: InputMaybe<Order_By>;
  place_of_service_id?: InputMaybe<Order_By>;
  provider_id?: InputMaybe<Order_By>;
  pulse_ox?: InputMaybe<Order_By>;
  pulse_rate?: InputMaybe<Order_By>;
  respiration_rate?: InputMaybe<Order_By>;
  session_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  student_id?: InputMaybe<Order_By>;
  systolic_pressure?: InputMaybe<Order_By>;
  test_admin_id?: InputMaybe<Order_By>;
  test_scheduler_id?: InputMaybe<Order_By>;
  total_time?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  visit_reason?: InputMaybe<Order_By>;
  visit_type_code?: InputMaybe<Order_By>;
  weight?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "visits" */
export type Visits_Mutation_Response = {
  __typename?: 'visits_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Visits>;
};

/** input type for inserting object relation for remote table "visits" */
export type Visits_Obj_Rel_Insert_Input = {
  data: Visits_Insert_Input;
  /** on conflict condition */
  on_conflict?: InputMaybe<Visits_On_Conflict>;
};

/** on conflict condition type for table "visits" */
export type Visits_On_Conflict = {
  constraint: Visits_Constraint;
  update_columns?: Array<Visits_Update_Column>;
  where?: InputMaybe<Visits_Bool_Exp>;
};

/** Ordering options when selecting data from "visits". */
export type Visits_Order_By = {
  additional_notes?: InputMaybe<Order_By>;
  allergies?: InputMaybe<Order_By>;
  birth_sex?: InputMaybe<Order_By>;
  body_temperature?: InputMaybe<Order_By>;
  can_email?: InputMaybe<Order_By>;
  clearance_note?: InputMaybe<Clearance_Notes_Order_By>;
  conditions?: InputMaybe<Order_By>;
  consumer_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  date_of_birth?: InputMaybe<Order_By>;
  date_of_documentation?: InputMaybe<Order_By>;
  date_of_service?: InputMaybe<Order_By>;
  diastolic_pressure?: InputMaybe<Order_By>;
  discharge?: InputMaybe<Discharges_Order_By>;
  email?: InputMaybe<Order_By>;
  facilitator?: InputMaybe<Users_Order_By>;
  facilitator_completed?: InputMaybe<Order_By>;
  facilitator_id?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  has_uil_printed?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_deleted?: InputMaybe<Order_By>;
  language?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  location?: InputMaybe<Organizations_Locations_Order_By>;
  location_id?: InputMaybe<Order_By>;
  medication_orders_aggregate?: InputMaybe<Medication_Orders_Aggregate_Order_By>;
  medications?: InputMaybe<Order_By>;
  orders_aggregate?: InputMaybe<Orders_Aggregate_Order_By>;
  organization?: InputMaybe<Organizations_Organizations_Order_By>;
  organization_id?: InputMaybe<Order_By>;
  patient_id?: InputMaybe<Order_By>;
  pharmacy_address?: InputMaybe<Order_By>;
  pharmacy_address2?: InputMaybe<Order_By>;
  pharmacy_city?: InputMaybe<Order_By>;
  pharmacy_latitude?: InputMaybe<Order_By>;
  pharmacy_longitude?: InputMaybe<Order_By>;
  pharmacy_name?: InputMaybe<Order_By>;
  pharmacy_state?: InputMaybe<Order_By>;
  pharmacy_zipcode?: InputMaybe<Order_By>;
  place_of_service_id?: InputMaybe<Order_By>;
  provider?: InputMaybe<Users_Order_By>;
  provider_completed?: InputMaybe<Order_By>;
  provider_id?: InputMaybe<Order_By>;
  pulse_ox?: InputMaybe<Order_By>;
  pulse_rate?: InputMaybe<Order_By>;
  respiration_rate?: InputMaybe<Order_By>;
  session?: InputMaybe<Sessions_Order_By>;
  session_id?: InputMaybe<Order_By>;
  session_logs_aggregate?: InputMaybe<Session_Logs_Aggregate_Order_By>;
  station_visit?: InputMaybe<Station_Visits_Order_By>;
  status?: InputMaybe<Order_By>;
  student_id?: InputMaybe<Order_By>;
  systolic_pressure?: InputMaybe<Order_By>;
  test_admin_id?: InputMaybe<Order_By>;
  test_orders_aggregate?: InputMaybe<Test_Orders_Aggregate_Order_By>;
  test_scheduler_id?: InputMaybe<Order_By>;
  total_time?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  visit_metadata?: InputMaybe<Order_By>;
  visit_reason?: InputMaybe<Order_By>;
  visit_type_code?: InputMaybe<Order_By>;
  weight?: InputMaybe<Order_By>;
};

/** primary key columns input for table: visits */
export type Visits_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Visits_Prepend_Input = {
  visit_metadata?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "visits" */
export enum Visits_Select_Column {
  /** column name */
  AdditionalNotes = 'additional_notes',
  /** column name */
  Allergies = 'allergies',
  /** column name */
  BirthSex = 'birth_sex',
  /** column name */
  BodyTemperature = 'body_temperature',
  /** column name */
  CanEmail = 'can_email',
  /** column name */
  Conditions = 'conditions',
  /** column name */
  ConsumerId = 'consumer_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Date = 'date',
  /** column name */
  DateOfBirth = 'date_of_birth',
  /** column name */
  DateOfDocumentation = 'date_of_documentation',
  /** column name */
  DateOfService = 'date_of_service',
  /** column name */
  DiastolicPressure = 'diastolic_pressure',
  /** column name */
  Email = 'email',
  /** column name */
  FacilitatorCompleted = 'facilitator_completed',
  /** column name */
  FacilitatorId = 'facilitator_id',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  HasUilPrinted = 'has_uil_printed',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  Language = 'language',
  /** column name */
  LastName = 'last_name',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  Medications = 'medications',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  PatientId = 'patient_id',
  /** column name */
  PharmacyAddress = 'pharmacy_address',
  /** column name */
  PharmacyAddress2 = 'pharmacy_address2',
  /** column name */
  PharmacyCity = 'pharmacy_city',
  /** column name */
  PharmacyLatitude = 'pharmacy_latitude',
  /** column name */
  PharmacyLongitude = 'pharmacy_longitude',
  /** column name */
  PharmacyName = 'pharmacy_name',
  /** column name */
  PharmacyState = 'pharmacy_state',
  /** column name */
  PharmacyZipcode = 'pharmacy_zipcode',
  /** column name */
  PlaceOfServiceId = 'place_of_service_id',
  /** column name */
  ProviderCompleted = 'provider_completed',
  /** column name */
  ProviderId = 'provider_id',
  /** column name */
  PulseOx = 'pulse_ox',
  /** column name */
  PulseRate = 'pulse_rate',
  /** column name */
  RespirationRate = 'respiration_rate',
  /** column name */
  SessionId = 'session_id',
  /** column name */
  Status = 'status',
  /** column name */
  StudentId = 'student_id',
  /** column name */
  SystolicPressure = 'systolic_pressure',
  /** column name */
  TestAdminId = 'test_admin_id',
  /** column name */
  TestSchedulerId = 'test_scheduler_id',
  /** column name */
  TotalTime = 'total_time',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VisitMetadata = 'visit_metadata',
  /** column name */
  VisitReason = 'visit_reason',
  /** column name */
  VisitTypeCode = 'visit_type_code',
  /** column name */
  Weight = 'weight'
}

/** input type for updating data in table "visits" */
export type Visits_Set_Input = {
  additional_notes?: InputMaybe<Scalars['String']>;
  allergies?: InputMaybe<Scalars['String']>;
  birth_sex?: InputMaybe<Scalars['String']>;
  body_temperature?: InputMaybe<Scalars['numeric']>;
  can_email?: InputMaybe<Scalars['Boolean']>;
  conditions?: InputMaybe<Scalars['String']>;
  consumer_id?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  date?: InputMaybe<Scalars['timestamptz']>;
  date_of_birth?: InputMaybe<Scalars['date']>;
  date_of_documentation?: InputMaybe<Scalars['date']>;
  date_of_service?: InputMaybe<Scalars['date']>;
  diastolic_pressure?: InputMaybe<Scalars['Int']>;
  email?: InputMaybe<Scalars['String']>;
  facilitator_completed?: InputMaybe<Scalars['Boolean']>;
  facilitator_id?: InputMaybe<Scalars['Int']>;
  first_name?: InputMaybe<Scalars['String']>;
  has_uil_printed?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  is_deleted?: InputMaybe<Scalars['Boolean']>;
  language?: InputMaybe<Scalars['String']>;
  last_name?: InputMaybe<Scalars['String']>;
  location_id?: InputMaybe<Scalars['Int']>;
  medications?: InputMaybe<Scalars['String']>;
  organization_id?: InputMaybe<Scalars['Int']>;
  patient_id?: InputMaybe<Scalars['String']>;
  pharmacy_address?: InputMaybe<Scalars['String']>;
  pharmacy_address2?: InputMaybe<Scalars['String']>;
  pharmacy_city?: InputMaybe<Scalars['String']>;
  pharmacy_latitude?: InputMaybe<Scalars['numeric']>;
  pharmacy_longitude?: InputMaybe<Scalars['numeric']>;
  pharmacy_name?: InputMaybe<Scalars['String']>;
  pharmacy_state?: InputMaybe<Scalars['String']>;
  pharmacy_zipcode?: InputMaybe<Scalars['String']>;
  place_of_service_id?: InputMaybe<Scalars['Int']>;
  provider_completed?: InputMaybe<Scalars['Boolean']>;
  provider_id?: InputMaybe<Scalars['Int']>;
  pulse_ox?: InputMaybe<Scalars['Int']>;
  pulse_rate?: InputMaybe<Scalars['Int']>;
  respiration_rate?: InputMaybe<Scalars['Int']>;
  session_id?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
  student_id?: InputMaybe<Scalars['String']>;
  systolic_pressure?: InputMaybe<Scalars['Int']>;
  test_admin_id?: InputMaybe<Scalars['String']>;
  test_scheduler_id?: InputMaybe<Scalars['String']>;
  total_time?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  visit_metadata?: InputMaybe<Scalars['jsonb']>;
  visit_reason?: InputMaybe<Scalars['String']>;
  visit_type_code?: InputMaybe<Scalars['String']>;
  weight?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Visits_Stddev_Fields = {
  __typename?: 'visits_stddev_fields';
  body_temperature?: Maybe<Scalars['Float']>;
  diastolic_pressure?: Maybe<Scalars['Float']>;
  facilitator_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
  organization_id?: Maybe<Scalars['Float']>;
  pharmacy_latitude?: Maybe<Scalars['Float']>;
  pharmacy_longitude?: Maybe<Scalars['Float']>;
  place_of_service_id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
  pulse_ox?: Maybe<Scalars['Float']>;
  pulse_rate?: Maybe<Scalars['Float']>;
  respiration_rate?: Maybe<Scalars['Float']>;
  session_id?: Maybe<Scalars['Float']>;
  systolic_pressure?: Maybe<Scalars['Float']>;
  total_time?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "visits" */
export type Visits_Stddev_Order_By = {
  body_temperature?: InputMaybe<Order_By>;
  diastolic_pressure?: InputMaybe<Order_By>;
  facilitator_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  location_id?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  pharmacy_latitude?: InputMaybe<Order_By>;
  pharmacy_longitude?: InputMaybe<Order_By>;
  place_of_service_id?: InputMaybe<Order_By>;
  provider_id?: InputMaybe<Order_By>;
  pulse_ox?: InputMaybe<Order_By>;
  pulse_rate?: InputMaybe<Order_By>;
  respiration_rate?: InputMaybe<Order_By>;
  session_id?: InputMaybe<Order_By>;
  systolic_pressure?: InputMaybe<Order_By>;
  total_time?: InputMaybe<Order_By>;
  weight?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Visits_Stddev_Pop_Fields = {
  __typename?: 'visits_stddev_pop_fields';
  body_temperature?: Maybe<Scalars['Float']>;
  diastolic_pressure?: Maybe<Scalars['Float']>;
  facilitator_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
  organization_id?: Maybe<Scalars['Float']>;
  pharmacy_latitude?: Maybe<Scalars['Float']>;
  pharmacy_longitude?: Maybe<Scalars['Float']>;
  place_of_service_id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
  pulse_ox?: Maybe<Scalars['Float']>;
  pulse_rate?: Maybe<Scalars['Float']>;
  respiration_rate?: Maybe<Scalars['Float']>;
  session_id?: Maybe<Scalars['Float']>;
  systolic_pressure?: Maybe<Scalars['Float']>;
  total_time?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "visits" */
export type Visits_Stddev_Pop_Order_By = {
  body_temperature?: InputMaybe<Order_By>;
  diastolic_pressure?: InputMaybe<Order_By>;
  facilitator_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  location_id?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  pharmacy_latitude?: InputMaybe<Order_By>;
  pharmacy_longitude?: InputMaybe<Order_By>;
  place_of_service_id?: InputMaybe<Order_By>;
  provider_id?: InputMaybe<Order_By>;
  pulse_ox?: InputMaybe<Order_By>;
  pulse_rate?: InputMaybe<Order_By>;
  respiration_rate?: InputMaybe<Order_By>;
  session_id?: InputMaybe<Order_By>;
  systolic_pressure?: InputMaybe<Order_By>;
  total_time?: InputMaybe<Order_By>;
  weight?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Visits_Stddev_Samp_Fields = {
  __typename?: 'visits_stddev_samp_fields';
  body_temperature?: Maybe<Scalars['Float']>;
  diastolic_pressure?: Maybe<Scalars['Float']>;
  facilitator_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
  organization_id?: Maybe<Scalars['Float']>;
  pharmacy_latitude?: Maybe<Scalars['Float']>;
  pharmacy_longitude?: Maybe<Scalars['Float']>;
  place_of_service_id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
  pulse_ox?: Maybe<Scalars['Float']>;
  pulse_rate?: Maybe<Scalars['Float']>;
  respiration_rate?: Maybe<Scalars['Float']>;
  session_id?: Maybe<Scalars['Float']>;
  systolic_pressure?: Maybe<Scalars['Float']>;
  total_time?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "visits" */
export type Visits_Stddev_Samp_Order_By = {
  body_temperature?: InputMaybe<Order_By>;
  diastolic_pressure?: InputMaybe<Order_By>;
  facilitator_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  location_id?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  pharmacy_latitude?: InputMaybe<Order_By>;
  pharmacy_longitude?: InputMaybe<Order_By>;
  place_of_service_id?: InputMaybe<Order_By>;
  provider_id?: InputMaybe<Order_By>;
  pulse_ox?: InputMaybe<Order_By>;
  pulse_rate?: InputMaybe<Order_By>;
  respiration_rate?: InputMaybe<Order_By>;
  session_id?: InputMaybe<Order_By>;
  systolic_pressure?: InputMaybe<Order_By>;
  total_time?: InputMaybe<Order_By>;
  weight?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Visits_Sum_Fields = {
  __typename?: 'visits_sum_fields';
  body_temperature?: Maybe<Scalars['numeric']>;
  diastolic_pressure?: Maybe<Scalars['Int']>;
  facilitator_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  location_id?: Maybe<Scalars['Int']>;
  organization_id?: Maybe<Scalars['Int']>;
  pharmacy_latitude?: Maybe<Scalars['numeric']>;
  pharmacy_longitude?: Maybe<Scalars['numeric']>;
  place_of_service_id?: Maybe<Scalars['Int']>;
  provider_id?: Maybe<Scalars['Int']>;
  pulse_ox?: Maybe<Scalars['Int']>;
  pulse_rate?: Maybe<Scalars['Int']>;
  respiration_rate?: Maybe<Scalars['Int']>;
  session_id?: Maybe<Scalars['Int']>;
  systolic_pressure?: Maybe<Scalars['Int']>;
  total_time?: Maybe<Scalars['Int']>;
  weight?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "visits" */
export type Visits_Sum_Order_By = {
  body_temperature?: InputMaybe<Order_By>;
  diastolic_pressure?: InputMaybe<Order_By>;
  facilitator_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  location_id?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  pharmacy_latitude?: InputMaybe<Order_By>;
  pharmacy_longitude?: InputMaybe<Order_By>;
  place_of_service_id?: InputMaybe<Order_By>;
  provider_id?: InputMaybe<Order_By>;
  pulse_ox?: InputMaybe<Order_By>;
  pulse_rate?: InputMaybe<Order_By>;
  respiration_rate?: InputMaybe<Order_By>;
  session_id?: InputMaybe<Order_By>;
  systolic_pressure?: InputMaybe<Order_By>;
  total_time?: InputMaybe<Order_By>;
  weight?: InputMaybe<Order_By>;
};

/** update columns of table "visits" */
export enum Visits_Update_Column {
  /** column name */
  AdditionalNotes = 'additional_notes',
  /** column name */
  Allergies = 'allergies',
  /** column name */
  BirthSex = 'birth_sex',
  /** column name */
  BodyTemperature = 'body_temperature',
  /** column name */
  CanEmail = 'can_email',
  /** column name */
  Conditions = 'conditions',
  /** column name */
  ConsumerId = 'consumer_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Date = 'date',
  /** column name */
  DateOfBirth = 'date_of_birth',
  /** column name */
  DateOfDocumentation = 'date_of_documentation',
  /** column name */
  DateOfService = 'date_of_service',
  /** column name */
  DiastolicPressure = 'diastolic_pressure',
  /** column name */
  Email = 'email',
  /** column name */
  FacilitatorCompleted = 'facilitator_completed',
  /** column name */
  FacilitatorId = 'facilitator_id',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  HasUilPrinted = 'has_uil_printed',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  Language = 'language',
  /** column name */
  LastName = 'last_name',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  Medications = 'medications',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  PatientId = 'patient_id',
  /** column name */
  PharmacyAddress = 'pharmacy_address',
  /** column name */
  PharmacyAddress2 = 'pharmacy_address2',
  /** column name */
  PharmacyCity = 'pharmacy_city',
  /** column name */
  PharmacyLatitude = 'pharmacy_latitude',
  /** column name */
  PharmacyLongitude = 'pharmacy_longitude',
  /** column name */
  PharmacyName = 'pharmacy_name',
  /** column name */
  PharmacyState = 'pharmacy_state',
  /** column name */
  PharmacyZipcode = 'pharmacy_zipcode',
  /** column name */
  PlaceOfServiceId = 'place_of_service_id',
  /** column name */
  ProviderCompleted = 'provider_completed',
  /** column name */
  ProviderId = 'provider_id',
  /** column name */
  PulseOx = 'pulse_ox',
  /** column name */
  PulseRate = 'pulse_rate',
  /** column name */
  RespirationRate = 'respiration_rate',
  /** column name */
  SessionId = 'session_id',
  /** column name */
  Status = 'status',
  /** column name */
  StudentId = 'student_id',
  /** column name */
  SystolicPressure = 'systolic_pressure',
  /** column name */
  TestAdminId = 'test_admin_id',
  /** column name */
  TestSchedulerId = 'test_scheduler_id',
  /** column name */
  TotalTime = 'total_time',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VisitMetadata = 'visit_metadata',
  /** column name */
  VisitReason = 'visit_reason',
  /** column name */
  VisitTypeCode = 'visit_type_code',
  /** column name */
  Weight = 'weight'
}

/** aggregate var_pop on columns */
export type Visits_Var_Pop_Fields = {
  __typename?: 'visits_var_pop_fields';
  body_temperature?: Maybe<Scalars['Float']>;
  diastolic_pressure?: Maybe<Scalars['Float']>;
  facilitator_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
  organization_id?: Maybe<Scalars['Float']>;
  pharmacy_latitude?: Maybe<Scalars['Float']>;
  pharmacy_longitude?: Maybe<Scalars['Float']>;
  place_of_service_id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
  pulse_ox?: Maybe<Scalars['Float']>;
  pulse_rate?: Maybe<Scalars['Float']>;
  respiration_rate?: Maybe<Scalars['Float']>;
  session_id?: Maybe<Scalars['Float']>;
  systolic_pressure?: Maybe<Scalars['Float']>;
  total_time?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "visits" */
export type Visits_Var_Pop_Order_By = {
  body_temperature?: InputMaybe<Order_By>;
  diastolic_pressure?: InputMaybe<Order_By>;
  facilitator_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  location_id?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  pharmacy_latitude?: InputMaybe<Order_By>;
  pharmacy_longitude?: InputMaybe<Order_By>;
  place_of_service_id?: InputMaybe<Order_By>;
  provider_id?: InputMaybe<Order_By>;
  pulse_ox?: InputMaybe<Order_By>;
  pulse_rate?: InputMaybe<Order_By>;
  respiration_rate?: InputMaybe<Order_By>;
  session_id?: InputMaybe<Order_By>;
  systolic_pressure?: InputMaybe<Order_By>;
  total_time?: InputMaybe<Order_By>;
  weight?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Visits_Var_Samp_Fields = {
  __typename?: 'visits_var_samp_fields';
  body_temperature?: Maybe<Scalars['Float']>;
  diastolic_pressure?: Maybe<Scalars['Float']>;
  facilitator_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
  organization_id?: Maybe<Scalars['Float']>;
  pharmacy_latitude?: Maybe<Scalars['Float']>;
  pharmacy_longitude?: Maybe<Scalars['Float']>;
  place_of_service_id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
  pulse_ox?: Maybe<Scalars['Float']>;
  pulse_rate?: Maybe<Scalars['Float']>;
  respiration_rate?: Maybe<Scalars['Float']>;
  session_id?: Maybe<Scalars['Float']>;
  systolic_pressure?: Maybe<Scalars['Float']>;
  total_time?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "visits" */
export type Visits_Var_Samp_Order_By = {
  body_temperature?: InputMaybe<Order_By>;
  diastolic_pressure?: InputMaybe<Order_By>;
  facilitator_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  location_id?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  pharmacy_latitude?: InputMaybe<Order_By>;
  pharmacy_longitude?: InputMaybe<Order_By>;
  place_of_service_id?: InputMaybe<Order_By>;
  provider_id?: InputMaybe<Order_By>;
  pulse_ox?: InputMaybe<Order_By>;
  pulse_rate?: InputMaybe<Order_By>;
  respiration_rate?: InputMaybe<Order_By>;
  session_id?: InputMaybe<Order_By>;
  systolic_pressure?: InputMaybe<Order_By>;
  total_time?: InputMaybe<Order_By>;
  weight?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Visits_Variance_Fields = {
  __typename?: 'visits_variance_fields';
  body_temperature?: Maybe<Scalars['Float']>;
  diastolic_pressure?: Maybe<Scalars['Float']>;
  facilitator_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
  organization_id?: Maybe<Scalars['Float']>;
  pharmacy_latitude?: Maybe<Scalars['Float']>;
  pharmacy_longitude?: Maybe<Scalars['Float']>;
  place_of_service_id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
  pulse_ox?: Maybe<Scalars['Float']>;
  pulse_rate?: Maybe<Scalars['Float']>;
  respiration_rate?: Maybe<Scalars['Float']>;
  session_id?: Maybe<Scalars['Float']>;
  systolic_pressure?: Maybe<Scalars['Float']>;
  total_time?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "visits" */
export type Visits_Variance_Order_By = {
  body_temperature?: InputMaybe<Order_By>;
  diastolic_pressure?: InputMaybe<Order_By>;
  facilitator_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  location_id?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  pharmacy_latitude?: InputMaybe<Order_By>;
  pharmacy_longitude?: InputMaybe<Order_By>;
  place_of_service_id?: InputMaybe<Order_By>;
  provider_id?: InputMaybe<Order_By>;
  pulse_ox?: InputMaybe<Order_By>;
  pulse_rate?: InputMaybe<Order_By>;
  respiration_rate?: InputMaybe<Order_By>;
  session_id?: InputMaybe<Order_By>;
  systolic_pressure?: InputMaybe<Order_By>;
  total_time?: InputMaybe<Order_By>;
  weight?: InputMaybe<Order_By>;
};

export type GetFormQueryVariables = Exact<{
  type: Scalars['String'];
  patientId: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
}>;


export type GetFormQuery = { __typename?: 'query_root', forms_by_pk?: { __typename?: 'forms', data: any } | null, form_types_by_pk?: { __typename?: 'form_types', schema: any, ui_schema?: any | null, form_localizations: Array<{ __typename?: 'form_localizations', dictionary: any }> } | null };

export type InsertFormMutationVariables = Exact<{
  patientId: Scalars['String'];
  type: Scalars['String'];
  data: Scalars['jsonb'];
}>;


export type InsertFormMutation = { __typename?: 'mutation_root', insert_forms_one?: { __typename?: 'forms', data: any } | null };

export type UpdateFormMutationVariables = Exact<{
  patientId: Scalars['String'];
  type: Scalars['String'];
  data: Scalars['jsonb'];
}>;


export type UpdateFormMutation = { __typename?: 'mutation_root', update_forms_by_pk?: { __typename?: 'forms', data: any } | null };

export type GetLocationsQueryVariables = Exact<{
  organization_id?: InputMaybe<Scalars['Int']>;
}>;


export type GetLocationsQuery = { __typename?: 'query_root', locations: Array<{ __typename?: 'organizations_locations', id: number, name: string, organization_id: number }> };

export type GetOrganizationsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetOrganizationsQuery = { __typename?: 'query_root', organizations: Array<{ __typename?: 'organizations_organizations', id: number, name: string, state: string, slug?: string | null, is_active?: boolean | null }> };

export type GetOrganizationBySlugQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type GetOrganizationBySlugQuery = { __typename?: 'query_root', organizations: Array<{ __typename?: 'organizations_organizations', slug?: string | null, id: number, name: string }> };

export type GetOrganizationByIdQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetOrganizationByIdQuery = { __typename?: 'query_root', organization?: { __typename?: 'organizations_organizations', id: number, is_active?: boolean | null, name: string, slug?: string | null, state: string } | null };

export type GetFeatureQueryVariables = Exact<{
  organization_id: Scalars['Int'];
  feature_id: Scalars['String'];
}>;


export type GetFeatureQuery = { __typename?: 'query_root', organizations_organizations_features_by_pk?: { __typename?: 'organizations_organizations_features', feature_id: string } | null };

export type GetPatientFormsQueryVariables = Exact<{
  patientId: Scalars['String'];
}>;


export type GetPatientFormsQuery = { __typename?: 'query_root', form_types: Array<{ __typename?: 'form_types', id: string }>, forms: Array<{ __typename?: 'forms', type: string }> };

export type GetVideoCallUrlByVisitIdQueryVariables = Exact<{
  visit_id: Scalars['Int'];
}>;


export type GetVideoCallUrlByVisitIdQuery = { __typename?: 'query_root', getVideo?: { __typename?: 'Video', videoUrl: string } | null };

export type RequestVisitMutationVariables = Exact<{
  visit: Sessions_Insert_Input;
}>;


export type RequestVisitMutation = { __typename?: 'mutation_root', insert_sessions_one?: { __typename?: 'sessions', visits: Array<{ __typename?: 'visits', id: number }> } | null };

export type VisitStatusByIdQueryVariables = Exact<{
  visit_id: Scalars['Int'];
}>;


export type VisitStatusByIdQuery = { __typename?: 'query_root', visits: Array<{ __typename?: 'visits', id: number, status: string }> };

export type EndVisitMutationVariables = Exact<{
  visitId: Scalars['Int'];
}>;


export type EndVisitMutation = { __typename?: 'mutation_root', update_visits_by_pk?: { __typename?: 'visits', id: number } | null };


export const GetFormDocument = `
    query getForm($type: String!, $patientId: String!, $locale: String = "en") {
  forms_by_pk(patient_id: $patientId, type: $type) {
    data
  }
  form_types_by_pk(id: $type) {
    schema
    ui_schema
    form_localizations(where: {locale: {_eq: $locale}}) {
      dictionary
    }
  }
}
    `;
export const useGetFormQuery = <
      TData = GetFormQuery,
      TError = unknown
    >(
      variables: GetFormQueryVariables,
      options?: UseQueryOptions<GetFormQuery, TError, TData>
    ) =>
    useQuery<GetFormQuery, TError, TData>(
      ['getForm', variables],
      useFetch<GetFormQuery, GetFormQueryVariables>(GetFormDocument).bind(null, variables),
      options
    );
export const InsertFormDocument = `
    mutation insertForm($patientId: String!, $type: String!, $data: jsonb!) {
  insert_forms_one(object: {data: $data, patient_id: $patientId, type: $type}) {
    data
  }
}
    `;
export const useInsertFormMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<InsertFormMutation, TError, InsertFormMutationVariables, TContext>) =>
    useMutation<InsertFormMutation, TError, InsertFormMutationVariables, TContext>(
      ['insertForm'],
      useFetch<InsertFormMutation, InsertFormMutationVariables>(InsertFormDocument),
      options
    );
export const UpdateFormDocument = `
    mutation updateForm($patientId: String!, $type: String!, $data: jsonb!) {
  update_forms_by_pk(
    pk_columns: {patient_id: $patientId, type: $type}
    _set: {data: $data}
  ) {
    data
  }
}
    `;
export const useUpdateFormMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateFormMutation, TError, UpdateFormMutationVariables, TContext>) =>
    useMutation<UpdateFormMutation, TError, UpdateFormMutationVariables, TContext>(
      ['updateForm'],
      useFetch<UpdateFormMutation, UpdateFormMutationVariables>(UpdateFormDocument),
      options
    );
export const GetLocationsDocument = `
    query getLocations($organization_id: Int) {
  locations: organizations_locations(
    where: {organization_id: {_eq: $organization_id}}
    order_by: {name: asc}
  ) {
    id
    name
    organization_id
  }
}
    `;
export const useGetLocationsQuery = <
      TData = GetLocationsQuery,
      TError = unknown
    >(
      variables?: GetLocationsQueryVariables,
      options?: UseQueryOptions<GetLocationsQuery, TError, TData>
    ) =>
    useQuery<GetLocationsQuery, TError, TData>(
      variables === undefined ? ['getLocations'] : ['getLocations', variables],
      useFetch<GetLocationsQuery, GetLocationsQueryVariables>(GetLocationsDocument).bind(null, variables),
      options
    );
export const GetOrganizationsDocument = `
    query getOrganizations {
  organizations: organizations_organizations {
    id
    name
    state
    slug
    is_active
  }
}
    `;
export const useGetOrganizationsQuery = <
      TData = GetOrganizationsQuery,
      TError = unknown
    >(
      variables?: GetOrganizationsQueryVariables,
      options?: UseQueryOptions<GetOrganizationsQuery, TError, TData>
    ) =>
    useQuery<GetOrganizationsQuery, TError, TData>(
      variables === undefined ? ['getOrganizations'] : ['getOrganizations', variables],
      useFetch<GetOrganizationsQuery, GetOrganizationsQueryVariables>(GetOrganizationsDocument).bind(null, variables),
      options
    );
export const GetOrganizationBySlugDocument = `
    query getOrganizationBySlug($slug: String!) {
  organizations: organizations_organizations(
    distinct_on: id
    limit: 1
    where: {slug: {_eq: $slug}}
  ) {
    slug
    id
    name
  }
}
    `;
export const useGetOrganizationBySlugQuery = <
      TData = GetOrganizationBySlugQuery,
      TError = unknown
    >(
      variables: GetOrganizationBySlugQueryVariables,
      options?: UseQueryOptions<GetOrganizationBySlugQuery, TError, TData>
    ) =>
    useQuery<GetOrganizationBySlugQuery, TError, TData>(
      ['getOrganizationBySlug', variables],
      useFetch<GetOrganizationBySlugQuery, GetOrganizationBySlugQueryVariables>(GetOrganizationBySlugDocument).bind(null, variables),
      options
    );
export const GetOrganizationByIdDocument = `
    query getOrganizationById($id: Int!) {
  organization: organizations_organizations_by_pk(id: $id) {
    id
    is_active
    name
    slug
    state
  }
}
    `;
export const useGetOrganizationByIdQuery = <
      TData = GetOrganizationByIdQuery,
      TError = unknown
    >(
      variables: GetOrganizationByIdQueryVariables,
      options?: UseQueryOptions<GetOrganizationByIdQuery, TError, TData>
    ) =>
    useQuery<GetOrganizationByIdQuery, TError, TData>(
      ['getOrganizationById', variables],
      useFetch<GetOrganizationByIdQuery, GetOrganizationByIdQueryVariables>(GetOrganizationByIdDocument).bind(null, variables),
      options
    );
export const GetFeatureDocument = `
    query getFeature($organization_id: Int!, $feature_id: String!) {
  organizations_organizations_features_by_pk(
    organization_id: $organization_id
    feature_id: $feature_id
  ) {
    feature_id
  }
}
    `;
export const useGetFeatureQuery = <
      TData = GetFeatureQuery,
      TError = unknown
    >(
      variables: GetFeatureQueryVariables,
      options?: UseQueryOptions<GetFeatureQuery, TError, TData>
    ) =>
    useQuery<GetFeatureQuery, TError, TData>(
      ['getFeature', variables],
      useFetch<GetFeatureQuery, GetFeatureQueryVariables>(GetFeatureDocument).bind(null, variables),
      options
    );
export const GetPatientFormsDocument = `
    query getPatientForms($patientId: String!) {
  form_types(where: {on_registration: {_eq: true}}) {
    id
  }
  forms(
    where: {_and: {patient_id: {_eq: $patientId}, form_type: {on_registration: {_eq: true}}}}
  ) {
    type
  }
}
    `;
export const useGetPatientFormsQuery = <
      TData = GetPatientFormsQuery,
      TError = unknown
    >(
      variables: GetPatientFormsQueryVariables,
      options?: UseQueryOptions<GetPatientFormsQuery, TError, TData>
    ) =>
    useQuery<GetPatientFormsQuery, TError, TData>(
      ['getPatientForms', variables],
      useFetch<GetPatientFormsQuery, GetPatientFormsQueryVariables>(GetPatientFormsDocument).bind(null, variables),
      options
    );
export const GetVideoCallUrlByVisitIdDocument = `
    query getVideoCallUrlByVisitId($visit_id: Int!) {
  getVideo(visitId: $visit_id) {
    videoUrl
  }
}
    `;
export const useGetVideoCallUrlByVisitIdQuery = <
      TData = GetVideoCallUrlByVisitIdQuery,
      TError = unknown
    >(
      variables: GetVideoCallUrlByVisitIdQueryVariables,
      options?: UseQueryOptions<GetVideoCallUrlByVisitIdQuery, TError, TData>
    ) =>
    useQuery<GetVideoCallUrlByVisitIdQuery, TError, TData>(
      ['getVideoCallUrlByVisitId', variables],
      useFetch<GetVideoCallUrlByVisitIdQuery, GetVideoCallUrlByVisitIdQueryVariables>(GetVideoCallUrlByVisitIdDocument).bind(null, variables),
      options
    );
export const RequestVisitDocument = `
    mutation requestVisit($visit: sessions_insert_input!) {
  insert_sessions_one(object: $visit) {
    visits {
      id
    }
  }
}
    `;
export const useRequestVisitMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<RequestVisitMutation, TError, RequestVisitMutationVariables, TContext>) =>
    useMutation<RequestVisitMutation, TError, RequestVisitMutationVariables, TContext>(
      ['requestVisit'],
      useFetch<RequestVisitMutation, RequestVisitMutationVariables>(RequestVisitDocument),
      options
    );
export const VisitStatusByIdDocument = `
    query visitStatusById($visit_id: Int!) {
  visits(where: {id: {_eq: $visit_id}}) {
    id
    status
  }
}
    `;
export const useVisitStatusByIdQuery = <
      TData = VisitStatusByIdQuery,
      TError = unknown
    >(
      variables: VisitStatusByIdQueryVariables,
      options?: UseQueryOptions<VisitStatusByIdQuery, TError, TData>
    ) =>
    useQuery<VisitStatusByIdQuery, TError, TData>(
      ['visitStatusById', variables],
      useFetch<VisitStatusByIdQuery, VisitStatusByIdQueryVariables>(VisitStatusByIdDocument).bind(null, variables),
      options
    );
export const EndVisitDocument = `
    mutation endVisit($visitId: Int!) {
  update_visits_by_pk(pk_columns: {id: $visitId}, _set: {status: "Completed"}) {
    id
  }
}
    `;
export const useEndVisitMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<EndVisitMutation, TError, EndVisitMutationVariables, TContext>) =>
    useMutation<EndVisitMutation, TError, EndVisitMutationVariables, TContext>(
      ['endVisit'],
      useFetch<EndVisitMutation, EndVisitMutationVariables>(EndVisitDocument),
      options
    );