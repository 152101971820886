export type HeaderLogoProps = { type: 'logo-duck' | 'logo-heading' }

export const HeaderLogo = ({ type }: HeaderLogoProps) =>
  type === 'logo-heading' ? (
    <img
      className="md:w-64 sm:w-36 w-30"
      src="/GSH_PrimaryLogomark.svg"
      alt="Goodside Health Logo"
    />
  ) : (
    <img
      className="w-10"
      src="/logo-duck-white-background.svg"
      alt="Goodside Health Logo"
    />
  )
